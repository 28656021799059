import { useEffect, useRef, useState } from 'react';

import { ElementTypeEnum } from 'src/common/enums';
import { AnalysisElement, ElementSubtype } from 'src/common/types';
import { getDetectionBoxes } from 'src/controller/basics/math';
import { buildClientAnnotations } from 'src/controller/consultation/buildClientAnnotations';
import { buildTeethToSnakeCase } from 'src/controller/consultation/buildTeethToSnakeCase';
import { changeRadiolucencyColors } from 'src/controller/consultation/changeRadiolucencyColors';
import { getIsRetro } from 'src/controller/dentistry/xrayTypeUtils';
import { useAppGetters, useAppStore } from 'src/controller/store';
import { useFetchIdentifications } from 'src/ui/components/Spotimplant/hooks';
import { ToothEdit } from 'src/ui/components/Tooth/types/ToothEdit';
import {
  Annotation,
  EditionMode,
  IntraVisualisationType,
  PanoVisualisationType,
  VisualisationType,
  XrayQuality,
} from 'src/ui/components/XrayAnnotationTool/types';

import { useElementTypes } from '../ElementTypes/useElementTypes';
import { useTreatmentContext } from '../Treatment/useTreatment';
import { useAnnotations } from './useAnnotation';
import { useRetroManager } from './useRetroManager';
import { XRayValues } from './useXRay';

const panoVisualizationTypes: PanoVisualisationType[] = ['periodontic', 'oralStructure'];
const retroVisualizationTypes: IntraVisualisationType[] = [
  'anatomy',
  'retroBoneLevel',
  'retroRuler',
];

export type LockedTooltip = {
  top?: number;
  left?: number;
  annotation?: Annotation;
  isEditor?: boolean;
};

export const useXRayProvider = (): XRayValues => {
  const { locationVersions } = useAppStore();
  const { displayedXray } = useAppGetters();
  const { ce, fda, ukca, commercial } = locationVersions;
  const elementTypes = useElementTypes();

  const {
    submitXRayEdition,
    xrayContainsChanges,
    setXrayContainsChanges,
    fullScreenVisible,
    editionMode,
  } = useTreatmentContext();

  const [cancelChanges, setCancelChanges] = useState(false);
  const [annotationsMultiSelect, setAnnotationsMultiSelect] = useState<Annotation[]>([]);

  const {
    annotations,
    onCreateAnnotation,
    onUpdateAnnotation,
    onUpdateSeveralAnnotations,
    onDeleteAnnotation,
  } = useAnnotations(displayedXray?.elements, cancelChanges);

  const onSave = (teeth?: ToothEdit[]) => {
    if (!displayedXray || !annotations) return;
    setXrayContainsChanges(false);
    const updatedData = getUpdatedData(annotations, elementTypes, !commercial);
    submitXRayEdition(updatedData, buildTeethToSnakeCase(teeth));
  };

  const onUpdateMultiSelectedAnnotations = (updatedMultiSelectedAnnotations: Annotation[]) => {
    if (!displayedXray || !annotations) return;
    onUpdateSeveralAnnotations(updatedMultiSelectedAnnotations);
  };

  // The below useState and useEffect are used for synchronous update of the
  // treatmentPlan in case of specific deletion cases
  const [onSynchronousSave, setOnSynchronousSave] = useState(false);
  useEffect(() => {
    if (onSynchronousSave && (!fullScreenVisible || editionMode === EditionMode.VISUAL)) {
      onSave();
      setOnSynchronousSave(false);
    }
  }, [onSynchronousSave, annotations, fullScreenVisible, editionMode]);

  const [lockedTooltip, setLockedTooltip] = useState<LockedTooltip | null>(null);

  useFetchIdentifications({
    xrayId: displayedXray?.id ?? null,
  });

  const [isTooltipEditor, setIsTooltipEditor] = useState<boolean>(false);
  const [visualizationTypes, setVisualizationTypes] = useState<VisualisationType[]>(['element']);
  const [xrayQuality, setXrayQuality] = useState<XrayQuality>({
    brightness: 0,
    contrast: 0,
    sharpness: 0,
    revert: false,
  });

  const retroManager = useRetroManager();
  const isPreviousXrayRetro = useRef<boolean>(false);

  const excludeVisualizatonTypes = (xrayValidTypes: VisualisationType[]) => {
    if (visualizationTypes.some((vType) => xrayValidTypes.includes(vType))) {
      setVisualizationTypes(visualizationTypes.filter((vType) => !xrayValidTypes.includes(vType)));
    }
  };

  const isCertified = fda || ce || ukca;
  const updatedAnnotations = isCertified ? annotations : buildClientAnnotations(annotations);
  const annotationsColorsUpdated = changeRadiolucencyColors(updatedAnnotations, editionMode);

  // Reset annotationsMultiSelect mode when changing xray
  // Reset xrayQuality when changing xray
  useEffect(() => {
    if (editionMode === EditionMode.IDENTIFY_RADIOLUCENCY) {
      setAnnotationsMultiSelect([]);
    }
    setXrayQuality({ brightness: 0, contrast: 0, sharpness: 0, revert: false });
  }, [displayedXray?.id]);

  // Deactivate visualisation types when xray type has changed
  useEffect(() => {
    const isCurrentXrayRetro = getIsRetro(displayedXray?.type);
    if (isPreviousXrayRetro.current === isCurrentXrayRetro) return;

    if (isPreviousXrayRetro.current) {
      excludeVisualizatonTypes(retroVisualizationTypes);
    } else {
      excludeVisualizatonTypes(panoVisualizationTypes);
    }

    isPreviousXrayRetro.current = isCurrentXrayRetro;
  }, [displayedXray?.type]);

  return {
    setXrayContainsChanges,
    onSave,
    annotations: annotationsColorsUpdated || [],
    onCreateAnnotation,
    onUpdateAnnotation,
    onDeleteAnnotation,
    xrayContainsChanges,
    lockedTooltip,
    setLockedTooltip,
    isTooltipEditor,
    setIsTooltipEditor,
    setOnSynchronousSave,
    cancelChanges,
    setCancelChanges,
    visualizationTypes,
    setVisualizationTypes,
    retroManager,
    onUpdateMultiSelectedAnnotations,
    annotationsMultiSelect,
    setAnnotationsMultiSelect,
    xrayQuality,
    setXrayQuality,
  };
};

const getUpdatedData = (
  annotations: Annotation[],
  elementTypes: ElementSubtype[],
  isCertified: boolean
): AnalysisElement[] => {
  return annotations.map((annotation: Annotation) =>
    transformAnnotationToAnalysisElement(annotation, elementTypes, isCertified)
  );
};

const transformAnnotationToAnalysisElement = (
  annotation: Annotation,
  elementTypes: ElementSubtype[],
  isCertified: boolean
): AnalysisElement => {
  const elementTypeFromDb = elementTypes.find((elem) => elem.id === annotation.type.id);
  const elementTypeBaseFromDb = elementTypes.find(
    (elem) => elem.id === elementTypeFromDb?.fullId
  ) as ElementSubtype;
  const elementType = elementTypeBaseFromDb ?? elementTypeFromDb;
  const elementTypeFull = elementTypeBaseFromDb ? elementTypeFromDb : elementType;
  return {
    id: typeof annotation.id === 'string' ? parseInt(annotation.id) : annotation.id,
    elementType: elementType.name,
    elementTypeFull: elementTypeFull?.name || '',
    detectionBox: getDetectionBoxes(annotation.path),
    detectionPoly: annotation.path,
    detectionScore: annotation.detectionScore ?? 100,
    detectionClass: annotation.detectionScore || 0,
    editByUser: !!annotation.editedByUser,
    xCenter: 0,
    yCenter: 0,
    onTooth: annotation.onTooth ?? [],
    isRadiolucency: !isCertified && !annotation.editedByUser && isRadiolucency(elementType.name),
  };
};

const isRadiolucency = (elementType: string): boolean => {
  //If the annotation is a decay or periapical lesion or a calculus and not identified by the user, it is a "radiolucency"
  return (
    elementType === ElementTypeEnum.DECAY ||
    elementType === ElementTypeEnum.PERIAPICAL_LESION ||
    elementType === ElementTypeEnum.CALCULUS
  );
};
