import { Locale, Role } from 'src/common/enums';
import { getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { useAppStore } from 'src/controller/store';

import { ApiCredentials } from '../types';

export const useGetCredentials = (): ApiCredentials => {
  const { user, currentRole } = useAppStore();
  const authTokens = getAuthTokens();

  const locale = user?.locale || Locale.En;
  const token = authTokens?.access_token || '';
  const role = currentRole || Role.DENTIST;

  return {
    token,
    role,
    locale,
  };
};
