import { useContext } from 'react';

import { ElementSubtype } from 'src/common/types';

import { ElementsTypesContext } from './ElementTypesProvider';

export const useElementTypes = (): ElementSubtype[] => {
  const values = useContext(ElementsTypesContext);

  if (!values)
    throw new Error(`${useElementTypes.name} can only be used in children of ElementsTypesContext`);
  return values;
};
