import { client } from 'src/communication/apollo';
import {
  RegisterEventUserDocument,
  RegisterEventUserMutationVariables,
} from 'src/communication/hasura/generated/graphql';

import { UserEvent } from './UserEvent';

type EventOptions = {
  patientId?: number;
  consultationId?: number;
};

export const registerUserEvent = (
  eventName: RegisterEventUserMutationVariables['eventName'] | UserEvent,
  options: EventOptions
) => {
  return client.mutate({
    mutation: RegisterEventUserDocument,
    variables: {
      eventName,
      patientId: options.patientId,
      consultationId: options.consultationId,
    },
  });
};
