//This modules allow us to convert DB json models (using snake case) to front-end models (using camel case)
import {
  Analysis,
  AnalysisPeriodontics,
  Anatomy as ApiAnatomy,
  DetectionBox,
  ElementSubtype,
  PeriodonticLine,
  PeriodonticSegments,
  Tooth,
  ToothMl,
  Xray,
} from 'src/common/types';
import { RetroSnakeCasePeriodontics } from 'src/common/types/imaging/RetroSnakeCasePeriodontics';
import { getOralStructureType, tempMapTeethCorner } from 'src/controller/dentistry';
import { Anatomy as DbAnatomy } from 'src/ui/components/XrayAnnotationTool/types/Anatomy';
import { DbPeriodonticLine } from 'src/ui/components/XrayAnnotationTool/types/DbPeriodonticLine';

//## 1. Convert camel case to snake case for DB
const getDbParodonticLine = (analysis: Analysis, fullType: string): DbPeriodonticLine[] => {
  if (!analysis?.periodontics?.lines) return []; //Defensive because ML response can be inconsistent

  return analysis.periodontics.lines
    .filter((line) => line.elementTypeFull === fullType)
    .map((line) => ({
      element_type: line.elementType as 'upper_line' | 'lower_line',
      element_type_full: line.elementTypeFull as 'periodontic_line',
      detection_line: line.detectionLine,
    }));
};

const getDbRetroBoneLevels = (analysis: Analysis): RetroSnakeCasePeriodontics[] => {
  if (!analysis?.periodontics?.segments) return []; //Defensive because ML response can be inconsistent

  return analysis.periodontics.segments.map((boneLevel) => ({
    segment: boneLevel.segment.map((segment) => ({
      detection_coords: segment.detectionCoords,
      detection_score: segment.detectionScore,
      element_type: segment.elementType,
      element_type_full: segment.elementTypeFull,
    })),
    on_tooth: boneLevel.onTooth,
  }));
};

const getDbTeeth = (teeth: Tooth[]): ToothMl[] =>
  teeth.map((tooth) => ({
    id: tooth.id,
    detection_boxes: tooth.detectionBox,
    detection_classes: tooth.detectionClass,
    detection_poly: tooth.detectionPoly,
    detection_scores: tooth.detectionScore,
    element_type: tooth.elementType,
    element_type_full: tooth.elementTypeFull,
    detected_as_missing: tooth.spaceForImplant,
    missing_tooth: tooth.missingTooth,
    x_center: tooth.xCenter, //this is used in pdf generator to display teeth numbers
    y_center: tooth.yCenter,
    corner: tempMapTeethCorner(tooth.corner),
  }));

const getDbOralStructures = (analysis: Analysis) =>
  analysis.oralStructures.map(({ elementTypeFull, detectionBox, detectionPoly }) => ({
    type: getOralStructureType(elementTypeFull),
    detectionBox,
    detectionPoly,
  }));

const getDbAnatomies = (analysis: Analysis) =>
  analysis.anatomies.map((anatomy) => ({
    type: anatomy.elementType,
    detectionPoly: anatomy.detectionPoly,
    detectionBox: anatomy.detectionBox,
    onTooth: anatomy.onTooth,
  }));

const getDbElements = (analysis: Analysis, elementTypes: ElementSubtype[]) =>
  analysis.elements.map((element) => ({
    id: element.id || undefined,
    onTooth: element.onTooth,
    coordinates: element.detectionPoly,
    typeId: elementTypes?.find(({ name }) => name === element.elementType)?.id || 0,
    fullId: elementTypes?.find(({ name }) => name === element.elementTypeFull)?.id || 0,
    detectionScore: element.detectionScore,
    editedByUser: element.editByUser,
  }));

export const formatToSnakeCase = (analysis: Analysis, elementTypes: ElementSubtype[]) => ({
  elements: getDbElements(analysis, elementTypes),
  teeth: getDbTeeth(analysis.teeth),
  report: analysis.report,
  anatomies: getDbAnatomies(analysis),
  oralStructures: getDbOralStructures(analysis),
  periodonticLines: getDbParodonticLine(analysis, 'periodontic_line'),
  cejLines: getDbParodonticLine(analysis, 'cementoenamel_junction_line'),
  retroBoneLevels: getDbRetroBoneLevels(analysis),
  centralTeeth: analysis.centralTeeth ?? [],
});

//## 2. Convert snake case to camel case
export const getApiTeeth = (teeth: ToothMl[]): Tooth[] =>
  teeth.map((tooth, index) => ({
    id: index,
    elementType: tooth.element_type,
    elementTypeFull: tooth.element_type_full,
    detectionPoly: tooth.detection_poly,
    detectionBox: tooth.detection_boxes as DetectionBox,
    detectionScore: tooth.detection_scores,
    detectionClass: tooth.detection_classes,
    editByUser: !!tooth.edit_by_user,
    missingTooth: !!tooth.missing_tooth,
    spaceForImplant: !!tooth.detected_as_missing,
    corner: tempMapTeethCorner(tooth.corner),
    xCenter: tooth.x_center as number,
    yCenter: tooth.y_center as number,
    includedTooth: !!tooth.included_tooth,
    ponticTooth: false,
  }));

export const getApiAnatomy = (anatomies: DbAnatomy[]): ApiAnatomy[] =>
  anatomies.map((anatomy) => ({
    id: parseInt(anatomy.id),
    elementType: anatomy.anatomyType.name,
    elementTypeFull: anatomy.anatomyType.name,
    detectionPoly: anatomy.detectionPoly,
    detectionBox: anatomy.detectionBox,
    detectionScore: 100,
    detectionClass: 0,
    editByUser: false,
    xCenter: 0,
    yCenter: 0,
    onTooth: anatomy.onTooth,
  }));

export const getApiPeriodontics = (xray: Xray): AnalysisPeriodontics => {
  return { lines: getPeriodonticLines(xray), segments: getRetroBoneLevels(xray) };
};

const getPeriodonticLines = (xray: Xray): PeriodonticLine[] => {
  //Defensive code because old intraoral xray doesn't have periodontics
  return [...(xray?.detectedCejLines || []), ...(xray?.detectedPeriodonticLines || [])].map(
    (line) => ({
      elementType: line.element_type,
      elementTypeFull: line.element_type_full,
      detectionLine: line.detection_line,
    })
  );
};

export const getRetroBoneLevels = (xray: Xray): PeriodonticSegments[] => {
  //defensive code because old panoramics doesn't have bone levels
  if (!xray.retroBoneLevels) return [];

  return xray.retroBoneLevels
    .filter((boneLevel) => boneLevel?.segment?.length === 2) // boneLevel might be undefined, type issue
    .map((retroSegment) => {
      return {
        onTooth: retroSegment.on_tooth,
        segment: retroSegment.segment.map((segment) => ({
          elementType: segment.element_type,
          elementTypeFull: segment.element_type_full,
          detectionCoords: segment.detection_coords,
          detectionScore: segment.detection_score,
        })),
      };
    }) as PeriodonticSegments[];
};
