import { Button, TableLine } from '@allisone/react-components';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EnrichedBilling } from 'src/common/types';
import { getCurrency } from 'src/controller/basics/getCurrency';
import { useFormatDate } from 'src/controller/basics/useDate';

import { BillingDetailsTooltip } from './BillingDetails/BillingDetailsTooltip';

import styles from './StripeModal.module.scss';

type Props = {
  billing: EnrichedBilling;
  index: number;
  onPay(id: string): void;
};

export const StripeTableLine: FC<Props> = ({ billing, index, onPay }) => {
  const formatDate = useFormatDate();
  const { t } = useTranslation('centrePaymentModal');
  const tableLineBackgroundColor = index % 2 === 0 ? '#18263C' : '#232C3E';

  return (
    <TableLine key={index}>
      <th style={{ background: tableLineBackgroundColor }}>{billing.locationName}</th>
      <td style={{ background: tableLineBackgroundColor }}>{billing.price.productName} </td>
      <td style={{ background: tableLineBackgroundColor }}>{billing.quantity}</td>
      <td style={{ background: tableLineBackgroundColor }}>
        {billing.unitAmountExclTax / 100} {getCurrency(billing.price.currency)}
      </td>
      <td style={{ background: tableLineBackgroundColor }}>
        {(billing.unitAmountExclTax / 100) * billing.quantity} {getCurrency(billing.price.currency)}
      </td>
      <td style={{ background: tableLineBackgroundColor }}>
        {(billing.unitAmountInclTax / 100) * billing.quantity} {getCurrency(billing.price.currency)}
      </td>
      <td style={{ background: tableLineBackgroundColor }}>
        {formatDate(new Date(billing.expectedBillingDate as Date))}
      </td>
      <td style={{ background: tableLineBackgroundColor }}>
        <div className={styles.amountToPay}>
          <span>
            {billing.prorata && billing.prorata.totalInclTax
              ? billing.prorata.totalInclTax / 100
              : 'N/A'}
            {getCurrency(billing.price.currency)}
          </span>
          {!!billing.prorata?.totalInclTax && <BillingDetailsTooltip billing={billing} />}
        </div>
      </td>
      <td style={{ background: tableLineBackgroundColor }}>
        <Button
          testId={'billing-' + billing.id}
          onClick={() => onPay(billing.id)}
          label={t('columns.subscribe')}
        />
      </td>
    </TableLine>
  );
};
