import { FC } from 'react';
import { useAuth } from 'react-oidc-context';

import { useJimo } from 'src/controller/store/user/useJimo';

export const Jimo: FC = () => {
  const auth = useAuth();
  useJimo(auth);
  return null;
};
