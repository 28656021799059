import { useCallback } from 'react';

import { WaitingTreatment } from 'src/common/types';
import {
  GetConsultationDocument,
  useCreateActsMutation,
} from 'src/communication/hasura/generated/graphql';

import { useOperationTypes } from '../consultation/useOperationTypes';

export const useCreateActs = () => {
  const { operationTypes } = useOperationTypes();
  const [createActsMutation] = useCreateActsMutation({
    refetchQueries: [GetConsultationDocument],
  });

  const createActs = useCallback(
    (consultationId: number, treatments: WaitingTreatment[]) => {
      const operations = treatments.map((treatment, order) => {
        const operationType = operationTypes?.find((o) => o.name === treatment.operationType.name);
        if (!operationType) {
          throw new Error('Operation type not found');
        }

        return {
          order,
          consultationId,
          toothNumbers: treatment.toothNumbers,
          pillarTeeth: treatment.pillarTeeth,
          completedAt: null,
          operationTypeId: operationType.id,
          groupId: treatment.groupId,
        };
      });
      return createActsMutation({
        variables: { operations, consultationId },
      });
    },
    [operationTypes]
  );

  return { createActs };
};
