import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  date: string;
  float8: any;
  json: any;
  jsonb: any;
  timestamptz: string;
  uuid: string;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CreateUserInput = {
  centerId: Scalars['Int'];
  contact_email?: InputMaybe<Scalars['String']>;
  dentist_speciality?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  locale?: InputMaybe<Scalars['String']>;
  roles: Array<InputMaybe<Scalars['Int']>>;
  rpps_number?: InputMaybe<Scalars['String']>;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['Int'];
  lastname: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['Float']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['Float']>>;
  _eq?: InputMaybe<Array<Scalars['Float']>>;
  _gt?: InputMaybe<Array<Scalars['Float']>>;
  _gte?: InputMaybe<Array<Scalars['Float']>>;
  _in?: InputMaybe<Array<Array<Scalars['Float']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['Float']>>;
  _lte?: InputMaybe<Array<Scalars['Float']>>;
  _neq?: InputMaybe<Array<Scalars['Float']>>;
  _nin?: InputMaybe<Array<Array<Scalars['Float']>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "allisone_plus_configuration" */
export type Allisone_Plus_Configuration = {
  __typename?: 'allisone_plus_configuration';
  /** An array relationship */
  allisone_plus_clients: Array<Allisone_Plus_Users_Configurations>;
  cpu: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  enabled_modes: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  location: Locations;
  locationId: Scalars['Int'];
  machine_id: Scalars['String'];
  os: Scalars['String'];
  os_language: Maybe<Scalars['String']>;
  os_version: Scalars['String'];
  ram: Maybe<Scalars['Int']>;
  software: Scalars['String'];
  softwareVersion: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "allisone_plus_configuration" */
export type Allisone_Plus_ConfigurationAllisone_Plus_ClientsArgs = {
  distinct_on?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Order_By>>;
  where?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "allisone_plus_configuration". All fields are combined with a logical 'AND'. */
export type Allisone_Plus_Configuration_Bool_Exp = {
  _and?: InputMaybe<Array<Allisone_Plus_Configuration_Bool_Exp>>;
  _not?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
  _or?: InputMaybe<Array<Allisone_Plus_Configuration_Bool_Exp>>;
  allisone_plus_clients?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
  cpu?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled_modes?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  locationId?: InputMaybe<Int_Comparison_Exp>;
  machine_id?: InputMaybe<String_Comparison_Exp>;
  os?: InputMaybe<String_Comparison_Exp>;
  os_language?: InputMaybe<String_Comparison_Exp>;
  os_version?: InputMaybe<String_Comparison_Exp>;
  ram?: InputMaybe<Int_Comparison_Exp>;
  software?: InputMaybe<String_Comparison_Exp>;
  softwareVersion?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "allisone_plus_configuration" */
export enum Allisone_Plus_Configuration_Constraint {
  /** unique or primary key constraint on columns "machine_id" */
  AllisonePlusConfigurationComputerUniqueKeyKey = 'allisone_plus_configuration_computer_unique_key_key',
  /** unique or primary key constraint on columns "id" */
  AllisonePlusConfigurationIdKey = 'allisone_plus_configuration_id_key',
}

/** input type for incrementing numeric columns in table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  ram?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Insert_Input = {
  allisone_plus_clients?: InputMaybe<Allisone_Plus_Users_Configurations_Arr_Rel_Insert_Input>;
  cpu?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled_modes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['Int']>;
  machine_id?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  os_language?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  ram?: InputMaybe<Scalars['Int']>;
  software?: InputMaybe<Scalars['String']>;
  softwareVersion?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Mutation_Response = {
  __typename?: 'allisone_plus_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allisone_Plus_Configuration>;
};

/** input type for inserting object relation for remote table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Obj_Rel_Insert_Input = {
  data: Allisone_Plus_Configuration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Allisone_Plus_Configuration_On_Conflict>;
};

/** on_conflict condition type for table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_On_Conflict = {
  constraint: Allisone_Plus_Configuration_Constraint;
  update_columns?: Array<Allisone_Plus_Configuration_Update_Column>;
  where?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
};

/** Ordering options when selecting data from "allisone_plus_configuration". */
export type Allisone_Plus_Configuration_Order_By = {
  allisone_plus_clients_aggregate?: InputMaybe<Allisone_Plus_Users_Configurations_Aggregate_Order_By>;
  cpu?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enabled_modes?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  machine_id?: InputMaybe<Order_By>;
  os?: InputMaybe<Order_By>;
  os_language?: InputMaybe<Order_By>;
  os_version?: InputMaybe<Order_By>;
  ram?: InputMaybe<Order_By>;
  software?: InputMaybe<Order_By>;
  softwareVersion?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "allisone_plus_configuration" */
export enum Allisone_Plus_Configuration_Select_Column {
  /** column name */
  Cpu = 'cpu',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnabledModes = 'enabled_modes',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  MachineId = 'machine_id',
  /** column name */
  Os = 'os',
  /** column name */
  OsLanguage = 'os_language',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  Ram = 'ram',
  /** column name */
  Software = 'software',
  /** column name */
  SoftwareVersion = 'softwareVersion',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Set_Input = {
  cpu?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled_modes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  machine_id?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  os_language?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  ram?: InputMaybe<Scalars['Int']>;
  software?: InputMaybe<Scalars['String']>;
  softwareVersion?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "allisone_plus_configuration" */
export type Allisone_Plus_Configuration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allisone_Plus_Configuration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allisone_Plus_Configuration_Stream_Cursor_Value_Input = {
  cpu?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled_modes?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  machine_id?: InputMaybe<Scalars['String']>;
  os?: InputMaybe<Scalars['String']>;
  os_language?: InputMaybe<Scalars['String']>;
  os_version?: InputMaybe<Scalars['String']>;
  ram?: InputMaybe<Scalars['Int']>;
  software?: InputMaybe<Scalars['String']>;
  softwareVersion?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "allisone_plus_configuration" */
export enum Allisone_Plus_Configuration_Update_Column {
  /** column name */
  Cpu = 'cpu',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnabledModes = 'enabled_modes',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  MachineId = 'machine_id',
  /** column name */
  Os = 'os',
  /** column name */
  OsLanguage = 'os_language',
  /** column name */
  OsVersion = 'os_version',
  /** column name */
  Ram = 'ram',
  /** column name */
  Software = 'software',
  /** column name */
  SoftwareVersion = 'softwareVersion',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Allisone_Plus_Configuration_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Allisone_Plus_Configuration_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allisone_Plus_Configuration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allisone_Plus_Configuration_Bool_Exp;
};

/** columns and relationships of "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations = {
  __typename?: 'allisone_plus_users_configurations';
  /** An object relationship */
  allisone_plus_configuration: Allisone_Plus_Configuration;
  config_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  latestLogin: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  userId: Maybe<Users>;
  user_id: Scalars['Int'];
};

/** order by aggregate values of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Aggregate_Order_By = {
  avg?: InputMaybe<Allisone_Plus_Users_Configurations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Allisone_Plus_Users_Configurations_Max_Order_By>;
  min?: InputMaybe<Allisone_Plus_Users_Configurations_Min_Order_By>;
  stddev?: InputMaybe<Allisone_Plus_Users_Configurations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Allisone_Plus_Users_Configurations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Allisone_Plus_Users_Configurations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Allisone_Plus_Users_Configurations_Sum_Order_By>;
  var_pop?: InputMaybe<Allisone_Plus_Users_Configurations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Allisone_Plus_Users_Configurations_Var_Samp_Order_By>;
  variance?: InputMaybe<Allisone_Plus_Users_Configurations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Arr_Rel_Insert_Input = {
  data: Array<Allisone_Plus_Users_Configurations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Allisone_Plus_Users_Configurations_On_Conflict>;
};

/** order by avg() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Avg_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "allisone_plus_users_configurations". All fields are combined with a logical 'AND'. */
export type Allisone_Plus_Users_Configurations_Bool_Exp = {
  _and?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Bool_Exp>>;
  _not?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
  _or?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Bool_Exp>>;
  allisone_plus_configuration?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
  config_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latestLogin?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "allisone_plus_users_configurations" */
export enum Allisone_Plus_Users_Configurations_Constraint {
  /** unique or primary key constraint on columns "id" */
  AllisonePlusClientPkey = 'allisone_plus_client_pkey',
  /** unique or primary key constraint on columns "user_id", "config_id" */
  AllisonePlusUsersConfigurationsConfigIdUserIdKey = 'allisone_plus_users_configurations_config_id_user_id_key',
}

/** input type for incrementing numeric columns in table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Inc_Input = {
  config_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Insert_Input = {
  allisone_plus_configuration?: InputMaybe<Allisone_Plus_Configuration_Obj_Rel_Insert_Input>;
  config_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestLogin?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Max_Order_By = {
  config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestLogin?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Min_Order_By = {
  config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestLogin?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Mutation_Response = {
  __typename?: 'allisone_plus_users_configurations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Allisone_Plus_Users_Configurations>;
};

/** on_conflict condition type for table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_On_Conflict = {
  constraint: Allisone_Plus_Users_Configurations_Constraint;
  update_columns?: Array<Allisone_Plus_Users_Configurations_Update_Column>;
  where?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
};

/** Ordering options when selecting data from "allisone_plus_users_configurations". */
export type Allisone_Plus_Users_Configurations_Order_By = {
  allisone_plus_configuration?: InputMaybe<Allisone_Plus_Configuration_Order_By>;
  config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestLogin?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  userId?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: allisone_plus_users_configurations */
export type Allisone_Plus_Users_Configurations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "allisone_plus_users_configurations" */
export enum Allisone_Plus_Users_Configurations_Select_Column {
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestLogin = 'latestLogin',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Set_Input = {
  config_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestLogin?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Stddev_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Stddev_Pop_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Stddev_Samp_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Allisone_Plus_Users_Configurations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Allisone_Plus_Users_Configurations_Stream_Cursor_Value_Input = {
  config_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestLogin?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Sum_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "allisone_plus_users_configurations" */
export enum Allisone_Plus_Users_Configurations_Update_Column {
  /** column name */
  ConfigId = 'config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LatestLogin = 'latestLogin',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Allisone_Plus_Users_Configurations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Allisone_Plus_Users_Configurations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Allisone_Plus_Users_Configurations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Allisone_Plus_Users_Configurations_Bool_Exp;
};

/** order by var_pop() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Var_Pop_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Var_Samp_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "allisone_plus_users_configurations" */
export type Allisone_Plus_Users_Configurations_Variance_Order_By = {
  config_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "anatomies" */
export type Anatomies = {
  __typename?: 'anatomies';
  /** An object relationship */
  anatomyType: Anatomy_Types;
  detectionBox: Scalars['jsonb'];
  detectionPoly: Scalars['jsonb'];
  id: Scalars['uuid'];
  onTooth: Scalars['jsonb'];
  type: Scalars['String'];
  /** An object relationship */
  xray: Xrays;
  xrayId: Scalars['uuid'];
};

/** columns and relationships of "anatomies" */
export type AnatomiesDetectionBoxArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "anatomies" */
export type AnatomiesDetectionPolyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "anatomies" */
export type AnatomiesOnToothArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "anatomies" */
export type Anatomies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Anatomies_Max_Order_By>;
  min?: InputMaybe<Anatomies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "anatomies" */
export type Anatomies_Arr_Rel_Insert_Input = {
  data: Array<Anatomies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Anatomies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "anatomies". All fields are combined with a logical 'AND'. */
export type Anatomies_Bool_Exp = {
  _and?: InputMaybe<Array<Anatomies_Bool_Exp>>;
  _not?: InputMaybe<Anatomies_Bool_Exp>;
  _or?: InputMaybe<Array<Anatomies_Bool_Exp>>;
  anatomyType?: InputMaybe<Anatomy_Types_Bool_Exp>;
  detectionBox?: InputMaybe<Jsonb_Comparison_Exp>;
  detectionPoly?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  onTooth?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  xray?: InputMaybe<Xrays_Bool_Exp>;
  xrayId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "anatomies" */
export enum Anatomies_Constraint {
  /** unique or primary key constraint on columns "id" */
  AnatomiesPkey = 'anatomies_pkey',
}

/** input type for inserting data into table "anatomies" */
export type Anatomies_Insert_Input = {
  detectionBox?: InputMaybe<Scalars['jsonb']>;
  detectionPoly?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  xray?: InputMaybe<Xrays_Obj_Rel_Insert_Input>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "anatomies" */
export type Anatomies_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "anatomies" */
export type Anatomies_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "anatomies" */
export type Anatomies_Mutation_Response = {
  __typename?: 'anatomies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Anatomies>;
};

/** on_conflict condition type for table "anatomies" */
export type Anatomies_On_Conflict = {
  constraint: Anatomies_Constraint;
  update_columns?: Array<Anatomies_Update_Column>;
  where?: InputMaybe<Anatomies_Bool_Exp>;
};

/** Ordering options when selecting data from "anatomies". */
export type Anatomies_Order_By = {
  anatomyType?: InputMaybe<Anatomy_Types_Order_By>;
  detectionBox?: InputMaybe<Order_By>;
  detectionPoly?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  onTooth?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  xray?: InputMaybe<Xrays_Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** select columns of table "anatomies" */
export enum Anatomies_Select_Column {
  /** column name */
  DetectionBox = 'detectionBox',
  /** column name */
  DetectionPoly = 'detectionPoly',
  /** column name */
  Id = 'id',
  /** column name */
  OnTooth = 'onTooth',
  /** column name */
  Type = 'type',
  /** column name */
  XrayId = 'xrayId',
}

/** Streaming cursor of the table "anatomies" */
export type Anatomies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Anatomies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Anatomies_Stream_Cursor_Value_Input = {
  detectionBox?: InputMaybe<Scalars['jsonb']>;
  detectionPoly?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Scalars['String']>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "anatomies" (current role has no relevant permissions) */
export enum Anatomies_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "anatomy_types" */
export type Anatomy_Types = {
  __typename?: 'anatomy_types';
  color: Scalars['String'];
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "anatomy_types". All fields are combined with a logical 'AND'. */
export type Anatomy_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Anatomy_Types_Bool_Exp>>;
  _not?: InputMaybe<Anatomy_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Anatomy_Types_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "anatomy_types". */
export type Anatomy_Types_Order_By = {
  color?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "anatomy_types" */
export enum Anatomy_Types_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "anatomy_types" */
export type Anatomy_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Anatomy_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Anatomy_Types_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** input type for inserting array relation for remote table "api_clients_logs" */
export type Api_Clients_Logs_Arr_Rel_Insert_Input = {
  data: Array<Api_Clients_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Api_Clients_Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "api_clients_logs". All fields are combined with a logical 'AND'. */
export type Api_Clients_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Clients_Logs_Bool_Exp>>;
  _not?: InputMaybe<Api_Clients_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Clients_Logs_Bool_Exp>>;
};

/** unique or primary key constraints on table "api_clients_logs" */
export enum Api_Clients_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApiClientsLogsPkey = 'api_clients_logs_pkey',
}

/** input type for inserting data into table "api_clients_logs" */
export type Api_Clients_Logs_Insert_Input = {
  api_log?: InputMaybe<Api_Logs_Obj_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  log_id?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "api_clients_logs" */
export type Api_Clients_Logs_Mutation_Response = {
  __typename?: 'api_clients_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
};

/** on_conflict condition type for table "api_clients_logs" */
export type Api_Clients_Logs_On_Conflict = {
  constraint: Api_Clients_Logs_Constraint;
  update_columns?: Array<Api_Clients_Logs_Update_Column>;
  where?: InputMaybe<Api_Clients_Logs_Bool_Exp>;
};

/** placeholder for update columns of table "api_clients_logs" (current role has no relevant permissions) */
export enum Api_Clients_Logs_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "api_clients_permissions" */
export type Api_Clients_Permissions = {
  __typename?: 'api_clients_permissions';
  /** An object relationship */
  api_permission: Maybe<Api_Permissions>;
  client_id: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  permission_id: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "api_clients_permissions" */
export type Api_Clients_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Api_Clients_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Api_Clients_Permissions_Max_Order_By>;
  min?: InputMaybe<Api_Clients_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Api_Clients_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Api_Clients_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Api_Clients_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Api_Clients_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Api_Clients_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Api_Clients_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Api_Clients_Permissions_Variance_Order_By>;
};

/** order by avg() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "api_clients_permissions". All fields are combined with a logical 'AND'. */
export type Api_Clients_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Clients_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Clients_Permissions_Bool_Exp>>;
  api_permission?: InputMaybe<Api_Permissions_Bool_Exp>;
  client_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  permission_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Max_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Min_Order_By = {
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "api_clients_permissions". */
export type Api_Clients_Permissions_Order_By = {
  api_permission?: InputMaybe<Api_Permissions_Order_By>;
  client_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** select columns of table "api_clients_permissions" */
export enum Api_Clients_Permissions_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id',
}

/** order by stddev() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "api_clients_permissions" */
export type Api_Clients_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Clients_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Clients_Permissions_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  permission_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "api_clients_permissions" */
export type Api_Clients_Permissions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "api_logs". All fields are combined with a logical 'AND'. */
export type Api_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Logs_Bool_Exp>>;
  _not?: InputMaybe<Api_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Logs_Bool_Exp>>;
};

/** unique or primary key constraints on table "api_logs" */
export enum Api_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ApiLogsPkey = 'api_logs_pkey',
}

/** input type for inserting data into table "api_logs" */
export type Api_Logs_Insert_Input = {
  api_clients_logs?: InputMaybe<Api_Clients_Logs_Arr_Rel_Insert_Input>;
  client_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ip_address?: InputMaybe<Scalars['String']>;
  request_details?: InputMaybe<Scalars['jsonb']>;
  response_code?: InputMaybe<Scalars['String']>;
  response_details?: InputMaybe<Scalars['jsonb']>;
  success?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "api_logs" */
export type Api_Logs_Mutation_Response = {
  __typename?: 'api_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
};

/** input type for inserting object relation for remote table "api_logs" */
export type Api_Logs_Obj_Rel_Insert_Input = {
  data: Api_Logs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Api_Logs_On_Conflict>;
};

/** on_conflict condition type for table "api_logs" */
export type Api_Logs_On_Conflict = {
  constraint: Api_Logs_Constraint;
  update_columns?: Array<Api_Logs_Update_Column>;
  where?: InputMaybe<Api_Logs_Bool_Exp>;
};

/** placeholder for update columns of table "api_logs" (current role has no relevant permissions) */
export enum Api_Logs_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "api_partner_tmp" */
export type Api_Partner_Tmp = {
  __typename?: 'api_partner_tmp';
  client_id: Scalars['String'];
  created: Maybe<Scalars['Boolean']>;
  data: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
};

/** columns and relationships of "api_partner_tmp" */
export type Api_Partner_TmpDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "api_partner_tmp". All fields are combined with a logical 'AND'. */
export type Api_Partner_Tmp_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Partner_Tmp_Bool_Exp>>;
  _not?: InputMaybe<Api_Partner_Tmp_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Partner_Tmp_Bool_Exp>>;
  client_id?: InputMaybe<String_Comparison_Exp>;
  created?: InputMaybe<Boolean_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "api_partner_tmp". */
export type Api_Partner_Tmp_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "api_partner_tmp" */
export enum Api_Partner_Tmp_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  Created = 'created',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "api_partner_tmp" */
export type Api_Partner_Tmp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Partner_Tmp_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Partner_Tmp_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['String']>;
  created?: InputMaybe<Scalars['Boolean']>;
  data?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "api_permissions" */
export type Api_Permissions = {
  __typename?: 'api_permissions';
  /** An array relationship */
  api_clients_permissions: Array<Api_Clients_Permissions>;
  description: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** columns and relationships of "api_permissions" */
export type Api_PermissionsApi_Clients_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Api_Clients_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Clients_Permissions_Order_By>>;
  where?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "api_permissions". All fields are combined with a logical 'AND'. */
export type Api_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Api_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Api_Permissions_Bool_Exp>>;
  api_clients_permissions?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "api_permissions". */
export type Api_Permissions_Order_By = {
  api_clients_permissions_aggregate?: InputMaybe<Api_Clients_Permissions_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "api_permissions" */
export enum Api_Permissions_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "api_permissions" */
export type Api_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Api_Permissions_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "app_versions" */
export type App_Versions = {
  __typename?: 'app_versions';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "app_versions". All fields are combined with a logical 'AND'. */
export type App_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<App_Versions_Bool_Exp>>;
  _not?: InputMaybe<App_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<App_Versions_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

export enum App_Versions_Enum {
  Ce = 'CE',
  Commercial = 'Commercial',
  Fda = 'FDA',
  Ukca = 'UKCA',
}

/** Boolean expression to compare columns of type "app_versions_enum". All fields are combined with logical 'AND'. */
export type App_Versions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<App_Versions_Enum>;
  _in?: InputMaybe<Array<App_Versions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<App_Versions_Enum>;
  _nin?: InputMaybe<Array<App_Versions_Enum>>;
};

/** Ordering options when selecting data from "app_versions". */
export type App_Versions_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** select columns of table "app_versions" */
export enum App_Versions_Select_Column {
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "app_versions" */
export type App_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: App_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type App_Versions_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "billings" */
export type Billings = {
  __typename?: 'billings';
  beneficiaries: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  customerId: Maybe<Scalars['String']>;
  expectedBillingDate: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  latestPaymentStatus: Maybe<Scalars['String']>;
  /** An object relationship */
  location: Locations;
  locationId: Scalars['Int'];
  paymasterHistoryIds: Scalars['jsonb'];
  paymasterId: Maybe<Scalars['Int']>;
  paymentStatus: Maybe<Scalars['String']>;
  potentialPaymasterIds: Maybe<Scalars['jsonb']>;
  priceId: Maybe<Scalars['String']>;
  promoCode: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Int']>;
  realBillingDate: Maybe<Scalars['timestamptz']>;
  subscriptionId: Maybe<Scalars['String']>;
  subscriptionStatus: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Maybe<Users>;
};

/** columns and relationships of "billings" */
export type BillingsBeneficiariesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "billings" */
export type BillingsPaymasterHistoryIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "billings" */
export type BillingsPotentialPaymasterIdsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "billings" */
export type Billings_Aggregate = {
  __typename?: 'billings_aggregate';
  aggregate: Maybe<Billings_Aggregate_Fields>;
  nodes: Array<Billings>;
};

export type Billings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Billings_Aggregate_Bool_Exp_Count>;
};

export type Billings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Billings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Billings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "billings" */
export type Billings_Aggregate_Fields = {
  __typename?: 'billings_aggregate_fields';
  avg: Maybe<Billings_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Billings_Max_Fields>;
  min: Maybe<Billings_Min_Fields>;
  stddev: Maybe<Billings_Stddev_Fields>;
  stddev_pop: Maybe<Billings_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Billings_Stddev_Samp_Fields>;
  sum: Maybe<Billings_Sum_Fields>;
  var_pop: Maybe<Billings_Var_Pop_Fields>;
  var_samp: Maybe<Billings_Var_Samp_Fields>;
  variance: Maybe<Billings_Variance_Fields>;
};

/** aggregate fields of "billings" */
export type Billings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "billings" */
export type Billings_Aggregate_Order_By = {
  avg?: InputMaybe<Billings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Billings_Max_Order_By>;
  min?: InputMaybe<Billings_Min_Order_By>;
  stddev?: InputMaybe<Billings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Billings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Billings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Billings_Sum_Order_By>;
  var_pop?: InputMaybe<Billings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Billings_Var_Samp_Order_By>;
  variance?: InputMaybe<Billings_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Billings_Append_Input = {
  beneficiaries?: InputMaybe<Scalars['jsonb']>;
  paymasterHistoryIds?: InputMaybe<Scalars['jsonb']>;
  potentialPaymasterIds?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "billings" */
export type Billings_Arr_Rel_Insert_Input = {
  data: Array<Billings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Billings_On_Conflict>;
};

/** aggregate avg on columns */
export type Billings_Avg_Fields = {
  __typename?: 'billings_avg_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "billings" */
export type Billings_Avg_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "billings". All fields are combined with a logical 'AND'. */
export type Billings_Bool_Exp = {
  _and?: InputMaybe<Array<Billings_Bool_Exp>>;
  _not?: InputMaybe<Billings_Bool_Exp>;
  _or?: InputMaybe<Array<Billings_Bool_Exp>>;
  beneficiaries?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerId?: InputMaybe<String_Comparison_Exp>;
  expectedBillingDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  latestPaymentStatus?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  locationId?: InputMaybe<Int_Comparison_Exp>;
  paymasterHistoryIds?: InputMaybe<Jsonb_Comparison_Exp>;
  paymasterId?: InputMaybe<Int_Comparison_Exp>;
  paymentStatus?: InputMaybe<String_Comparison_Exp>;
  potentialPaymasterIds?: InputMaybe<Jsonb_Comparison_Exp>;
  priceId?: InputMaybe<String_Comparison_Exp>;
  promoCode?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  realBillingDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  subscriptionId?: InputMaybe<String_Comparison_Exp>;
  subscriptionStatus?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "billings" */
export enum Billings_Constraint {
  /** unique or primary key constraint on columns "id" */
  BillingsPkey = 'billings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Billings_Delete_At_Path_Input = {
  beneficiaries?: InputMaybe<Array<Scalars['String']>>;
  paymasterHistoryIds?: InputMaybe<Array<Scalars['String']>>;
  potentialPaymasterIds?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Billings_Delete_Elem_Input = {
  beneficiaries?: InputMaybe<Scalars['Int']>;
  paymasterHistoryIds?: InputMaybe<Scalars['Int']>;
  potentialPaymasterIds?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Billings_Delete_Key_Input = {
  beneficiaries?: InputMaybe<Scalars['String']>;
  paymasterHistoryIds?: InputMaybe<Scalars['String']>;
  potentialPaymasterIds?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "billings" */
export type Billings_Inc_Input = {
  locationId?: InputMaybe<Scalars['Int']>;
  paymasterId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "billings" */
export type Billings_Insert_Input = {
  beneficiaries?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['String']>;
  expectedBillingDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestPaymentStatus?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['Int']>;
  paymasterHistoryIds?: InputMaybe<Scalars['jsonb']>;
  paymasterId?: InputMaybe<Scalars['Int']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  potentialPaymasterIds?: InputMaybe<Scalars['jsonb']>;
  priceId?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  realBillingDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Billings_Max_Fields = {
  __typename?: 'billings_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customerId: Maybe<Scalars['String']>;
  expectedBillingDate: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  latestPaymentStatus: Maybe<Scalars['String']>;
  locationId: Maybe<Scalars['Int']>;
  paymasterId: Maybe<Scalars['Int']>;
  paymentStatus: Maybe<Scalars['String']>;
  priceId: Maybe<Scalars['String']>;
  promoCode: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Int']>;
  realBillingDate: Maybe<Scalars['timestamptz']>;
  subscriptionId: Maybe<Scalars['String']>;
  subscriptionStatus: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "billings" */
export type Billings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  expectedBillingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestPaymentStatus?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  priceId?: InputMaybe<Order_By>;
  promoCode?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  realBillingDate?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  subscriptionStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Billings_Min_Fields = {
  __typename?: 'billings_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  customerId: Maybe<Scalars['String']>;
  expectedBillingDate: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  latestPaymentStatus: Maybe<Scalars['String']>;
  locationId: Maybe<Scalars['Int']>;
  paymasterId: Maybe<Scalars['Int']>;
  paymentStatus: Maybe<Scalars['String']>;
  priceId: Maybe<Scalars['String']>;
  promoCode: Maybe<Scalars['String']>;
  quantity: Maybe<Scalars['Int']>;
  realBillingDate: Maybe<Scalars['timestamptz']>;
  subscriptionId: Maybe<Scalars['String']>;
  subscriptionStatus: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "billings" */
export type Billings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  expectedBillingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestPaymentStatus?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  priceId?: InputMaybe<Order_By>;
  promoCode?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  realBillingDate?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  subscriptionStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "billings" */
export type Billings_Mutation_Response = {
  __typename?: 'billings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billings>;
};

/** on_conflict condition type for table "billings" */
export type Billings_On_Conflict = {
  constraint: Billings_Constraint;
  update_columns?: Array<Billings_Update_Column>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

/** Ordering options when selecting data from "billings". */
export type Billings_Order_By = {
  beneficiaries?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  expectedBillingDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  latestPaymentStatus?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  paymasterHistoryIds?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  paymentStatus?: InputMaybe<Order_By>;
  potentialPaymasterIds?: InputMaybe<Order_By>;
  priceId?: InputMaybe<Order_By>;
  promoCode?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  realBillingDate?: InputMaybe<Order_By>;
  subscriptionId?: InputMaybe<Order_By>;
  subscriptionStatus?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: billings */
export type Billings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Billings_Prepend_Input = {
  beneficiaries?: InputMaybe<Scalars['jsonb']>;
  paymasterHistoryIds?: InputMaybe<Scalars['jsonb']>;
  potentialPaymasterIds?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "billings" */
export enum Billings_Select_Column {
  /** column name */
  Beneficiaries = 'beneficiaries',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  ExpectedBillingDate = 'expectedBillingDate',
  /** column name */
  Id = 'id',
  /** column name */
  LatestPaymentStatus = 'latestPaymentStatus',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PaymasterHistoryIds = 'paymasterHistoryIds',
  /** column name */
  PaymasterId = 'paymasterId',
  /** column name */
  PaymentStatus = 'paymentStatus',
  /** column name */
  PotentialPaymasterIds = 'potentialPaymasterIds',
  /** column name */
  PriceId = 'priceId',
  /** column name */
  PromoCode = 'promoCode',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RealBillingDate = 'realBillingDate',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "billings" */
export type Billings_Set_Input = {
  beneficiaries?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['String']>;
  expectedBillingDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestPaymentStatus?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  paymasterHistoryIds?: InputMaybe<Scalars['jsonb']>;
  paymasterId?: InputMaybe<Scalars['Int']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  potentialPaymasterIds?: InputMaybe<Scalars['jsonb']>;
  priceId?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  realBillingDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Billings_Stddev_Fields = {
  __typename?: 'billings_stddev_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "billings" */
export type Billings_Stddev_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Billings_Stddev_Pop_Fields = {
  __typename?: 'billings_stddev_pop_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "billings" */
export type Billings_Stddev_Pop_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Billings_Stddev_Samp_Fields = {
  __typename?: 'billings_stddev_samp_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "billings" */
export type Billings_Stddev_Samp_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "billings" */
export type Billings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billings_Stream_Cursor_Value_Input = {
  beneficiaries?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['String']>;
  expectedBillingDate?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  latestPaymentStatus?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  paymasterHistoryIds?: InputMaybe<Scalars['jsonb']>;
  paymasterId?: InputMaybe<Scalars['Int']>;
  paymentStatus?: InputMaybe<Scalars['String']>;
  potentialPaymasterIds?: InputMaybe<Scalars['jsonb']>;
  priceId?: InputMaybe<Scalars['String']>;
  promoCode?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  realBillingDate?: InputMaybe<Scalars['timestamptz']>;
  subscriptionId?: InputMaybe<Scalars['String']>;
  subscriptionStatus?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Billings_Sum_Fields = {
  __typename?: 'billings_sum_fields';
  locationId: Maybe<Scalars['Int']>;
  paymasterId: Maybe<Scalars['Int']>;
  quantity: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "billings" */
export type Billings_Sum_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** update columns of table "billings" */
export enum Billings_Update_Column {
  /** column name */
  Beneficiaries = 'beneficiaries',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  ExpectedBillingDate = 'expectedBillingDate',
  /** column name */
  Id = 'id',
  /** column name */
  LatestPaymentStatus = 'latestPaymentStatus',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PaymasterHistoryIds = 'paymasterHistoryIds',
  /** column name */
  PaymasterId = 'paymasterId',
  /** column name */
  PaymentStatus = 'paymentStatus',
  /** column name */
  PotentialPaymasterIds = 'potentialPaymasterIds',
  /** column name */
  PriceId = 'priceId',
  /** column name */
  PromoCode = 'promoCode',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  RealBillingDate = 'realBillingDate',
  /** column name */
  SubscriptionId = 'subscriptionId',
  /** column name */
  SubscriptionStatus = 'subscriptionStatus',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Billings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Billings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Billings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Billings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Billings_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Billings_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Billings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Billings_Var_Pop_Fields = {
  __typename?: 'billings_var_pop_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "billings" */
export type Billings_Var_Pop_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Billings_Var_Samp_Fields = {
  __typename?: 'billings_var_samp_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "billings" */
export type Billings_Var_Samp_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Billings_Variance_Fields = {
  __typename?: 'billings_variance_fields';
  locationId: Maybe<Scalars['Float']>;
  paymasterId: Maybe<Scalars['Float']>;
  quantity: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "billings" */
export type Billings_Variance_Order_By = {
  locationId?: InputMaybe<Order_By>;
  paymasterId?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_Monitoring = {
  __typename?: 'clinical_monitoring';
  /** An object relationship */
  consultation: Maybe<Consultation>;
  consultationComment: Scalars['String'];
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Scalars['timestamptz'];
  damagedTeeth: Maybe<Scalars['jsonb']>;
  decayedTeeth: Maybe<Scalars['jsonb']>;
  emergency: Scalars['Boolean'];
  esthetic: Scalars['Boolean'];
  id: Scalars['uuid'];
  inflammationTeeth: Maybe<Scalars['jsonb']>;
  initialBilanDate: Maybe<Scalars['timestamptz']>;
  lastConsultationDate: Maybe<Scalars['timestamptz']>;
  looseTeeth: Maybe<Scalars['jsonb']>;
  medicalBackground: Scalars['String'];
  missingTeeth: Maybe<Scalars['jsonb']>;
  occlusion: Scalars['Boolean'];
  occlusionTeeth: Maybe<Scalars['jsonb']>;
  pain: Scalars['Boolean'];
  prosthesis: Scalars['Boolean'];
  routineCheck: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringDamagedTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringDecayedTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringInflammationTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringLooseTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringMissingTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "clinical_monitoring" */
export type Clinical_MonitoringOcclusionTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "clinical_monitoring" */
export type Clinical_Monitoring_Aggregate = {
  __typename?: 'clinical_monitoring_aggregate';
  aggregate: Maybe<Clinical_Monitoring_Aggregate_Fields>;
  nodes: Array<Clinical_Monitoring>;
};

/** aggregate fields of "clinical_monitoring" */
export type Clinical_Monitoring_Aggregate_Fields = {
  __typename?: 'clinical_monitoring_aggregate_fields';
  avg: Maybe<Clinical_Monitoring_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Clinical_Monitoring_Max_Fields>;
  min: Maybe<Clinical_Monitoring_Min_Fields>;
  stddev: Maybe<Clinical_Monitoring_Stddev_Fields>;
  stddev_pop: Maybe<Clinical_Monitoring_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Clinical_Monitoring_Stddev_Samp_Fields>;
  sum: Maybe<Clinical_Monitoring_Sum_Fields>;
  var_pop: Maybe<Clinical_Monitoring_Var_Pop_Fields>;
  var_samp: Maybe<Clinical_Monitoring_Var_Samp_Fields>;
  variance: Maybe<Clinical_Monitoring_Variance_Fields>;
};

/** aggregate fields of "clinical_monitoring" */
export type Clinical_Monitoring_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clinical_Monitoring_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clinical_Monitoring_Append_Input = {
  damagedTeeth?: InputMaybe<Scalars['jsonb']>;
  decayedTeeth?: InputMaybe<Scalars['jsonb']>;
  inflammationTeeth?: InputMaybe<Scalars['jsonb']>;
  looseTeeth?: InputMaybe<Scalars['jsonb']>;
  missingTeeth?: InputMaybe<Scalars['jsonb']>;
  occlusionTeeth?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Clinical_Monitoring_Avg_Fields = {
  __typename?: 'clinical_monitoring_avg_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "clinical_monitoring". All fields are combined with a logical 'AND'. */
export type Clinical_Monitoring_Bool_Exp = {
  _and?: InputMaybe<Array<Clinical_Monitoring_Bool_Exp>>;
  _not?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
  _or?: InputMaybe<Array<Clinical_Monitoring_Bool_Exp>>;
  consultation?: InputMaybe<Consultation_Bool_Exp>;
  consultationComment?: InputMaybe<String_Comparison_Exp>;
  consultationId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  damagedTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  decayedTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  emergency?: InputMaybe<Boolean_Comparison_Exp>;
  esthetic?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inflammationTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  initialBilanDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  lastConsultationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  looseTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  medicalBackground?: InputMaybe<String_Comparison_Exp>;
  missingTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  occlusion?: InputMaybe<Boolean_Comparison_Exp>;
  occlusionTeeth?: InputMaybe<Jsonb_Comparison_Exp>;
  pain?: InputMaybe<Boolean_Comparison_Exp>;
  prosthesis?: InputMaybe<Boolean_Comparison_Exp>;
  routineCheck?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clinical_monitoring" */
export enum Clinical_Monitoring_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClinicalMonitoringPkey = 'clinical_monitoring_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clinical_Monitoring_Delete_At_Path_Input = {
  damagedTeeth?: InputMaybe<Array<Scalars['String']>>;
  decayedTeeth?: InputMaybe<Array<Scalars['String']>>;
  inflammationTeeth?: InputMaybe<Array<Scalars['String']>>;
  looseTeeth?: InputMaybe<Array<Scalars['String']>>;
  missingTeeth?: InputMaybe<Array<Scalars['String']>>;
  occlusionTeeth?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clinical_Monitoring_Delete_Elem_Input = {
  damagedTeeth?: InputMaybe<Scalars['Int']>;
  decayedTeeth?: InputMaybe<Scalars['Int']>;
  inflammationTeeth?: InputMaybe<Scalars['Int']>;
  looseTeeth?: InputMaybe<Scalars['Int']>;
  missingTeeth?: InputMaybe<Scalars['Int']>;
  occlusionTeeth?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clinical_Monitoring_Delete_Key_Input = {
  damagedTeeth?: InputMaybe<Scalars['String']>;
  decayedTeeth?: InputMaybe<Scalars['String']>;
  inflammationTeeth?: InputMaybe<Scalars['String']>;
  looseTeeth?: InputMaybe<Scalars['String']>;
  missingTeeth?: InputMaybe<Scalars['String']>;
  occlusionTeeth?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "clinical_monitoring" */
export type Clinical_Monitoring_Insert_Input = {
  consultation?: InputMaybe<Consultation_Obj_Rel_Insert_Input>;
  consultationComment?: InputMaybe<Scalars['String']>;
  consultationId?: InputMaybe<Scalars['Int']>;
  damagedTeeth?: InputMaybe<Scalars['jsonb']>;
  decayedTeeth?: InputMaybe<Scalars['jsonb']>;
  emergency?: InputMaybe<Scalars['Boolean']>;
  esthetic?: InputMaybe<Scalars['Boolean']>;
  inflammationTeeth?: InputMaybe<Scalars['jsonb']>;
  initialBilanDate?: InputMaybe<Scalars['timestamptz']>;
  lastConsultationDate?: InputMaybe<Scalars['timestamptz']>;
  looseTeeth?: InputMaybe<Scalars['jsonb']>;
  medicalBackground?: InputMaybe<Scalars['String']>;
  missingTeeth?: InputMaybe<Scalars['jsonb']>;
  occlusion?: InputMaybe<Scalars['Boolean']>;
  occlusionTeeth?: InputMaybe<Scalars['jsonb']>;
  pain?: InputMaybe<Scalars['Boolean']>;
  prosthesis?: InputMaybe<Scalars['Boolean']>;
  routineCheck?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Clinical_Monitoring_Max_Fields = {
  __typename?: 'clinical_monitoring_max_fields';
  consultationComment: Maybe<Scalars['String']>;
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  initialBilanDate: Maybe<Scalars['timestamptz']>;
  lastConsultationDate: Maybe<Scalars['timestamptz']>;
  medicalBackground: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Clinical_Monitoring_Min_Fields = {
  __typename?: 'clinical_monitoring_min_fields';
  consultationComment: Maybe<Scalars['String']>;
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['uuid']>;
  initialBilanDate: Maybe<Scalars['timestamptz']>;
  lastConsultationDate: Maybe<Scalars['timestamptz']>;
  medicalBackground: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "clinical_monitoring" */
export type Clinical_Monitoring_Mutation_Response = {
  __typename?: 'clinical_monitoring_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clinical_Monitoring>;
};

/** input type for inserting object relation for remote table "clinical_monitoring" */
export type Clinical_Monitoring_Obj_Rel_Insert_Input = {
  data: Clinical_Monitoring_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Clinical_Monitoring_On_Conflict>;
};

/** on_conflict condition type for table "clinical_monitoring" */
export type Clinical_Monitoring_On_Conflict = {
  constraint: Clinical_Monitoring_Constraint;
  update_columns?: Array<Clinical_Monitoring_Update_Column>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

/** Ordering options when selecting data from "clinical_monitoring". */
export type Clinical_Monitoring_Order_By = {
  consultation?: InputMaybe<Consultation_Order_By>;
  consultationComment?: InputMaybe<Order_By>;
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  damagedTeeth?: InputMaybe<Order_By>;
  decayedTeeth?: InputMaybe<Order_By>;
  emergency?: InputMaybe<Order_By>;
  esthetic?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inflammationTeeth?: InputMaybe<Order_By>;
  initialBilanDate?: InputMaybe<Order_By>;
  lastConsultationDate?: InputMaybe<Order_By>;
  looseTeeth?: InputMaybe<Order_By>;
  medicalBackground?: InputMaybe<Order_By>;
  missingTeeth?: InputMaybe<Order_By>;
  occlusion?: InputMaybe<Order_By>;
  occlusionTeeth?: InputMaybe<Order_By>;
  pain?: InputMaybe<Order_By>;
  prosthesis?: InputMaybe<Order_By>;
  routineCheck?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clinical_monitoring */
export type Clinical_Monitoring_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clinical_Monitoring_Prepend_Input = {
  damagedTeeth?: InputMaybe<Scalars['jsonb']>;
  decayedTeeth?: InputMaybe<Scalars['jsonb']>;
  inflammationTeeth?: InputMaybe<Scalars['jsonb']>;
  looseTeeth?: InputMaybe<Scalars['jsonb']>;
  missingTeeth?: InputMaybe<Scalars['jsonb']>;
  occlusionTeeth?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "clinical_monitoring" */
export enum Clinical_Monitoring_Select_Column {
  /** column name */
  ConsultationComment = 'consultationComment',
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DamagedTeeth = 'damagedTeeth',
  /** column name */
  DecayedTeeth = 'decayedTeeth',
  /** column name */
  Emergency = 'emergency',
  /** column name */
  Esthetic = 'esthetic',
  /** column name */
  Id = 'id',
  /** column name */
  InflammationTeeth = 'inflammationTeeth',
  /** column name */
  InitialBilanDate = 'initialBilanDate',
  /** column name */
  LastConsultationDate = 'lastConsultationDate',
  /** column name */
  LooseTeeth = 'looseTeeth',
  /** column name */
  MedicalBackground = 'medicalBackground',
  /** column name */
  MissingTeeth = 'missingTeeth',
  /** column name */
  Occlusion = 'occlusion',
  /** column name */
  OcclusionTeeth = 'occlusionTeeth',
  /** column name */
  Pain = 'pain',
  /** column name */
  Prosthesis = 'prosthesis',
  /** column name */
  RoutineCheck = 'routineCheck',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "clinical_monitoring" */
export type Clinical_Monitoring_Set_Input = {
  consultationComment?: InputMaybe<Scalars['String']>;
  damagedTeeth?: InputMaybe<Scalars['jsonb']>;
  decayedTeeth?: InputMaybe<Scalars['jsonb']>;
  emergency?: InputMaybe<Scalars['Boolean']>;
  esthetic?: InputMaybe<Scalars['Boolean']>;
  inflammationTeeth?: InputMaybe<Scalars['jsonb']>;
  initialBilanDate?: InputMaybe<Scalars['timestamptz']>;
  lastConsultationDate?: InputMaybe<Scalars['timestamptz']>;
  looseTeeth?: InputMaybe<Scalars['jsonb']>;
  medicalBackground?: InputMaybe<Scalars['String']>;
  missingTeeth?: InputMaybe<Scalars['jsonb']>;
  occlusion?: InputMaybe<Scalars['Boolean']>;
  occlusionTeeth?: InputMaybe<Scalars['jsonb']>;
  pain?: InputMaybe<Scalars['Boolean']>;
  prosthesis?: InputMaybe<Scalars['Boolean']>;
  routineCheck?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Clinical_Monitoring_Stddev_Fields = {
  __typename?: 'clinical_monitoring_stddev_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Clinical_Monitoring_Stddev_Pop_Fields = {
  __typename?: 'clinical_monitoring_stddev_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Clinical_Monitoring_Stddev_Samp_Fields = {
  __typename?: 'clinical_monitoring_stddev_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "clinical_monitoring" */
export type Clinical_Monitoring_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clinical_Monitoring_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clinical_Monitoring_Stream_Cursor_Value_Input = {
  consultationComment?: InputMaybe<Scalars['String']>;
  consultationId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  damagedTeeth?: InputMaybe<Scalars['jsonb']>;
  decayedTeeth?: InputMaybe<Scalars['jsonb']>;
  emergency?: InputMaybe<Scalars['Boolean']>;
  esthetic?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  inflammationTeeth?: InputMaybe<Scalars['jsonb']>;
  initialBilanDate?: InputMaybe<Scalars['timestamptz']>;
  lastConsultationDate?: InputMaybe<Scalars['timestamptz']>;
  looseTeeth?: InputMaybe<Scalars['jsonb']>;
  medicalBackground?: InputMaybe<Scalars['String']>;
  missingTeeth?: InputMaybe<Scalars['jsonb']>;
  occlusion?: InputMaybe<Scalars['Boolean']>;
  occlusionTeeth?: InputMaybe<Scalars['jsonb']>;
  pain?: InputMaybe<Scalars['Boolean']>;
  prosthesis?: InputMaybe<Scalars['Boolean']>;
  routineCheck?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Clinical_Monitoring_Sum_Fields = {
  __typename?: 'clinical_monitoring_sum_fields';
  consultationId: Maybe<Scalars['Int']>;
};

/** update columns of table "clinical_monitoring" */
export enum Clinical_Monitoring_Update_Column {
  /** column name */
  ConsultationComment = 'consultationComment',
  /** column name */
  DamagedTeeth = 'damagedTeeth',
  /** column name */
  DecayedTeeth = 'decayedTeeth',
  /** column name */
  Emergency = 'emergency',
  /** column name */
  Esthetic = 'esthetic',
  /** column name */
  InflammationTeeth = 'inflammationTeeth',
  /** column name */
  InitialBilanDate = 'initialBilanDate',
  /** column name */
  LastConsultationDate = 'lastConsultationDate',
  /** column name */
  LooseTeeth = 'looseTeeth',
  /** column name */
  MedicalBackground = 'medicalBackground',
  /** column name */
  MissingTeeth = 'missingTeeth',
  /** column name */
  Occlusion = 'occlusion',
  /** column name */
  OcclusionTeeth = 'occlusionTeeth',
  /** column name */
  Pain = 'pain',
  /** column name */
  Prosthesis = 'prosthesis',
  /** column name */
  RoutineCheck = 'routineCheck',
}

export type Clinical_Monitoring_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Clinical_Monitoring_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Clinical_Monitoring_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Clinical_Monitoring_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Clinical_Monitoring_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Clinical_Monitoring_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clinical_Monitoring_Set_Input>;
  /** filter the rows which have to be updated */
  where: Clinical_Monitoring_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Clinical_Monitoring_Var_Pop_Fields = {
  __typename?: 'clinical_monitoring_var_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Clinical_Monitoring_Var_Samp_Fields = {
  __typename?: 'clinical_monitoring_var_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Clinical_Monitoring_Variance_Fields = {
  __typename?: 'clinical_monitoring_variance_fields';
  consultationId: Maybe<Scalars['Float']>;
};

/** columns and relationships of "consultation" */
export type Consultation = {
  __typename?: 'consultation';
  /** An object relationship */
  clinicalMonitoring: Maybe<Clinical_Monitoring>;
  clinical_monitoring_id: Maybe<Scalars['uuid']>;
  consultationDate: Scalars['timestamptz'];
  createdAt: Scalars['timestamptz'];
  /** An array relationship */
  elements: Array<Elements>;
  /** An aggregate relationship */
  elements_aggregate: Elements_Aggregate;
  fromMigration: Maybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  last_sent_date: Scalars['timestamptz'];
  /** An array relationship */
  operations: Array<Operations>;
  /** An aggregate relationship */
  operations_aggregate: Operations_Aggregate;
  /** An object relationship */
  patient: Maybe<Patients>;
  patientId: Maybe<Scalars['Int']>;
  proposedTreatment: Maybe<Scalars['jsonb']>;
  report: Maybe<Scalars['jsonb']>;
  updatedAt: Scalars['timestamptz'];
  /** An array relationship */
  xrays: Array<Xrays>;
  /** An aggregate relationship */
  xrays_aggregate: Xrays_Aggregate;
};

/** columns and relationships of "consultation" */
export type ConsultationElementsArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "consultation" */
export type ConsultationElements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "consultation" */
export type ConsultationOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** columns and relationships of "consultation" */
export type ConsultationOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** columns and relationships of "consultation" */
export type ConsultationProposedTreatmentArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "consultation" */
export type ConsultationReportArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "consultation" */
export type ConsultationXraysArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

/** columns and relationships of "consultation" */
export type ConsultationXrays_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

/** aggregated selection of "consultation" */
export type Consultation_Aggregate = {
  __typename?: 'consultation_aggregate';
  aggregate: Maybe<Consultation_Aggregate_Fields>;
  nodes: Array<Consultation>;
};

export type Consultation_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Consultation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Consultation_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Consultation_Aggregate_Bool_Exp_Count>;
};

export type Consultation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Consultation_Select_Column_Consultation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consultation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consultation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Consultation_Select_Column_Consultation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consultation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Consultation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Consultation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "consultation" */
export type Consultation_Aggregate_Fields = {
  __typename?: 'consultation_aggregate_fields';
  avg: Maybe<Consultation_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Consultation_Max_Fields>;
  min: Maybe<Consultation_Min_Fields>;
  stddev: Maybe<Consultation_Stddev_Fields>;
  stddev_pop: Maybe<Consultation_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Consultation_Stddev_Samp_Fields>;
  sum: Maybe<Consultation_Sum_Fields>;
  var_pop: Maybe<Consultation_Var_Pop_Fields>;
  var_samp: Maybe<Consultation_Var_Samp_Fields>;
  variance: Maybe<Consultation_Variance_Fields>;
};

/** aggregate fields of "consultation" */
export type Consultation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Consultation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "consultation" */
export type Consultation_Aggregate_Order_By = {
  avg?: InputMaybe<Consultation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Consultation_Max_Order_By>;
  min?: InputMaybe<Consultation_Min_Order_By>;
  stddev?: InputMaybe<Consultation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Consultation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Consultation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Consultation_Sum_Order_By>;
  var_pop?: InputMaybe<Consultation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Consultation_Var_Samp_Order_By>;
  variance?: InputMaybe<Consultation_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Consultation_Append_Input = {
  proposedTreatment?: InputMaybe<Scalars['jsonb']>;
  report?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "consultation" */
export type Consultation_Arr_Rel_Insert_Input = {
  data: Array<Consultation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Consultation_On_Conflict>;
};

/** aggregate avg on columns */
export type Consultation_Avg_Fields = {
  __typename?: 'consultation_avg_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "consultation" */
export type Consultation_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "consultation". All fields are combined with a logical 'AND'. */
export type Consultation_Bool_Exp = {
  _and?: InputMaybe<Array<Consultation_Bool_Exp>>;
  _not?: InputMaybe<Consultation_Bool_Exp>;
  _or?: InputMaybe<Array<Consultation_Bool_Exp>>;
  clinicalMonitoring?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
  clinical_monitoring_id?: InputMaybe<Uuid_Comparison_Exp>;
  consultationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  elements?: InputMaybe<Elements_Bool_Exp>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Bool_Exp>;
  fromMigration?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  last_sent_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  operations?: InputMaybe<Operations_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operations_Aggregate_Bool_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Int_Comparison_Exp>;
  proposedTreatment?: InputMaybe<Jsonb_Comparison_Exp>;
  report?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  xrays?: InputMaybe<Xrays_Bool_Exp>;
  xrays_aggregate?: InputMaybe<Xrays_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "consultation" */
export enum Consultation_Constraint {
  /** unique or primary key constraint on columns "clinical_monitoring_id" */
  TreatmentPlanClinicalMonitoringIdKey = 'treatment_plan_clinical_monitoring_id_key',
  /** unique or primary key constraint on columns "patient_id", "id" */
  TreatmentPlanPatientsIds = 'treatment_plan_patients_ids',
  /** unique or primary key constraint on columns "id" */
  TreatmentPlanPkey = 'treatment_plan_pkey',
  /** unique or primary key constraint on columns "patient_id", "id", "created_at" */
  TttpDateReversed = 'tttp_date_reversed',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Consultation_Delete_At_Path_Input = {
  proposedTreatment?: InputMaybe<Array<Scalars['String']>>;
  report?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Consultation_Delete_Elem_Input = {
  proposedTreatment?: InputMaybe<Scalars['Int']>;
  report?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Consultation_Delete_Key_Input = {
  proposedTreatment?: InputMaybe<Scalars['String']>;
  report?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "consultation" */
export type Consultation_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "consultation" */
export type Consultation_Insert_Input = {
  clinicalMonitoring?: InputMaybe<Clinical_Monitoring_Obj_Rel_Insert_Input>;
  clinical_monitoring_id?: InputMaybe<Scalars['uuid']>;
  consultationDate?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  elements?: InputMaybe<Elements_Arr_Rel_Insert_Input>;
  fromMigration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sent_date?: InputMaybe<Scalars['timestamptz']>;
  operations?: InputMaybe<Operations_Arr_Rel_Insert_Input>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['Int']>;
  proposedTreatment?: InputMaybe<Scalars['jsonb']>;
  report?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  xrays?: InputMaybe<Xrays_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Consultation_Max_Fields = {
  __typename?: 'consultation_max_fields';
  clinical_monitoring_id: Maybe<Scalars['uuid']>;
  consultationDate: Maybe<Scalars['timestamptz']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  last_sent_date: Maybe<Scalars['timestamptz']>;
  patientId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "consultation" */
export type Consultation_Max_Order_By = {
  clinical_monitoring_id?: InputMaybe<Order_By>;
  consultationDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_sent_date?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Consultation_Min_Fields = {
  __typename?: 'consultation_min_fields';
  clinical_monitoring_id: Maybe<Scalars['uuid']>;
  consultationDate: Maybe<Scalars['timestamptz']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  id: Maybe<Scalars['Int']>;
  last_sent_date: Maybe<Scalars['timestamptz']>;
  patientId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "consultation" */
export type Consultation_Min_Order_By = {
  clinical_monitoring_id?: InputMaybe<Order_By>;
  consultationDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_sent_date?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "consultation" */
export type Consultation_Mutation_Response = {
  __typename?: 'consultation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Consultation>;
};

/** input type for inserting object relation for remote table "consultation" */
export type Consultation_Obj_Rel_Insert_Input = {
  data: Consultation_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Consultation_On_Conflict>;
};

/** on_conflict condition type for table "consultation" */
export type Consultation_On_Conflict = {
  constraint: Consultation_Constraint;
  update_columns?: Array<Consultation_Update_Column>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

/** Ordering options when selecting data from "consultation". */
export type Consultation_Order_By = {
  clinicalMonitoring?: InputMaybe<Clinical_Monitoring_Order_By>;
  clinical_monitoring_id?: InputMaybe<Order_By>;
  consultationDate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Order_By>;
  fromMigration?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_sent_date?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operations_Aggregate_Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
  proposedTreatment?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  xrays_aggregate?: InputMaybe<Xrays_Aggregate_Order_By>;
};

/** primary key columns input for table: consultation */
export type Consultation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Consultation_Prepend_Input = {
  proposedTreatment?: InputMaybe<Scalars['jsonb']>;
  report?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "consultation" */
export enum Consultation_Select_Column {
  /** column name */
  ClinicalMonitoringId = 'clinical_monitoring_id',
  /** column name */
  ConsultationDate = 'consultationDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FromMigration = 'fromMigration',
  /** column name */
  Id = 'id',
  /** column name */
  LastSentDate = 'last_sent_date',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProposedTreatment = 'proposedTreatment',
  /** column name */
  Report = 'report',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "consultation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "consultation" */
export enum Consultation_Select_Column_Consultation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FromMigration = 'fromMigration',
}

/** select "consultation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "consultation" */
export enum Consultation_Select_Column_Consultation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FromMigration = 'fromMigration',
}

/** input type for updating data in table "consultation" */
export type Consultation_Set_Input = {
  clinical_monitoring_id?: InputMaybe<Scalars['uuid']>;
  consultationDate?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fromMigration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sent_date?: InputMaybe<Scalars['timestamptz']>;
  patientId?: InputMaybe<Scalars['Int']>;
  proposedTreatment?: InputMaybe<Scalars['jsonb']>;
  report?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Consultation_Stddev_Fields = {
  __typename?: 'consultation_stddev_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "consultation" */
export type Consultation_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Consultation_Stddev_Pop_Fields = {
  __typename?: 'consultation_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "consultation" */
export type Consultation_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Consultation_Stddev_Samp_Fields = {
  __typename?: 'consultation_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "consultation" */
export type Consultation_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "consultation" */
export type Consultation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Consultation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Consultation_Stream_Cursor_Value_Input = {
  clinical_monitoring_id?: InputMaybe<Scalars['uuid']>;
  consultationDate?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  fromMigration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  last_sent_date?: InputMaybe<Scalars['timestamptz']>;
  patientId?: InputMaybe<Scalars['Int']>;
  proposedTreatment?: InputMaybe<Scalars['jsonb']>;
  report?: InputMaybe<Scalars['jsonb']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Consultation_Sum_Fields = {
  __typename?: 'consultation_sum_fields';
  id: Maybe<Scalars['Int']>;
  patientId: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "consultation" */
export type Consultation_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** update columns of table "consultation" */
export enum Consultation_Update_Column {
  /** column name */
  ClinicalMonitoringId = 'clinical_monitoring_id',
  /** column name */
  ConsultationDate = 'consultationDate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FromMigration = 'fromMigration',
  /** column name */
  Id = 'id',
  /** column name */
  LastSentDate = 'last_sent_date',
  /** column name */
  PatientId = 'patientId',
  /** column name */
  ProposedTreatment = 'proposedTreatment',
  /** column name */
  Report = 'report',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Consultation_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Consultation_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Consultation_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Consultation_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Consultation_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Consultation_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Consultation_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Consultation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Consultation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Consultation_Var_Pop_Fields = {
  __typename?: 'consultation_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "consultation" */
export type Consultation_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Consultation_Var_Samp_Fields = {
  __typename?: 'consultation_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "consultation" */
export type Consultation_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Consultation_Variance_Fields = {
  __typename?: 'consultation_variance_fields';
  id: Maybe<Scalars['Float']>;
  patientId: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "consultation" */
export type Consultation_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** columns and relationships of "countries" */
export type Countries = {
  __typename?: 'countries';
  code: Scalars['String'];
  label: Scalars['String'];
};

/** Boolean expression to filter rows from the table "countries". All fields are combined with a logical 'AND'. */
export type Countries_Bool_Exp = {
  _and?: InputMaybe<Array<Countries_Bool_Exp>>;
  _not?: InputMaybe<Countries_Bool_Exp>;
  _or?: InputMaybe<Array<Countries_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  label?: InputMaybe<String_Comparison_Exp>;
};

export enum Countries_Enum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua and Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia and Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** Saint Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei Darussalam */
  Bn = 'BN',
  /** Bolivia, Plurinational State of */
  Bo = 'BO',
  /** Bonaire, Sint Eustatius and Saba */
  Bq = 'BQ',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo, Democratic Republic of the */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d'Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cabo Verde */
  Cv = 'CV',
  /** Curaçao */
  Cw = 'CW',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czechia */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands (Malvinas) */
  Fk = 'FK',
  /** Micronesia, Federated States of */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom of Great Britain and Northern Ireland */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia and the South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong */
  Hk = 'HK',
  /** Heard Island and McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran, Islamic Republic of */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** Saint Kitts and Nevis */
  Kn = 'KN',
  /** Korea, Democratic People's Republic of */
  Kp = 'KP',
  /** Korea, Republic of */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Lao People's Democratic Republic */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** Saint Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova, Republic of */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** Saint Martin, (French part) */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** North Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macao */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** Saint Pierre and Miquelon */
  Pm = 'PM',
  /** Pitcairn */
  Pn = 'PN',
  /** Puerto Rico */
  Pr = 'PR',
  /** Palestine, State of */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russian Federation */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** Saint Helena, Ascension and Tristan da Cunha */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard and Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** South Sudan */
  Ss = 'SS',
  /** Sao Tome and Principe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Sint Maarten, (Dutch part) */
  Sx = 'SX',
  /** Syrian Arab Republic */
  Sy = 'SY',
  /** Eswatini */
  Sz = 'SZ',
  /** Turks and Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad and Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan, Province of China */
  Tw = 'TW',
  /** Tanzania, United Republic of */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** United States Minor Outlying Islands */
  Um = 'UM',
  /** United States of America */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Holy See */
  Va = 'VA',
  /** Saint Vincent and the Grenadines */
  Vc = 'VC',
  /** Venezuela, Bolivarian Republic of */
  Ve = 'VE',
  /** Virgin Islands, British */
  Vg = 'VG',
  /** Virgin Islands, U.S. */
  Vi = 'VI',
  /** Viet Nam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis and Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}

/** Boolean expression to compare columns of type "countries_enum". All fields are combined with logical 'AND'. */
export type Countries_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Countries_Enum>;
  _in?: InputMaybe<Array<Countries_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Countries_Enum>;
  _nin?: InputMaybe<Array<Countries_Enum>>;
};

/** Ordering options when selecting data from "countries". */
export type Countries_Order_By = {
  code?: InputMaybe<Order_By>;
  label?: InputMaybe<Order_By>;
};

/** select columns of table "countries" */
export enum Countries_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Label = 'label',
}

/** Streaming cursor of the table "countries" */
export type Countries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Countries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Countries_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "customer_emails" */
export type Customer_Emails = {
  __typename?: 'customer_emails';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer: Partner_Customers;
  customerId: Scalars['uuid'];
  email: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "customer_emails". All fields are combined with a logical 'AND'. */
export type Customer_Emails_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Emails_Bool_Exp>>;
  _not?: InputMaybe<Customer_Emails_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Emails_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Partner_Customers_Bool_Exp>;
  customerId?: InputMaybe<Uuid_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  isEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "customer_emails" */
export enum Customer_Emails_Constraint {
  /** unique or primary key constraint on columns "email", "customer_id" */
  CustomerEmailsPkey = 'customer_emails_pkey',
}

/** input type for inserting data into table "customer_emails" */
export type Customer_Emails_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer?: InputMaybe<Partner_Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "customer_emails" */
export type Customer_Emails_Mutation_Response = {
  __typename?: 'customer_emails_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Emails>;
};

/** on_conflict condition type for table "customer_emails" */
export type Customer_Emails_On_Conflict = {
  constraint: Customer_Emails_Constraint;
  update_columns?: Array<Customer_Emails_Update_Column>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_emails". */
export type Customer_Emails_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Partner_Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  isEnabled?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: customer_emails */
export type Customer_Emails_Pk_Columns_Input = {
  customerId: Scalars['uuid'];
  email: Scalars['String'];
};

/** select columns of table "customer_emails" */
export enum Customer_Emails_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Email = 'email',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "customer_emails" */
export type Customer_Emails_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "customer_emails" */
export type Customer_Emails_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Emails_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Emails_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customerId?: InputMaybe<Scalars['uuid']>;
  email?: InputMaybe<Scalars['String']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "customer_emails" */
export enum Customer_Emails_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Email = 'email',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Customer_Emails_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Emails_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dental_software" */
export type Dental_Software = {
  __typename?: 'dental_software';
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "dental_software". All fields are combined with a logical 'AND'. */
export type Dental_Software_Bool_Exp = {
  _and?: InputMaybe<Array<Dental_Software_Bool_Exp>>;
  _not?: InputMaybe<Dental_Software_Bool_Exp>;
  _or?: InputMaybe<Array<Dental_Software_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "dental_software". */
export type Dental_Software_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "dental_software" */
export enum Dental_Software_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "dental_software" */
export type Dental_Software_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dental_Software_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dental_Software_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "dentist_specialities" */
export type Dentist_Specialities = {
  __typename?: 'dentist_specialities';
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "dentist_specialities". All fields are combined with a logical 'AND'. */
export type Dentist_Specialities_Bool_Exp = {
  _and?: InputMaybe<Array<Dentist_Specialities_Bool_Exp>>;
  _not?: InputMaybe<Dentist_Specialities_Bool_Exp>;
  _or?: InputMaybe<Array<Dentist_Specialities_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

export enum Dentist_Specialities_Enum {
  Endodontist = 'endodontist',
  GeneralPractitioner = 'generalPractitioner',
  Implantologist = 'implantologist',
  MaxilloFacialSurgeon = 'maxilloFacialSurgeon',
  Orthodontist = 'orthodontist',
  Paedodontist = 'paedodontist',
  Periodontist = 'periodontist',
  Radiologist = 'radiologist',
}

/** Boolean expression to compare columns of type "dentist_specialities_enum". All fields are combined with logical 'AND'. */
export type Dentist_Specialities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Dentist_Specialities_Enum>;
  _in?: InputMaybe<Array<Dentist_Specialities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Dentist_Specialities_Enum>;
  _nin?: InputMaybe<Array<Dentist_Specialities_Enum>>;
};

/** Ordering options when selecting data from "dentist_specialities". */
export type Dentist_Specialities_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** select columns of table "dentist_specialities" */
export enum Dentist_Specialities_Select_Column {
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "dentist_specialities" */
export type Dentist_Specialities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dentist_Specialities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dentist_Specialities_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "educationalSheets" */
export type EducationalSheets = {
  __typename?: 'educationalSheets';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  language: Maybe<Locale_Enum>;
  /** An object relationship */
  location: Locations;
  locationId: Scalars['Int'];
  type: EducationalSheetsTypes_Enum;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** columns and relationships of "educationalSheetsTypes" */
export type EducationalSheetsTypes = {
  __typename?: 'educationalSheetsTypes';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "educationalSheetsTypes". All fields are combined with a logical 'AND'. */
export type EducationalSheetsTypes_Bool_Exp = {
  _and?: InputMaybe<Array<EducationalSheetsTypes_Bool_Exp>>;
  _not?: InputMaybe<EducationalSheetsTypes_Bool_Exp>;
  _or?: InputMaybe<Array<EducationalSheetsTypes_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

export enum EducationalSheetsTypes_Enum {
  Aligner = 'ALIGNER',
  AllOn = 'ALL_ON',
  ApicalResection = 'APICAL_RESECTION',
  BoneGraft = 'BONE_GRAFT',
  Bridge = 'BRIDGE',
  Crown = 'CROWN',
  Decay = 'DECAY',
  DentalHygiene = 'DENTAL_HYGIENE',
  Denture = 'DENTURE',
  Implant = 'IMPLANT',
  InlayOnlay = 'INLAY_ONLAY',
  NightGuard = 'NIGHT_GUARD',
  PeriapicalLesion = 'PERIAPICAL_LESION',
  Periodontics = 'PERIODONTICS',
  PostCore = 'POST_CORE',
  RootCanal = 'ROOT_CANAL',
  RootPlaning = 'ROOT_PLANING',
  SinusLift = 'SINUS_LIFT',
  Veneer = 'VENEER',
}

/** Boolean expression to compare columns of type "educationalSheetsTypes_enum". All fields are combined with logical 'AND'. */
export type EducationalSheetsTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<EducationalSheetsTypes_Enum>;
  _in?: InputMaybe<Array<EducationalSheetsTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<EducationalSheetsTypes_Enum>;
  _nin?: InputMaybe<Array<EducationalSheetsTypes_Enum>>;
};

/** Ordering options when selecting data from "educationalSheetsTypes". */
export type EducationalSheetsTypes_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** select columns of table "educationalSheetsTypes" */
export enum EducationalSheetsTypes_Select_Column {
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "educationalSheetsTypes" */
export type EducationalSheetsTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EducationalSheetsTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EducationalSheetsTypes_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "educationalSheets". All fields are combined with a logical 'AND'. */
export type EducationalSheets_Bool_Exp = {
  _and?: InputMaybe<Array<EducationalSheets_Bool_Exp>>;
  _not?: InputMaybe<EducationalSheets_Bool_Exp>;
  _or?: InputMaybe<Array<EducationalSheets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<Locale_Enum_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  locationId?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<EducationalSheetsTypes_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "educationalSheets" */
export enum EducationalSheets_Constraint {
  /** unique or primary key constraint on columns "id" */
  EducationalSheetsPkey = 'educationalSheets_pkey',
}

/** input type for incrementing numeric columns in table "educationalSheets" */
export type EducationalSheets_Inc_Input = {
  locationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "educationalSheets" */
export type EducationalSheets_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Locale_Enum>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<EducationalSheetsTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "educationalSheets" */
export type EducationalSheets_Mutation_Response = {
  __typename?: 'educationalSheets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<EducationalSheets>;
};

/** on_conflict condition type for table "educationalSheets" */
export type EducationalSheets_On_Conflict = {
  constraint: EducationalSheets_Constraint;
  update_columns?: Array<EducationalSheets_Update_Column>;
  where?: InputMaybe<EducationalSheets_Bool_Exp>;
};

/** Ordering options when selecting data from "educationalSheets". */
export type EducationalSheets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: educationalSheets */
export type EducationalSheets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "educationalSheets" */
export enum EducationalSheets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "educationalSheets" */
export type EducationalSheets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Locale_Enum>;
  locationId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<EducationalSheetsTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "educationalSheets" */
export type EducationalSheets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: EducationalSheets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type EducationalSheets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Locale_Enum>;
  locationId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<EducationalSheetsTypes_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "educationalSheets" */
export enum EducationalSheets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type EducationalSheets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<EducationalSheets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<EducationalSheets_Set_Input>;
  /** filter the rows which have to be updated */
  where: EducationalSheets_Bool_Exp;
};

export enum Element_Group_Modes_Enum {
  Element = 'element',
  Tooth = 'tooth',
}

/** Boolean expression to compare columns of type "element_group_modes_enum". All fields are combined with logical 'AND'. */
export type Element_Group_Modes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Element_Group_Modes_Enum>;
  _in?: InputMaybe<Array<Element_Group_Modes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Element_Group_Modes_Enum>;
  _nin?: InputMaybe<Array<Element_Group_Modes_Enum>>;
};

/** columns and relationships of "element_types" */
export type Element_Types = {
  __typename?: 'element_types';
  color: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  elements: Array<Elements>;
  /** An aggregate relationship */
  elements_aggregate: Elements_Aggregate;
  /** An array relationship */
  full: Array<Element_Types>;
  fullId: Maybe<Scalars['Int']>;
  /** An aggregate relationship */
  full_aggregate: Element_Types_Aggregate;
  id: Scalars['Int'];
  isRetro: Scalars['Boolean'];
  isRoot: Scalars['Boolean'];
  name: Scalars['String'];
  /** An object relationship */
  parent: Maybe<Element_Types>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "element_types" */
export type Element_TypesElementsArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "element_types" */
export type Element_TypesElements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "element_types" */
export type Element_TypesFullArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

/** columns and relationships of "element_types" */
export type Element_TypesFull_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

/** aggregated selection of "element_types" */
export type Element_Types_Aggregate = {
  __typename?: 'element_types_aggregate';
  aggregate: Maybe<Element_Types_Aggregate_Fields>;
  nodes: Array<Element_Types>;
};

export type Element_Types_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Element_Types_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Element_Types_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Element_Types_Aggregate_Bool_Exp_Count>;
};

export type Element_Types_Aggregate_Bool_Exp_Bool_And = {
  arguments: Element_Types_Select_Column_Element_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Element_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Element_Types_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Element_Types_Select_Column_Element_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Element_Types_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Element_Types_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Element_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Element_Types_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "element_types" */
export type Element_Types_Aggregate_Fields = {
  __typename?: 'element_types_aggregate_fields';
  avg: Maybe<Element_Types_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Element_Types_Max_Fields>;
  min: Maybe<Element_Types_Min_Fields>;
  stddev: Maybe<Element_Types_Stddev_Fields>;
  stddev_pop: Maybe<Element_Types_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Element_Types_Stddev_Samp_Fields>;
  sum: Maybe<Element_Types_Sum_Fields>;
  var_pop: Maybe<Element_Types_Var_Pop_Fields>;
  var_samp: Maybe<Element_Types_Var_Samp_Fields>;
  variance: Maybe<Element_Types_Variance_Fields>;
};

/** aggregate fields of "element_types" */
export type Element_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Element_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "element_types" */
export type Element_Types_Aggregate_Order_By = {
  avg?: InputMaybe<Element_Types_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Element_Types_Max_Order_By>;
  min?: InputMaybe<Element_Types_Min_Order_By>;
  stddev?: InputMaybe<Element_Types_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Element_Types_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Element_Types_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Element_Types_Sum_Order_By>;
  var_pop?: InputMaybe<Element_Types_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Element_Types_Var_Samp_Order_By>;
  variance?: InputMaybe<Element_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "element_types" */
export type Element_Types_Arr_Rel_Insert_Input = {
  data: Array<Element_Types_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Element_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Element_Types_Avg_Fields = {
  __typename?: 'element_types_avg_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "element_types" */
export type Element_Types_Avg_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "element_types". All fields are combined with a logical 'AND'. */
export type Element_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Element_Types_Bool_Exp>>;
  _not?: InputMaybe<Element_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Element_Types_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  elements?: InputMaybe<Elements_Bool_Exp>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Bool_Exp>;
  full?: InputMaybe<Element_Types_Bool_Exp>;
  fullId?: InputMaybe<Int_Comparison_Exp>;
  full_aggregate?: InputMaybe<Element_Types_Aggregate_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  isRetro?: InputMaybe<Boolean_Comparison_Exp>;
  isRoot?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  parent?: InputMaybe<Element_Types_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "element_types" */
export enum Element_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  ElementTypesNameUnique = 'element_types_name_unique',
  /** unique or primary key constraint on columns "id" */
  ElementTypesPkey = 'element_types_pkey',
}

/** input type for incrementing numeric columns in table "element_types" */
export type Element_Types_Inc_Input = {
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "element_types" */
export type Element_Types_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  elements?: InputMaybe<Elements_Arr_Rel_Insert_Input>;
  full?: InputMaybe<Element_Types_Arr_Rel_Insert_Input>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isRetro?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Element_Types_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Element_Types_Max_Fields = {
  __typename?: 'element_types_max_fields';
  color: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "element_types" */
export type Element_Types_Max_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Element_Types_Min_Fields = {
  __typename?: 'element_types_min_fields';
  color: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "element_types" */
export type Element_Types_Min_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "element_types" */
export type Element_Types_Mutation_Response = {
  __typename?: 'element_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Element_Types>;
};

/** input type for inserting object relation for remote table "element_types" */
export type Element_Types_Obj_Rel_Insert_Input = {
  data: Element_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Element_Types_On_Conflict>;
};

/** on_conflict condition type for table "element_types" */
export type Element_Types_On_Conflict = {
  constraint: Element_Types_Constraint;
  update_columns?: Array<Element_Types_Update_Column>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "element_types". */
export type Element_Types_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Order_By>;
  fullId?: InputMaybe<Order_By>;
  full_aggregate?: InputMaybe<Element_Types_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isRetro?: InputMaybe<Order_By>;
  isRoot?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent?: InputMaybe<Element_Types_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: element_types */
export type Element_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "element_types" */
export enum Element_Types_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FullId = 'fullId',
  /** column name */
  Id = 'id',
  /** column name */
  IsRetro = 'isRetro',
  /** column name */
  IsRoot = 'isRoot',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "element_types_aggregate_bool_exp_bool_and_arguments_columns" columns of table "element_types" */
export enum Element_Types_Select_Column_Element_Types_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRetro = 'isRetro',
  /** column name */
  IsRoot = 'isRoot',
}

/** select "element_types_aggregate_bool_exp_bool_or_arguments_columns" columns of table "element_types" */
export enum Element_Types_Select_Column_Element_Types_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRetro = 'isRetro',
  /** column name */
  IsRoot = 'isRoot',
}

/** input type for updating data in table "element_types" */
export type Element_Types_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isRetro?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Element_Types_Stddev_Fields = {
  __typename?: 'element_types_stddev_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "element_types" */
export type Element_Types_Stddev_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Element_Types_Stddev_Pop_Fields = {
  __typename?: 'element_types_stddev_pop_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "element_types" */
export type Element_Types_Stddev_Pop_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Element_Types_Stddev_Samp_Fields = {
  __typename?: 'element_types_stddev_samp_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "element_types" */
export type Element_Types_Stddev_Samp_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "element_types" */
export type Element_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Element_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Element_Types_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isRetro?: InputMaybe<Scalars['Boolean']>;
  isRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Element_Types_Sum_Fields = {
  __typename?: 'element_types_sum_fields';
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "element_types" */
export type Element_Types_Sum_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "element_types" */
export enum Element_Types_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FullId = 'fullId',
  /** column name */
  Id = 'id',
  /** column name */
  IsRetro = 'isRetro',
  /** column name */
  IsRoot = 'isRoot',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Element_Types_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Element_Types_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Element_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Element_Types_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Element_Types_Var_Pop_Fields = {
  __typename?: 'element_types_var_pop_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "element_types" */
export type Element_Types_Var_Pop_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Element_Types_Var_Samp_Fields = {
  __typename?: 'element_types_var_samp_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "element_types" */
export type Element_Types_Var_Samp_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Element_Types_Variance_Fields = {
  __typename?: 'element_types_variance_fields';
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "element_types" */
export type Element_Types_Variance_Order_By = {
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "elements" */
export type Elements = {
  __typename?: 'elements';
  /** An object relationship */
  consultation: Maybe<Consultation>;
  consultationId: Maybe<Scalars['Int']>;
  coordinates: Scalars['jsonb'];
  createdAt: Scalars['timestamptz'];
  detectionScore: Maybe<Scalars['float8']>;
  editedByUser: Maybe<Scalars['Boolean']>;
  fullId: Scalars['Int'];
  id: Scalars['Int'];
  onTooth: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  type: Element_Types;
  typeId: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  xray: Maybe<Xrays>;
  xrayId: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "elements" */
export type ElementsCoordinatesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "elements" */
export type ElementsOnToothArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "elements" */
export type Elements_Aggregate = {
  __typename?: 'elements_aggregate';
  aggregate: Maybe<Elements_Aggregate_Fields>;
  nodes: Array<Elements>;
};

export type Elements_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Elements_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Elements_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Elements_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Elements_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Elements_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Elements_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Elements_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Elements_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Elements_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Elements_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Elements_Aggregate_Bool_Exp_Var_Samp>;
};

export type Elements_Aggregate_Bool_Exp_Avg = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Bool_And = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Corr = {
  arguments: Elements_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Elements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Elements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Elements_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Elements_Aggregate_Bool_Exp_Max = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Min = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Sum = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Elements_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Elements_Select_Column_Elements_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Elements_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "elements" */
export type Elements_Aggregate_Fields = {
  __typename?: 'elements_aggregate_fields';
  avg: Maybe<Elements_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Elements_Max_Fields>;
  min: Maybe<Elements_Min_Fields>;
  stddev: Maybe<Elements_Stddev_Fields>;
  stddev_pop: Maybe<Elements_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Elements_Stddev_Samp_Fields>;
  sum: Maybe<Elements_Sum_Fields>;
  var_pop: Maybe<Elements_Var_Pop_Fields>;
  var_samp: Maybe<Elements_Var_Samp_Fields>;
  variance: Maybe<Elements_Variance_Fields>;
};

/** aggregate fields of "elements" */
export type Elements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Elements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "elements" */
export type Elements_Aggregate_Order_By = {
  avg?: InputMaybe<Elements_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Elements_Max_Order_By>;
  min?: InputMaybe<Elements_Min_Order_By>;
  stddev?: InputMaybe<Elements_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Elements_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Elements_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Elements_Sum_Order_By>;
  var_pop?: InputMaybe<Elements_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Elements_Var_Samp_Order_By>;
  variance?: InputMaybe<Elements_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Elements_Append_Input = {
  coordinates?: InputMaybe<Scalars['jsonb']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "elements" */
export type Elements_Arr_Rel_Insert_Input = {
  data: Array<Elements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Elements_On_Conflict>;
};

/** aggregate avg on columns */
export type Elements_Avg_Fields = {
  __typename?: 'elements_avg_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "elements" */
export type Elements_Avg_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "elements". All fields are combined with a logical 'AND'. */
export type Elements_Bool_Exp = {
  _and?: InputMaybe<Array<Elements_Bool_Exp>>;
  _not?: InputMaybe<Elements_Bool_Exp>;
  _or?: InputMaybe<Array<Elements_Bool_Exp>>;
  consultation?: InputMaybe<Consultation_Bool_Exp>;
  consultationId?: InputMaybe<Int_Comparison_Exp>;
  coordinates?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  detectionScore?: InputMaybe<Float8_Comparison_Exp>;
  editedByUser?: InputMaybe<Boolean_Comparison_Exp>;
  fullId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  onTooth?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Element_Types_Bool_Exp>;
  typeId?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  xray?: InputMaybe<Xrays_Bool_Exp>;
  xrayId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "elements" */
export enum Elements_Constraint {
  /** unique or primary key constraint on columns "id" */
  ElementsPkey = 'elements_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Elements_Delete_At_Path_Input = {
  coordinates?: InputMaybe<Array<Scalars['String']>>;
  onTooth?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Elements_Delete_Elem_Input = {
  coordinates?: InputMaybe<Scalars['Int']>;
  onTooth?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Elements_Delete_Key_Input = {
  coordinates?: InputMaybe<Scalars['String']>;
  onTooth?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "elements" */
export type Elements_Inc_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  detectionScore?: InputMaybe<Scalars['float8']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  typeId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "elements" */
export type Elements_Insert_Input = {
  consultation?: InputMaybe<Consultation_Obj_Rel_Insert_Input>;
  consultationId?: InputMaybe<Scalars['Int']>;
  coordinates?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectionScore?: InputMaybe<Scalars['float8']>;
  editedByUser?: InputMaybe<Scalars['Boolean']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Element_Types_Obj_Rel_Insert_Input>;
  typeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  xray?: InputMaybe<Xrays_Obj_Rel_Insert_Input>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Elements_Max_Fields = {
  __typename?: 'elements_max_fields';
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  detectionScore: Maybe<Scalars['float8']>;
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  typeId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
  xrayId: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "elements" */
export type Elements_Max_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Elements_Min_Fields = {
  __typename?: 'elements_min_fields';
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  detectionScore: Maybe<Scalars['float8']>;
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  typeId: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
  xrayId: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "elements" */
export type Elements_Min_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "elements" */
export type Elements_Mutation_Response = {
  __typename?: 'elements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Elements>;
};

/** on_conflict condition type for table "elements" */
export type Elements_On_Conflict = {
  constraint: Elements_Constraint;
  update_columns?: Array<Elements_Update_Column>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** Ordering options when selecting data from "elements". */
export type Elements_Order_By = {
  consultation?: InputMaybe<Consultation_Order_By>;
  consultationId?: InputMaybe<Order_By>;
  coordinates?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  editedByUser?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  onTooth?: InputMaybe<Order_By>;
  type?: InputMaybe<Element_Types_Order_By>;
  typeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  xray?: InputMaybe<Xrays_Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: elements */
export type Elements_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Elements_Prepend_Input = {
  coordinates?: InputMaybe<Scalars['jsonb']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "elements" */
export enum Elements_Select_Column {
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DetectionScore = 'detectionScore',
  /** column name */
  EditedByUser = 'editedByUser',
  /** column name */
  FullId = 'fullId',
  /** column name */
  Id = 'id',
  /** column name */
  OnTooth = 'onTooth',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  XrayId = 'xrayId',
}

/** select "elements_aggregate_bool_exp_avg_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_bool_and_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  EditedByUser = 'editedByUser',
}

/** select "elements_aggregate_bool_exp_bool_or_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  EditedByUser = 'editedByUser',
}

/** select "elements_aggregate_bool_exp_corr_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_max_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_min_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_sum_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** select "elements_aggregate_bool_exp_var_samp_arguments_columns" columns of table "elements" */
export enum Elements_Select_Column_Elements_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  DetectionScore = 'detectionScore',
}

/** input type for updating data in table "elements" */
export type Elements_Set_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  coordinates?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectionScore?: InputMaybe<Scalars['float8']>;
  editedByUser?: InputMaybe<Scalars['Boolean']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
  typeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Elements_Stddev_Fields = {
  __typename?: 'elements_stddev_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "elements" */
export type Elements_Stddev_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Elements_Stddev_Pop_Fields = {
  __typename?: 'elements_stddev_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "elements" */
export type Elements_Stddev_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Elements_Stddev_Samp_Fields = {
  __typename?: 'elements_stddev_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "elements" */
export type Elements_Stddev_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "elements" */
export type Elements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Elements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Elements_Stream_Cursor_Value_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  coordinates?: InputMaybe<Scalars['jsonb']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  detectionScore?: InputMaybe<Scalars['float8']>;
  editedByUser?: InputMaybe<Scalars['Boolean']>;
  fullId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  onTooth?: InputMaybe<Scalars['jsonb']>;
  typeId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Elements_Sum_Fields = {
  __typename?: 'elements_sum_fields';
  consultationId: Maybe<Scalars['Int']>;
  detectionScore: Maybe<Scalars['float8']>;
  fullId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  typeId: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "elements" */
export type Elements_Sum_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** update columns of table "elements" */
export enum Elements_Update_Column {
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  Coordinates = 'coordinates',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DetectionScore = 'detectionScore',
  /** column name */
  EditedByUser = 'editedByUser',
  /** column name */
  FullId = 'fullId',
  /** column name */
  Id = 'id',
  /** column name */
  OnTooth = 'onTooth',
  /** column name */
  TypeId = 'typeId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  XrayId = 'xrayId',
}

export type Elements_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Elements_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Elements_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Elements_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Elements_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Elements_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Elements_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Elements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Elements_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Elements_Var_Pop_Fields = {
  __typename?: 'elements_var_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "elements" */
export type Elements_Var_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Elements_Var_Samp_Fields = {
  __typename?: 'elements_var_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "elements" */
export type Elements_Var_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Elements_Variance_Fields = {
  __typename?: 'elements_variance_fields';
  consultationId: Maybe<Scalars['Float']>;
  detectionScore: Maybe<Scalars['Float']>;
  fullId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  typeId: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "elements" */
export type Elements_Variance_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  detectionScore?: InputMaybe<Order_By>;
  fullId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  typeId?: InputMaybe<Order_By>;
};

/** columns and relationships of "event_user" */
export type Event_User = {
  __typename?: 'event_user';
  consultation_id: Maybe<Scalars['Int']>;
  date: Scalars['timestamptz'];
  event_name: Scalars['String'];
  id: Scalars['Int'];
  patient_id: Maybe<Scalars['Int']>;
  user_id: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "event_user". All fields are combined with a logical 'AND'. */
export type Event_User_Bool_Exp = {
  _and?: InputMaybe<Array<Event_User_Bool_Exp>>;
  _not?: InputMaybe<Event_User_Bool_Exp>;
  _or?: InputMaybe<Array<Event_User_Bool_Exp>>;
  consultation_id?: InputMaybe<Int_Comparison_Exp>;
  date?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  patient_id?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "event_user" */
export enum Event_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  EventUserPkey = 'event_user_pkey',
}

/** input type for inserting data into table "event_user" */
export type Event_User_Insert_Input = {
  consultation_id?: InputMaybe<Scalars['Int']>;
  event_name?: InputMaybe<Scalars['String']>;
  patient_id?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "event_user" */
export type Event_User_Mutation_Response = {
  __typename?: 'event_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Event_User>;
};

/** on_conflict condition type for table "event_user" */
export type Event_User_On_Conflict = {
  constraint: Event_User_Constraint;
  update_columns?: Array<Event_User_Update_Column>;
  where?: InputMaybe<Event_User_Bool_Exp>;
};

/** Ordering options when selecting data from "event_user". */
export type Event_User_Order_By = {
  consultation_id?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  patient_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "event_user" */
export enum Event_User_Select_Column {
  /** column name */
  ConsultationId = 'consultation_id',
  /** column name */
  Date = 'date',
  /** column name */
  EventName = 'event_name',
  /** column name */
  Id = 'id',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "event_user" */
export type Event_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Event_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Event_User_Stream_Cursor_Value_Input = {
  consultation_id?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['timestamptz']>;
  event_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  patient_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** placeholder for update columns of table "event_user" (current role has no relevant permissions) */
export enum Event_User_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export enum Locale_Enum {
  Ca = 'ca',
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Pl = 'pl',
  Pt = 'pt',
  Sv = 'sv',
}

/** Boolean expression to compare columns of type "locale_enum". All fields are combined with logical 'AND'. */
export type Locale_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Locale_Enum>;
  _in?: InputMaybe<Array<Locale_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Locale_Enum>;
  _nin?: InputMaybe<Array<Locale_Enum>>;
};

/** columns and relationships of "locations" */
export type Locations = {
  __typename?: 'locations';
  address: Maybe<Scalars['String']>;
  allisonePlusVersion: Maybe<Scalars['Int']>;
  /** An array relationship */
  billings: Array<Billings>;
  /** An aggregate relationship */
  billings_aggregate: Billings_Aggregate;
  churnDate: Maybe<Scalars['timestamptz']>;
  city: Maybe<Scalars['String']>;
  country: Maybe<Countries_Enum>;
  cp: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dental_software: Maybe<Dental_Software>;
  dental_software_id: Maybe<Scalars['Int']>;
  dental_software_other: Maybe<Scalars['String']>;
  finess: Maybe<Scalars['String']>;
  hasProductTour: Scalars['Boolean'];
  id: Scalars['Int'];
  image: Maybe<Scalars['String']>;
  isClinicBetaPilot: Scalars['Boolean'];
  isMeasuresEnabled: Scalars['Boolean'];
  isTreatmentPreferencesEnabled: Maybe<Scalars['Boolean']>;
  isWatcherBetaPilot: Scalars['Boolean'];
  /** An array relationship */
  locations_users: Array<Users_Locations>;
  /** An aggregate relationship */
  locations_users_aggregate: Users_Locations_Aggregate;
  /** An array relationship */
  partner_customers: Array<Partner_Customers>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phone: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  reseller: Maybe<Scalars['String']>;
  salesforceBusinessAccountID: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  version: App_Versions_Enum;
  /** An object relationship */
  xray_software: Maybe<Xray_Software>;
  xray_software_id: Maybe<Scalars['Int']>;
  xray_software_other: Maybe<Scalars['String']>;
};

/** columns and relationships of "locations" */
export type LocationsBillingsArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsBillings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsLocations_UsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsLocations_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsPartner_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Customers_Order_By>>;
  where?: InputMaybe<Partner_Customers_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "locations" */
export type LocationsPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** aggregated selection of "locations" */
export type Locations_Aggregate = {
  __typename?: 'locations_aggregate';
  aggregate: Maybe<Locations_Aggregate_Fields>;
  nodes: Array<Locations>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_Fields = {
  __typename?: 'locations_aggregate_fields';
  avg: Maybe<Locations_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Locations_Max_Fields>;
  min: Maybe<Locations_Min_Fields>;
  stddev: Maybe<Locations_Stddev_Fields>;
  stddev_pop: Maybe<Locations_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Locations_Stddev_Samp_Fields>;
  sum: Maybe<Locations_Sum_Fields>;
  var_pop: Maybe<Locations_Var_Pop_Fields>;
  var_samp: Maybe<Locations_Var_Samp_Fields>;
  variance: Maybe<Locations_Variance_Fields>;
};

/** aggregate fields of "locations" */
export type Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Locations_Avg_Fields = {
  __typename?: 'locations_avg_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Locations_Bool_Exp>>;
  _not?: InputMaybe<Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Locations_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  allisonePlusVersion?: InputMaybe<Int_Comparison_Exp>;
  billings?: InputMaybe<Billings_Bool_Exp>;
  billings_aggregate?: InputMaybe<Billings_Aggregate_Bool_Exp>;
  churnDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<Countries_Enum_Comparison_Exp>;
  cp?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dental_software?: InputMaybe<Dental_Software_Bool_Exp>;
  dental_software_id?: InputMaybe<Int_Comparison_Exp>;
  dental_software_other?: InputMaybe<String_Comparison_Exp>;
  finess?: InputMaybe<String_Comparison_Exp>;
  hasProductTour?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  isClinicBetaPilot?: InputMaybe<Boolean_Comparison_Exp>;
  isMeasuresEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  isTreatmentPreferencesEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  isWatcherBetaPilot?: InputMaybe<Boolean_Comparison_Exp>;
  locations_users?: InputMaybe<Users_Locations_Bool_Exp>;
  locations_users_aggregate?: InputMaybe<Users_Locations_Aggregate_Bool_Exp>;
  partner_customers?: InputMaybe<Partner_Customers_Bool_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  reseller?: InputMaybe<String_Comparison_Exp>;
  salesforceBusinessAccountID?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<App_Versions_Enum_Comparison_Exp>;
  xray_software?: InputMaybe<Xray_Software_Bool_Exp>;
  xray_software_id?: InputMaybe<Int_Comparison_Exp>;
  xray_software_other?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  CentersPkey = 'centers_pkey',
}

/** input type for incrementing numeric columns in table "locations" */
export type Locations_Inc_Input = {
  allisonePlusVersion?: InputMaybe<Scalars['Int']>;
  dental_software_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  xray_software_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
  address?: InputMaybe<Scalars['String']>;
  allisonePlusVersion?: InputMaybe<Scalars['Int']>;
  billings?: InputMaybe<Billings_Arr_Rel_Insert_Input>;
  churnDate?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries_Enum>;
  cp?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_software_id?: InputMaybe<Scalars['Int']>;
  dental_software_other?: InputMaybe<Scalars['String']>;
  finess?: InputMaybe<Scalars['String']>;
  hasProductTour?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  isClinicBetaPilot?: InputMaybe<Scalars['Boolean']>;
  isMeasuresEnabled?: InputMaybe<Scalars['Boolean']>;
  isTreatmentPreferencesEnabled?: InputMaybe<Scalars['Boolean']>;
  isWatcherBetaPilot?: InputMaybe<Scalars['Boolean']>;
  locations_users?: InputMaybe<Users_Locations_Arr_Rel_Insert_Input>;
  partner_customers?: InputMaybe<Partner_Customers_Arr_Rel_Insert_Input>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  reseller?: InputMaybe<Scalars['String']>;
  salesforceBusinessAccountID?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<App_Versions_Enum>;
  xray_software_id?: InputMaybe<Scalars['Int']>;
  xray_software_other?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Locations_Max_Fields = {
  __typename?: 'locations_max_fields';
  address: Maybe<Scalars['String']>;
  allisonePlusVersion: Maybe<Scalars['Int']>;
  churnDate: Maybe<Scalars['timestamptz']>;
  city: Maybe<Scalars['String']>;
  cp: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  dental_software_id: Maybe<Scalars['Int']>;
  dental_software_other: Maybe<Scalars['String']>;
  finess: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  image: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  reseller: Maybe<Scalars['String']>;
  salesforceBusinessAccountID: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  xray_software_id: Maybe<Scalars['Int']>;
  xray_software_other: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Locations_Min_Fields = {
  __typename?: 'locations_min_fields';
  address: Maybe<Scalars['String']>;
  allisonePlusVersion: Maybe<Scalars['Int']>;
  churnDate: Maybe<Scalars['timestamptz']>;
  city: Maybe<Scalars['String']>;
  cp: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  dental_software_id: Maybe<Scalars['Int']>;
  dental_software_other: Maybe<Scalars['String']>;
  finess: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  image: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  region: Maybe<Scalars['String']>;
  reseller: Maybe<Scalars['String']>;
  salesforceBusinessAccountID: Maybe<Scalars['String']>;
  status: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  xray_software_id: Maybe<Scalars['Int']>;
  xray_software_other: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
  __typename?: 'locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
  data: Locations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
  constraint: Locations_Constraint;
  update_columns?: Array<Locations_Update_Column>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
  address?: InputMaybe<Order_By>;
  allisonePlusVersion?: InputMaybe<Order_By>;
  billings_aggregate?: InputMaybe<Billings_Aggregate_Order_By>;
  churnDate?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  cp?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dental_software?: InputMaybe<Dental_Software_Order_By>;
  dental_software_id?: InputMaybe<Order_By>;
  dental_software_other?: InputMaybe<Order_By>;
  finess?: InputMaybe<Order_By>;
  hasProductTour?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  isClinicBetaPilot?: InputMaybe<Order_By>;
  isMeasuresEnabled?: InputMaybe<Order_By>;
  isTreatmentPreferencesEnabled?: InputMaybe<Order_By>;
  isWatcherBetaPilot?: InputMaybe<Order_By>;
  locations_users_aggregate?: InputMaybe<Users_Locations_Aggregate_Order_By>;
  partner_customers_aggregate?: InputMaybe<Partner_Customers_Aggregate_Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  reseller?: InputMaybe<Order_By>;
  salesforceBusinessAccountID?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
  xray_software?: InputMaybe<Xray_Software_Order_By>;
  xray_software_id?: InputMaybe<Order_By>;
  xray_software_other?: InputMaybe<Order_By>;
};

/** primary key columns input for table: locations */
export type Locations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AllisonePlusVersion = 'allisonePlusVersion',
  /** column name */
  ChurnDate = 'churnDate',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Cp = 'cp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DentalSoftwareId = 'dental_software_id',
  /** column name */
  DentalSoftwareOther = 'dental_software_other',
  /** column name */
  Finess = 'finess',
  /** column name */
  HasProductTour = 'hasProductTour',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsClinicBetaPilot = 'isClinicBetaPilot',
  /** column name */
  IsMeasuresEnabled = 'isMeasuresEnabled',
  /** column name */
  IsTreatmentPreferencesEnabled = 'isTreatmentPreferencesEnabled',
  /** column name */
  IsWatcherBetaPilot = 'isWatcherBetaPilot',
  /** column name */
  Phone = 'phone',
  /** column name */
  Region = 'region',
  /** column name */
  Reseller = 'reseller',
  /** column name */
  SalesforceBusinessAccountId = 'salesforceBusinessAccountID',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  XraySoftwareId = 'xray_software_id',
  /** column name */
  XraySoftwareOther = 'xray_software_other',
}

/** input type for updating data in table "locations" */
export type Locations_Set_Input = {
  address?: InputMaybe<Scalars['String']>;
  allisonePlusVersion?: InputMaybe<Scalars['Int']>;
  churnDate?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries_Enum>;
  cp?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_software_id?: InputMaybe<Scalars['Int']>;
  dental_software_other?: InputMaybe<Scalars['String']>;
  finess?: InputMaybe<Scalars['String']>;
  hasProductTour?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  isClinicBetaPilot?: InputMaybe<Scalars['Boolean']>;
  isMeasuresEnabled?: InputMaybe<Scalars['Boolean']>;
  isTreatmentPreferencesEnabled?: InputMaybe<Scalars['Boolean']>;
  isWatcherBetaPilot?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  reseller?: InputMaybe<Scalars['String']>;
  salesforceBusinessAccountID?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<App_Versions_Enum>;
  xray_software_id?: InputMaybe<Scalars['Int']>;
  xray_software_other?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Locations_Stddev_Fields = {
  __typename?: 'locations_stddev_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Locations_Stddev_Pop_Fields = {
  __typename?: 'locations_stddev_pop_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Locations_Stddev_Samp_Fields = {
  __typename?: 'locations_stddev_samp_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  allisonePlusVersion?: InputMaybe<Scalars['Int']>;
  churnDate?: InputMaybe<Scalars['timestamptz']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Countries_Enum>;
  cp?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dental_software_id?: InputMaybe<Scalars['Int']>;
  dental_software_other?: InputMaybe<Scalars['String']>;
  finess?: InputMaybe<Scalars['String']>;
  hasProductTour?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  image?: InputMaybe<Scalars['String']>;
  isClinicBetaPilot?: InputMaybe<Scalars['Boolean']>;
  isMeasuresEnabled?: InputMaybe<Scalars['Boolean']>;
  isTreatmentPreferencesEnabled?: InputMaybe<Scalars['Boolean']>;
  isWatcherBetaPilot?: InputMaybe<Scalars['Boolean']>;
  phone?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  reseller?: InputMaybe<Scalars['String']>;
  salesforceBusinessAccountID?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<App_Versions_Enum>;
  xray_software_id?: InputMaybe<Scalars['Int']>;
  xray_software_other?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Locations_Sum_Fields = {
  __typename?: 'locations_sum_fields';
  allisonePlusVersion: Maybe<Scalars['Int']>;
  dental_software_id: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  xray_software_id: Maybe<Scalars['Int']>;
};

/** update columns of table "locations" */
export enum Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AllisonePlusVersion = 'allisonePlusVersion',
  /** column name */
  ChurnDate = 'churnDate',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  Cp = 'cp',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DentalSoftwareId = 'dental_software_id',
  /** column name */
  DentalSoftwareOther = 'dental_software_other',
  /** column name */
  Finess = 'finess',
  /** column name */
  HasProductTour = 'hasProductTour',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsClinicBetaPilot = 'isClinicBetaPilot',
  /** column name */
  IsMeasuresEnabled = 'isMeasuresEnabled',
  /** column name */
  IsTreatmentPreferencesEnabled = 'isTreatmentPreferencesEnabled',
  /** column name */
  IsWatcherBetaPilot = 'isWatcherBetaPilot',
  /** column name */
  Phone = 'phone',
  /** column name */
  Region = 'region',
  /** column name */
  Reseller = 'reseller',
  /** column name */
  SalesforceBusinessAccountId = 'salesforceBusinessAccountID',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
  /** column name */
  XraySoftwareId = 'xray_software_id',
  /** column name */
  XraySoftwareOther = 'xray_software_other',
}

export type Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Locations_Var_Pop_Fields = {
  __typename?: 'locations_var_pop_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Locations_Var_Samp_Fields = {
  __typename?: 'locations_var_samp_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Locations_Variance_Fields = {
  __typename?: 'locations_variance_fields';
  allisonePlusVersion: Maybe<Scalars['Float']>;
  dental_software_id: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  xray_software_id: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** insert a single row into the table: "observations" */
  addObservation: Maybe<Observations>;
  /** insert data into the table: "observations" */
  addObservations: Maybe<Observations_Mutation_Response>;
  createUser: Maybe<CreateUserOutput>;
  /** insert a single row into the table: "consultation" */
  create_consultation: Maybe<Consultation>;
  /** insert data into the table: "consultation" */
  create_consultations: Maybe<Consultation_Mutation_Response>;
  /** insert a single row into the table: "operations" */
  create_operation: Maybe<Operations>;
  /** insert data into the table: "operations" */
  create_operations: Maybe<Operations_Mutation_Response>;
  /** delete data from the table: "anatomies" */
  delete_anatomies: Maybe<Anatomies_Mutation_Response>;
  /** delete single row from the table: "anatomies" */
  delete_anatomies_by_pk: Maybe<Anatomies>;
  /** delete data from the table: "billings" */
  delete_billings: Maybe<Billings_Mutation_Response>;
  /** delete single row from the table: "billings" */
  delete_billings_by_pk: Maybe<Billings>;
  /** delete data from the table: "customer_emails" */
  delete_customer_emails: Maybe<Customer_Emails_Mutation_Response>;
  /** delete single row from the table: "customer_emails" */
  delete_customer_emails_by_pk: Maybe<Customer_Emails>;
  /** delete data from the table: "educationalSheets" */
  delete_educationalSheets: Maybe<EducationalSheets_Mutation_Response>;
  /** delete single row from the table: "educationalSheets" */
  delete_educationalSheets_by_pk: Maybe<EducationalSheets>;
  /** delete data from the table: "element_types" */
  delete_element_types: Maybe<Element_Types_Mutation_Response>;
  /** delete single row from the table: "element_types" */
  delete_element_types_by_pk: Maybe<Element_Types>;
  /** delete data from the table: "elements" */
  delete_elements: Maybe<Elements_Mutation_Response>;
  /** delete single row from the table: "elements" */
  delete_elements_by_pk: Maybe<Elements>;
  /** delete data from the table: "event_user" */
  delete_event_user: Maybe<Event_User_Mutation_Response>;
  /** delete single row from the table: "event_user" */
  delete_event_user_by_pk: Maybe<Event_User>;
  /** delete data from the table: "locations" */
  delete_locations: Maybe<Locations_Mutation_Response>;
  /** delete single row from the table: "locations" */
  delete_locations_by_pk: Maybe<Locations>;
  /** delete single row from the table: "operations" */
  delete_operation: Maybe<Operations>;
  /** delete data from the table: "operations" */
  delete_operations: Maybe<Operations_Mutation_Response>;
  /** delete data from the table: "patients_partners" */
  delete_patients_partners: Maybe<Patients_Partners_Mutation_Response>;
  /** delete single row from the table: "patients_partners" */
  delete_patients_partners_by_pk: Maybe<Patients_Partners>;
  /** delete data from the table: "personas" */
  delete_personas: Maybe<Personas_Mutation_Response>;
  /** delete single row from the table: "personas" */
  delete_personas_by_pk: Maybe<Personas>;
  /** delete data from the table: "resellers" */
  delete_resellers: Maybe<Resellers_Mutation_Response>;
  /** delete single row from the table: "resellers" */
  delete_resellers_by_pk: Maybe<Resellers>;
  /** delete data from the table: "treatment_preferences" */
  delete_treatment_preferences: Maybe<Treatment_Preferences_Mutation_Response>;
  /** delete single row from the table: "treatment_preferences" */
  delete_treatment_preferences_by_pk: Maybe<Treatment_Preferences>;
  /** delete data from the table: "users" */
  delete_users: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk: Maybe<Users>;
  /** delete data from the table: "users_locations" */
  delete_users_locations: Maybe<Users_Locations_Mutation_Response>;
  /** delete single row from the table: "users_locations" */
  delete_users_locations_by_pk: Maybe<Users_Locations>;
  /** delete data from the table: "users_roles" */
  delete_users_roles: Maybe<Users_Roles_Mutation_Response>;
  /** delete single row from the table: "users_roles" */
  delete_users_roles_by_pk: Maybe<Users_Roles>;
  /** insert a single row into the table: "notifications" */
  insertNotification: Maybe<Notifications>;
  /** insert data into the table: "notifications" */
  insertNotifications: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "oral_structures" */
  insertOralStructure: Maybe<OralStructures>;
  /** insert data into the table: "oral_structures" */
  insertOralStructures: Maybe<OralStructures_Mutation_Response>;
  /** insert a single row into the table: "patients" */
  insertPatient: Maybe<Patients>;
  /** insert data into the table: "patients" */
  insertPatients: Maybe<Patients_Mutation_Response>;
  /** insert a single row into the table: "xrays" */
  insertXray: Maybe<Xrays>;
  /** insert data into the table: "xrays" */
  insertXrays: Maybe<Xrays_Mutation_Response>;
  /** insert data into the table: "allisone_plus_configuration" */
  insert_allisone_plus_configuration: Maybe<Allisone_Plus_Configuration_Mutation_Response>;
  /** insert a single row into the table: "allisone_plus_configuration" */
  insert_allisone_plus_configuration_one: Maybe<Allisone_Plus_Configuration>;
  /** insert data into the table: "allisone_plus_users_configurations" */
  insert_allisone_plus_users_configurations: Maybe<Allisone_Plus_Users_Configurations_Mutation_Response>;
  /** insert a single row into the table: "allisone_plus_users_configurations" */
  insert_allisone_plus_users_configurations_one: Maybe<Allisone_Plus_Users_Configurations>;
  /** insert data into the table: "anatomies" */
  insert_anatomies: Maybe<Anatomies_Mutation_Response>;
  /** insert a single row into the table: "anatomies" */
  insert_anatomies_one: Maybe<Anatomies>;
  /** insert data into the table: "api_clients_logs" */
  insert_api_clients_logs: Maybe<Api_Clients_Logs_Mutation_Response>;
  /** insert data into the table: "api_logs" */
  insert_api_logs: Maybe<Api_Logs_Mutation_Response>;
  /** insert data into the table: "billings" */
  insert_billings: Maybe<Billings_Mutation_Response>;
  /** insert a single row into the table: "billings" */
  insert_billings_one: Maybe<Billings>;
  /** insert data into the table: "clinical_monitoring" */
  insert_clinical_monitoring: Maybe<Clinical_Monitoring_Mutation_Response>;
  /** insert a single row into the table: "clinical_monitoring" */
  insert_clinical_monitoring_one: Maybe<Clinical_Monitoring>;
  /** insert data into the table: "customer_emails" */
  insert_customer_emails: Maybe<Customer_Emails_Mutation_Response>;
  /** insert a single row into the table: "customer_emails" */
  insert_customer_emails_one: Maybe<Customer_Emails>;
  /** insert data into the table: "educationalSheets" */
  insert_educationalSheets: Maybe<EducationalSheets_Mutation_Response>;
  /** insert a single row into the table: "educationalSheets" */
  insert_educationalSheets_one: Maybe<EducationalSheets>;
  /** insert data into the table: "element_types" */
  insert_element_types: Maybe<Element_Types_Mutation_Response>;
  /** insert a single row into the table: "element_types" */
  insert_element_types_one: Maybe<Element_Types>;
  /** insert data into the table: "elements" */
  insert_elements: Maybe<Elements_Mutation_Response>;
  /** insert a single row into the table: "elements" */
  insert_elements_one: Maybe<Elements>;
  /** insert data into the table: "event_user" */
  insert_event_user: Maybe<Event_User_Mutation_Response>;
  /** insert a single row into the table: "event_user" */
  insert_event_user_one: Maybe<Event_User>;
  /** insert data into the table: "locations" */
  insert_locations: Maybe<Locations_Mutation_Response>;
  /** insert a single row into the table: "locations" */
  insert_locations_one: Maybe<Locations>;
  /** insert data into the table: "partner_customers" */
  insert_partner_customers: Maybe<Partner_Customers_Mutation_Response>;
  /** insert a single row into the table: "partner_customers" */
  insert_partner_customers_one: Maybe<Partner_Customers>;
  /** insert data into the table: "partners" */
  insert_partners: Maybe<Partners_Mutation_Response>;
  /** insert a single row into the table: "partners" */
  insert_partners_one: Maybe<Partners>;
  /** insert data into the table: "patients_partners" */
  insert_patients_partners: Maybe<Patients_Partners_Mutation_Response>;
  /** insert a single row into the table: "patients_partners" */
  insert_patients_partners_one: Maybe<Patients_Partners>;
  /** insert data into the table: "personas" */
  insert_personas: Maybe<Personas_Mutation_Response>;
  /** insert a single row into the table: "personas" */
  insert_personas_one: Maybe<Personas>;
  /** insert data into the table: "resellers" */
  insert_resellers: Maybe<Resellers_Mutation_Response>;
  /** insert a single row into the table: "resellers" */
  insert_resellers_one: Maybe<Resellers>;
  /** insert data into the table: "treatment_preferences" */
  insert_treatment_preferences: Maybe<Treatment_Preferences_Mutation_Response>;
  /** insert a single row into the table: "treatment_preferences" */
  insert_treatment_preferences_one: Maybe<Treatment_Preferences>;
  /** insert data into the table: "users" */
  insert_users: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_locations" */
  insert_users_locations: Maybe<Users_Locations_Mutation_Response>;
  /** insert a single row into the table: "users_locations" */
  insert_users_locations_one: Maybe<Users_Locations>;
  /** insert a single row into the table: "users" */
  insert_users_one: Maybe<Users>;
  /** insert data into the table: "users_roles" */
  insert_users_roles: Maybe<Users_Roles_Mutation_Response>;
  /** insert a single row into the table: "users_roles" */
  insert_users_roles_one: Maybe<Users_Roles>;
  /** update single row of the table: "notifications" */
  updateNotification: Maybe<Notifications>;
  /** update data of the table: "notifications" */
  updateNotifications: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "patients" */
  updatePatient: Maybe<Patients>;
  /** update data of the table: "patients" */
  updatePatients: Maybe<Patients_Mutation_Response>;
  /** update single row of the table: "xrays" */
  updateXray: Maybe<Xrays>;
  /** update data of the table: "xrays" */
  updateXrays: Maybe<Xrays_Mutation_Response>;
  /** update data of the table: "allisone_plus_configuration" */
  update_allisone_plus_configuration: Maybe<Allisone_Plus_Configuration_Mutation_Response>;
  /** update multiples rows of table: "allisone_plus_configuration" */
  update_allisone_plus_configuration_many: Maybe<
    Array<Maybe<Allisone_Plus_Configuration_Mutation_Response>>
  >;
  /** update data of the table: "allisone_plus_users_configurations" */
  update_allisone_plus_users_configurations: Maybe<Allisone_Plus_Users_Configurations_Mutation_Response>;
  /** update single row of the table: "allisone_plus_users_configurations" */
  update_allisone_plus_users_configurations_by_pk: Maybe<Allisone_Plus_Users_Configurations>;
  /** update multiples rows of table: "allisone_plus_users_configurations" */
  update_allisone_plus_users_configurations_many: Maybe<
    Array<Maybe<Allisone_Plus_Users_Configurations_Mutation_Response>>
  >;
  /** update data of the table: "billings" */
  update_billings: Maybe<Billings_Mutation_Response>;
  /** update single row of the table: "billings" */
  update_billings_by_pk: Maybe<Billings>;
  /** update multiples rows of table: "billings" */
  update_billings_many: Maybe<Array<Maybe<Billings_Mutation_Response>>>;
  /** update data of the table: "clinical_monitoring" */
  update_clinical_monitoring: Maybe<Clinical_Monitoring_Mutation_Response>;
  /** update single row of the table: "clinical_monitoring" */
  update_clinical_monitoring_by_pk: Maybe<Clinical_Monitoring>;
  /** update multiples rows of table: "clinical_monitoring" */
  update_clinical_monitoring_many: Maybe<Array<Maybe<Clinical_Monitoring_Mutation_Response>>>;
  /** update single row of the table: "consultation" */
  update_consultation: Maybe<Consultation>;
  /** update multiples rows of table: "consultation" */
  update_consultation_many: Maybe<Array<Maybe<Consultation_Mutation_Response>>>;
  /** update data of the table: "consultation" */
  update_consultations: Maybe<Consultation_Mutation_Response>;
  /** update data of the table: "customer_emails" */
  update_customer_emails: Maybe<Customer_Emails_Mutation_Response>;
  /** update single row of the table: "customer_emails" */
  update_customer_emails_by_pk: Maybe<Customer_Emails>;
  /** update multiples rows of table: "customer_emails" */
  update_customer_emails_many: Maybe<Array<Maybe<Customer_Emails_Mutation_Response>>>;
  /** update data of the table: "educationalSheets" */
  update_educationalSheets: Maybe<EducationalSheets_Mutation_Response>;
  /** update single row of the table: "educationalSheets" */
  update_educationalSheets_by_pk: Maybe<EducationalSheets>;
  /** update multiples rows of table: "educationalSheets" */
  update_educationalSheets_many: Maybe<Array<Maybe<EducationalSheets_Mutation_Response>>>;
  /** update data of the table: "element_types" */
  update_element_types: Maybe<Element_Types_Mutation_Response>;
  /** update single row of the table: "element_types" */
  update_element_types_by_pk: Maybe<Element_Types>;
  /** update multiples rows of table: "element_types" */
  update_element_types_many: Maybe<Array<Maybe<Element_Types_Mutation_Response>>>;
  /** update data of the table: "elements" */
  update_elements: Maybe<Elements_Mutation_Response>;
  /** update single row of the table: "elements" */
  update_elements_by_pk: Maybe<Elements>;
  /** update multiples rows of table: "elements" */
  update_elements_many: Maybe<Array<Maybe<Elements_Mutation_Response>>>;
  /** update data of the table: "locations" */
  update_locations: Maybe<Locations_Mutation_Response>;
  /** update single row of the table: "locations" */
  update_locations_by_pk: Maybe<Locations>;
  /** update multiples rows of table: "locations" */
  update_locations_many: Maybe<Array<Maybe<Locations_Mutation_Response>>>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update single row of the table: "operations" */
  update_operation: Maybe<Operations>;
  /** update data of the table: "operations" */
  update_operations: Maybe<Operations_Mutation_Response>;
  /** update multiples rows of table: "operations" */
  update_operations_many: Maybe<Array<Maybe<Operations_Mutation_Response>>>;
  /** update data of the table: "partner_customers" */
  update_partner_customers: Maybe<Partner_Customers_Mutation_Response>;
  /** update single row of the table: "partner_customers" */
  update_partner_customers_by_pk: Maybe<Partner_Customers>;
  /** update multiples rows of table: "partner_customers" */
  update_partner_customers_many: Maybe<Array<Maybe<Partner_Customers_Mutation_Response>>>;
  /** update multiples rows of table: "patients" */
  update_patients_many: Maybe<Array<Maybe<Patients_Mutation_Response>>>;
  /** update data of the table: "patients_partners" */
  update_patients_partners: Maybe<Patients_Partners_Mutation_Response>;
  /** update single row of the table: "patients_partners" */
  update_patients_partners_by_pk: Maybe<Patients_Partners>;
  /** update multiples rows of table: "patients_partners" */
  update_patients_partners_many: Maybe<Array<Maybe<Patients_Partners_Mutation_Response>>>;
  /** update data of the table: "personas" */
  update_personas: Maybe<Personas_Mutation_Response>;
  /** update single row of the table: "personas" */
  update_personas_by_pk: Maybe<Personas>;
  /** update multiples rows of table: "personas" */
  update_personas_many: Maybe<Array<Maybe<Personas_Mutation_Response>>>;
  /** update data of the table: "resellers" */
  update_resellers: Maybe<Resellers_Mutation_Response>;
  /** update single row of the table: "resellers" */
  update_resellers_by_pk: Maybe<Resellers>;
  /** update multiples rows of table: "resellers" */
  update_resellers_many: Maybe<Array<Maybe<Resellers_Mutation_Response>>>;
  /** update data of the table: "treatment_preferences" */
  update_treatment_preferences: Maybe<Treatment_Preferences_Mutation_Response>;
  /** update single row of the table: "treatment_preferences" */
  update_treatment_preferences_by_pk: Maybe<Treatment_Preferences>;
  /** update multiples rows of table: "treatment_preferences" */
  update_treatment_preferences_many: Maybe<Array<Maybe<Treatment_Preferences_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk: Maybe<Users>;
  /** update data of the table: "users_locations" */
  update_users_locations: Maybe<Users_Locations_Mutation_Response>;
  /** update single row of the table: "users_locations" */
  update_users_locations_by_pk: Maybe<Users_Locations>;
  /** update multiples rows of table: "users_locations" */
  update_users_locations_many: Maybe<Array<Maybe<Users_Locations_Mutation_Response>>>;
  /** update multiples rows of table: "users" */
  update_users_many: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update multiples rows of table: "xrays" */
  update_xrays_many: Maybe<Array<Maybe<Xrays_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootAddObservationArgs = {
  object: Observations_Insert_Input;
  on_conflict?: InputMaybe<Observations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootAddObservationsArgs = {
  objects: Array<Observations_Insert_Input>;
  on_conflict?: InputMaybe<Observations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreateUserArgs = {
  userData: CreateUserInput;
};

/** mutation root */
export type Mutation_RootCreate_ConsultationArgs = {
  object: Consultation_Insert_Input;
  on_conflict?: InputMaybe<Consultation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreate_ConsultationsArgs = {
  objects: Array<Consultation_Insert_Input>;
  on_conflict?: InputMaybe<Consultation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreate_OperationArgs = {
  object: Operations_Insert_Input;
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootCreate_OperationsArgs = {
  objects: Array<Operations_Insert_Input>;
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootDelete_AnatomiesArgs = {
  where: Anatomies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Anatomies_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_BillingsArgs = {
  where: Billings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Billings_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Customer_EmailsArgs = {
  where: Customer_Emails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Customer_Emails_By_PkArgs = {
  customerId: Scalars['uuid'];
  email: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_EducationalSheetsArgs = {
  where: EducationalSheets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_EducationalSheets_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Element_TypesArgs = {
  where: Element_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Element_Types_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_ElementsArgs = {
  where: Elements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Elements_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Event_UserArgs = {
  where: Event_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Event_User_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_LocationsArgs = {
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Locations_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_OperationArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_OperationsArgs = {
  where: Operations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patients_PartnersArgs = {
  where: Patients_Partners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Patients_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PersonasArgs = {
  where: Personas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Personas_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_ResellersArgs = {
  where: Resellers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Resellers_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Treatment_PreferencesArgs = {
  where: Treatment_Preferences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Treatment_Preferences_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Users_LocationsArgs = {
  where: Users_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_Locations_By_PkArgs = {
  location_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Users_RolesArgs = {
  where: Users_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_Roles_By_PkArgs = {
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootInsertNotificationArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertNotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertOralStructureArgs = {
  object: OralStructures_Insert_Input;
  on_conflict?: InputMaybe<OralStructures_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertOralStructuresArgs = {
  objects: Array<OralStructures_Insert_Input>;
  on_conflict?: InputMaybe<OralStructures_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPatientArgs = {
  object: Patients_Insert_Input;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertPatientsArgs = {
  objects: Array<Patients_Insert_Input>;
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertXrayArgs = {
  object: Xrays_Insert_Input;
  on_conflict?: InputMaybe<Xrays_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertXraysArgs = {
  objects: Array<Xrays_Insert_Input>;
  on_conflict?: InputMaybe<Xrays_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allisone_Plus_ConfigurationArgs = {
  objects: Array<Allisone_Plus_Configuration_Insert_Input>;
  on_conflict?: InputMaybe<Allisone_Plus_Configuration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allisone_Plus_Configuration_OneArgs = {
  object: Allisone_Plus_Configuration_Insert_Input;
  on_conflict?: InputMaybe<Allisone_Plus_Configuration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allisone_Plus_Users_ConfigurationsArgs = {
  objects: Array<Allisone_Plus_Users_Configurations_Insert_Input>;
  on_conflict?: InputMaybe<Allisone_Plus_Users_Configurations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Allisone_Plus_Users_Configurations_OneArgs = {
  object: Allisone_Plus_Users_Configurations_Insert_Input;
  on_conflict?: InputMaybe<Allisone_Plus_Users_Configurations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AnatomiesArgs = {
  objects: Array<Anatomies_Insert_Input>;
  on_conflict?: InputMaybe<Anatomies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Anatomies_OneArgs = {
  object: Anatomies_Insert_Input;
  on_conflict?: InputMaybe<Anatomies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Api_Clients_LogsArgs = {
  objects: Array<Api_Clients_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Api_Clients_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Api_LogsArgs = {
  objects: Array<Api_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Api_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_BillingsArgs = {
  objects: Array<Billings_Insert_Input>;
  on_conflict?: InputMaybe<Billings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billings_OneArgs = {
  object: Billings_Insert_Input;
  on_conflict?: InputMaybe<Billings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinical_MonitoringArgs = {
  objects: Array<Clinical_Monitoring_Insert_Input>;
  on_conflict?: InputMaybe<Clinical_Monitoring_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Clinical_Monitoring_OneArgs = {
  object: Clinical_Monitoring_Insert_Input;
  on_conflict?: InputMaybe<Clinical_Monitoring_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Customer_EmailsArgs = {
  objects: Array<Customer_Emails_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Emails_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Customer_Emails_OneArgs = {
  object: Customer_Emails_Insert_Input;
  on_conflict?: InputMaybe<Customer_Emails_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EducationalSheetsArgs = {
  objects: Array<EducationalSheets_Insert_Input>;
  on_conflict?: InputMaybe<EducationalSheets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EducationalSheets_OneArgs = {
  object: EducationalSheets_Insert_Input;
  on_conflict?: InputMaybe<EducationalSheets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Element_TypesArgs = {
  objects: Array<Element_Types_Insert_Input>;
  on_conflict?: InputMaybe<Element_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Element_Types_OneArgs = {
  object: Element_Types_Insert_Input;
  on_conflict?: InputMaybe<Element_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ElementsArgs = {
  objects: Array<Elements_Insert_Input>;
  on_conflict?: InputMaybe<Elements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Elements_OneArgs = {
  object: Elements_Insert_Input;
  on_conflict?: InputMaybe<Elements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Event_UserArgs = {
  objects: Array<Event_User_Insert_Input>;
  on_conflict?: InputMaybe<Event_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Event_User_OneArgs = {
  object: Event_User_Insert_Input;
  on_conflict?: InputMaybe<Event_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
  objects: Array<Locations_Insert_Input>;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
  object: Locations_Insert_Input;
  on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Partner_CustomersArgs = {
  objects: Array<Partner_Customers_Insert_Input>;
  on_conflict?: InputMaybe<Partner_Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Partner_Customers_OneArgs = {
  object: Partner_Customers_Insert_Input;
  on_conflict?: InputMaybe<Partner_Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PartnersArgs = {
  objects: Array<Partners_Insert_Input>;
  on_conflict?: InputMaybe<Partners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Partners_OneArgs = {
  object: Partners_Insert_Input;
  on_conflict?: InputMaybe<Partners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patients_PartnersArgs = {
  objects: Array<Patients_Partners_Insert_Input>;
  on_conflict?: InputMaybe<Patients_Partners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Patients_Partners_OneArgs = {
  object: Patients_Partners_Insert_Input;
  on_conflict?: InputMaybe<Patients_Partners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PersonasArgs = {
  objects: Array<Personas_Insert_Input>;
  on_conflict?: InputMaybe<Personas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Personas_OneArgs = {
  object: Personas_Insert_Input;
  on_conflict?: InputMaybe<Personas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ResellersArgs = {
  objects: Array<Resellers_Insert_Input>;
  on_conflict?: InputMaybe<Resellers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Resellers_OneArgs = {
  object: Resellers_Insert_Input;
  on_conflict?: InputMaybe<Resellers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Treatment_PreferencesArgs = {
  objects: Array<Treatment_Preferences_Insert_Input>;
  on_conflict?: InputMaybe<Treatment_Preferences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Treatment_Preferences_OneArgs = {
  object: Treatment_Preferences_Insert_Input;
  on_conflict?: InputMaybe<Treatment_Preferences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_LocationsArgs = {
  objects: Array<Users_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Users_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_Locations_OneArgs = {
  object: Users_Locations_Insert_Input;
  on_conflict?: InputMaybe<Users_Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_RolesArgs = {
  objects: Array<Users_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Users_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_Roles_OneArgs = {
  object: Users_Roles_Insert_Input;
  on_conflict?: InputMaybe<Users_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdateNotificationArgs = {
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateNotificationsArgs = {
  _inc?: InputMaybe<Notifications_Inc_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdatePatientArgs = {
  _inc?: InputMaybe<Patients_Inc_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  pk_columns: Patients_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdatePatientsArgs = {
  _inc?: InputMaybe<Patients_Inc_Input>;
  _set?: InputMaybe<Patients_Set_Input>;
  where: Patients_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateXrayArgs = {
  _append?: InputMaybe<Xrays_Append_Input>;
  _delete_at_path?: InputMaybe<Xrays_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Xrays_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Xrays_Delete_Key_Input>;
  _inc?: InputMaybe<Xrays_Inc_Input>;
  _prepend?: InputMaybe<Xrays_Prepend_Input>;
  _set?: InputMaybe<Xrays_Set_Input>;
  pk_columns: Xrays_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateXraysArgs = {
  _append?: InputMaybe<Xrays_Append_Input>;
  _delete_at_path?: InputMaybe<Xrays_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Xrays_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Xrays_Delete_Key_Input>;
  _inc?: InputMaybe<Xrays_Inc_Input>;
  _prepend?: InputMaybe<Xrays_Prepend_Input>;
  _set?: InputMaybe<Xrays_Set_Input>;
  where: Xrays_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allisone_Plus_ConfigurationArgs = {
  _inc?: InputMaybe<Allisone_Plus_Configuration_Inc_Input>;
  _set?: InputMaybe<Allisone_Plus_Configuration_Set_Input>;
  where: Allisone_Plus_Configuration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allisone_Plus_Configuration_ManyArgs = {
  updates: Array<Allisone_Plus_Configuration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Allisone_Plus_Users_ConfigurationsArgs = {
  _inc?: InputMaybe<Allisone_Plus_Users_Configurations_Inc_Input>;
  _set?: InputMaybe<Allisone_Plus_Users_Configurations_Set_Input>;
  where: Allisone_Plus_Users_Configurations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Allisone_Plus_Users_Configurations_By_PkArgs = {
  _inc?: InputMaybe<Allisone_Plus_Users_Configurations_Inc_Input>;
  _set?: InputMaybe<Allisone_Plus_Users_Configurations_Set_Input>;
  pk_columns: Allisone_Plus_Users_Configurations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Allisone_Plus_Users_Configurations_ManyArgs = {
  updates: Array<Allisone_Plus_Users_Configurations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_BillingsArgs = {
  _append?: InputMaybe<Billings_Append_Input>;
  _delete_at_path?: InputMaybe<Billings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Billings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Billings_Delete_Key_Input>;
  _inc?: InputMaybe<Billings_Inc_Input>;
  _prepend?: InputMaybe<Billings_Prepend_Input>;
  _set?: InputMaybe<Billings_Set_Input>;
  where: Billings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Billings_By_PkArgs = {
  _append?: InputMaybe<Billings_Append_Input>;
  _delete_at_path?: InputMaybe<Billings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Billings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Billings_Delete_Key_Input>;
  _inc?: InputMaybe<Billings_Inc_Input>;
  _prepend?: InputMaybe<Billings_Prepend_Input>;
  _set?: InputMaybe<Billings_Set_Input>;
  pk_columns: Billings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Billings_ManyArgs = {
  updates: Array<Billings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Clinical_MonitoringArgs = {
  _append?: InputMaybe<Clinical_Monitoring_Append_Input>;
  _delete_at_path?: InputMaybe<Clinical_Monitoring_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clinical_Monitoring_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clinical_Monitoring_Delete_Key_Input>;
  _prepend?: InputMaybe<Clinical_Monitoring_Prepend_Input>;
  _set?: InputMaybe<Clinical_Monitoring_Set_Input>;
  where: Clinical_Monitoring_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Clinical_Monitoring_By_PkArgs = {
  _append?: InputMaybe<Clinical_Monitoring_Append_Input>;
  _delete_at_path?: InputMaybe<Clinical_Monitoring_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clinical_Monitoring_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clinical_Monitoring_Delete_Key_Input>;
  _prepend?: InputMaybe<Clinical_Monitoring_Prepend_Input>;
  _set?: InputMaybe<Clinical_Monitoring_Set_Input>;
  pk_columns: Clinical_Monitoring_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Clinical_Monitoring_ManyArgs = {
  updates: Array<Clinical_Monitoring_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConsultationArgs = {
  _append?: InputMaybe<Consultation_Append_Input>;
  _delete_at_path?: InputMaybe<Consultation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consultation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consultation_Delete_Key_Input>;
  _inc?: InputMaybe<Consultation_Inc_Input>;
  _prepend?: InputMaybe<Consultation_Prepend_Input>;
  _set?: InputMaybe<Consultation_Set_Input>;
  pk_columns: Consultation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Consultation_ManyArgs = {
  updates: Array<Consultation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConsultationsArgs = {
  _append?: InputMaybe<Consultation_Append_Input>;
  _delete_at_path?: InputMaybe<Consultation_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Consultation_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Consultation_Delete_Key_Input>;
  _inc?: InputMaybe<Consultation_Inc_Input>;
  _prepend?: InputMaybe<Consultation_Prepend_Input>;
  _set?: InputMaybe<Consultation_Set_Input>;
  where: Consultation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_EmailsArgs = {
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  where: Customer_Emails_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_Emails_By_PkArgs = {
  _set?: InputMaybe<Customer_Emails_Set_Input>;
  pk_columns: Customer_Emails_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_Emails_ManyArgs = {
  updates: Array<Customer_Emails_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_EducationalSheetsArgs = {
  _inc?: InputMaybe<EducationalSheets_Inc_Input>;
  _set?: InputMaybe<EducationalSheets_Set_Input>;
  where: EducationalSheets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_EducationalSheets_By_PkArgs = {
  _inc?: InputMaybe<EducationalSheets_Inc_Input>;
  _set?: InputMaybe<EducationalSheets_Set_Input>;
  pk_columns: EducationalSheets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_EducationalSheets_ManyArgs = {
  updates: Array<EducationalSheets_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Element_TypesArgs = {
  _inc?: InputMaybe<Element_Types_Inc_Input>;
  _set?: InputMaybe<Element_Types_Set_Input>;
  where: Element_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Element_Types_By_PkArgs = {
  _inc?: InputMaybe<Element_Types_Inc_Input>;
  _set?: InputMaybe<Element_Types_Set_Input>;
  pk_columns: Element_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Element_Types_ManyArgs = {
  updates: Array<Element_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ElementsArgs = {
  _append?: InputMaybe<Elements_Append_Input>;
  _delete_at_path?: InputMaybe<Elements_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Elements_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Elements_Delete_Key_Input>;
  _inc?: InputMaybe<Elements_Inc_Input>;
  _prepend?: InputMaybe<Elements_Prepend_Input>;
  _set?: InputMaybe<Elements_Set_Input>;
  where: Elements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Elements_By_PkArgs = {
  _append?: InputMaybe<Elements_Append_Input>;
  _delete_at_path?: InputMaybe<Elements_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Elements_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Elements_Delete_Key_Input>;
  _inc?: InputMaybe<Elements_Inc_Input>;
  _prepend?: InputMaybe<Elements_Prepend_Input>;
  _set?: InputMaybe<Elements_Set_Input>;
  pk_columns: Elements_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Elements_ManyArgs = {
  updates: Array<Elements_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LocationsArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  where: Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_By_PkArgs = {
  _inc?: InputMaybe<Locations_Inc_Input>;
  _set?: InputMaybe<Locations_Set_Input>;
  pk_columns: Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Locations_ManyArgs = {
  updates: Array<Locations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OperationArgs = {
  _inc?: InputMaybe<Operations_Inc_Input>;
  _set?: InputMaybe<Operations_Set_Input>;
  pk_columns: Operations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_OperationsArgs = {
  _inc?: InputMaybe<Operations_Inc_Input>;
  _set?: InputMaybe<Operations_Set_Input>;
  where: Operations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_ManyArgs = {
  updates: Array<Operations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Partner_CustomersArgs = {
  _inc?: InputMaybe<Partner_Customers_Inc_Input>;
  _set?: InputMaybe<Partner_Customers_Set_Input>;
  where: Partner_Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Partner_Customers_By_PkArgs = {
  _inc?: InputMaybe<Partner_Customers_Inc_Input>;
  _set?: InputMaybe<Partner_Customers_Set_Input>;
  pk_columns: Partner_Customers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Partner_Customers_ManyArgs = {
  updates: Array<Partner_Customers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_ManyArgs = {
  updates: Array<Patients_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_PartnersArgs = {
  _append?: InputMaybe<Patients_Partners_Append_Input>;
  _delete_at_path?: InputMaybe<Patients_Partners_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patients_Partners_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patients_Partners_Delete_Key_Input>;
  _inc?: InputMaybe<Patients_Partners_Inc_Input>;
  _prepend?: InputMaybe<Patients_Partners_Prepend_Input>;
  _set?: InputMaybe<Patients_Partners_Set_Input>;
  where: Patients_Partners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_Partners_By_PkArgs = {
  _append?: InputMaybe<Patients_Partners_Append_Input>;
  _delete_at_path?: InputMaybe<Patients_Partners_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Patients_Partners_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Patients_Partners_Delete_Key_Input>;
  _inc?: InputMaybe<Patients_Partners_Inc_Input>;
  _prepend?: InputMaybe<Patients_Partners_Prepend_Input>;
  _set?: InputMaybe<Patients_Partners_Set_Input>;
  pk_columns: Patients_Partners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Patients_Partners_ManyArgs = {
  updates: Array<Patients_Partners_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PersonasArgs = {
  _set?: InputMaybe<Personas_Set_Input>;
  where: Personas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Personas_By_PkArgs = {
  _set?: InputMaybe<Personas_Set_Input>;
  pk_columns: Personas_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Personas_ManyArgs = {
  updates: Array<Personas_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ResellersArgs = {
  _set?: InputMaybe<Resellers_Set_Input>;
  where: Resellers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Resellers_By_PkArgs = {
  _set?: InputMaybe<Resellers_Set_Input>;
  pk_columns: Resellers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Resellers_ManyArgs = {
  updates: Array<Resellers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_PreferencesArgs = {
  _append?: InputMaybe<Treatment_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Treatment_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treatment_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treatment_Preferences_Delete_Key_Input>;
  _inc?: InputMaybe<Treatment_Preferences_Inc_Input>;
  _prepend?: InputMaybe<Treatment_Preferences_Prepend_Input>;
  _set?: InputMaybe<Treatment_Preferences_Set_Input>;
  where: Treatment_Preferences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_Preferences_By_PkArgs = {
  _append?: InputMaybe<Treatment_Preferences_Append_Input>;
  _delete_at_path?: InputMaybe<Treatment_Preferences_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Treatment_Preferences_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Treatment_Preferences_Delete_Key_Input>;
  _inc?: InputMaybe<Treatment_Preferences_Inc_Input>;
  _prepend?: InputMaybe<Treatment_Preferences_Prepend_Input>;
  _set?: InputMaybe<Treatment_Preferences_Set_Input>;
  pk_columns: Treatment_Preferences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_Preferences_ManyArgs = {
  updates: Array<Treatment_Preferences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_LocationsArgs = {
  _inc?: InputMaybe<Users_Locations_Inc_Input>;
  _set?: InputMaybe<Users_Locations_Set_Input>;
  where: Users_Locations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Locations_By_PkArgs = {
  _inc?: InputMaybe<Users_Locations_Inc_Input>;
  _set?: InputMaybe<Users_Locations_Set_Input>;
  pk_columns: Users_Locations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Locations_ManyArgs = {
  updates: Array<Users_Locations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Xrays_ManyArgs = {
  updates: Array<Xrays_Updates>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  id: Scalars['Int'];
  name: Notifications_Type_Enum;
  url: Maybe<Scalars['String']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['Int'];
  viewed: Scalars['Boolean'];
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Notifications_Type_Enum_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  viewed?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey',
}

/** input type for incrementing numeric columns in table "notifications" */
export type Notifications_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Notifications_Type_Enum>;
  url?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  viewed?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Viewed = 'viewed',
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Notifications_Type_Enum>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Notifications_Type_Enum>;
  url?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  viewed?: InputMaybe<Scalars['Boolean']>;
};

/** Table with all possibles notifications types */
export type Notifications_Type = {
  __typename?: 'notifications_type';
  name: Scalars['String'];
};

/** Boolean expression to filter rows from the table "notifications_type". All fields are combined with a logical 'AND'. */
export type Notifications_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Type_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Type_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
};

export enum Notifications_Type_Enum {
  NewTreatmentPlanForYou = 'NEW_TREATMENT_PLAN_FOR_YOU',
}

/** Boolean expression to compare columns of type "notifications_type_enum". All fields are combined with logical 'AND'. */
export type Notifications_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notifications_Type_Enum>;
  _in?: InputMaybe<Array<Notifications_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Notifications_Type_Enum>;
  _nin?: InputMaybe<Array<Notifications_Type_Enum>>;
};

/** Ordering options when selecting data from "notifications_type". */
export type Notifications_Type_Order_By = {
  name?: InputMaybe<Order_By>;
};

/** select columns of table "notifications_type" */
export enum Notifications_Type_Select_Column {
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "notifications_type" */
export type Notifications_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Type_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Url = 'url',
  /** column name */
  UserId = 'userId',
  /** column name */
  Viewed = 'viewed',
}

export type Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** columns and relationships of "observations" */
export type Observations = {
  __typename?: 'observations';
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  observations: Scalars['String'];
  /** An object relationship */
  patient: Patients;
  patientId: Scalars['Int'];
};

/** Boolean expression to filter rows from the table "observations". All fields are combined with a logical 'AND'. */
export type Observations_Bool_Exp = {
  _and?: InputMaybe<Array<Observations_Bool_Exp>>;
  _not?: InputMaybe<Observations_Bool_Exp>;
  _or?: InputMaybe<Array<Observations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  observations?: InputMaybe<String_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "observations" */
export enum Observations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ObservationsPkey = 'observations_pkey',
}

/** input type for inserting data into table "observations" */
export type Observations_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  observations?: InputMaybe<Scalars['String']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "observations" */
export type Observations_Mutation_Response = {
  __typename?: 'observations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Observations>;
};

/** on_conflict condition type for table "observations" */
export type Observations_On_Conflict = {
  constraint: Observations_Constraint;
  update_columns?: Array<Observations_Update_Column>;
  where?: InputMaybe<Observations_Bool_Exp>;
};

/** Ordering options when selecting data from "observations". */
export type Observations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  observations?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** select columns of table "observations" */
export enum Observations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Observations = 'observations',
  /** column name */
  PatientId = 'patientId',
}

/** Streaming cursor of the table "observations" */
export type Observations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Observations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Observations_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  observations?: InputMaybe<Scalars['String']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** placeholder for update columns of table "observations" (current role has no relevant permissions) */
export enum Observations_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "operation_types" */
export type Operation_Types = {
  __typename?: 'operation_types';
  allowed_teeth: Maybe<Scalars['jsonb']>;
  color: Scalars['String'];
  defaultOrder: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  operations: Array<Operations>;
  /** An aggregate relationship */
  operations_aggregate: Operations_Aggregate;
};

/** columns and relationships of "operation_types" */
export type Operation_TypesAllowed_TeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "operation_types" */
export type Operation_TypesOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** columns and relationships of "operation_types" */
export type Operation_TypesOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "operation_types". All fields are combined with a logical 'AND'. */
export type Operation_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Operation_Types_Bool_Exp>>;
  _not?: InputMaybe<Operation_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Operation_Types_Bool_Exp>>;
  allowed_teeth?: InputMaybe<Jsonb_Comparison_Exp>;
  color?: InputMaybe<String_Comparison_Exp>;
  defaultOrder?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operations?: InputMaybe<Operations_Bool_Exp>;
  operations_aggregate?: InputMaybe<Operations_Aggregate_Bool_Exp>;
};

/** Ordering options when selecting data from "operation_types". */
export type Operation_Types_Order_By = {
  allowed_teeth?: InputMaybe<Order_By>;
  color?: InputMaybe<Order_By>;
  defaultOrder?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operations_aggregate?: InputMaybe<Operations_Aggregate_Order_By>;
};

/** select columns of table "operation_types" */
export enum Operation_Types_Select_Column {
  /** column name */
  AllowedTeeth = 'allowed_teeth',
  /** column name */
  Color = 'color',
  /** column name */
  DefaultOrder = 'defaultOrder',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "operation_types" */
export type Operation_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operation_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operation_Types_Stream_Cursor_Value_Input = {
  allowed_teeth?: InputMaybe<Scalars['jsonb']>;
  color?: InputMaybe<Scalars['String']>;
  defaultOrder?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "operations" */
export type Operations = {
  __typename?: 'operations';
  completedAt: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  consultation: Consultation;
  consultationId: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  groupId: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  operationType: Operation_Types;
  operationTypeId: Scalars['Int'];
  order: Scalars['Int'];
  pillarTeeth: Scalars['json'];
  toothNumbers: Scalars['json'];
  updatedAt: Scalars['timestamptz'];
};

/** columns and relationships of "operations" */
export type OperationsPillarTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "operations" */
export type OperationsToothNumbersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "operations" */
export type Operations_Aggregate = {
  __typename?: 'operations_aggregate';
  aggregate: Maybe<Operations_Aggregate_Fields>;
  nodes: Array<Operations>;
};

export type Operations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Operations_Aggregate_Bool_Exp_Count>;
};

export type Operations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Operations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Operations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "operations" */
export type Operations_Aggregate_Fields = {
  __typename?: 'operations_aggregate_fields';
  avg: Maybe<Operations_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Operations_Max_Fields>;
  min: Maybe<Operations_Min_Fields>;
  stddev: Maybe<Operations_Stddev_Fields>;
  stddev_pop: Maybe<Operations_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Operations_Stddev_Samp_Fields>;
  sum: Maybe<Operations_Sum_Fields>;
  var_pop: Maybe<Operations_Var_Pop_Fields>;
  var_samp: Maybe<Operations_Var_Samp_Fields>;
  variance: Maybe<Operations_Variance_Fields>;
};

/** aggregate fields of "operations" */
export type Operations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "operations" */
export type Operations_Aggregate_Order_By = {
  avg?: InputMaybe<Operations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Operations_Max_Order_By>;
  min?: InputMaybe<Operations_Min_Order_By>;
  stddev?: InputMaybe<Operations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Operations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Operations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Operations_Sum_Order_By>;
  var_pop?: InputMaybe<Operations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Operations_Var_Samp_Order_By>;
  variance?: InputMaybe<Operations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "operations" */
export type Operations_Arr_Rel_Insert_Input = {
  data: Array<Operations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Operations_On_Conflict>;
};

/** aggregate avg on columns */
export type Operations_Avg_Fields = {
  __typename?: 'operations_avg_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "operations" */
export type Operations_Avg_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "operations". All fields are combined with a logical 'AND'. */
export type Operations_Bool_Exp = {
  _and?: InputMaybe<Array<Operations_Bool_Exp>>;
  _not?: InputMaybe<Operations_Bool_Exp>;
  _or?: InputMaybe<Array<Operations_Bool_Exp>>;
  completedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  consultation?: InputMaybe<Consultation_Bool_Exp>;
  consultationId?: InputMaybe<Int_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  groupId?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  operationType?: InputMaybe<Operation_Types_Bool_Exp>;
  operationTypeId?: InputMaybe<Int_Comparison_Exp>;
  order?: InputMaybe<Int_Comparison_Exp>;
  pillarTeeth?: InputMaybe<Json_Comparison_Exp>;
  toothNumbers?: InputMaybe<Json_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "operations" */
export enum Operations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OperationsPkey1 = 'operations_pkey1',
}

/** input type for incrementing numeric columns in table "operations" */
export type Operations_Inc_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  operationTypeId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operations" */
export type Operations_Insert_Input = {
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  consultation?: InputMaybe<Consultation_Obj_Rel_Insert_Input>;
  consultationId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  operationTypeId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  pillarTeeth?: InputMaybe<Scalars['json']>;
  toothNumbers?: InputMaybe<Scalars['json']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Operations_Max_Fields = {
  __typename?: 'operations_max_fields';
  completedAt: Maybe<Scalars['timestamptz']>;
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  groupId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  operationTypeId: Maybe<Scalars['Int']>;
  order: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "operations" */
export type Operations_Max_Order_By = {
  completedAt?: InputMaybe<Order_By>;
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Operations_Min_Fields = {
  __typename?: 'operations_min_fields';
  completedAt: Maybe<Scalars['timestamptz']>;
  consultationId: Maybe<Scalars['Int']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  groupId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  operationTypeId: Maybe<Scalars['Int']>;
  order: Maybe<Scalars['Int']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "operations" */
export type Operations_Min_Order_By = {
  completedAt?: InputMaybe<Order_By>;
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "operations" */
export type Operations_Mutation_Response = {
  __typename?: 'operations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operations>;
};

/** on_conflict condition type for table "operations" */
export type Operations_On_Conflict = {
  constraint: Operations_Constraint;
  update_columns?: Array<Operations_Update_Column>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

/** Ordering options when selecting data from "operations". */
export type Operations_Order_By = {
  completedAt?: InputMaybe<Order_By>;
  consultation?: InputMaybe<Consultation_Order_By>;
  consultationId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationType?: InputMaybe<Operation_Types_Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  pillarTeeth?: InputMaybe<Order_By>;
  toothNumbers?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operations */
export type Operations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "operations" */
export enum Operations_Select_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  OperationTypeId = 'operationTypeId',
  /** column name */
  Order = 'order',
  /** column name */
  PillarTeeth = 'pillarTeeth',
  /** column name */
  ToothNumbers = 'toothNumbers',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "operations" */
export type Operations_Set_Input = {
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  consultationId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  operationTypeId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  pillarTeeth?: InputMaybe<Scalars['json']>;
  toothNumbers?: InputMaybe<Scalars['json']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Operations_Stddev_Fields = {
  __typename?: 'operations_stddev_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "operations" */
export type Operations_Stddev_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Operations_Stddev_Pop_Fields = {
  __typename?: 'operations_stddev_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "operations" */
export type Operations_Stddev_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Operations_Stddev_Samp_Fields = {
  __typename?: 'operations_stddev_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "operations" */
export type Operations_Stddev_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "operations" */
export type Operations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operations_Stream_Cursor_Value_Input = {
  completedAt?: InputMaybe<Scalars['timestamptz']>;
  consultationId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  operationTypeId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  pillarTeeth?: InputMaybe<Scalars['json']>;
  toothNumbers?: InputMaybe<Scalars['json']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Operations_Sum_Fields = {
  __typename?: 'operations_sum_fields';
  consultationId: Maybe<Scalars['Int']>;
  groupId: Maybe<Scalars['Int']>;
  id: Maybe<Scalars['Int']>;
  operationTypeId: Maybe<Scalars['Int']>;
  order: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "operations" */
export type Operations_Sum_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** update columns of table "operations" */
export enum Operations_Update_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  GroupId = 'groupId',
  /** column name */
  Id = 'id',
  /** column name */
  OperationTypeId = 'operationTypeId',
  /** column name */
  Order = 'order',
  /** column name */
  PillarTeeth = 'pillarTeeth',
  /** column name */
  ToothNumbers = 'toothNumbers',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Operations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operations_Var_Pop_Fields = {
  __typename?: 'operations_var_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "operations" */
export type Operations_Var_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Operations_Var_Samp_Fields = {
  __typename?: 'operations_var_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "operations" */
export type Operations_Var_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Operations_Variance_Fields = {
  __typename?: 'operations_variance_fields';
  consultationId: Maybe<Scalars['Float']>;
  groupId: Maybe<Scalars['Float']>;
  id: Maybe<Scalars['Float']>;
  operationTypeId: Maybe<Scalars['Float']>;
  order: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "operations" */
export type Operations_Variance_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  groupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  operationTypeId?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
};

/** columns and relationships of "oral_structure_types" */
export type OralStructureTypes = {
  __typename?: 'oralStructureTypes';
  color: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  oral_structures: Array<OralStructures>;
};

/** columns and relationships of "oral_structure_types" */
export type OralStructureTypesOral_StructuresArgs = {
  distinct_on?: InputMaybe<Array<OralStructures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructures_Order_By>>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "oral_structure_types". All fields are combined with a logical 'AND'. */
export type OralStructureTypes_Bool_Exp = {
  _and?: InputMaybe<Array<OralStructureTypes_Bool_Exp>>;
  _not?: InputMaybe<OralStructureTypes_Bool_Exp>;
  _or?: InputMaybe<Array<OralStructureTypes_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oral_structures?: InputMaybe<OralStructures_Bool_Exp>;
};

export enum OralStructureTypes_Enum {
  /** #FFFF00 */
  InferiorAlveolarNerve = 'INFERIOR_ALVEOLAR_NERVE',
  /** #99E8AF */
  InferiorMaxillarySinus = 'INFERIOR_MAXILLARY_SINUS',
}

/** Boolean expression to compare columns of type "oralStructureTypes_enum". All fields are combined with logical 'AND'. */
export type OralStructureTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<OralStructureTypes_Enum>;
  _in?: InputMaybe<Array<OralStructureTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<OralStructureTypes_Enum>;
  _nin?: InputMaybe<Array<OralStructureTypes_Enum>>;
};

/** Ordering options when selecting data from "oral_structure_types". */
export type OralStructureTypes_Order_By = {
  color?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oral_structures_aggregate?: InputMaybe<OralStructures_Aggregate_Order_By>;
};

/** select columns of table "oral_structure_types" */
export enum OralStructureTypes_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "oralStructureTypes" */
export type OralStructureTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OralStructureTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OralStructureTypes_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "oral_structures" */
export type OralStructures = {
  __typename?: 'oralStructures';
  detectionBox: Scalars['jsonb'];
  detectionPoly: Scalars['jsonb'];
  id: Scalars['uuid'];
  /** An object relationship */
  oralStructureType: OralStructureTypes;
  type: OralStructureTypes_Enum;
  /** An object relationship */
  xray: Xrays;
  xrayId: Scalars['uuid'];
};

/** columns and relationships of "oral_structures" */
export type OralStructuresDetectionBoxArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "oral_structures" */
export type OralStructuresDetectionPolyArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "oral_structures" */
export type OralStructures_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<OralStructures_Max_Order_By>;
  min?: InputMaybe<OralStructures_Min_Order_By>;
};

/** input type for inserting array relation for remote table "oral_structures" */
export type OralStructures_Arr_Rel_Insert_Input = {
  data: Array<OralStructures_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<OralStructures_On_Conflict>;
};

/** Boolean expression to filter rows from the table "oral_structures". All fields are combined with a logical 'AND'. */
export type OralStructures_Bool_Exp = {
  _and?: InputMaybe<Array<OralStructures_Bool_Exp>>;
  _not?: InputMaybe<OralStructures_Bool_Exp>;
  _or?: InputMaybe<Array<OralStructures_Bool_Exp>>;
  detectionBox?: InputMaybe<Jsonb_Comparison_Exp>;
  detectionPoly?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  oralStructureType?: InputMaybe<OralStructureTypes_Bool_Exp>;
  type?: InputMaybe<OralStructureTypes_Enum_Comparison_Exp>;
  xray?: InputMaybe<Xrays_Bool_Exp>;
  xrayId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "oral_structures" */
export enum OralStructures_Constraint {
  /** unique or primary key constraint on columns "id" */
  OralStructuresPkey = 'oral_structures_pkey',
}

/** input type for inserting data into table "oral_structures" */
export type OralStructures_Insert_Input = {
  detectionBox?: InputMaybe<Scalars['jsonb']>;
  detectionPoly?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<OralStructureTypes_Enum>;
  xray?: InputMaybe<Xrays_Obj_Rel_Insert_Input>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "oral_structures" */
export type OralStructures_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "oral_structures" */
export type OralStructures_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "oral_structures" */
export type OralStructures_Mutation_Response = {
  __typename?: 'oralStructures_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<OralStructures>;
};

/** on_conflict condition type for table "oral_structures" */
export type OralStructures_On_Conflict = {
  constraint: OralStructures_Constraint;
  update_columns?: Array<OralStructures_Update_Column>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

/** Ordering options when selecting data from "oral_structures". */
export type OralStructures_Order_By = {
  detectionBox?: InputMaybe<Order_By>;
  detectionPoly?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oralStructureType?: InputMaybe<OralStructureTypes_Order_By>;
  type?: InputMaybe<Order_By>;
  xray?: InputMaybe<Xrays_Order_By>;
  xrayId?: InputMaybe<Order_By>;
};

/** select columns of table "oral_structures" */
export enum OralStructures_Select_Column {
  /** column name */
  DetectionBox = 'detectionBox',
  /** column name */
  DetectionPoly = 'detectionPoly',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  XrayId = 'xrayId',
}

/** Streaming cursor of the table "oralStructures" */
export type OralStructures_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: OralStructures_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type OralStructures_Stream_Cursor_Value_Input = {
  detectionBox?: InputMaybe<Scalars['jsonb']>;
  detectionPoly?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<OralStructureTypes_Enum>;
  xrayId?: InputMaybe<Scalars['uuid']>;
};

/** placeholder for update columns of table "oral_structures" (current role has no relevant permissions) */
export enum OralStructures_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "partner_customers" */
export type Partner_Customers = {
  __typename?: 'partner_customers';
  authUrl: Maybe<Scalars['String']>;
  clientId: Maybe<Scalars['String']>;
  clientSecret: Maybe<Scalars['String']>;
  clinicId: Scalars['String'];
  clinicName: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  diagnosticUrl: Maybe<Scalars['String']>;
  getPatientUrl: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  locationId: Maybe<Scalars['Int']>;
  /** An object relationship */
  partner: Partners;
  partnerId: Scalars['uuid'];
  /** An object relationship */
  partner_customers_location: Maybe<Locations>;
  patientConfirmationUrl: Maybe<Scalars['String']>;
  patientIds: Maybe<Scalars['uuid']>;
  /** An array relationship */
  patients: Array<Patients_Partners>;
  treatmentPlanUrl: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "partner_customers" */
export type Partner_CustomersPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Partners_Order_By>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

/** order by aggregate values of table "partner_customers" */
export type Partner_Customers_Aggregate_Order_By = {
  avg?: InputMaybe<Partner_Customers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Partner_Customers_Max_Order_By>;
  min?: InputMaybe<Partner_Customers_Min_Order_By>;
  stddev?: InputMaybe<Partner_Customers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Partner_Customers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Partner_Customers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Partner_Customers_Sum_Order_By>;
  var_pop?: InputMaybe<Partner_Customers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Partner_Customers_Var_Samp_Order_By>;
  variance?: InputMaybe<Partner_Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "partner_customers" */
export type Partner_Customers_Arr_Rel_Insert_Input = {
  data: Array<Partner_Customers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Customers_On_Conflict>;
};

/** order by avg() on columns of table "partner_customers" */
export type Partner_Customers_Avg_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "partner_customers". All fields are combined with a logical 'AND'. */
export type Partner_Customers_Bool_Exp = {
  _and?: InputMaybe<Array<Partner_Customers_Bool_Exp>>;
  _not?: InputMaybe<Partner_Customers_Bool_Exp>;
  _or?: InputMaybe<Array<Partner_Customers_Bool_Exp>>;
  authUrl?: InputMaybe<String_Comparison_Exp>;
  clientId?: InputMaybe<String_Comparison_Exp>;
  clientSecret?: InputMaybe<String_Comparison_Exp>;
  clinicId?: InputMaybe<String_Comparison_Exp>;
  clinicName?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  diagnosticUrl?: InputMaybe<String_Comparison_Exp>;
  getPatientUrl?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locationId?: InputMaybe<Int_Comparison_Exp>;
  partner?: InputMaybe<Partners_Bool_Exp>;
  partnerId?: InputMaybe<Uuid_Comparison_Exp>;
  partner_customers_location?: InputMaybe<Locations_Bool_Exp>;
  patientConfirmationUrl?: InputMaybe<String_Comparison_Exp>;
  patientIds?: InputMaybe<Uuid_Comparison_Exp>;
  patients?: InputMaybe<Patients_Partners_Bool_Exp>;
  treatmentPlanUrl?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "partner_customers" */
export enum Partner_Customers_Constraint {
  /** unique or primary key constraint on columns "location_id" */
  PartnerCustomersLocationIdKey = 'partner_customers_location_id_key',
  /** unique or primary key constraint on columns "clinic_id", "partner_id" */
  PartnerCustomersPartnerIdClinicIdKey = 'partner_customers_partner_id_clinic_id_key',
  /** unique or primary key constraint on columns "id" */
  PartnerCustomersPkey = 'partner_customers_pkey',
}

/** input type for incrementing numeric columns in table "partner_customers" */
export type Partner_Customers_Inc_Input = {
  locationId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "partner_customers" */
export type Partner_Customers_Insert_Input = {
  authUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  clinicName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosticUrl?: InputMaybe<Scalars['String']>;
  getPatientUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['Int']>;
  partner?: InputMaybe<Partners_Obj_Rel_Insert_Input>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  partner_customers_location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  patientConfirmationUrl?: InputMaybe<Scalars['String']>;
  patientIds?: InputMaybe<Scalars['uuid']>;
  patients?: InputMaybe<Patients_Partners_Arr_Rel_Insert_Input>;
  treatmentPlanUrl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "partner_customers" */
export type Partner_Customers_Max_Order_By = {
  authUrl?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  clientSecret?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  diagnosticUrl?: InputMaybe<Order_By>;
  getPatientUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  partnerId?: InputMaybe<Order_By>;
  patientConfirmationUrl?: InputMaybe<Order_By>;
  patientIds?: InputMaybe<Order_By>;
  treatmentPlanUrl?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "partner_customers" */
export type Partner_Customers_Min_Order_By = {
  authUrl?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  clientSecret?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  diagnosticUrl?: InputMaybe<Order_By>;
  getPatientUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  partnerId?: InputMaybe<Order_By>;
  patientConfirmationUrl?: InputMaybe<Order_By>;
  patientIds?: InputMaybe<Order_By>;
  treatmentPlanUrl?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "partner_customers" */
export type Partner_Customers_Mutation_Response = {
  __typename?: 'partner_customers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partner_Customers>;
};

/** input type for inserting object relation for remote table "partner_customers" */
export type Partner_Customers_Obj_Rel_Insert_Input = {
  data: Partner_Customers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partner_Customers_On_Conflict>;
};

/** on_conflict condition type for table "partner_customers" */
export type Partner_Customers_On_Conflict = {
  constraint: Partner_Customers_Constraint;
  update_columns?: Array<Partner_Customers_Update_Column>;
  where?: InputMaybe<Partner_Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "partner_customers". */
export type Partner_Customers_Order_By = {
  authUrl?: InputMaybe<Order_By>;
  clientId?: InputMaybe<Order_By>;
  clientSecret?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  clinicName?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  diagnosticUrl?: InputMaybe<Order_By>;
  getPatientUrl?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  partner?: InputMaybe<Partners_Order_By>;
  partnerId?: InputMaybe<Order_By>;
  partner_customers_location?: InputMaybe<Locations_Order_By>;
  patientConfirmationUrl?: InputMaybe<Order_By>;
  patientIds?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Partners_Aggregate_Order_By>;
  treatmentPlanUrl?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: partner_customers */
export type Partner_Customers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "partner_customers" */
export enum Partner_Customers_Select_Column {
  /** column name */
  AuthUrl = 'authUrl',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicName = 'clinicName',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiagnosticUrl = 'diagnosticUrl',
  /** column name */
  GetPatientUrl = 'getPatientUrl',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PartnerId = 'partnerId',
  /** column name */
  PatientConfirmationUrl = 'patientConfirmationUrl',
  /** column name */
  PatientIds = 'patientIds',
  /** column name */
  TreatmentPlanUrl = 'treatmentPlanUrl',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "partner_customers" */
export type Partner_Customers_Set_Input = {
  authUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  clinicName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosticUrl?: InputMaybe<Scalars['String']>;
  getPatientUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  patientConfirmationUrl?: InputMaybe<Scalars['String']>;
  patientIds?: InputMaybe<Scalars['uuid']>;
  treatmentPlanUrl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by stddev() on columns of table "partner_customers" */
export type Partner_Customers_Stddev_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "partner_customers" */
export type Partner_Customers_Stddev_Pop_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "partner_customers" */
export type Partner_Customers_Stddev_Samp_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "partner_customers" */
export type Partner_Customers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partner_Customers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partner_Customers_Stream_Cursor_Value_Input = {
  authUrl?: InputMaybe<Scalars['String']>;
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  clinicName?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  diagnosticUrl?: InputMaybe<Scalars['String']>;
  getPatientUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  locationId?: InputMaybe<Scalars['Int']>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  patientConfirmationUrl?: InputMaybe<Scalars['String']>;
  patientIds?: InputMaybe<Scalars['uuid']>;
  treatmentPlanUrl?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "partner_customers" */
export type Partner_Customers_Sum_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** update columns of table "partner_customers" */
export enum Partner_Customers_Update_Column {
  /** column name */
  AuthUrl = 'authUrl',
  /** column name */
  ClientId = 'clientId',
  /** column name */
  ClientSecret = 'clientSecret',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  ClinicName = 'clinicName',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiagnosticUrl = 'diagnosticUrl',
  /** column name */
  GetPatientUrl = 'getPatientUrl',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  PartnerId = 'partnerId',
  /** column name */
  PatientConfirmationUrl = 'patientConfirmationUrl',
  /** column name */
  PatientIds = 'patientIds',
  /** column name */
  TreatmentPlanUrl = 'treatmentPlanUrl',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Partner_Customers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Partner_Customers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Partner_Customers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Partner_Customers_Bool_Exp;
};

/** order by var_pop() on columns of table "partner_customers" */
export type Partner_Customers_Var_Pop_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "partner_customers" */
export type Partner_Customers_Var_Samp_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "partner_customers" */
export type Partner_Customers_Variance_Order_By = {
  locationId?: InputMaybe<Order_By>;
};

/** columns and relationships of "partners" */
export type Partners = {
  __typename?: 'partners';
  id: Scalars['uuid'];
  name: Maybe<Scalars['String']>;
  /** An array relationship */
  patients_partners: Array<Patients_Partners>;
};

/** columns and relationships of "partners" */
export type PartnersPatients_PartnersArgs = {
  distinct_on?: InputMaybe<Array<Patients_Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Partners_Order_By>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "partners". All fields are combined with a logical 'AND'. */
export type Partners_Bool_Exp = {
  _and?: InputMaybe<Array<Partners_Bool_Exp>>;
  _not?: InputMaybe<Partners_Bool_Exp>;
  _or?: InputMaybe<Array<Partners_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  patients_partners?: InputMaybe<Patients_Partners_Bool_Exp>;
};

/** unique or primary key constraints on table "partners" */
export enum Partners_Constraint {
  /** unique or primary key constraint on columns "id" */
  PartnersIdUnique = 'partners_id_unique',
  /** unique or primary key constraint on columns "name" */
  PartnersNameUnique = 'partners_name_unique',
  /** unique or primary key constraint on columns "id" */
  PartnersPkey = 'partners_pkey',
}

/** input type for inserting data into table "partners" */
export type Partners_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  patients_partners?: InputMaybe<Patients_Partners_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "partners" */
export type Partners_Mutation_Response = {
  __typename?: 'partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Partners>;
};

/** input type for inserting object relation for remote table "partners" */
export type Partners_Obj_Rel_Insert_Input = {
  data: Partners_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Partners_On_Conflict>;
};

/** on_conflict condition type for table "partners" */
export type Partners_On_Conflict = {
  constraint: Partners_Constraint;
  update_columns?: Array<Partners_Update_Column>;
  where?: InputMaybe<Partners_Bool_Exp>;
};

/** Ordering options when selecting data from "partners". */
export type Partners_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  patients_partners_aggregate?: InputMaybe<Patients_Partners_Aggregate_Order_By>;
};

/** select columns of table "partners" */
export enum Partners_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "partners" */
export type Partners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Partners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Partners_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "partners" (current role has no relevant permissions) */
export enum Partners_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "patients" */
export type Patients = {
  __typename?: 'patients';
  archivedAt: Maybe<Scalars['timestamptz']>;
  birthdate: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  consultations: Array<Consultation>;
  /** An aggregate relationship */
  consultations_aggregate: Consultation_Aggregate;
  createdAt: Scalars['timestamptz'];
  email: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  id: Scalars['Int'];
  lastname: Scalars['String'];
  /** An object relationship */
  location: Maybe<Locations>;
  locationId: Maybe<Scalars['Int']>;
  notes: Maybe<Scalars['String']>;
  /** An array relationship */
  patients_partners: Array<Patients_Partners>;
  sex: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: Maybe<Users>;
  userId: Maybe<Scalars['Int']>;
  usersViewed: Maybe<Scalars['json']>;
};

/** columns and relationships of "patients" */
export type PatientsConsultationsArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsConsultations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsPatients_PartnersArgs = {
  distinct_on?: InputMaybe<Array<Patients_Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Partners_Order_By>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

/** columns and relationships of "patients" */
export type PatientsUsersViewedArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "patients" */
export type Patients_Aggregate = {
  __typename?: 'patients_aggregate';
  aggregate: Maybe<Patients_Aggregate_Fields>;
  nodes: Array<Patients>;
};

export type Patients_Aggregate_Bool_Exp = {
  count?: InputMaybe<Patients_Aggregate_Bool_Exp_Count>;
};

export type Patients_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_Fields = {
  __typename?: 'patients_aggregate_fields';
  avg: Maybe<Patients_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Patients_Max_Fields>;
  min: Maybe<Patients_Min_Fields>;
  stddev: Maybe<Patients_Stddev_Fields>;
  stddev_pop: Maybe<Patients_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Patients_Stddev_Samp_Fields>;
  sum: Maybe<Patients_Sum_Fields>;
  var_pop: Maybe<Patients_Var_Pop_Fields>;
  var_samp: Maybe<Patients_Var_Samp_Fields>;
  variance: Maybe<Patients_Variance_Fields>;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Patients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients" */
export type Patients_Aggregate_Order_By = {
  avg?: InputMaybe<Patients_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patients_Max_Order_By>;
  min?: InputMaybe<Patients_Min_Order_By>;
  stddev?: InputMaybe<Patients_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patients_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patients_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patients_Sum_Order_By>;
  var_pop?: InputMaybe<Patients_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patients_Var_Samp_Order_By>;
  variance?: InputMaybe<Patients_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "patients" */
export type Patients_Arr_Rel_Insert_Input = {
  data: Array<Patients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** aggregate avg on columns */
export type Patients_Avg_Fields = {
  __typename?: 'patients_avg_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patients" */
export type Patients_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients". All fields are combined with a logical 'AND'. */
export type Patients_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Bool_Exp>>;
  _not?: InputMaybe<Patients_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Bool_Exp>>;
  archivedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  birthdate?: InputMaybe<Timestamptz_Comparison_Exp>;
  consultations?: InputMaybe<Consultation_Bool_Exp>;
  consultations_aggregate?: InputMaybe<Consultation_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstname?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  lastname?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  locationId?: InputMaybe<Int_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  patients_partners?: InputMaybe<Patients_Partners_Bool_Exp>;
  sex?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
  usersViewed?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients" */
export enum Patients_Constraint {
  /** unique or primary key constraint on columns "user_id", "id", "location_id" */
  PatientLocationUserIds = 'patient_location_user_ids',
  /** unique or primary key constraint on columns "id" */
  PatientsPkey = 'patients_pkey',
}

/** input type for incrementing numeric columns in table "patients" */
export type Patients_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patients" */
export type Patients_Insert_Input = {
  birthdate?: InputMaybe<Scalars['timestamptz']>;
  consultations?: InputMaybe<Consultation_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  locationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  patients_partners?: InputMaybe<Patients_Partners_Arr_Rel_Insert_Input>;
  sex?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
  usersViewed?: InputMaybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Patients_Max_Fields = {
  __typename?: 'patients_max_fields';
  archivedAt: Maybe<Scalars['timestamptz']>;
  birthdate: Maybe<Scalars['timestamptz']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  locationId: Maybe<Scalars['Int']>;
  notes: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
  userId: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "patients" */
export type Patients_Max_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Min_Fields = {
  __typename?: 'patients_min_fields';
  archivedAt: Maybe<Scalars['timestamptz']>;
  birthdate: Maybe<Scalars['timestamptz']>;
  createdAt: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  lastname: Maybe<Scalars['String']>;
  locationId: Maybe<Scalars['Int']>;
  notes: Maybe<Scalars['String']>;
  sex: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['timestamptz']>;
  userId: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "patients" */
export type Patients_Min_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  sex?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients" */
export type Patients_Mutation_Response = {
  __typename?: 'patients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients>;
};

/** input type for inserting object relation for remote table "patients" */
export type Patients_Obj_Rel_Insert_Input = {
  data: Patients_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_On_Conflict>;
};

/** on_conflict condition type for table "patients" */
export type Patients_On_Conflict = {
  constraint: Patients_Constraint;
  update_columns?: Array<Patients_Update_Column>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** Ordering options when selecting data from "patients". */
export type Patients_Order_By = {
  archivedAt?: InputMaybe<Order_By>;
  birthdate?: InputMaybe<Order_By>;
  consultations_aggregate?: InputMaybe<Consultation_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  locationId?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  patients_partners_aggregate?: InputMaybe<Patients_Partners_Aggregate_Order_By>;
  sex?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  usersViewed?: InputMaybe<Order_By>;
};

/** columns and relationships of "patients_partners" */
export type Patients_Partners = {
  __typename?: 'patients_partners';
  bridgePatientId: Maybe<Scalars['String']>;
  clinicId: Maybe<Scalars['String']>;
  /** An object relationship */
  customer: Maybe<Partner_Customers>;
  customerId: Maybe<Scalars['uuid']>;
  data: Maybe<Scalars['jsonb']>;
  externalPatientId: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  metadata: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  partner: Maybe<Partners>;
  partnerId: Maybe<Scalars['uuid']>;
  /** An object relationship */
  patient: Maybe<Patients>;
  patientId: Maybe<Scalars['Int']>;
};

/** columns and relationships of "patients_partners" */
export type Patients_PartnersDataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "patients_partners" */
export type Patients_PartnersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "patients_partners" */
export type Patients_Partners_Aggregate_Order_By = {
  avg?: InputMaybe<Patients_Partners_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Patients_Partners_Max_Order_By>;
  min?: InputMaybe<Patients_Partners_Min_Order_By>;
  stddev?: InputMaybe<Patients_Partners_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Patients_Partners_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Patients_Partners_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Patients_Partners_Sum_Order_By>;
  var_pop?: InputMaybe<Patients_Partners_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Patients_Partners_Var_Samp_Order_By>;
  variance?: InputMaybe<Patients_Partners_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patients_Partners_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patients_partners" */
export type Patients_Partners_Arr_Rel_Insert_Input = {
  data: Array<Patients_Partners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Patients_Partners_On_Conflict>;
};

/** order by avg() on columns of table "patients_partners" */
export type Patients_Partners_Avg_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients_partners". All fields are combined with a logical 'AND'. */
export type Patients_Partners_Bool_Exp = {
  _and?: InputMaybe<Array<Patients_Partners_Bool_Exp>>;
  _not?: InputMaybe<Patients_Partners_Bool_Exp>;
  _or?: InputMaybe<Array<Patients_Partners_Bool_Exp>>;
  bridgePatientId?: InputMaybe<String_Comparison_Exp>;
  clinicId?: InputMaybe<String_Comparison_Exp>;
  customer?: InputMaybe<Partner_Customers_Bool_Exp>;
  customerId?: InputMaybe<Uuid_Comparison_Exp>;
  data?: InputMaybe<Jsonb_Comparison_Exp>;
  externalPatientId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  partner?: InputMaybe<Partners_Bool_Exp>;
  partnerId?: InputMaybe<Uuid_Comparison_Exp>;
  patient?: InputMaybe<Patients_Bool_Exp>;
  patientId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients_partners" */
export enum Patients_Partners_Constraint {
  /** unique or primary key constraint on columns "patientId", "id" */
  PatientsPartnersIds = 'patients_partners_ids',
  /** unique or primary key constraint on columns "id" */
  PatientsPartnersPkey = 'patients_partners_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patients_Partners_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>;
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patients_Partners_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>;
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patients_Partners_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "patients_partners" */
export type Patients_Partners_Inc_Input = {
  patientId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "patients_partners" */
export type Patients_Partners_Insert_Input = {
  bridgePatientId?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Partner_Customers_Obj_Rel_Insert_Input>;
  customerId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  externalPatientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  partner?: InputMaybe<Partners_Obj_Rel_Insert_Input>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  patient?: InputMaybe<Patients_Obj_Rel_Insert_Input>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by max() on columns of table "patients_partners" */
export type Patients_Partners_Max_Order_By = {
  bridgePatientId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  externalPatientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  partnerId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "patients_partners" */
export type Patients_Partners_Min_Order_By = {
  bridgePatientId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  customerId?: InputMaybe<Order_By>;
  externalPatientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  partnerId?: InputMaybe<Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "patients_partners" */
export type Patients_Partners_Mutation_Response = {
  __typename?: 'patients_partners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Patients_Partners>;
};

/** on_conflict condition type for table "patients_partners" */
export type Patients_Partners_On_Conflict = {
  constraint: Patients_Partners_Constraint;
  update_columns?: Array<Patients_Partners_Update_Column>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

/** Ordering options when selecting data from "patients_partners". */
export type Patients_Partners_Order_By = {
  bridgePatientId?: InputMaybe<Order_By>;
  clinicId?: InputMaybe<Order_By>;
  customer?: InputMaybe<Partner_Customers_Order_By>;
  customerId?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  externalPatientId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  partner?: InputMaybe<Partners_Order_By>;
  partnerId?: InputMaybe<Order_By>;
  patient?: InputMaybe<Patients_Order_By>;
  patientId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patients_partners */
export type Patients_Partners_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patients_Partners_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "patients_partners" */
export enum Patients_Partners_Select_Column {
  /** column name */
  BridgePatientId = 'bridgePatientId',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalPatientId = 'externalPatientId',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PartnerId = 'partnerId',
  /** column name */
  PatientId = 'patientId',
}

/** input type for updating data in table "patients_partners" */
export type Patients_Partners_Set_Input = {
  bridgePatientId?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  externalPatientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "patients_partners" */
export type Patients_Partners_Stddev_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "patients_partners" */
export type Patients_Partners_Stddev_Pop_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "patients_partners" */
export type Patients_Partners_Stddev_Samp_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patients_partners" */
export type Patients_Partners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patients_Partners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patients_Partners_Stream_Cursor_Value_Input = {
  bridgePatientId?: InputMaybe<Scalars['String']>;
  clinicId?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['uuid']>;
  data?: InputMaybe<Scalars['jsonb']>;
  externalPatientId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  partnerId?: InputMaybe<Scalars['uuid']>;
  patientId?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "patients_partners" */
export type Patients_Partners_Sum_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** update columns of table "patients_partners" */
export enum Patients_Partners_Update_Column {
  /** column name */
  BridgePatientId = 'bridgePatientId',
  /** column name */
  ClinicId = 'clinicId',
  /** column name */
  CustomerId = 'customerId',
  /** column name */
  Data = 'data',
  /** column name */
  ExternalPatientId = 'externalPatientId',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  PartnerId = 'partnerId',
  /** column name */
  PatientId = 'patientId',
}

export type Patients_Partners_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Patients_Partners_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Patients_Partners_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Patients_Partners_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Patients_Partners_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patients_Partners_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Patients_Partners_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patients_Partners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patients_Partners_Bool_Exp;
};

/** order by var_pop() on columns of table "patients_partners" */
export type Patients_Partners_Var_Pop_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "patients_partners" */
export type Patients_Partners_Var_Samp_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "patients_partners" */
export type Patients_Partners_Variance_Order_By = {
  patientId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: patients */
export type Patients_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "patients" */
export enum Patients_Select_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Notes = 'notes',
  /** column name */
  Sex = 'sex',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  UsersViewed = 'usersViewed',
}

/** input type for updating data in table "patients" */
export type Patients_Set_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  birthdate?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['Int']>;
  usersViewed?: InputMaybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Patients_Stddev_Fields = {
  __typename?: 'patients_stddev_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patients" */
export type Patients_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patients_Stddev_Pop_Fields = {
  __typename?: 'patients_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patients" */
export type Patients_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patients_Stddev_Samp_Fields = {
  __typename?: 'patients_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patients" */
export type Patients_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "patients" */
export type Patients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Patients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Patients_Stream_Cursor_Value_Input = {
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
  birthdate?: InputMaybe<Scalars['timestamptz']>;
  createdAt?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  lastname?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  sex?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']>;
  userId?: InputMaybe<Scalars['Int']>;
  usersViewed?: InputMaybe<Scalars['json']>;
};

/** aggregate sum on columns */
export type Patients_Sum_Fields = {
  __typename?: 'patients_sum_fields';
  id: Maybe<Scalars['Int']>;
  locationId: Maybe<Scalars['Int']>;
  userId: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "patients" */
export type Patients_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** update columns of table "patients" */
export enum Patients_Update_Column {
  /** column name */
  ArchivedAt = 'archivedAt',
  /** column name */
  Birthdate = 'birthdate',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  LocationId = 'locationId',
  /** column name */
  Notes = 'notes',
  /** column name */
  Sex = 'sex',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  UsersViewed = 'usersViewed',
}

export type Patients_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Patients_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Patients_Set_Input>;
  /** filter the rows which have to be updated */
  where: Patients_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Patients_Var_Pop_Fields = {
  __typename?: 'patients_var_pop_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patients" */
export type Patients_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patients_Var_Samp_Fields = {
  __typename?: 'patients_var_samp_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patients" */
export type Patients_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Patients_Variance_Fields = {
  __typename?: 'patients_variance_fields';
  id: Maybe<Scalars['Float']>;
  locationId: Maybe<Scalars['Float']>;
  userId: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patients" */
export type Patients_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  locationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** columns and relationships of "personas" */
export type Personas = {
  __typename?: 'personas';
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "personas". All fields are combined with a logical 'AND'. */
export type Personas_Bool_Exp = {
  _and?: InputMaybe<Array<Personas_Bool_Exp>>;
  _not?: InputMaybe<Personas_Bool_Exp>;
  _or?: InputMaybe<Array<Personas_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "personas" */
export enum Personas_Constraint {
  /** unique or primary key constraint on columns "value" */
  PersonasPkey = 'personas_pkey',
}

/** input type for inserting data into table "personas" */
export type Personas_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "personas" */
export type Personas_Mutation_Response = {
  __typename?: 'personas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Personas>;
};

/** on_conflict condition type for table "personas" */
export type Personas_On_Conflict = {
  constraint: Personas_Constraint;
  update_columns?: Array<Personas_Update_Column>;
  where?: InputMaybe<Personas_Bool_Exp>;
};

/** Ordering options when selecting data from "personas". */
export type Personas_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personas */
export type Personas_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "personas" */
export enum Personas_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "personas" */
export type Personas_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "personas" */
export type Personas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personas_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "personas" */
export enum Personas_Update_Column {
  /** column name */
  Value = 'value',
}

export type Personas_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personas_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personas_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "allisone_plus_configuration" */
  allisone_plus_configuration: Array<Allisone_Plus_Configuration>;
  /** fetch data from the table: "allisone_plus_users_configurations" */
  allisone_plus_users_configurations: Array<Allisone_Plus_Users_Configurations>;
  /** fetch data from the table: "allisone_plus_users_configurations" using primary key columns */
  allisone_plus_users_configurations_by_pk: Maybe<Allisone_Plus_Users_Configurations>;
  /** An array relationship */
  anatomies: Array<Anatomies>;
  /** fetch data from the table: "anatomies" using primary key columns */
  anatomies_by_pk: Maybe<Anatomies>;
  /** fetch data from the table: "anatomy_types" */
  anatomy_types: Array<Anatomy_Types>;
  /** fetch data from the table: "anatomy_types" using primary key columns */
  anatomy_types_by_pk: Maybe<Anatomy_Types>;
  /** An array relationship */
  api_clients_permissions: Array<Api_Clients_Permissions>;
  /** fetch data from the table: "api_clients_permissions" using primary key columns */
  api_clients_permissions_by_pk: Maybe<Api_Clients_Permissions>;
  /** fetch data from the table: "api_partner_tmp" */
  api_partner_tmp: Array<Api_Partner_Tmp>;
  /** fetch data from the table: "api_partner_tmp" using primary key columns */
  api_partner_tmp_by_pk: Maybe<Api_Partner_Tmp>;
  /** fetch data from the table: "api_permissions" */
  api_permissions: Array<Api_Permissions>;
  /** fetch data from the table: "api_permissions" using primary key columns */
  api_permissions_by_pk: Maybe<Api_Permissions>;
  /** fetch data from the table: "app_versions" */
  app_versions: Array<App_Versions>;
  /** fetch data from the table: "app_versions" using primary key columns */
  app_versions_by_pk: Maybe<App_Versions>;
  /** An array relationship */
  billings: Array<Billings>;
  /** An aggregate relationship */
  billings_aggregate: Billings_Aggregate;
  /** fetch data from the table: "billings" using primary key columns */
  billings_by_pk: Maybe<Billings>;
  /** fetch data from the table: "clinical_monitoring" */
  clinical_monitoring: Array<Clinical_Monitoring>;
  /** fetch aggregated fields from the table: "clinical_monitoring" */
  clinical_monitoring_aggregate: Clinical_Monitoring_Aggregate;
  /** fetch data from the table: "clinical_monitoring" using primary key columns */
  clinical_monitoring_by_pk: Maybe<Clinical_Monitoring>;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation: Maybe<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** An array relationship */
  consultations: Array<Consultation>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk: Maybe<Countries>;
  /** fetch data from the table: "customer_emails" */
  customer_emails: Array<Customer_Emails>;
  /** fetch data from the table: "customer_emails" using primary key columns */
  customer_emails_by_pk: Maybe<Customer_Emails>;
  /** fetch data from the table: "dental_software" */
  dental_software: Array<Dental_Software>;
  /** fetch data from the table: "dental_software" using primary key columns */
  dental_software_by_pk: Maybe<Dental_Software>;
  /** fetch data from the table: "dentist_specialities" */
  dentist_specialities: Array<Dentist_Specialities>;
  /** fetch data from the table: "dentist_specialities" using primary key columns */
  dentist_specialities_by_pk: Maybe<Dentist_Specialities>;
  /** fetch data from the table: "educationalSheets" */
  educationalSheets: Array<EducationalSheets>;
  /** fetch data from the table: "educationalSheetsTypes" */
  educationalSheetsTypes: Array<EducationalSheetsTypes>;
  /** fetch data from the table: "educationalSheetsTypes" using primary key columns */
  educationalSheetsTypes_by_pk: Maybe<EducationalSheetsTypes>;
  /** fetch data from the table: "educationalSheets" using primary key columns */
  educationalSheets_by_pk: Maybe<EducationalSheets>;
  /** fetch data from the table: "element_types" */
  element_types: Array<Element_Types>;
  /** fetch aggregated fields from the table: "element_types" */
  element_types_aggregate: Element_Types_Aggregate;
  /** fetch data from the table: "element_types" using primary key columns */
  element_types_by_pk: Maybe<Element_Types>;
  /** An array relationship */
  elements: Array<Elements>;
  /** An aggregate relationship */
  elements_aggregate: Elements_Aggregate;
  /** fetch data from the table: "elements" using primary key columns */
  elements_by_pk: Maybe<Elements>;
  /** fetch data from the table: "event_user" */
  event_user: Array<Event_User>;
  /** fetch data from the table: "event_user" using primary key columns */
  event_user_by_pk: Maybe<Event_User>;
  /** fetch data from the table: "observations" using primary key columns */
  getObservation: Maybe<Observations>;
  /** fetch data from the table: "observations" */
  getObservations: Array<Observations>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk: Maybe<Locations>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk: Maybe<Notifications>;
  /** fetch data from the table: "notifications_type" */
  notifications_type: Array<Notifications_Type>;
  /** fetch data from the table: "notifications_type" using primary key columns */
  notifications_type_by_pk: Maybe<Notifications_Type>;
  /** fetch data from the table: "operations" using primary key columns */
  operation: Maybe<Operations>;
  /** fetch data from the table: "operation_types" */
  operation_types: Array<Operation_Types>;
  /** fetch data from the table: "operation_types" using primary key columns */
  operation_types_by_pk: Maybe<Operation_Types>;
  /** An array relationship */
  operations: Array<Operations>;
  /** An aggregate relationship */
  operations_aggregate: Operations_Aggregate;
  /** fetch data from the table: "oral_structures" using primary key columns */
  oralStructure: Maybe<OralStructures>;
  /** fetch data from the table: "oral_structure_types" */
  oralStructureTypes: Array<OralStructureTypes>;
  /** fetch data from the table: "oral_structure_types" using primary key columns */
  oralStructureTypes_by_pk: Maybe<OralStructureTypes>;
  /** An array relationship */
  oralStructures: Array<OralStructures>;
  /** An array relationship */
  partner_customers: Array<Partner_Customers>;
  /** fetch data from the table: "partner_customers" using primary key columns */
  partner_customers_by_pk: Maybe<Partner_Customers>;
  /** fetch data from the table: "partners" */
  partners: Array<Partners>;
  /** fetch data from the table: "partners" using primary key columns */
  partners_by_pk: Maybe<Partners>;
  /** fetch data from the table: "patients" using primary key columns */
  patient: Maybe<Patients>;
  /** An array relationship */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "patients" */
  patientsAggregate: Patients_Aggregate;
  /** An array relationship */
  patients_partners: Array<Patients_Partners>;
  /** fetch data from the table: "patients_partners" using primary key columns */
  patients_partners_by_pk: Maybe<Patients_Partners>;
  /** fetch data from the table: "personas" */
  personas: Array<Personas>;
  /** fetch data from the table: "personas" using primary key columns */
  personas_by_pk: Maybe<Personas>;
  /** fetch data from the table: "resellers" */
  resellers: Array<Resellers>;
  /** fetch data from the table: "resellers" using primary key columns */
  resellers_by_pk: Maybe<Resellers>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk: Maybe<Roles>;
  /** fetch data from the table: "treatment_preferences" */
  treatment_preferences: Array<Treatment_Preferences>;
  /** fetch data from the table: "treatment_preferences" using primary key columns */
  treatment_preferences_by_pk: Maybe<Treatment_Preferences>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk: Maybe<Users>;
  /** fetch data from the table: "users_locations" */
  users_locations: Array<Users_Locations>;
  /** fetch aggregated fields from the table: "users_locations" */
  users_locations_aggregate: Users_Locations_Aggregate;
  /** fetch data from the table: "users_locations" using primary key columns */
  users_locations_by_pk: Maybe<Users_Locations>;
  /** fetch data from the table: "users_roles" */
  users_roles: Array<Users_Roles>;
  /** fetch aggregated fields from the table: "users_roles" */
  users_roles_aggregate: Users_Roles_Aggregate;
  /** fetch data from the table: "users_roles" using primary key columns */
  users_roles_by_pk: Maybe<Users_Roles>;
  /** fetch data from the table: "xrays" using primary key columns */
  xray: Maybe<Xrays>;
  /** fetch data from the table: "xray_software" */
  xray_software: Array<Xray_Software>;
  /** fetch data from the table: "xray_software" using primary key columns */
  xray_software_by_pk: Maybe<Xray_Software>;
  /** An array relationship */
  xrays: Array<Xrays>;
  /** fetch aggregated fields from the table: "xrays" */
  xraysAggregate: Xrays_Aggregate;
  /** fetch data from the table: "xrays_origin" */
  xrays_origin: Array<Xrays_Origin>;
  /** fetch data from the table: "xrays_origin" using primary key columns */
  xrays_origin_by_pk: Maybe<Xrays_Origin>;
};

export type Query_RootAllisone_Plus_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Allisone_Plus_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allisone_Plus_Configuration_Order_By>>;
  where?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
};

export type Query_RootAllisone_Plus_Users_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Order_By>>;
  where?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
};

export type Query_RootAllisone_Plus_Users_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAnatomiesArgs = {
  distinct_on?: InputMaybe<Array<Anatomies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anatomies_Order_By>>;
  where?: InputMaybe<Anatomies_Bool_Exp>;
};

export type Query_RootAnatomies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootAnatomy_TypesArgs = {
  distinct_on?: InputMaybe<Array<Anatomy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anatomy_Types_Order_By>>;
  where?: InputMaybe<Anatomy_Types_Bool_Exp>;
};

export type Query_RootAnatomy_Types_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootApi_Clients_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Api_Clients_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Clients_Permissions_Order_By>>;
  where?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
};

export type Query_RootApi_Clients_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApi_Partner_TmpArgs = {
  distinct_on?: InputMaybe<Array<Api_Partner_Tmp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Partner_Tmp_Order_By>>;
  where?: InputMaybe<Api_Partner_Tmp_Bool_Exp>;
};

export type Query_RootApi_Partner_Tmp_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootApi_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Api_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Permissions_Order_By>>;
  where?: InputMaybe<Api_Permissions_Bool_Exp>;
};

export type Query_RootApi_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootApp_VersionsArgs = {
  distinct_on?: InputMaybe<Array<App_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Versions_Order_By>>;
  where?: InputMaybe<App_Versions_Bool_Exp>;
};

export type Query_RootApp_Versions_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootBillingsArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

export type Query_RootBillings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

export type Query_RootBillings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootClinical_MonitoringArgs = {
  distinct_on?: InputMaybe<Array<Clinical_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinical_Monitoring_Order_By>>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

export type Query_RootClinical_Monitoring_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinical_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinical_Monitoring_Order_By>>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

export type Query_RootClinical_Monitoring_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootConsultationArgs = {
  id: Scalars['Int'];
};

export type Query_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

export type Query_RootConsultationsArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

export type Query_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Query_RootCountries_By_PkArgs = {
  code: Scalars['String'];
};

export type Query_RootCustomer_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};

export type Query_RootCustomer_Emails_By_PkArgs = {
  customerId: Scalars['uuid'];
  email: Scalars['String'];
};

export type Query_RootDental_SoftwareArgs = {
  distinct_on?: InputMaybe<Array<Dental_Software_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dental_Software_Order_By>>;
  where?: InputMaybe<Dental_Software_Bool_Exp>;
};

export type Query_RootDental_Software_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootDentist_SpecialitiesArgs = {
  distinct_on?: InputMaybe<Array<Dentist_Specialities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dentist_Specialities_Order_By>>;
  where?: InputMaybe<Dentist_Specialities_Bool_Exp>;
};

export type Query_RootDentist_Specialities_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootEducationalSheetsArgs = {
  distinct_on?: InputMaybe<Array<EducationalSheets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EducationalSheets_Order_By>>;
  where?: InputMaybe<EducationalSheets_Bool_Exp>;
};

export type Query_RootEducationalSheetsTypesArgs = {
  distinct_on?: InputMaybe<Array<EducationalSheetsTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EducationalSheetsTypes_Order_By>>;
  where?: InputMaybe<EducationalSheetsTypes_Bool_Exp>;
};

export type Query_RootEducationalSheetsTypes_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootEducationalSheets_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootElement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

export type Query_RootElement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

export type Query_RootElement_Types_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootElementsArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

export type Query_RootElements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

export type Query_RootElements_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEvent_UserArgs = {
  distinct_on?: InputMaybe<Array<Event_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_User_Order_By>>;
  where?: InputMaybe<Event_User_Bool_Exp>;
};

export type Query_RootEvent_User_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGetObservationArgs = {
  id: Scalars['Int'];
};

export type Query_RootGetObservationsArgs = {
  distinct_on?: InputMaybe<Array<Observations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Observations_Order_By>>;
  where?: InputMaybe<Observations_Bool_Exp>;
};

export type Query_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootNotifications_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Type_Order_By>>;
  where?: InputMaybe<Notifications_Type_Bool_Exp>;
};

export type Query_RootNotifications_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootOperationArgs = {
  id: Scalars['Int'];
};

export type Query_RootOperation_TypesArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};

export type Query_RootOperation_Types_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

export type Query_RootOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

export type Query_RootOralStructureArgs = {
  id: Scalars['uuid'];
};

export type Query_RootOralStructureTypesArgs = {
  distinct_on?: InputMaybe<Array<OralStructureTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructureTypes_Order_By>>;
  where?: InputMaybe<OralStructureTypes_Bool_Exp>;
};

export type Query_RootOralStructureTypes_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootOralStructuresArgs = {
  distinct_on?: InputMaybe<Array<OralStructures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructures_Order_By>>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

export type Query_RootPartner_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Customers_Order_By>>;
  where?: InputMaybe<Partner_Customers_Bool_Exp>;
};

export type Query_RootPartner_Customers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPartnersArgs = {
  distinct_on?: InputMaybe<Array<Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partners_Order_By>>;
  where?: InputMaybe<Partners_Bool_Exp>;
};

export type Query_RootPartners_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPatientArgs = {
  id: Scalars['Int'];
};

export type Query_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootPatientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Query_RootPatients_PartnersArgs = {
  distinct_on?: InputMaybe<Array<Patients_Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Partners_Order_By>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

export type Query_RootPatients_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPersonasArgs = {
  distinct_on?: InputMaybe<Array<Personas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personas_Order_By>>;
  where?: InputMaybe<Personas_Bool_Exp>;
};

export type Query_RootPersonas_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootResellersArgs = {
  distinct_on?: InputMaybe<Array<Resellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resellers_Order_By>>;
  where?: InputMaybe<Resellers_Bool_Exp>;
};

export type Query_RootResellers_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootTreatment_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Treatment_Preferences_Order_By>>;
  where?: InputMaybe<Treatment_Preferences_Bool_Exp>;
};

export type Query_RootTreatment_Preferences_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUsers_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

export type Query_RootUsers_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

export type Query_RootUsers_Locations_By_PkArgs = {
  location_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type Query_RootUsers_RolesArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

export type Query_RootUsers_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

export type Query_RootUsers_Roles_By_PkArgs = {
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type Query_RootXrayArgs = {
  id: Scalars['uuid'];
};

export type Query_RootXray_SoftwareArgs = {
  distinct_on?: InputMaybe<Array<Xray_Software_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xray_Software_Order_By>>;
  where?: InputMaybe<Xray_Software_Bool_Exp>;
};

export type Query_RootXray_Software_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootXraysArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

export type Query_RootXraysAggregateArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

export type Query_RootXrays_OriginArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Origin_Order_By>>;
  where?: InputMaybe<Xrays_Origin_Bool_Exp>;
};

export type Query_RootXrays_Origin_By_PkArgs = {
  origin: Scalars['String'];
};

/** columns and relationships of "resellers" */
export type Resellers = {
  __typename?: 'resellers';
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "resellers". All fields are combined with a logical 'AND'. */
export type Resellers_Bool_Exp = {
  _and?: InputMaybe<Array<Resellers_Bool_Exp>>;
  _not?: InputMaybe<Resellers_Bool_Exp>;
  _or?: InputMaybe<Array<Resellers_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "resellers" */
export enum Resellers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ResellersNameKey = 'resellers_name_key',
  /** unique or primary key constraint on columns "id" */
  ResellersPkey = 'resellers_pkey',
}

/** input type for inserting data into table "resellers" */
export type Resellers_Insert_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "resellers" */
export type Resellers_Mutation_Response = {
  __typename?: 'resellers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resellers>;
};

/** on_conflict condition type for table "resellers" */
export type Resellers_On_Conflict = {
  constraint: Resellers_Constraint;
  update_columns?: Array<Resellers_Update_Column>;
  where?: InputMaybe<Resellers_Bool_Exp>;
};

/** Ordering options when selecting data from "resellers". */
export type Resellers_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: resellers */
export type Resellers_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "resellers" */
export enum Resellers_Select_Column {
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "resellers" */
export type Resellers_Set_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "resellers" */
export type Resellers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Resellers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Resellers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "resellers" */
export enum Resellers_Update_Column {
  /** column name */
  Id = 'id',
}

export type Resellers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Resellers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Resellers_Bool_Exp;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "allisone_plus_configuration" */
  allisone_plus_configuration: Array<Allisone_Plus_Configuration>;
  /** fetch data from the table in a streaming manner: "allisone_plus_configuration" */
  allisone_plus_configuration_stream: Array<Allisone_Plus_Configuration>;
  /** fetch data from the table: "allisone_plus_users_configurations" */
  allisone_plus_users_configurations: Array<Allisone_Plus_Users_Configurations>;
  /** fetch data from the table: "allisone_plus_users_configurations" using primary key columns */
  allisone_plus_users_configurations_by_pk: Maybe<Allisone_Plus_Users_Configurations>;
  /** fetch data from the table in a streaming manner: "allisone_plus_users_configurations" */
  allisone_plus_users_configurations_stream: Array<Allisone_Plus_Users_Configurations>;
  /** An array relationship */
  anatomies: Array<Anatomies>;
  /** fetch data from the table: "anatomies" using primary key columns */
  anatomies_by_pk: Maybe<Anatomies>;
  /** fetch data from the table in a streaming manner: "anatomies" */
  anatomies_stream: Array<Anatomies>;
  /** fetch data from the table: "anatomy_types" */
  anatomy_types: Array<Anatomy_Types>;
  /** fetch data from the table: "anatomy_types" using primary key columns */
  anatomy_types_by_pk: Maybe<Anatomy_Types>;
  /** fetch data from the table in a streaming manner: "anatomy_types" */
  anatomy_types_stream: Array<Anatomy_Types>;
  /** An array relationship */
  api_clients_permissions: Array<Api_Clients_Permissions>;
  /** fetch data from the table: "api_clients_permissions" using primary key columns */
  api_clients_permissions_by_pk: Maybe<Api_Clients_Permissions>;
  /** fetch data from the table in a streaming manner: "api_clients_permissions" */
  api_clients_permissions_stream: Array<Api_Clients_Permissions>;
  /** fetch data from the table: "api_partner_tmp" */
  api_partner_tmp: Array<Api_Partner_Tmp>;
  /** fetch data from the table: "api_partner_tmp" using primary key columns */
  api_partner_tmp_by_pk: Maybe<Api_Partner_Tmp>;
  /** fetch data from the table in a streaming manner: "api_partner_tmp" */
  api_partner_tmp_stream: Array<Api_Partner_Tmp>;
  /** fetch data from the table: "api_permissions" */
  api_permissions: Array<Api_Permissions>;
  /** fetch data from the table: "api_permissions" using primary key columns */
  api_permissions_by_pk: Maybe<Api_Permissions>;
  /** fetch data from the table in a streaming manner: "api_permissions" */
  api_permissions_stream: Array<Api_Permissions>;
  /** fetch data from the table: "app_versions" */
  app_versions: Array<App_Versions>;
  /** fetch data from the table: "app_versions" using primary key columns */
  app_versions_by_pk: Maybe<App_Versions>;
  /** fetch data from the table in a streaming manner: "app_versions" */
  app_versions_stream: Array<App_Versions>;
  /** An array relationship */
  billings: Array<Billings>;
  /** An aggregate relationship */
  billings_aggregate: Billings_Aggregate;
  /** fetch data from the table: "billings" using primary key columns */
  billings_by_pk: Maybe<Billings>;
  /** fetch data from the table in a streaming manner: "billings" */
  billings_stream: Array<Billings>;
  /** fetch data from the table: "clinical_monitoring" */
  clinical_monitoring: Array<Clinical_Monitoring>;
  /** fetch aggregated fields from the table: "clinical_monitoring" */
  clinical_monitoring_aggregate: Clinical_Monitoring_Aggregate;
  /** fetch data from the table: "clinical_monitoring" using primary key columns */
  clinical_monitoring_by_pk: Maybe<Clinical_Monitoring>;
  /** fetch data from the table in a streaming manner: "clinical_monitoring" */
  clinical_monitoring_stream: Array<Clinical_Monitoring>;
  /** fetch data from the table: "consultation" using primary key columns */
  consultation: Maybe<Consultation>;
  /** fetch aggregated fields from the table: "consultation" */
  consultation_aggregate: Consultation_Aggregate;
  /** fetch data from the table in a streaming manner: "consultation" */
  consultation_stream: Array<Consultation>;
  /** An array relationship */
  consultations: Array<Consultation>;
  /** fetch data from the table: "countries" */
  countries: Array<Countries>;
  /** fetch data from the table: "countries" using primary key columns */
  countries_by_pk: Maybe<Countries>;
  /** fetch data from the table in a streaming manner: "countries" */
  countries_stream: Array<Countries>;
  /** fetch data from the table: "customer_emails" */
  customer_emails: Array<Customer_Emails>;
  /** fetch data from the table: "customer_emails" using primary key columns */
  customer_emails_by_pk: Maybe<Customer_Emails>;
  /** fetch data from the table in a streaming manner: "customer_emails" */
  customer_emails_stream: Array<Customer_Emails>;
  /** fetch data from the table: "dental_software" */
  dental_software: Array<Dental_Software>;
  /** fetch data from the table: "dental_software" using primary key columns */
  dental_software_by_pk: Maybe<Dental_Software>;
  /** fetch data from the table in a streaming manner: "dental_software" */
  dental_software_stream: Array<Dental_Software>;
  /** fetch data from the table: "dentist_specialities" */
  dentist_specialities: Array<Dentist_Specialities>;
  /** fetch data from the table: "dentist_specialities" using primary key columns */
  dentist_specialities_by_pk: Maybe<Dentist_Specialities>;
  /** fetch data from the table in a streaming manner: "dentist_specialities" */
  dentist_specialities_stream: Array<Dentist_Specialities>;
  /** fetch data from the table: "educationalSheets" */
  educationalSheets: Array<EducationalSheets>;
  /** fetch data from the table: "educationalSheetsTypes" */
  educationalSheetsTypes: Array<EducationalSheetsTypes>;
  /** fetch data from the table: "educationalSheetsTypes" using primary key columns */
  educationalSheetsTypes_by_pk: Maybe<EducationalSheetsTypes>;
  /** fetch data from the table in a streaming manner: "educationalSheetsTypes" */
  educationalSheetsTypes_stream: Array<EducationalSheetsTypes>;
  /** fetch data from the table: "educationalSheets" using primary key columns */
  educationalSheets_by_pk: Maybe<EducationalSheets>;
  /** fetch data from the table in a streaming manner: "educationalSheets" */
  educationalSheets_stream: Array<EducationalSheets>;
  /** fetch data from the table: "element_types" */
  element_types: Array<Element_Types>;
  /** fetch aggregated fields from the table: "element_types" */
  element_types_aggregate: Element_Types_Aggregate;
  /** fetch data from the table: "element_types" using primary key columns */
  element_types_by_pk: Maybe<Element_Types>;
  /** fetch data from the table in a streaming manner: "element_types" */
  element_types_stream: Array<Element_Types>;
  /** An array relationship */
  elements: Array<Elements>;
  /** An aggregate relationship */
  elements_aggregate: Elements_Aggregate;
  /** fetch data from the table: "elements" using primary key columns */
  elements_by_pk: Maybe<Elements>;
  /** fetch data from the table in a streaming manner: "elements" */
  elements_stream: Array<Elements>;
  /** fetch data from the table: "event_user" */
  event_user: Array<Event_User>;
  /** fetch data from the table: "event_user" using primary key columns */
  event_user_by_pk: Maybe<Event_User>;
  /** fetch data from the table in a streaming manner: "event_user" */
  event_user_stream: Array<Event_User>;
  /** fetch data from the table: "observations" using primary key columns */
  getObservation: Maybe<Observations>;
  /** fetch data from the table: "observations" */
  getObservations: Array<Observations>;
  /** fetch data from the table: "locations" */
  locations: Array<Locations>;
  /** fetch aggregated fields from the table: "locations" */
  locations_aggregate: Locations_Aggregate;
  /** fetch data from the table: "locations" using primary key columns */
  locations_by_pk: Maybe<Locations>;
  /** fetch data from the table in a streaming manner: "locations" */
  locations_stream: Array<Locations>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "notifications_type" */
  notifications_type: Array<Notifications_Type>;
  /** fetch data from the table: "notifications_type" using primary key columns */
  notifications_type_by_pk: Maybe<Notifications_Type>;
  /** fetch data from the table in a streaming manner: "notifications_type" */
  notifications_type_stream: Array<Notifications_Type>;
  /** fetch data from the table in a streaming manner: "observations" */
  observations_stream: Array<Observations>;
  /** fetch data from the table: "operations" using primary key columns */
  operation: Maybe<Operations>;
  /** fetch data from the table: "operation_types" */
  operation_types: Array<Operation_Types>;
  /** fetch data from the table: "operation_types" using primary key columns */
  operation_types_by_pk: Maybe<Operation_Types>;
  /** fetch data from the table in a streaming manner: "operation_types" */
  operation_types_stream: Array<Operation_Types>;
  /** An array relationship */
  operations: Array<Operations>;
  /** An aggregate relationship */
  operations_aggregate: Operations_Aggregate;
  /** fetch data from the table in a streaming manner: "operations" */
  operations_stream: Array<Operations>;
  /** fetch data from the table: "oral_structures" using primary key columns */
  oralStructure: Maybe<OralStructures>;
  /** fetch data from the table: "oral_structure_types" */
  oralStructureTypes: Array<OralStructureTypes>;
  /** fetch data from the table: "oral_structure_types" using primary key columns */
  oralStructureTypes_by_pk: Maybe<OralStructureTypes>;
  /** fetch data from the table in a streaming manner: "oral_structure_types" */
  oralStructureTypes_stream: Array<OralStructureTypes>;
  /** An array relationship */
  oralStructures: Array<OralStructures>;
  /** fetch data from the table in a streaming manner: "oral_structures" */
  oralStructures_stream: Array<OralStructures>;
  /** An array relationship */
  partner_customers: Array<Partner_Customers>;
  /** fetch data from the table: "partner_customers" using primary key columns */
  partner_customers_by_pk: Maybe<Partner_Customers>;
  /** fetch data from the table in a streaming manner: "partner_customers" */
  partner_customers_stream: Array<Partner_Customers>;
  /** fetch data from the table: "partners" */
  partners: Array<Partners>;
  /** fetch data from the table: "partners" using primary key columns */
  partners_by_pk: Maybe<Partners>;
  /** fetch data from the table in a streaming manner: "partners" */
  partners_stream: Array<Partners>;
  /** fetch data from the table: "patients" using primary key columns */
  patient: Maybe<Patients>;
  /** An array relationship */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "patients" */
  patientsAggregate: Patients_Aggregate;
  /** fetch data from the table in a streaming manner: "patients" */
  patientsStream: Array<Patients>;
  /** An array relationship */
  patients_partners: Array<Patients_Partners>;
  /** fetch data from the table: "patients_partners" using primary key columns */
  patients_partners_by_pk: Maybe<Patients_Partners>;
  /** fetch data from the table in a streaming manner: "patients_partners" */
  patients_partners_stream: Array<Patients_Partners>;
  /** fetch data from the table: "personas" */
  personas: Array<Personas>;
  /** fetch data from the table: "personas" using primary key columns */
  personas_by_pk: Maybe<Personas>;
  /** fetch data from the table in a streaming manner: "personas" */
  personas_stream: Array<Personas>;
  /** fetch data from the table: "resellers" */
  resellers: Array<Resellers>;
  /** fetch data from the table: "resellers" using primary key columns */
  resellers_by_pk: Maybe<Resellers>;
  /** fetch data from the table in a streaming manner: "resellers" */
  resellers_stream: Array<Resellers>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "treatment_preferences" */
  treatment_preferences: Array<Treatment_Preferences>;
  /** fetch data from the table: "treatment_preferences" using primary key columns */
  treatment_preferences_by_pk: Maybe<Treatment_Preferences>;
  /** fetch data from the table in a streaming manner: "treatment_preferences" */
  treatment_preferences_stream: Array<Treatment_Preferences>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk: Maybe<Users>;
  /** fetch data from the table: "users_locations" */
  users_locations: Array<Users_Locations>;
  /** fetch aggregated fields from the table: "users_locations" */
  users_locations_aggregate: Users_Locations_Aggregate;
  /** fetch data from the table: "users_locations" using primary key columns */
  users_locations_by_pk: Maybe<Users_Locations>;
  /** fetch data from the table in a streaming manner: "users_locations" */
  users_locations_stream: Array<Users_Locations>;
  /** fetch data from the table: "users_roles" */
  users_roles: Array<Users_Roles>;
  /** fetch aggregated fields from the table: "users_roles" */
  users_roles_aggregate: Users_Roles_Aggregate;
  /** fetch data from the table: "users_roles" using primary key columns */
  users_roles_by_pk: Maybe<Users_Roles>;
  /** fetch data from the table in a streaming manner: "users_roles" */
  users_roles_stream: Array<Users_Roles>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "xrays" using primary key columns */
  xray: Maybe<Xrays>;
  /** fetch data from the table: "xray_software" */
  xray_software: Array<Xray_Software>;
  /** fetch data from the table: "xray_software" using primary key columns */
  xray_software_by_pk: Maybe<Xray_Software>;
  /** fetch data from the table in a streaming manner: "xray_software" */
  xray_software_stream: Array<Xray_Software>;
  /** An array relationship */
  xrays: Array<Xrays>;
  /** fetch aggregated fields from the table: "xrays" */
  xraysAggregate: Xrays_Aggregate;
  /** fetch data from the table in a streaming manner: "xrays" */
  xraysStream: Array<Xrays>;
  /** fetch data from the table: "xrays_origin" */
  xrays_origin: Array<Xrays_Origin>;
  /** fetch data from the table: "xrays_origin" using primary key columns */
  xrays_origin_by_pk: Maybe<Xrays_Origin>;
  /** fetch data from the table in a streaming manner: "xrays_origin" */
  xrays_origin_stream: Array<Xrays_Origin>;
};

export type Subscription_RootAllisone_Plus_ConfigurationArgs = {
  distinct_on?: InputMaybe<Array<Allisone_Plus_Configuration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allisone_Plus_Configuration_Order_By>>;
  where?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
};

export type Subscription_RootAllisone_Plus_Configuration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Allisone_Plus_Configuration_Stream_Cursor_Input>>;
  where?: InputMaybe<Allisone_Plus_Configuration_Bool_Exp>;
};

export type Subscription_RootAllisone_Plus_Users_ConfigurationsArgs = {
  distinct_on?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Allisone_Plus_Users_Configurations_Order_By>>;
  where?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
};

export type Subscription_RootAllisone_Plus_Users_Configurations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAllisone_Plus_Users_Configurations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Allisone_Plus_Users_Configurations_Stream_Cursor_Input>>;
  where?: InputMaybe<Allisone_Plus_Users_Configurations_Bool_Exp>;
};

export type Subscription_RootAnatomiesArgs = {
  distinct_on?: InputMaybe<Array<Anatomies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anatomies_Order_By>>;
  where?: InputMaybe<Anatomies_Bool_Exp>;
};

export type Subscription_RootAnatomies_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootAnatomies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Anatomies_Stream_Cursor_Input>>;
  where?: InputMaybe<Anatomies_Bool_Exp>;
};

export type Subscription_RootAnatomy_TypesArgs = {
  distinct_on?: InputMaybe<Array<Anatomy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anatomy_Types_Order_By>>;
  where?: InputMaybe<Anatomy_Types_Bool_Exp>;
};

export type Subscription_RootAnatomy_Types_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootAnatomy_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Anatomy_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Anatomy_Types_Bool_Exp>;
};

export type Subscription_RootApi_Clients_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Api_Clients_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Clients_Permissions_Order_By>>;
  where?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
};

export type Subscription_RootApi_Clients_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApi_Clients_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Api_Clients_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Api_Clients_Permissions_Bool_Exp>;
};

export type Subscription_RootApi_Partner_TmpArgs = {
  distinct_on?: InputMaybe<Array<Api_Partner_Tmp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Partner_Tmp_Order_By>>;
  where?: InputMaybe<Api_Partner_Tmp_Bool_Exp>;
};

export type Subscription_RootApi_Partner_Tmp_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootApi_Partner_Tmp_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Api_Partner_Tmp_Stream_Cursor_Input>>;
  where?: InputMaybe<Api_Partner_Tmp_Bool_Exp>;
};

export type Subscription_RootApi_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Api_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Api_Permissions_Order_By>>;
  where?: InputMaybe<Api_Permissions_Bool_Exp>;
};

export type Subscription_RootApi_Permissions_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootApi_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Api_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Api_Permissions_Bool_Exp>;
};

export type Subscription_RootApp_VersionsArgs = {
  distinct_on?: InputMaybe<Array<App_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<App_Versions_Order_By>>;
  where?: InputMaybe<App_Versions_Bool_Exp>;
};

export type Subscription_RootApp_Versions_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootApp_Versions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<App_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<App_Versions_Bool_Exp>;
};

export type Subscription_RootBillingsArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

export type Subscription_RootBillings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

export type Subscription_RootBillings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootBillings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Billings_Stream_Cursor_Input>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

export type Subscription_RootClinical_MonitoringArgs = {
  distinct_on?: InputMaybe<Array<Clinical_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinical_Monitoring_Order_By>>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

export type Subscription_RootClinical_Monitoring_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clinical_Monitoring_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clinical_Monitoring_Order_By>>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

export type Subscription_RootClinical_Monitoring_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootClinical_Monitoring_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clinical_Monitoring_Stream_Cursor_Input>>;
  where?: InputMaybe<Clinical_Monitoring_Bool_Exp>;
};

export type Subscription_RootConsultationArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootConsultation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

export type Subscription_RootConsultation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Consultation_Stream_Cursor_Input>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

export type Subscription_RootConsultationsArgs = {
  distinct_on?: InputMaybe<Array<Consultation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Consultation_Order_By>>;
  where?: InputMaybe<Consultation_Bool_Exp>;
};

export type Subscription_RootCountriesArgs = {
  distinct_on?: InputMaybe<Array<Countries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Countries_Order_By>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Subscription_RootCountries_By_PkArgs = {
  code: Scalars['String'];
};

export type Subscription_RootCountries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Countries_Stream_Cursor_Input>>;
  where?: InputMaybe<Countries_Bool_Exp>;
};

export type Subscription_RootCustomer_EmailsArgs = {
  distinct_on?: InputMaybe<Array<Customer_Emails_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Emails_Order_By>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};

export type Subscription_RootCustomer_Emails_By_PkArgs = {
  customerId: Scalars['uuid'];
  email: Scalars['String'];
};

export type Subscription_RootCustomer_Emails_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Emails_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Emails_Bool_Exp>;
};

export type Subscription_RootDental_SoftwareArgs = {
  distinct_on?: InputMaybe<Array<Dental_Software_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dental_Software_Order_By>>;
  where?: InputMaybe<Dental_Software_Bool_Exp>;
};

export type Subscription_RootDental_Software_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDental_Software_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dental_Software_Stream_Cursor_Input>>;
  where?: InputMaybe<Dental_Software_Bool_Exp>;
};

export type Subscription_RootDentist_SpecialitiesArgs = {
  distinct_on?: InputMaybe<Array<Dentist_Specialities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dentist_Specialities_Order_By>>;
  where?: InputMaybe<Dentist_Specialities_Bool_Exp>;
};

export type Subscription_RootDentist_Specialities_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDentist_Specialities_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dentist_Specialities_Stream_Cursor_Input>>;
  where?: InputMaybe<Dentist_Specialities_Bool_Exp>;
};

export type Subscription_RootEducationalSheetsArgs = {
  distinct_on?: InputMaybe<Array<EducationalSheets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EducationalSheets_Order_By>>;
  where?: InputMaybe<EducationalSheets_Bool_Exp>;
};

export type Subscription_RootEducationalSheetsTypesArgs = {
  distinct_on?: InputMaybe<Array<EducationalSheetsTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<EducationalSheetsTypes_Order_By>>;
  where?: InputMaybe<EducationalSheetsTypes_Bool_Exp>;
};

export type Subscription_RootEducationalSheetsTypes_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootEducationalSheetsTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EducationalSheetsTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<EducationalSheetsTypes_Bool_Exp>;
};

export type Subscription_RootEducationalSheets_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEducationalSheets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<EducationalSheets_Stream_Cursor_Input>>;
  where?: InputMaybe<EducationalSheets_Bool_Exp>;
};

export type Subscription_RootElement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

export type Subscription_RootElement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Element_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Element_Types_Order_By>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

export type Subscription_RootElement_Types_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootElement_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Element_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Element_Types_Bool_Exp>;
};

export type Subscription_RootElementsArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

export type Subscription_RootElements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

export type Subscription_RootElements_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootElements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Elements_Stream_Cursor_Input>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

export type Subscription_RootEvent_UserArgs = {
  distinct_on?: InputMaybe<Array<Event_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Event_User_Order_By>>;
  where?: InputMaybe<Event_User_Bool_Exp>;
};

export type Subscription_RootEvent_User_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEvent_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Event_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Event_User_Bool_Exp>;
};

export type Subscription_RootGetObservationArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGetObservationsArgs = {
  distinct_on?: InputMaybe<Array<Observations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Observations_Order_By>>;
  where?: InputMaybe<Observations_Bool_Exp>;
};

export type Subscription_RootLocationsArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Locations_Order_By>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLocations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Type_Order_By>>;
  where?: InputMaybe<Notifications_Type_Bool_Exp>;
};

export type Subscription_RootNotifications_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootNotifications_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Type_Bool_Exp>;
};

export type Subscription_RootObservations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Observations_Stream_Cursor_Input>>;
  where?: InputMaybe<Observations_Bool_Exp>;
};

export type Subscription_RootOperationArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOperation_TypesArgs = {
  distinct_on?: InputMaybe<Array<Operation_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operation_Types_Order_By>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};

export type Subscription_RootOperation_Types_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootOperation_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operation_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Operation_Types_Bool_Exp>;
};

export type Subscription_RootOperationsArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

export type Subscription_RootOperations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Order_By>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

export type Subscription_RootOperations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operations_Stream_Cursor_Input>>;
  where?: InputMaybe<Operations_Bool_Exp>;
};

export type Subscription_RootOralStructureArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootOralStructureTypesArgs = {
  distinct_on?: InputMaybe<Array<OralStructureTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructureTypes_Order_By>>;
  where?: InputMaybe<OralStructureTypes_Bool_Exp>;
};

export type Subscription_RootOralStructureTypes_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootOralStructureTypes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OralStructureTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<OralStructureTypes_Bool_Exp>;
};

export type Subscription_RootOralStructuresArgs = {
  distinct_on?: InputMaybe<Array<OralStructures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructures_Order_By>>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

export type Subscription_RootOralStructures_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<OralStructures_Stream_Cursor_Input>>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

export type Subscription_RootPartner_CustomersArgs = {
  distinct_on?: InputMaybe<Array<Partner_Customers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partner_Customers_Order_By>>;
  where?: InputMaybe<Partner_Customers_Bool_Exp>;
};

export type Subscription_RootPartner_Customers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPartner_Customers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partner_Customers_Stream_Cursor_Input>>;
  where?: InputMaybe<Partner_Customers_Bool_Exp>;
};

export type Subscription_RootPartnersArgs = {
  distinct_on?: InputMaybe<Array<Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Partners_Order_By>>;
  where?: InputMaybe<Partners_Bool_Exp>;
};

export type Subscription_RootPartners_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPartners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Partners_Stream_Cursor_Input>>;
  where?: InputMaybe<Partners_Bool_Exp>;
};

export type Subscription_RootPatientArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPatientsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPatientsStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patients_Stream_Cursor_Input>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

export type Subscription_RootPatients_PartnersArgs = {
  distinct_on?: InputMaybe<Array<Patients_Partners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Partners_Order_By>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

export type Subscription_RootPatients_Partners_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPatients_Partners_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Patients_Partners_Stream_Cursor_Input>>;
  where?: InputMaybe<Patients_Partners_Bool_Exp>;
};

export type Subscription_RootPersonasArgs = {
  distinct_on?: InputMaybe<Array<Personas_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Personas_Order_By>>;
  where?: InputMaybe<Personas_Bool_Exp>;
};

export type Subscription_RootPersonas_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootPersonas_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Personas_Stream_Cursor_Input>>;
  where?: InputMaybe<Personas_Bool_Exp>;
};

export type Subscription_RootResellersArgs = {
  distinct_on?: InputMaybe<Array<Resellers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Resellers_Order_By>>;
  where?: InputMaybe<Resellers_Bool_Exp>;
};

export type Subscription_RootResellers_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootResellers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Resellers_Stream_Cursor_Input>>;
  where?: InputMaybe<Resellers_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootTreatment_PreferencesArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Preferences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Treatment_Preferences_Order_By>>;
  where?: InputMaybe<Treatment_Preferences_Bool_Exp>;
};

export type Subscription_RootTreatment_Preferences_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTreatment_Preferences_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Treatment_Preferences_Stream_Cursor_Input>>;
  where?: InputMaybe<Treatment_Preferences_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUsers_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

export type Subscription_RootUsers_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

export type Subscription_RootUsers_Locations_By_PkArgs = {
  location_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type Subscription_RootUsers_Locations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

export type Subscription_RootUsers_RolesArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

export type Subscription_RootUsers_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

export type Subscription_RootUsers_Roles_By_PkArgs = {
  role_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

export type Subscription_RootUsers_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootXrayArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootXray_SoftwareArgs = {
  distinct_on?: InputMaybe<Array<Xray_Software_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xray_Software_Order_By>>;
  where?: InputMaybe<Xray_Software_Bool_Exp>;
};

export type Subscription_RootXray_Software_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootXray_Software_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Xray_Software_Stream_Cursor_Input>>;
  where?: InputMaybe<Xray_Software_Bool_Exp>;
};

export type Subscription_RootXraysArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

export type Subscription_RootXraysAggregateArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Order_By>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

export type Subscription_RootXraysStreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Xrays_Stream_Cursor_Input>>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

export type Subscription_RootXrays_OriginArgs = {
  distinct_on?: InputMaybe<Array<Xrays_Origin_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Xrays_Origin_Order_By>>;
  where?: InputMaybe<Xrays_Origin_Bool_Exp>;
};

export type Subscription_RootXrays_Origin_By_PkArgs = {
  origin: Scalars['String'];
};

export type Subscription_RootXrays_Origin_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Xrays_Origin_Stream_Cursor_Input>>;
  where?: InputMaybe<Xrays_Origin_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "treatment_preferences" */
export type Treatment_Preferences = {
  __typename?: 'treatment_preferences';
  id: Scalars['uuid'];
  treatmentPreferences: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  user: Maybe<Users>;
  userId: Maybe<Scalars['Int']>;
};

/** columns and relationships of "treatment_preferences" */
export type Treatment_PreferencesTreatmentPreferencesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Treatment_Preferences_Append_Input = {
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "treatment_preferences". All fields are combined with a logical 'AND'. */
export type Treatment_Preferences_Bool_Exp = {
  _and?: InputMaybe<Array<Treatment_Preferences_Bool_Exp>>;
  _not?: InputMaybe<Treatment_Preferences_Bool_Exp>;
  _or?: InputMaybe<Array<Treatment_Preferences_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  treatmentPreferences?: InputMaybe<Jsonb_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "treatment_preferences" */
export enum Treatment_Preferences_Constraint {
  /** unique or primary key constraint on columns "id" */
  TreatmentPreferencesPkey = 'treatment_preferences_pkey',
  /** unique or primary key constraint on columns "user_id" */
  TreatmentPreferencesUserIdKey = 'treatment_preferences_user_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Treatment_Preferences_Delete_At_Path_Input = {
  treatmentPreferences?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Treatment_Preferences_Delete_Elem_Input = {
  treatmentPreferences?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Treatment_Preferences_Delete_Key_Input = {
  treatmentPreferences?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "treatment_preferences" */
export type Treatment_Preferences_Inc_Input = {
  userId?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "treatment_preferences" */
export type Treatment_Preferences_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** response of any mutation on the table "treatment_preferences" */
export type Treatment_Preferences_Mutation_Response = {
  __typename?: 'treatment_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Treatment_Preferences>;
};

/** input type for inserting object relation for remote table "treatment_preferences" */
export type Treatment_Preferences_Obj_Rel_Insert_Input = {
  data: Treatment_Preferences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Treatment_Preferences_On_Conflict>;
};

/** on_conflict condition type for table "treatment_preferences" */
export type Treatment_Preferences_On_Conflict = {
  constraint: Treatment_Preferences_Constraint;
  update_columns?: Array<Treatment_Preferences_Update_Column>;
  where?: InputMaybe<Treatment_Preferences_Bool_Exp>;
};

/** Ordering options when selecting data from "treatment_preferences". */
export type Treatment_Preferences_Order_By = {
  id?: InputMaybe<Order_By>;
  treatmentPreferences?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: treatment_preferences */
export type Treatment_Preferences_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Treatment_Preferences_Prepend_Input = {
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "treatment_preferences" */
export enum Treatment_Preferences_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TreatmentPreferences = 'treatmentPreferences',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "treatment_preferences" */
export type Treatment_Preferences_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** Streaming cursor of the table "treatment_preferences" */
export type Treatment_Preferences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Treatment_Preferences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Treatment_Preferences_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
  userId?: InputMaybe<Scalars['Int']>;
};

/** update columns of table "treatment_preferences" */
export enum Treatment_Preferences_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TreatmentPreferences = 'treatmentPreferences',
  /** column name */
  UserId = 'userId',
}

export type Treatment_Preferences_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Treatment_Preferences_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Treatment_Preferences_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Treatment_Preferences_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Treatment_Preferences_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Treatment_Preferences_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Treatment_Preferences_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Treatment_Preferences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Treatment_Preferences_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  billingsByPaymasterid: Array<Billings>;
  /** An aggregate relationship */
  billingsByPaymasterid_aggregate: Billings_Aggregate;
  cgu_approved_at: Maybe<Scalars['timestamptz']>;
  contactEmail: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  customer_id: Maybe<Scalars['String']>;
  demoMadeAt: Maybe<Scalars['date']>;
  dentistSpeciality: Maybe<Dentist_Specialities_Enum>;
  disclaimer_approved_at: Maybe<Scalars['timestamptz']>;
  element_group_mode: Maybe<Element_Group_Modes_Enum>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  id: Scalars['Int'];
  identityProvider: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  intercom_token_id: Maybe<Scalars['String']>;
  is_admin: Maybe<Scalars['Boolean']>;
  is_dentist: Maybe<Scalars['Boolean']>;
  is_enabled: Scalars['Boolean'];
  is_first: Maybe<Scalars['Boolean']>;
  is_validated: Scalars['Boolean'];
  lastname: Scalars['String'];
  locale: Locale_Enum;
  password: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregate relationship */
  patients_aggregate: Patients_Aggregate;
  phone: Maybe<Scalars['String']>;
  renew_pwd: Scalars['Boolean'];
  rpps_number: Maybe<Scalars['String']>;
  toothNumberingSystem: Scalars['String'];
  /** An object relationship */
  treatment_preferences: Maybe<Treatment_Preferences>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users_users_locations: Array<Users_Locations>;
  /** An aggregate relationship */
  users_users_locations_aggregate: Users_Locations_Aggregate;
  /** An array relationship */
  users_users_roles: Array<Users_Roles>;
  /** An aggregate relationship */
  users_users_roles_aggregate: Users_Roles_Aggregate;
  verification_code: Maybe<Scalars['String']>;
};

/** columns and relationships of "users" */
export type UsersBillingsByPaymasteridArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersBillingsByPaymasterid_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billings_Order_By>>;
  where?: InputMaybe<Billings_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatientsArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersPatients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Patients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Patients_Order_By>>;
  where?: InputMaybe<Patients_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Users_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Users_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Locations_Order_By>>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Users_RolesArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersUsers_Users_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Roles_Order_By>>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Users_Max_Fields>;
  min: Maybe<Users_Min_Fields>;
  stddev: Maybe<Users_Stddev_Fields>;
  stddev_pop: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Users_Stddev_Samp_Fields>;
  sum: Maybe<Users_Sum_Fields>;
  var_pop: Maybe<Users_Var_Pop_Fields>;
  var_samp: Maybe<Users_Var_Samp_Fields>;
  variance: Maybe<Users_Variance_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  billingsByPaymasterid?: InputMaybe<Billings_Bool_Exp>;
  billingsByPaymasterid_aggregate?: InputMaybe<Billings_Aggregate_Bool_Exp>;
  cgu_approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  demoMadeAt?: InputMaybe<Date_Comparison_Exp>;
  dentistSpeciality?: InputMaybe<Dentist_Specialities_Enum_Comparison_Exp>;
  disclaimer_approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  element_group_mode?: InputMaybe<Element_Group_Modes_Enum_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  firstname?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  identityProvider?: InputMaybe<String_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  intercom_token_id?: InputMaybe<String_Comparison_Exp>;
  is_admin?: InputMaybe<Boolean_Comparison_Exp>;
  is_dentist?: InputMaybe<Boolean_Comparison_Exp>;
  is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_first?: InputMaybe<Boolean_Comparison_Exp>;
  is_validated?: InputMaybe<Boolean_Comparison_Exp>;
  lastname?: InputMaybe<String_Comparison_Exp>;
  locale?: InputMaybe<Locale_Enum_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  patients?: InputMaybe<Patients_Bool_Exp>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Bool_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  renew_pwd?: InputMaybe<Boolean_Comparison_Exp>;
  rpps_number?: InputMaybe<String_Comparison_Exp>;
  toothNumberingSystem?: InputMaybe<String_Comparison_Exp>;
  treatment_preferences?: InputMaybe<Treatment_Preferences_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users_users_locations?: InputMaybe<Users_Locations_Bool_Exp>;
  users_users_locations_aggregate?: InputMaybe<Users_Locations_Aggregate_Bool_Exp>;
  users_users_roles?: InputMaybe<Users_Roles_Bool_Exp>;
  users_users_roles_aggregate?: InputMaybe<Users_Roles_Aggregate_Bool_Exp>;
  verification_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailUnique = 'users_email_unique',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  billingsByPaymasterid?: InputMaybe<Billings_Arr_Rel_Insert_Input>;
  cgu_approved_at?: InputMaybe<Scalars['timestamptz']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  demoMadeAt?: InputMaybe<Scalars['date']>;
  dentistSpeciality?: InputMaybe<Dentist_Specialities_Enum>;
  disclaimer_approved_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProvider?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  intercom_token_id?: InputMaybe<Scalars['String']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_dentist?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_first?: InputMaybe<Scalars['Boolean']>;
  is_validated?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locale_Enum>;
  password?: InputMaybe<Scalars['String']>;
  patients?: InputMaybe<Patients_Arr_Rel_Insert_Input>;
  phone?: InputMaybe<Scalars['String']>;
  renew_pwd?: InputMaybe<Scalars['Boolean']>;
  rpps_number?: InputMaybe<Scalars['String']>;
  toothNumberingSystem?: InputMaybe<Scalars['String']>;
  treatment_preferences?: InputMaybe<Treatment_Preferences_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users_users_locations?: InputMaybe<Users_Locations_Arr_Rel_Insert_Input>;
  users_users_roles?: InputMaybe<Users_Roles_Arr_Rel_Insert_Input>;
  verification_code?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "users_locations" */
export type Users_Locations = {
  __typename?: 'users_locations';
  created_at: Scalars['timestamptz'];
  isEnabled: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  location: Locations;
  location_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "users_locations" */
export type Users_Locations_Aggregate = {
  __typename?: 'users_locations_aggregate';
  aggregate: Maybe<Users_Locations_Aggregate_Fields>;
  nodes: Array<Users_Locations>;
};

export type Users_Locations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Locations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Locations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Locations_Aggregate_Bool_Exp_Count>;
};

export type Users_Locations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Locations_Select_Column_Users_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Locations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Locations_Select_Column_Users_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_locations" */
export type Users_Locations_Aggregate_Fields = {
  __typename?: 'users_locations_aggregate_fields';
  avg: Maybe<Users_Locations_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Users_Locations_Max_Fields>;
  min: Maybe<Users_Locations_Min_Fields>;
  stddev: Maybe<Users_Locations_Stddev_Fields>;
  stddev_pop: Maybe<Users_Locations_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Users_Locations_Stddev_Samp_Fields>;
  sum: Maybe<Users_Locations_Sum_Fields>;
  var_pop: Maybe<Users_Locations_Var_Pop_Fields>;
  var_samp: Maybe<Users_Locations_Var_Samp_Fields>;
  variance: Maybe<Users_Locations_Variance_Fields>;
};

/** aggregate fields of "users_locations" */
export type Users_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_locations" */
export type Users_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Locations_Max_Order_By>;
  min?: InputMaybe<Users_Locations_Min_Order_By>;
  stddev?: InputMaybe<Users_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Locations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_locations" */
export type Users_Locations_Arr_Rel_Insert_Input = {
  data: Array<Users_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Locations_Avg_Fields = {
  __typename?: 'users_locations_avg_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users_locations" */
export type Users_Locations_Avg_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_locations". All fields are combined with a logical 'AND'. */
export type Users_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Locations_Bool_Exp>>;
  _not?: InputMaybe<Users_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Locations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  isEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  location?: InputMaybe<Locations_Bool_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_locations" */
export enum Users_Locations_Constraint {
  /** unique or primary key constraint on columns "user_id", "location_id" */
  UsersLocationsPkey = 'users_locations_pkey',
}

/** input type for incrementing numeric columns in table "users_locations" */
export type Users_Locations_Inc_Input = {
  location_id?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "users_locations" */
export type Users_Locations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Users_Locations_Max_Fields = {
  __typename?: 'users_locations_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  location_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "users_locations" */
export type Users_Locations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Locations_Min_Fields = {
  __typename?: 'users_locations_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  location_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "users_locations" */
export type Users_Locations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_locations" */
export type Users_Locations_Mutation_Response = {
  __typename?: 'users_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Locations>;
};

/** on_conflict condition type for table "users_locations" */
export type Users_Locations_On_Conflict = {
  constraint: Users_Locations_Constraint;
  update_columns?: Array<Users_Locations_Update_Column>;
  where?: InputMaybe<Users_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "users_locations". */
export type Users_Locations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  isEnabled?: InputMaybe<Order_By>;
  location?: InputMaybe<Locations_Order_By>;
  location_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users_locations */
export type Users_Locations_Pk_Columns_Input = {
  location_id: Scalars['Int'];
  user_id: Scalars['Int'];
};

/** select columns of table "users_locations" */
export enum Users_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "users_locations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users_locations" */
export enum Users_Locations_Select_Column_Users_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsEnabled = 'isEnabled',
}

/** select "users_locations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users_locations" */
export enum Users_Locations_Select_Column_Users_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsEnabled = 'isEnabled',
}

/** input type for updating data in table "users_locations" */
export type Users_Locations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Users_Locations_Stddev_Fields = {
  __typename?: 'users_locations_stddev_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users_locations" */
export type Users_Locations_Stddev_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Locations_Stddev_Pop_Fields = {
  __typename?: 'users_locations_stddev_pop_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users_locations" */
export type Users_Locations_Stddev_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Locations_Stddev_Samp_Fields = {
  __typename?: 'users_locations_stddev_samp_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users_locations" */
export type Users_Locations_Stddev_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_locations" */
export type Users_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Locations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  location_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Users_Locations_Sum_Fields = {
  __typename?: 'users_locations_sum_fields';
  location_id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users_locations" */
export type Users_Locations_Sum_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** update columns of table "users_locations" */
export enum Users_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsEnabled = 'isEnabled',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Users_Locations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Locations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Locations_Var_Pop_Fields = {
  __typename?: 'users_locations_var_pop_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users_locations" */
export type Users_Locations_Var_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Locations_Var_Samp_Fields = {
  __typename?: 'users_locations_var_samp_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users_locations" */
export type Users_Locations_Var_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Locations_Variance_Fields = {
  __typename?: 'users_locations_variance_fields';
  location_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users_locations" */
export type Users_Locations_Variance_Order_By = {
  location_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  cgu_approved_at: Maybe<Scalars['timestamptz']>;
  contactEmail: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['String']>;
  demoMadeAt: Maybe<Scalars['date']>;
  disclaimer_approved_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  identityProvider: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  intercom_token_id: Maybe<Scalars['String']>;
  lastname: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  rpps_number: Maybe<Scalars['String']>;
  toothNumberingSystem: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  verification_code: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  cgu_approved_at: Maybe<Scalars['timestamptz']>;
  contactEmail: Maybe<Scalars['String']>;
  created_at: Maybe<Scalars['timestamptz']>;
  customer_id: Maybe<Scalars['String']>;
  demoMadeAt: Maybe<Scalars['date']>;
  disclaimer_approved_at: Maybe<Scalars['timestamptz']>;
  email: Maybe<Scalars['String']>;
  firstname: Maybe<Scalars['String']>;
  id: Maybe<Scalars['Int']>;
  identityProvider: Maybe<Scalars['String']>;
  image: Maybe<Scalars['String']>;
  intercom_token_id: Maybe<Scalars['String']>;
  lastname: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  phone: Maybe<Scalars['String']>;
  rpps_number: Maybe<Scalars['String']>;
  toothNumberingSystem: Maybe<Scalars['String']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  verification_code: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  billingsByPaymasterid_aggregate?: InputMaybe<Billings_Aggregate_Order_By>;
  cgu_approved_at?: InputMaybe<Order_By>;
  contactEmail?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  demoMadeAt?: InputMaybe<Order_By>;
  dentistSpeciality?: InputMaybe<Order_By>;
  disclaimer_approved_at?: InputMaybe<Order_By>;
  element_group_mode?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  firstname?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identityProvider?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  intercom_token_id?: InputMaybe<Order_By>;
  is_admin?: InputMaybe<Order_By>;
  is_dentist?: InputMaybe<Order_By>;
  is_enabled?: InputMaybe<Order_By>;
  is_first?: InputMaybe<Order_By>;
  is_validated?: InputMaybe<Order_By>;
  lastname?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  patients_aggregate?: InputMaybe<Patients_Aggregate_Order_By>;
  phone?: InputMaybe<Order_By>;
  renew_pwd?: InputMaybe<Order_By>;
  rpps_number?: InputMaybe<Order_By>;
  toothNumberingSystem?: InputMaybe<Order_By>;
  treatment_preferences?: InputMaybe<Treatment_Preferences_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users_users_locations_aggregate?: InputMaybe<Users_Locations_Aggregate_Order_By>;
  users_users_roles_aggregate?: InputMaybe<Users_Roles_Aggregate_Order_By>;
  verification_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** columns and relationships of "users_roles" */
export type Users_Roles = {
  __typename?: 'users_roles';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['Int'];
};

/** aggregated selection of "users_roles" */
export type Users_Roles_Aggregate = {
  __typename?: 'users_roles_aggregate';
  aggregate: Maybe<Users_Roles_Aggregate_Fields>;
  nodes: Array<Users_Roles>;
};

export type Users_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Roles_Aggregate_Bool_Exp_Count>;
};

export type Users_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users_roles" */
export type Users_Roles_Aggregate_Fields = {
  __typename?: 'users_roles_aggregate_fields';
  avg: Maybe<Users_Roles_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Users_Roles_Max_Fields>;
  min: Maybe<Users_Roles_Min_Fields>;
  stddev: Maybe<Users_Roles_Stddev_Fields>;
  stddev_pop: Maybe<Users_Roles_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Users_Roles_Stddev_Samp_Fields>;
  sum: Maybe<Users_Roles_Sum_Fields>;
  var_pop: Maybe<Users_Roles_Var_Pop_Fields>;
  var_samp: Maybe<Users_Roles_Var_Samp_Fields>;
  variance: Maybe<Users_Roles_Variance_Fields>;
};

/** aggregate fields of "users_roles" */
export type Users_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_roles" */
export type Users_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Roles_Max_Order_By>;
  min?: InputMaybe<Users_Roles_Min_Order_By>;
  stddev?: InputMaybe<Users_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Roles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users_roles" */
export type Users_Roles_Arr_Rel_Insert_Input = {
  data: Array<Users_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_Roles_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Roles_Avg_Fields = {
  __typename?: 'users_roles_avg_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users_roles" */
export type Users_Roles_Avg_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_roles". All fields are combined with a logical 'AND'. */
export type Users_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Roles_Bool_Exp>>;
  _not?: InputMaybe<Users_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "users_roles" */
export enum Users_Roles_Constraint {
  /** unique or primary key constraint on columns "user_id", "role_id" */
  UsersRolesPkey = 'users_roles_pkey',
}

/** input type for inserting data into table "users_roles" */
export type Users_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  role_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Users_Roles_Max_Fields = {
  __typename?: 'users_roles_max_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  role_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "users_roles" */
export type Users_Roles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Roles_Min_Fields = {
  __typename?: 'users_roles_min_fields';
  created_at: Maybe<Scalars['timestamptz']>;
  role_id: Maybe<Scalars['Int']>;
  updated_at: Maybe<Scalars['timestamptz']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "users_roles" */
export type Users_Roles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users_roles" */
export type Users_Roles_Mutation_Response = {
  __typename?: 'users_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Roles>;
};

/** on_conflict condition type for table "users_roles" */
export type Users_Roles_On_Conflict = {
  constraint: Users_Roles_Constraint;
  update_columns?: Array<Users_Roles_Update_Column>;
  where?: InputMaybe<Users_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "users_roles". */
export type Users_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users_roles" */
export enum Users_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type Users_Roles_Stddev_Fields = {
  __typename?: 'users_roles_stddev_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users_roles" */
export type Users_Roles_Stddev_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Roles_Stddev_Pop_Fields = {
  __typename?: 'users_roles_stddev_pop_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users_roles" */
export type Users_Roles_Stddev_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Roles_Stddev_Samp_Fields = {
  __typename?: 'users_roles_stddev_samp_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users_roles" */
export type Users_Roles_Stddev_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users_roles" */
export type Users_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  role_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Users_Roles_Sum_Fields = {
  __typename?: 'users_roles_sum_fields';
  role_id: Maybe<Scalars['Int']>;
  user_id: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users_roles" */
export type Users_Roles_Sum_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "users_roles" (current role has no relevant permissions) */
export enum Users_Roles_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** aggregate var_pop on columns */
export type Users_Roles_Var_Pop_Fields = {
  __typename?: 'users_roles_var_pop_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users_roles" */
export type Users_Roles_Var_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Roles_Var_Samp_Fields = {
  __typename?: 'users_roles_var_samp_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users_roles" */
export type Users_Roles_Var_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Roles_Variance_Fields = {
  __typename?: 'users_roles_variance_fields';
  role_id: Maybe<Scalars['Float']>;
  user_id: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users_roles" */
export type Users_Roles_Variance_Order_By = {
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CguApprovedAt = 'cgu_approved_at',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DemoMadeAt = 'demoMadeAt',
  /** column name */
  DentistSpeciality = 'dentistSpeciality',
  /** column name */
  DisclaimerApprovedAt = 'disclaimer_approved_at',
  /** column name */
  ElementGroupMode = 'element_group_mode',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityProvider = 'identityProvider',
  /** column name */
  Image = 'image',
  /** column name */
  IntercomTokenId = 'intercom_token_id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsDentist = 'is_dentist',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsFirst = 'is_first',
  /** column name */
  IsValidated = 'is_validated',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Locale = 'locale',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RenewPwd = 'renew_pwd',
  /** column name */
  RppsNumber = 'rpps_number',
  /** column name */
  ToothNumberingSystem = 'toothNumberingSystem',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationCode = 'verification_code',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  cgu_approved_at?: InputMaybe<Scalars['timestamptz']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  demoMadeAt?: InputMaybe<Scalars['date']>;
  dentistSpeciality?: InputMaybe<Dentist_Specialities_Enum>;
  disclaimer_approved_at?: InputMaybe<Scalars['timestamptz']>;
  element_group_mode?: InputMaybe<Element_Group_Modes_Enum>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProvider?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  intercom_token_id?: InputMaybe<Scalars['String']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_dentist?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_first?: InputMaybe<Scalars['Boolean']>;
  is_validated?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locale_Enum>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  renew_pwd?: InputMaybe<Scalars['Boolean']>;
  rpps_number?: InputMaybe<Scalars['String']>;
  toothNumberingSystem?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verification_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  cgu_approved_at?: InputMaybe<Scalars['timestamptz']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  demoMadeAt?: InputMaybe<Scalars['date']>;
  dentistSpeciality?: InputMaybe<Dentist_Specialities_Enum>;
  disclaimer_approved_at?: InputMaybe<Scalars['timestamptz']>;
  element_group_mode?: InputMaybe<Element_Group_Modes_Enum>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProvider?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  intercom_token_id?: InputMaybe<Scalars['String']>;
  is_admin?: InputMaybe<Scalars['Boolean']>;
  is_dentist?: InputMaybe<Scalars['Boolean']>;
  is_enabled?: InputMaybe<Scalars['Boolean']>;
  is_first?: InputMaybe<Scalars['Boolean']>;
  is_validated?: InputMaybe<Scalars['Boolean']>;
  lastname?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locale_Enum>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  renew_pwd?: InputMaybe<Scalars['Boolean']>;
  rpps_number?: InputMaybe<Scalars['String']>;
  toothNumberingSystem?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  verification_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  id: Maybe<Scalars['Int']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CguApprovedAt = 'cgu_approved_at',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DemoMadeAt = 'demoMadeAt',
  /** column name */
  DentistSpeciality = 'dentistSpeciality',
  /** column name */
  DisclaimerApprovedAt = 'disclaimer_approved_at',
  /** column name */
  ElementGroupMode = 'element_group_mode',
  /** column name */
  Email = 'email',
  /** column name */
  Firstname = 'firstname',
  /** column name */
  Id = 'id',
  /** column name */
  IdentityProvider = 'identityProvider',
  /** column name */
  Image = 'image',
  /** column name */
  IntercomTokenId = 'intercom_token_id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsDentist = 'is_dentist',
  /** column name */
  IsEnabled = 'is_enabled',
  /** column name */
  IsFirst = 'is_first',
  /** column name */
  IsValidated = 'is_validated',
  /** column name */
  Lastname = 'lastname',
  /** column name */
  Locale = 'locale',
  /** column name */
  Password = 'password',
  /** column name */
  Phone = 'phone',
  /** column name */
  RenewPwd = 'renew_pwd',
  /** column name */
  RppsNumber = 'rpps_number',
  /** column name */
  ToothNumberingSystem = 'toothNumberingSystem',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationCode = 'verification_code',
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  id: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  id: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "xray_software" */
export type Xray_Software = {
  __typename?: 'xray_software';
  brand: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "xray_software". All fields are combined with a logical 'AND'. */
export type Xray_Software_Bool_Exp = {
  _and?: InputMaybe<Array<Xray_Software_Bool_Exp>>;
  _not?: InputMaybe<Xray_Software_Bool_Exp>;
  _or?: InputMaybe<Array<Xray_Software_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "xray_software". */
export type Xray_Software_Order_By = {
  brand?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "xray_software" */
export enum Xray_Software_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "xray_software" */
export type Xray_Software_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Xray_Software_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Xray_Software_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum Xray_Type_Enum {
  Bitewing = 'BITEWING',
  Cbct = 'CBCT',
  Cephalogram = 'CEPHALOGRAM',
  ClinicalPhotograph = 'CLINICAL_PHOTOGRAPH',
  Intraoral = 'INTRAORAL',
  Other = 'OTHER',
  Panoramic = 'PANORAMIC',
  Periapical = 'PERIAPICAL',
}

/** Boolean expression to compare columns of type "xray_type_enum". All fields are combined with logical 'AND'. */
export type Xray_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Xray_Type_Enum>;
  _in?: InputMaybe<Array<Xray_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Xray_Type_Enum>;
  _nin?: InputMaybe<Array<Xray_Type_Enum>>;
};

/** columns and relationships of "xrays" */
export type Xrays = {
  __typename?: 'xrays';
  /** An array relationship */
  anatomies: Array<Anatomies>;
  bridges: Maybe<Scalars['jsonb']>;
  centralTeeth: Maybe<Array<Scalars['String']>>;
  /** An object relationship */
  consultation: Consultation;
  consultationId: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  creationDate: Scalars['timestamptz'];
  detectedCejLines: Maybe<Scalars['json']>;
  detectedPeriodonticLines: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  elements: Array<Elements>;
  /** An aggregate relationship */
  elements_aggregate: Elements_Aggregate;
  hash: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  imageHeight: Maybe<Scalars['Int']>;
  imageKey: Maybe<Scalars['String']>;
  imageWidth: Maybe<Scalars['Int']>;
  isAnalysisValidated: Scalars['Boolean'];
  isIncludedInReport: Scalars['Boolean'];
  manufacturer: Maybe<Scalars['String']>;
  mlStatusCode: Maybe<Scalars['Int']>;
  name: Maybe<Scalars['json']>;
  /** An array relationship */
  oralStructures: Array<OralStructures>;
  origin: Maybe<Xrays_Origin_Enum>;
  pixelSpacing: Array<Scalars['Float']>;
  projection: Scalars['json'];
  report: Maybe<Scalars['json']>;
  retroBoneLevels: Maybe<Scalars['jsonb']>;
  retroRulerSegments: Maybe<Scalars['jsonb']>;
  teeth: Scalars['jsonb'];
  type: Xray_Type_Enum;
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** columns and relationships of "xrays" */
export type XraysAnatomiesArgs = {
  distinct_on?: InputMaybe<Array<Anatomies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Anatomies_Order_By>>;
  where?: InputMaybe<Anatomies_Bool_Exp>;
};

/** columns and relationships of "xrays" */
export type XraysBridgesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysDetectedCejLinesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysDetectedPeriodonticLinesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysElementsArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "xrays" */
export type XraysElements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Elements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Elements_Order_By>>;
  where?: InputMaybe<Elements_Bool_Exp>;
};

/** columns and relationships of "xrays" */
export type XraysNameArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysOralStructuresArgs = {
  distinct_on?: InputMaybe<Array<OralStructures_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<OralStructures_Order_By>>;
  where?: InputMaybe<OralStructures_Bool_Exp>;
};

/** columns and relationships of "xrays" */
export type XraysProjectionArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysReportArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysRetroBoneLevelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysRetroRulerSegmentsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "xrays" */
export type XraysTeethArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "xrays" */
export type Xrays_Aggregate = {
  __typename?: 'xrays_aggregate';
  aggregate: Maybe<Xrays_Aggregate_Fields>;
  nodes: Array<Xrays>;
};

export type Xrays_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Xrays_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Xrays_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Xrays_Aggregate_Bool_Exp_Count>;
};

export type Xrays_Aggregate_Bool_Exp_Bool_And = {
  arguments: Xrays_Select_Column_Xrays_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Xrays_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Xrays_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Xrays_Select_Column_Xrays_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Xrays_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Xrays_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Xrays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Xrays_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "xrays" */
export type Xrays_Aggregate_Fields = {
  __typename?: 'xrays_aggregate_fields';
  avg: Maybe<Xrays_Avg_Fields>;
  count: Scalars['Int'];
  max: Maybe<Xrays_Max_Fields>;
  min: Maybe<Xrays_Min_Fields>;
  stddev: Maybe<Xrays_Stddev_Fields>;
  stddev_pop: Maybe<Xrays_Stddev_Pop_Fields>;
  stddev_samp: Maybe<Xrays_Stddev_Samp_Fields>;
  sum: Maybe<Xrays_Sum_Fields>;
  var_pop: Maybe<Xrays_Var_Pop_Fields>;
  var_samp: Maybe<Xrays_Var_Samp_Fields>;
  variance: Maybe<Xrays_Variance_Fields>;
};

/** aggregate fields of "xrays" */
export type Xrays_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Xrays_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "xrays" */
export type Xrays_Aggregate_Order_By = {
  avg?: InputMaybe<Xrays_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Xrays_Max_Order_By>;
  min?: InputMaybe<Xrays_Min_Order_By>;
  stddev?: InputMaybe<Xrays_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Xrays_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Xrays_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Xrays_Sum_Order_By>;
  var_pop?: InputMaybe<Xrays_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Xrays_Var_Samp_Order_By>;
  variance?: InputMaybe<Xrays_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Xrays_Append_Input = {
  bridges?: InputMaybe<Scalars['jsonb']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  retroBoneLevels?: InputMaybe<Scalars['jsonb']>;
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
  teeth?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "xrays" */
export type Xrays_Arr_Rel_Insert_Input = {
  data: Array<Xrays_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Xrays_On_Conflict>;
};

/** aggregate avg on columns */
export type Xrays_Avg_Fields = {
  __typename?: 'xrays_avg_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "xrays" */
export type Xrays_Avg_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "xrays". All fields are combined with a logical 'AND'. */
export type Xrays_Bool_Exp = {
  _and?: InputMaybe<Array<Xrays_Bool_Exp>>;
  _not?: InputMaybe<Xrays_Bool_Exp>;
  _or?: InputMaybe<Array<Xrays_Bool_Exp>>;
  anatomies?: InputMaybe<Anatomies_Bool_Exp>;
  bridges?: InputMaybe<Jsonb_Comparison_Exp>;
  centralTeeth?: InputMaybe<String_Array_Comparison_Exp>;
  consultation?: InputMaybe<Consultation_Bool_Exp>;
  consultationId?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  creationDate?: InputMaybe<Timestamptz_Comparison_Exp>;
  detectedCejLines?: InputMaybe<Json_Comparison_Exp>;
  detectedPeriodonticLines?: InputMaybe<Jsonb_Comparison_Exp>;
  elements?: InputMaybe<Elements_Bool_Exp>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Bool_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  imageHeight?: InputMaybe<Int_Comparison_Exp>;
  imageKey?: InputMaybe<String_Comparison_Exp>;
  imageWidth?: InputMaybe<Int_Comparison_Exp>;
  isAnalysisValidated?: InputMaybe<Boolean_Comparison_Exp>;
  isIncludedInReport?: InputMaybe<Boolean_Comparison_Exp>;
  manufacturer?: InputMaybe<String_Comparison_Exp>;
  mlStatusCode?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<Json_Comparison_Exp>;
  oralStructures?: InputMaybe<OralStructures_Bool_Exp>;
  origin?: InputMaybe<Xrays_Origin_Enum_Comparison_Exp>;
  pixelSpacing?: InputMaybe<Float_Array_Comparison_Exp>;
  projection?: InputMaybe<Json_Comparison_Exp>;
  report?: InputMaybe<Json_Comparison_Exp>;
  retroBoneLevels?: InputMaybe<Jsonb_Comparison_Exp>;
  retroRulerSegments?: InputMaybe<Jsonb_Comparison_Exp>;
  teeth?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Xray_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "xrays" */
export enum Xrays_Constraint {
  /** unique or primary key constraint on columns "id" */
  XraysPkey = 'xrays_pkey',
  /** unique or primary key constraint on columns "consultation_id", "id" */
  XraysTttp = 'xrays_tttp',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Xrays_Delete_At_Path_Input = {
  bridges?: InputMaybe<Array<Scalars['String']>>;
  detectedPeriodonticLines?: InputMaybe<Array<Scalars['String']>>;
  retroBoneLevels?: InputMaybe<Array<Scalars['String']>>;
  retroRulerSegments?: InputMaybe<Array<Scalars['String']>>;
  teeth?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Xrays_Delete_Elem_Input = {
  bridges?: InputMaybe<Scalars['Int']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['Int']>;
  retroBoneLevels?: InputMaybe<Scalars['Int']>;
  retroRulerSegments?: InputMaybe<Scalars['Int']>;
  teeth?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Xrays_Delete_Key_Input = {
  bridges?: InputMaybe<Scalars['String']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['String']>;
  retroBoneLevels?: InputMaybe<Scalars['String']>;
  retroRulerSegments?: InputMaybe<Scalars['String']>;
  teeth?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "xrays" */
export type Xrays_Inc_Input = {
  consultationId?: InputMaybe<Scalars['Int']>;
  imageHeight?: InputMaybe<Scalars['Int']>;
  imageWidth?: InputMaybe<Scalars['Int']>;
  mlStatusCode?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "xrays" */
export type Xrays_Insert_Input = {
  anatomies?: InputMaybe<Anatomies_Arr_Rel_Insert_Input>;
  bridges?: InputMaybe<Scalars['jsonb']>;
  centralTeeth?: InputMaybe<Array<Scalars['String']>>;
  consultation?: InputMaybe<Consultation_Obj_Rel_Insert_Input>;
  consultationId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creationDate?: InputMaybe<Scalars['timestamptz']>;
  detectedCejLines?: InputMaybe<Scalars['json']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  elements?: InputMaybe<Elements_Arr_Rel_Insert_Input>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageHeight?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<Scalars['String']>;
  imageWidth?: InputMaybe<Scalars['Int']>;
  isAnalysisValidated?: InputMaybe<Scalars['Boolean']>;
  isIncludedInReport?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  mlStatusCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['json']>;
  oralStructures?: InputMaybe<OralStructures_Arr_Rel_Insert_Input>;
  origin?: InputMaybe<Xrays_Origin_Enum>;
  pixelSpacing?: InputMaybe<Array<Scalars['Float']>>;
  projection?: InputMaybe<Scalars['json']>;
  report?: InputMaybe<Scalars['json']>;
  retroBoneLevels?: InputMaybe<Scalars['jsonb']>;
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
  teeth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Xray_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Xrays_Max_Fields = {
  __typename?: 'xrays_max_fields';
  centralTeeth: Maybe<Array<Scalars['String']>>;
  consultationId: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  creationDate: Maybe<Scalars['timestamptz']>;
  hash: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  imageHeight: Maybe<Scalars['Int']>;
  imageKey: Maybe<Scalars['String']>;
  imageWidth: Maybe<Scalars['Int']>;
  manufacturer: Maybe<Scalars['String']>;
  mlStatusCode: Maybe<Scalars['Int']>;
  pixelSpacing: Maybe<Array<Scalars['Float']>>;
  updated_at: Maybe<Scalars['timestamptz']>;
  url: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "xrays" */
export type Xrays_Max_Order_By = {
  centralTeeth?: InputMaybe<Order_By>;
  consultationId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creationDate?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageKey?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
  pixelSpacing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Xrays_Min_Fields = {
  __typename?: 'xrays_min_fields';
  centralTeeth: Maybe<Array<Scalars['String']>>;
  consultationId: Maybe<Scalars['Int']>;
  created_at: Maybe<Scalars['timestamptz']>;
  creationDate: Maybe<Scalars['timestamptz']>;
  hash: Maybe<Scalars['String']>;
  id: Maybe<Scalars['uuid']>;
  imageHeight: Maybe<Scalars['Int']>;
  imageKey: Maybe<Scalars['String']>;
  imageWidth: Maybe<Scalars['Int']>;
  manufacturer: Maybe<Scalars['String']>;
  mlStatusCode: Maybe<Scalars['Int']>;
  pixelSpacing: Maybe<Array<Scalars['Float']>>;
  updated_at: Maybe<Scalars['timestamptz']>;
  url: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "xrays" */
export type Xrays_Min_Order_By = {
  centralTeeth?: InputMaybe<Order_By>;
  consultationId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creationDate?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageKey?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
  pixelSpacing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "xrays" */
export type Xrays_Mutation_Response = {
  __typename?: 'xrays_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Xrays>;
};

/** input type for inserting object relation for remote table "xrays" */
export type Xrays_Obj_Rel_Insert_Input = {
  data: Xrays_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Xrays_On_Conflict>;
};

/** on_conflict condition type for table "xrays" */
export type Xrays_On_Conflict = {
  constraint: Xrays_Constraint;
  update_columns?: Array<Xrays_Update_Column>;
  where?: InputMaybe<Xrays_Bool_Exp>;
};

/** Ordering options when selecting data from "xrays". */
export type Xrays_Order_By = {
  anatomies_aggregate?: InputMaybe<Anatomies_Aggregate_Order_By>;
  bridges?: InputMaybe<Order_By>;
  centralTeeth?: InputMaybe<Order_By>;
  consultation?: InputMaybe<Consultation_Order_By>;
  consultationId?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  creationDate?: InputMaybe<Order_By>;
  detectedCejLines?: InputMaybe<Order_By>;
  detectedPeriodonticLines?: InputMaybe<Order_By>;
  elements_aggregate?: InputMaybe<Elements_Aggregate_Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageKey?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  isAnalysisValidated?: InputMaybe<Order_By>;
  isIncludedInReport?: InputMaybe<Order_By>;
  manufacturer?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oralStructures_aggregate?: InputMaybe<OralStructures_Aggregate_Order_By>;
  origin?: InputMaybe<Order_By>;
  pixelSpacing?: InputMaybe<Order_By>;
  projection?: InputMaybe<Order_By>;
  report?: InputMaybe<Order_By>;
  retroBoneLevels?: InputMaybe<Order_By>;
  retroRulerSegments?: InputMaybe<Order_By>;
  teeth?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** columns and relationships of "xrays_origin" */
export type Xrays_Origin = {
  __typename?: 'xrays_origin';
  origin: Scalars['String'];
};

/** Boolean expression to filter rows from the table "xrays_origin". All fields are combined with a logical 'AND'. */
export type Xrays_Origin_Bool_Exp = {
  _and?: InputMaybe<Array<Xrays_Origin_Bool_Exp>>;
  _not?: InputMaybe<Xrays_Origin_Bool_Exp>;
  _or?: InputMaybe<Array<Xrays_Origin_Bool_Exp>>;
  origin?: InputMaybe<String_Comparison_Exp>;
};

export enum Xrays_Origin_Enum {
  Demo = 'DEMO',
  HomepageManual = 'HOMEPAGE_MANUAL',
  HomepageWatcher = 'HOMEPAGE_WATCHER',
  Partner = 'PARTNER',
  PatientFileManual = 'PATIENT_FILE_MANUAL',
  PatientFileWatcher = 'PATIENT_FILE_WATCHER',
}

/** Boolean expression to compare columns of type "xrays_origin_enum". All fields are combined with logical 'AND'. */
export type Xrays_Origin_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Xrays_Origin_Enum>;
  _in?: InputMaybe<Array<Xrays_Origin_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Xrays_Origin_Enum>;
  _nin?: InputMaybe<Array<Xrays_Origin_Enum>>;
};

/** Ordering options when selecting data from "xrays_origin". */
export type Xrays_Origin_Order_By = {
  origin?: InputMaybe<Order_By>;
};

/** select columns of table "xrays_origin" */
export enum Xrays_Origin_Select_Column {
  /** column name */
  Origin = 'origin',
}

/** Streaming cursor of the table "xrays_origin" */
export type Xrays_Origin_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Xrays_Origin_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Xrays_Origin_Stream_Cursor_Value_Input = {
  origin?: InputMaybe<Scalars['String']>;
};

/** primary key columns input for table: xrays */
export type Xrays_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Xrays_Prepend_Input = {
  bridges?: InputMaybe<Scalars['jsonb']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  retroBoneLevels?: InputMaybe<Scalars['jsonb']>;
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
  teeth?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "xrays" */
export enum Xrays_Select_Column {
  /** column name */
  Bridges = 'bridges',
  /** column name */
  CentralTeeth = 'centralTeeth',
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDate = 'creationDate',
  /** column name */
  DetectedCejLines = 'detectedCejLines',
  /** column name */
  DetectedPeriodonticLines = 'detectedPeriodonticLines',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeight = 'imageHeight',
  /** column name */
  ImageKey = 'imageKey',
  /** column name */
  ImageWidth = 'imageWidth',
  /** column name */
  IsAnalysisValidated = 'isAnalysisValidated',
  /** column name */
  IsIncludedInReport = 'isIncludedInReport',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MlStatusCode = 'mlStatusCode',
  /** column name */
  Name = 'name',
  /** column name */
  Origin = 'origin',
  /** column name */
  PixelSpacing = 'pixelSpacing',
  /** column name */
  Projection = 'projection',
  /** column name */
  Report = 'report',
  /** column name */
  RetroBoneLevels = 'retroBoneLevels',
  /** column name */
  RetroRulerSegments = 'retroRulerSegments',
  /** column name */
  Teeth = 'teeth',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** select "xrays_aggregate_bool_exp_bool_and_arguments_columns" columns of table "xrays" */
export enum Xrays_Select_Column_Xrays_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAnalysisValidated = 'isAnalysisValidated',
  /** column name */
  IsIncludedInReport = 'isIncludedInReport',
}

/** select "xrays_aggregate_bool_exp_bool_or_arguments_columns" columns of table "xrays" */
export enum Xrays_Select_Column_Xrays_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAnalysisValidated = 'isAnalysisValidated',
  /** column name */
  IsIncludedInReport = 'isIncludedInReport',
}

/** input type for updating data in table "xrays" */
export type Xrays_Set_Input = {
  bridges?: InputMaybe<Scalars['jsonb']>;
  centralTeeth?: InputMaybe<Array<Scalars['String']>>;
  consultationId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creationDate?: InputMaybe<Scalars['timestamptz']>;
  detectedCejLines?: InputMaybe<Scalars['json']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageHeight?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<Scalars['String']>;
  imageWidth?: InputMaybe<Scalars['Int']>;
  isAnalysisValidated?: InputMaybe<Scalars['Boolean']>;
  isIncludedInReport?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  mlStatusCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['json']>;
  origin?: InputMaybe<Xrays_Origin_Enum>;
  pixelSpacing?: InputMaybe<Array<Scalars['Float']>>;
  projection?: InputMaybe<Scalars['json']>;
  report?: InputMaybe<Scalars['json']>;
  retroBoneLevels?: InputMaybe<Scalars['jsonb']>;
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
  teeth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Xray_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Xrays_Stddev_Fields = {
  __typename?: 'xrays_stddev_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "xrays" */
export type Xrays_Stddev_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Xrays_Stddev_Pop_Fields = {
  __typename?: 'xrays_stddev_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "xrays" */
export type Xrays_Stddev_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Xrays_Stddev_Samp_Fields = {
  __typename?: 'xrays_stddev_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "xrays" */
export type Xrays_Stddev_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "xrays" */
export type Xrays_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Xrays_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Xrays_Stream_Cursor_Value_Input = {
  bridges?: InputMaybe<Scalars['jsonb']>;
  centralTeeth?: InputMaybe<Array<Scalars['String']>>;
  consultationId?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  creationDate?: InputMaybe<Scalars['timestamptz']>;
  detectedCejLines?: InputMaybe<Scalars['json']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  hash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  imageHeight?: InputMaybe<Scalars['Int']>;
  imageKey?: InputMaybe<Scalars['String']>;
  imageWidth?: InputMaybe<Scalars['Int']>;
  isAnalysisValidated?: InputMaybe<Scalars['Boolean']>;
  isIncludedInReport?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['String']>;
  mlStatusCode?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['json']>;
  origin?: InputMaybe<Xrays_Origin_Enum>;
  pixelSpacing?: InputMaybe<Array<Scalars['Float']>>;
  projection?: InputMaybe<Scalars['json']>;
  report?: InputMaybe<Scalars['json']>;
  retroBoneLevels?: InputMaybe<Scalars['jsonb']>;
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
  teeth?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Xray_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Xrays_Sum_Fields = {
  __typename?: 'xrays_sum_fields';
  consultationId: Maybe<Scalars['Int']>;
  imageHeight: Maybe<Scalars['Int']>;
  imageWidth: Maybe<Scalars['Int']>;
  mlStatusCode: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "xrays" */
export type Xrays_Sum_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** update columns of table "xrays" */
export enum Xrays_Update_Column {
  /** column name */
  Bridges = 'bridges',
  /** column name */
  CentralTeeth = 'centralTeeth',
  /** column name */
  ConsultationId = 'consultationId',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreationDate = 'creationDate',
  /** column name */
  DetectedCejLines = 'detectedCejLines',
  /** column name */
  DetectedPeriodonticLines = 'detectedPeriodonticLines',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  ImageHeight = 'imageHeight',
  /** column name */
  ImageKey = 'imageKey',
  /** column name */
  ImageWidth = 'imageWidth',
  /** column name */
  IsAnalysisValidated = 'isAnalysisValidated',
  /** column name */
  IsIncludedInReport = 'isIncludedInReport',
  /** column name */
  Manufacturer = 'manufacturer',
  /** column name */
  MlStatusCode = 'mlStatusCode',
  /** column name */
  Name = 'name',
  /** column name */
  Origin = 'origin',
  /** column name */
  PixelSpacing = 'pixelSpacing',
  /** column name */
  Projection = 'projection',
  /** column name */
  Report = 'report',
  /** column name */
  RetroBoneLevels = 'retroBoneLevels',
  /** column name */
  RetroRulerSegments = 'retroRulerSegments',
  /** column name */
  Teeth = 'teeth',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

export type Xrays_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Xrays_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Xrays_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Xrays_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Xrays_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Xrays_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Xrays_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Xrays_Set_Input>;
  /** filter the rows which have to be updated */
  where: Xrays_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Xrays_Var_Pop_Fields = {
  __typename?: 'xrays_var_pop_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "xrays" */
export type Xrays_Var_Pop_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Xrays_Var_Samp_Fields = {
  __typename?: 'xrays_var_samp_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "xrays" */
export type Xrays_Var_Samp_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Xrays_Variance_Fields = {
  __typename?: 'xrays_variance_fields';
  consultationId: Maybe<Scalars['Float']>;
  imageHeight: Maybe<Scalars['Float']>;
  imageWidth: Maybe<Scalars['Float']>;
  mlStatusCode: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "xrays" */
export type Xrays_Variance_Order_By = {
  consultationId?: InputMaybe<Order_By>;
  imageHeight?: InputMaybe<Order_By>;
  imageWidth?: InputMaybe<Order_By>;
  mlStatusCode?: InputMaybe<Order_By>;
};

export type CompleteActsMutationVariables = Exact<{
  completeIds: Array<Scalars['Int']> | Scalars['Int'];
  uncompleteIds: Array<Scalars['Int']> | Scalars['Int'];
  completedAt: Scalars['timestamptz'];
  consultationId: Scalars['Int'];
}>;

export type CompleteActsMutation = {
  __typename?: 'mutation_root';
  update_consultation: {
    __typename?: 'consultation';
    id: number;
    fromMigration: boolean | null;
  } | null;
  completeOperations: { __typename?: 'operations_mutation_response'; affected_rows: number } | null;
  uncompleteOperations: {
    __typename?: 'operations_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateActsMutationVariables = Exact<{
  operations: Array<Operations_Insert_Input> | Operations_Insert_Input;
  consultationId: Scalars['Int'];
}>;

export type CreateActsMutation = {
  __typename?: 'mutation_root';
  update_consultation: {
    __typename?: 'consultation';
    id: number;
    fromMigration: boolean | null;
  } | null;
  create_operations: { __typename?: 'operations_mutation_response'; affected_rows: number } | null;
};

export type DeleteActsMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
  consultationId: Scalars['Int'];
}>;

export type DeleteActsMutation = {
  __typename?: 'mutation_root';
  update_consultation: {
    __typename?: 'consultation';
    id: number;
    fromMigration: boolean | null;
  } | null;
  delete_operations: { __typename?: 'operations_mutation_response'; affected_rows: number } | null;
};

export type InsertClinicalMonitoringMutationVariables = Exact<{
  clinicalMonitoring: Clinical_Monitoring_Insert_Input;
}>;

export type InsertClinicalMonitoringMutation = {
  __typename?: 'mutation_root';
  insert_clinical_monitoring_one: { __typename?: 'clinical_monitoring'; id: string } | null;
};

export type UpdateClinicalMonitoringMutationVariables = Exact<{
  id: Scalars['uuid'];
  clinicalMonitoring?: InputMaybe<Clinical_Monitoring_Set_Input>;
}>;

export type UpdateClinicalMonitoringMutation = {
  __typename?: 'mutation_root';
  update_clinical_monitoring_by_pk: {
    __typename?: 'clinical_monitoring';
    id: string;
    consultationComment: string;
    lastConsultationDate: string | null;
    initialBilanDate: string | null;
    esthetic: boolean;
    prosthesis: boolean;
    pain: boolean;
    occlusion: boolean;
    emergency: boolean;
    routineCheck: boolean;
    medicalBackground: string;
    decayedTeeth: any | null;
    damagedTeeth: any | null;
    looseTeeth: any | null;
    inflammationTeeth: any | null;
    missingTeeth: any | null;
    occlusionTeeth: any | null;
  } | null;
};

export type CreateConsultationMutationVariables = Exact<{
  newConsultation: Consultation_Insert_Input;
}>;

export type CreateConsultationMutation = {
  __typename?: 'mutation_root';
  create_consultation: {
    __typename?: 'consultation';
    id: number;
    patient: { __typename?: 'patients'; id: number; userId: number | null } | null;
  } | null;
};

export type GetConsultationQueryVariables = Exact<{
  consultationId: Scalars['Int'];
}>;

export type GetConsultationQuery = {
  __typename?: 'query_root';
  consultation: {
    __typename?: 'consultation';
    id: number;
    consultationDate: string;
    report: any | null;
    proposedTreatment: any | null;
    fromMigration: boolean | null;
    last_sent_date: string;
    createdAt: string;
    updatedAt: string;
    operations: Array<{
      __typename?: 'operations';
      id: number;
      order: number;
      toothNumbers: any;
      completedAt: string | null;
      groupId: number | null;
      pillarTeeth: any;
      operationType: {
        __typename?: 'operation_types';
        id: number;
        name: string;
        color: string;
        allowed_teeth: any | null;
      };
    }>;
    clinicalMonitoring: {
      __typename?: 'clinical_monitoring';
      id: string;
      consultationComment: string;
      lastConsultationDate: string | null;
      initialBilanDate: string | null;
      esthetic: boolean;
      prosthesis: boolean;
      pain: boolean;
      occlusion: boolean;
      emergency: boolean;
      routineCheck: boolean;
      medicalBackground: string;
      decayedTeeth: any | null;
      damagedTeeth: any | null;
      looseTeeth: any | null;
      inflammationTeeth: any | null;
      missingTeeth: any | null;
      occlusionTeeth: any | null;
    } | null;
  } | null;
};

export type GetConsultationsByPatientQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetConsultationsByPatientQuery = {
  __typename?: 'query_root';
  consultations: Array<{
    __typename?: 'consultation';
    id: number;
    consultationDate: string;
    report: any | null;
    proposedTreatment: any | null;
    fromMigration: boolean | null;
    last_sent_date: string;
    createdAt: string;
    updatedAt: string;
    operations: Array<{
      __typename?: 'operations';
      id: number;
      order: number;
      toothNumbers: any;
      completedAt: string | null;
      groupId: number | null;
      pillarTeeth: any;
      operationType: {
        __typename?: 'operation_types';
        id: number;
        name: string;
        color: string;
        allowed_teeth: any | null;
      };
    }>;
    clinicalMonitoring: {
      __typename?: 'clinical_monitoring';
      id: string;
      consultationComment: string;
      lastConsultationDate: string | null;
      initialBilanDate: string | null;
      esthetic: boolean;
      prosthesis: boolean;
      pain: boolean;
      occlusion: boolean;
      emergency: boolean;
      routineCheck: boolean;
      medicalBackground: string;
      decayedTeeth: any | null;
      damagedTeeth: any | null;
      looseTeeth: any | null;
      inflammationTeeth: any | null;
      missingTeeth: any | null;
      occlusionTeeth: any | null;
    } | null;
  }>;
};

export type GetLastConsultationQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetLastConsultationQuery = {
  __typename?: 'query_root';
  consultations: Array<{
    __typename?: 'consultation';
    id: number;
    xrays: Array<{
      __typename?: 'xrays';
      id: string;
      url: string;
      imageKey: string | null;
      type: Xray_Type_Enum;
      elements: Array<{
        __typename?: 'elements';
        id: number;
        editedByUser: boolean | null;
        coordinates: any;
        onTooth: any | null;
        detectionScore: any | null;
        type: {
          __typename?: 'element_types';
          id: number;
          color: string;
          name: string;
          parent: { __typename?: 'element_types'; id: number; name: string; color: string } | null;
        };
      }>;
    }>;
    operations: Array<{
      __typename?: 'operations';
      id: number;
      order: number;
      toothNumbers: any;
      completedAt: string | null;
      groupId: number | null;
      pillarTeeth: any;
      operationType: {
        __typename?: 'operation_types';
        id: number;
        name: string;
        color: string;
        allowed_teeth: any | null;
      };
    }>;
    xraysCount: {
      __typename?: 'xrays_aggregate';
      aggregate: { __typename?: 'xrays_aggregate_fields'; count: number } | null;
    };
  }>;
};

export type UpdateConsultationMutationVariables = Exact<{
  id: Scalars['Int'];
  consultation: Consultation_Set_Input;
}>;

export type UpdateConsultationMutation = {
  __typename?: 'mutation_root';
  update_consultation: { __typename?: 'consultation'; id: number } | null;
};

export type UpdatedConsultationMutationVariables = Exact<{
  id: Scalars['Int'];
  consultation: Consultation_Set_Input;
}>;

export type UpdatedConsultationMutation = {
  __typename?: 'mutation_root';
  update_consultation: {
    __typename?: 'consultation';
    id: number;
    consultationDate: string;
    report: any | null;
    proposedTreatment: any | null;
    fromMigration: boolean | null;
    last_sent_date: string;
    createdAt: string;
    updatedAt: string;
    operations: Array<{
      __typename?: 'operations';
      id: number;
      order: number;
      toothNumbers: any;
      completedAt: string | null;
      groupId: number | null;
      pillarTeeth: any;
      operationType: {
        __typename?: 'operation_types';
        id: number;
        name: string;
        color: string;
        allowed_teeth: any | null;
      };
    }>;
    clinicalMonitoring: {
      __typename?: 'clinical_monitoring';
      id: string;
      consultationComment: string;
      lastConsultationDate: string | null;
      initialBilanDate: string | null;
      esthetic: boolean;
      prosthesis: boolean;
      pain: boolean;
      occlusion: boolean;
      emergency: boolean;
      routineCheck: boolean;
      medicalBackground: string;
      decayedTeeth: any | null;
      damagedTeeth: any | null;
      looseTeeth: any | null;
      inflammationTeeth: any | null;
      missingTeeth: any | null;
      occlusionTeeth: any | null;
    } | null;
  } | null;
};

export type GetEducationalSheetsQueryVariables = Exact<{
  locationId?: InputMaybe<Int_Comparison_Exp>;
}>;

export type GetEducationalSheetsQuery = {
  __typename?: 'query_root';
  educationalSheets: Array<{
    __typename?: 'educationalSheets';
    id: string;
    locationId: number;
    type: EducationalSheetsTypes_Enum;
    url: string;
  }>;
};

export type EducationalSheetsFragmentFragment = {
  __typename?: 'educationalSheets';
  id: string;
  locationId: number;
  type: EducationalSheetsTypes_Enum;
  url: string;
};

export type ElementTypesQueryVariables = Exact<{ [key: string]: never }>;

export type ElementTypesQuery = {
  __typename?: 'query_root';
  element_types: Array<{
    __typename?: 'element_types';
    id: number;
    color: string;
    name: string;
    isRoot: boolean;
    fullId: number | null;
    isRetro: boolean;
  }>;
};

export type RegisterEventUserMutationVariables = Exact<{
  eventName: Scalars['String'];
  patientId?: InputMaybe<Scalars['Int']>;
  consultationId?: InputMaybe<Scalars['Int']>;
}>;

export type RegisterEventUserMutation = {
  __typename?: 'mutation_root';
  insert_event_user: { __typename?: 'event_user_mutation_response'; affected_rows: number } | null;
};

export type AnatomiesCommonFieldsFragment = {
  __typename?: 'anatomies';
  detectionBox: any;
  detectionPoly: any;
  id: string;
  onTooth: any;
  type: string;
  anatomyType: { __typename?: 'anatomy_types'; name: string; color: string };
};

export type ClinicalCommonFieldsFragment = {
  __typename?: 'clinical_monitoring';
  id: string;
  consultationComment: string;
  lastConsultationDate: string | null;
  initialBilanDate: string | null;
  esthetic: boolean;
  prosthesis: boolean;
  pain: boolean;
  occlusion: boolean;
  emergency: boolean;
  routineCheck: boolean;
  medicalBackground: string;
  decayedTeeth: any | null;
  damagedTeeth: any | null;
  looseTeeth: any | null;
  inflammationTeeth: any | null;
  missingTeeth: any | null;
  occlusionTeeth: any | null;
};

export type ConsultationCommonFieldsFragment = {
  __typename?: 'consultation';
  id: number;
  consultationDate: string;
  report: any | null;
  proposedTreatment: any | null;
  fromMigration: boolean | null;
  last_sent_date: string;
  createdAt: string;
  updatedAt: string;
  operations: Array<{
    __typename?: 'operations';
    id: number;
    order: number;
    toothNumbers: any;
    completedAt: string | null;
    groupId: number | null;
    pillarTeeth: any;
    operationType: {
      __typename?: 'operation_types';
      id: number;
      name: string;
      color: string;
      allowed_teeth: any | null;
    };
  }>;
  clinicalMonitoring: {
    __typename?: 'clinical_monitoring';
    id: string;
    consultationComment: string;
    lastConsultationDate: string | null;
    initialBilanDate: string | null;
    esthetic: boolean;
    prosthesis: boolean;
    pain: boolean;
    occlusion: boolean;
    emergency: boolean;
    routineCheck: boolean;
    medicalBackground: string;
    decayedTeeth: any | null;
    damagedTeeth: any | null;
    looseTeeth: any | null;
    inflammationTeeth: any | null;
    missingTeeth: any | null;
    occlusionTeeth: any | null;
  } | null;
};

export type CustomerCommonFieldsFragment = {
  __typename?: 'partner_customers';
  id: string;
  clinicId: string;
  clinicName: string | null;
  diagnosticUrl: string | null;
  locationId: number | null;
  partnerId: string;
  partner: { __typename?: 'partners'; name: string | null };
};

export type ElementsCommonFieldsFragment = {
  __typename?: 'elements';
  id: number;
  editedByUser: boolean | null;
  coordinates: any;
  onTooth: any | null;
  detectionScore: any | null;
  type: {
    __typename?: 'element_types';
    id: number;
    color: string;
    name: string;
    parent: { __typename?: 'element_types'; id: number; name: string; color: string } | null;
  };
};

export type OperationsFieldsFragment = {
  __typename?: 'operations';
  id: number;
  order: number;
  toothNumbers: any;
  completedAt: string | null;
  groupId: number | null;
  pillarTeeth: any;
  operationType: {
    __typename?: 'operation_types';
    id: number;
    name: string;
    color: string;
    allowed_teeth: any | null;
  };
};

export type OralStructuresCommonFieldsFragment = {
  __typename?: 'oralStructures';
  id: string;
  detectionBox: any;
  detectionPoly: any;
  oralStructureType: { __typename?: 'oralStructureTypes'; name: string; color: string };
};

export type PatientFieldsFragment = {
  __typename?: 'patients';
  id: number;
  createdAt: string;
  updatedAt: string;
  archivedAt: string | null;
  email: string | null;
  firstname: string;
  lastname: string;
  locationId: number | null;
  usersViewed: any | null;
  birthdate: string | null;
  notes: string | null;
  sex: string | null;
  patients_partners: Array<{
    __typename?: 'patients_partners';
    id: string;
    data: any | null;
    externalPatientId: string | null;
    clinicId: string | null;
    bridgePatientId: string | null;
    customer: {
      __typename?: 'partner_customers';
      id: string;
      clinicId: string;
      clinicName: string | null;
      diagnosticUrl: string | null;
      locationId: number | null;
      partnerId: string;
      partner: { __typename?: 'partners'; name: string | null };
    } | null;
    partner: { __typename?: 'partners'; name: string | null } | null;
  }>;
  user: {
    __typename?: 'users';
    id: number;
    lastname: string;
    firstname: string;
    image: string | null;
    email: string;
    phone: string | null;
  } | null;
  location: {
    __typename?: 'locations';
    address: string | null;
    city: string | null;
    cp: string | null;
    id: number;
    title: string;
    phone: string | null;
    image: string | null;
    type: string | null;
    version: App_Versions_Enum;
    reseller: string | null;
    isMeasuresEnabled: boolean;
  } | null;
  consultations: Array<{
    __typename?: 'consultation';
    id: number;
    consultationDate: string;
    report: any | null;
    proposedTreatment: any | null;
    fromMigration: boolean | null;
    last_sent_date: string;
    createdAt: string;
    updatedAt: string;
    operations: Array<{
      __typename?: 'operations';
      id: number;
      order: number;
      toothNumbers: any;
      completedAt: string | null;
      groupId: number | null;
      pillarTeeth: any;
      operationType: {
        __typename?: 'operation_types';
        id: number;
        name: string;
        color: string;
        allowed_teeth: any | null;
      };
    }>;
    clinicalMonitoring: {
      __typename?: 'clinical_monitoring';
      id: string;
      consultationComment: string;
      lastConsultationDate: string | null;
      initialBilanDate: string | null;
      esthetic: boolean;
      prosthesis: boolean;
      pain: boolean;
      occlusion: boolean;
      emergency: boolean;
      routineCheck: boolean;
      medicalBackground: string;
      decayedTeeth: any | null;
      damagedTeeth: any | null;
      looseTeeth: any | null;
      inflammationTeeth: any | null;
      missingTeeth: any | null;
      occlusionTeeth: any | null;
    } | null;
  }>;
};

export type SearchedPatientsFragment = {
  __typename?: 'patients';
  id: number;
  createdAt: string;
  updatedAt: string;
  firstname: string;
  lastname: string;
  usersViewed: any | null;
  user: {
    __typename?: 'users';
    id: number;
    firstname: string;
    lastname: string;
    image: string | null;
  } | null;
  location: {
    __typename?: 'locations';
    id: number;
    title: string;
    locations_users: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      user_id: number;
    }>;
  } | null;
};

export type UserFieldsFragment = {
  __typename?: 'users';
  id: number;
  lastname: string;
  firstname: string;
  email: string;
  contactEmail: string | null;
  image: string | null;
  dentistSpeciality: Dentist_Specialities_Enum | null;
  locale: Locale_Enum;
  phone: string | null;
  demoMadeAt: string | null;
  toothNumberingSystem: string;
  element_group_mode: Element_Group_Modes_Enum | null;
  isFirst: boolean | null;
  isValidated: boolean;
  renewPwd: boolean;
  rppsNumber: string | null;
  disclaimerApprovedAt: string | null;
  intercomTokenId: string | null;
  cguApprovedAt: string | null;
  locations: Array<{
    __typename?: 'users_locations';
    isEnabled: boolean | null;
    location: {
      __typename?: 'locations';
      id: number;
      title: string;
      type: string | null;
      isWatcherBetaPilot: boolean;
      country: Countries_Enum | null;
      isClinicBetaPilot: boolean;
      version: App_Versions_Enum;
      isTreatmentPreferencesEnabled: boolean | null;
      reseller: string | null;
      allisonePlusVersion: number | null;
      hasProductTour: boolean;
    };
  }>;
  roles: Array<{
    __typename?: 'users_roles';
    role: { __typename?: 'roles'; id: number; value: string };
  }>;
  treatmentPreferences: {
    __typename?: 'treatment_preferences';
    id: string;
    treatmentPreferences: any | null;
  } | null;
};

export type XraysFieldsFragment = {
  __typename?: 'xrays';
  id: string;
  url: string;
  type: Xray_Type_Enum;
  name: any | null;
  consultationId: number;
  isAnalysisValidated: boolean;
  isIncludedInReport: boolean;
  creationDate: string;
  imageKey: string | null;
  imageHeight: number | null;
  imageWidth: number | null;
  centralTeeth: Array<string> | null;
  teeth: any;
  detectedPeriodonticLines: any | null;
  bridges: any | null;
  report: any | null;
  retroRulerSegments: any | null;
  pixelSpacing: Array<number>;
  projection: any;
  retroBoneLevels: any | null;
  detectedCejLines: any | null;
  origin: Xrays_Origin_Enum | null;
  elements: Array<{
    __typename?: 'elements';
    id: number;
    editedByUser: boolean | null;
    coordinates: any;
    onTooth: any | null;
    detectionScore: any | null;
    type: {
      __typename?: 'element_types';
      id: number;
      color: string;
      name: string;
      parent: { __typename?: 'element_types'; id: number; name: string; color: string } | null;
    };
  }>;
  anatomies: Array<{
    __typename?: 'anatomies';
    detectionBox: any;
    detectionPoly: any;
    id: string;
    onTooth: any;
    type: string;
    anatomyType: { __typename?: 'anatomy_types'; name: string; color: string };
  }>;
  oralStructures: Array<{
    __typename?: 'oralStructures';
    id: string;
    detectionBox: any;
    detectionPoly: any;
    oralStructureType: { __typename?: 'oralStructureTypes'; name: string; color: string };
  }>;
};

export type GetLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsQuery = {
  __typename?: 'query_root';
  locations: Array<{
    __typename?: 'locations';
    id: number;
    title: string;
    type: string | null;
    address: string | null;
    cp: string | null;
    city: string | null;
    phone: string | null;
    isTreatmentPreferencesEnabled: boolean | null;
    locations_users: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      user: {
        __typename?: 'users';
        id: number;
        firstname: string;
        lastname: string;
        roles: Array<{
          __typename?: 'users_roles';
          role: { __typename?: 'roles'; id: number; value: string };
        }>;
      };
    }>;
  }>;
};

export type GetPatientQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetPatientQuery = {
  __typename?: 'query_root';
  patient: {
    __typename?: 'patients';
    id: number;
    createdAt: string;
    updatedAt: string;
    archivedAt: string | null;
    email: string | null;
    firstname: string;
    lastname: string;
    locationId: number | null;
    usersViewed: any | null;
    birthdate: string | null;
    notes: string | null;
    sex: string | null;
    patients_partners: Array<{
      __typename?: 'patients_partners';
      id: string;
      data: any | null;
      externalPatientId: string | null;
      clinicId: string | null;
      bridgePatientId: string | null;
      customer: {
        __typename?: 'partner_customers';
        id: string;
        clinicId: string;
        clinicName: string | null;
        diagnosticUrl: string | null;
        locationId: number | null;
        partnerId: string;
        partner: { __typename?: 'partners'; name: string | null };
      } | null;
      partner: { __typename?: 'partners'; name: string | null } | null;
    }>;
    user: {
      __typename?: 'users';
      id: number;
      lastname: string;
      firstname: string;
      image: string | null;
      email: string;
      phone: string | null;
    } | null;
    location: {
      __typename?: 'locations';
      address: string | null;
      city: string | null;
      cp: string | null;
      id: number;
      title: string;
      phone: string | null;
      image: string | null;
      type: string | null;
      version: App_Versions_Enum;
      reseller: string | null;
      isMeasuresEnabled: boolean;
    } | null;
    consultations: Array<{
      __typename?: 'consultation';
      id: number;
      consultationDate: string;
      report: any | null;
      proposedTreatment: any | null;
      fromMigration: boolean | null;
      last_sent_date: string;
      createdAt: string;
      updatedAt: string;
      operations: Array<{
        __typename?: 'operations';
        id: number;
        order: number;
        toothNumbers: any;
        completedAt: string | null;
        groupId: number | null;
        pillarTeeth: any;
        operationType: {
          __typename?: 'operation_types';
          id: number;
          name: string;
          color: string;
          allowed_teeth: any | null;
        };
      }>;
      clinicalMonitoring: {
        __typename?: 'clinical_monitoring';
        id: string;
        consultationComment: string;
        lastConsultationDate: string | null;
        initialBilanDate: string | null;
        esthetic: boolean;
        prosthesis: boolean;
        pain: boolean;
        occlusion: boolean;
        emergency: boolean;
        routineCheck: boolean;
        medicalBackground: string;
        decayedTeeth: any | null;
        damagedTeeth: any | null;
        looseTeeth: any | null;
        inflammationTeeth: any | null;
        missingTeeth: any | null;
        occlusionTeeth: any | null;
      } | null;
    }>;
  } | null;
};

export type GetPatientXraysQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetPatientXraysQuery = {
  __typename?: 'query_root';
  xrays: Array<{
    __typename?: 'xrays';
    id: string;
    url: string;
    type: Xray_Type_Enum;
    name: any | null;
    consultationId: number;
    isAnalysisValidated: boolean;
    isIncludedInReport: boolean;
    creationDate: string;
    imageKey: string | null;
    imageHeight: number | null;
    imageWidth: number | null;
    centralTeeth: Array<string> | null;
    teeth: any;
    detectedPeriodonticLines: any | null;
    bridges: any | null;
    report: any | null;
    retroRulerSegments: any | null;
    pixelSpacing: Array<number>;
    projection: any;
    retroBoneLevels: any | null;
    detectedCejLines: any | null;
    origin: Xrays_Origin_Enum | null;
    elements: Array<{
      __typename?: 'elements';
      id: number;
      editedByUser: boolean | null;
      coordinates: any;
      onTooth: any | null;
      detectionScore: any | null;
      type: {
        __typename?: 'element_types';
        id: number;
        color: string;
        name: string;
        parent: { __typename?: 'element_types'; id: number; name: string; color: string } | null;
      };
    }>;
    anatomies: Array<{
      __typename?: 'anatomies';
      detectionBox: any;
      detectionPoly: any;
      id: string;
      onTooth: any;
      type: string;
      anatomyType: { __typename?: 'anatomy_types'; name: string; color: string };
    }>;
    oralStructures: Array<{
      __typename?: 'oralStructures';
      id: string;
      detectionBox: any;
      detectionPoly: any;
      oralStructureType: { __typename?: 'oralStructureTypes'; name: string; color: string };
    }>;
  }>;
};

export type GetRolesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRolesQuery = {
  __typename?: 'query_root';
  roles: Array<{ __typename?: 'roles'; id: number; value: string }>;
};

export type UpdateLocationByIdMutationVariables = Exact<{
  locationId: Scalars['Int'];
  data: Locations_Set_Input;
}>;

export type UpdateLocationByIdMutation = {
  __typename?: 'mutation_root';
  update_locations_by_pk: {
    __typename?: 'locations';
    id: number;
    title: string;
    address: string | null;
    cp: string | null;
    city: string | null;
    image: string | null;
    phone: string | null;
  } | null;
};

export type LocationByIdQueryVariables = Exact<{
  locationId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
}>;

export type LocationByIdQuery = {
  __typename?: 'query_root';
  location: {
    __typename?: 'locations';
    id: number;
    title: string;
    address: string | null;
    city: string | null;
    cp: string | null;
    country: Countries_Enum | null;
    phone: string | null;
    image: string | null;
    version: App_Versions_Enum;
    locations_users: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      user: {
        __typename?: 'users';
        id: number;
        lastname: string;
        firstname: string;
        email: string;
        created_at: string;
        rpps_number: string | null;
        is_validated: boolean;
        roles: Array<{
          __typename?: 'users_roles';
          role: { __typename?: 'roles'; id: number; value: string };
        }>;
      };
    }>;
  } | null;
  headof: Array<{ __typename?: 'users'; id: number; firstname: string; lastname: string }>;
  director: Array<{ __typename?: 'users'; id: number; firstname: string; lastname: string }>;
};

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;

export type DeleteNotificationMutation = {
  __typename?: 'mutation_root';
  updateNotification: { __typename?: 'notifications'; id: number } | null;
};

export type InsertNotificationMutationVariables = Exact<{
  name: Notifications_Type_Enum;
  userId: Scalars['Int'];
  url: Scalars['String'];
}>;

export type InsertNotificationMutation = {
  __typename?: 'mutation_root';
  insertNotification: { __typename?: 'notifications'; id: number } | null;
};

export type SubscribeNotificationsSubscriptionVariables = Exact<{ [key: string]: never }>;

export type SubscribeNotificationsSubscription = {
  __typename?: 'subscription_root';
  notifications: Array<{
    __typename?: 'notifications';
    id: number;
    name: Notifications_Type_Enum;
    url: string | null;
  }>;
};

export type CreateObservationMutationVariables = Exact<{
  observation: Observations_Insert_Input;
}>;

export type CreateObservationMutation = {
  __typename?: 'mutation_root';
  addObservation: { __typename?: 'observations'; id: number } | null;
};

export type GetObservationsQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetObservationsQuery = {
  __typename?: 'query_root';
  getObservations: Array<{ __typename?: 'observations'; observations: string; createdAt: string }>;
};

export type OperationTypesQueryVariables = Exact<{ [key: string]: never }>;

export type OperationTypesQuery = {
  __typename?: 'query_root';
  operation_types: Array<{
    __typename?: 'operation_types';
    id: number;
    color: string;
    name: string;
    allowed_teeth: any | null;
  }>;
};

export type GetPatientPartnerByPkQueryVariables = Exact<{
  patientPartnerId: Scalars['uuid'];
}>;

export type GetPatientPartnerByPkQuery = {
  __typename?: 'query_root';
  patients_partners_by_pk: {
    __typename?: 'patients_partners';
    data: any | null;
    patient: {
      __typename?: 'patients';
      id: number;
      firstname: string;
      lastname: string;
      birthdate: string | null;
      sex: string | null;
      email: string | null;
      locationId: number | null;
      userId: number | null;
      archivedAt: string | null;
      consultations: Array<{ __typename?: 'consultation'; id: number }>;
    } | null;
  } | null;
};

export type GetPatientPartnerIdQueryVariables = Exact<{
  patientId: Scalars['Int'];
}>;

export type GetPatientPartnerIdQuery = {
  __typename?: 'query_root';
  patient: {
    __typename?: 'patients';
    patients_partners: Array<{ __typename?: 'patients_partners'; partnerId: string | null }>;
  } | null;
};

export type UpdateLastSentDateMutationVariables = Exact<{
  id: Scalars['Int'];
  date: Scalars['timestamptz'];
}>;

export type UpdateLastSentDateMutation = {
  __typename?: 'mutation_root';
  update_consultation: {
    __typename: 'consultation';
    updatedAt: string;
    last_sent_date: string;
  } | null;
};

export type CreatePatientMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  userId?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['Int']>;
}>;

export type CreatePatientMutation = {
  __typename?: 'mutation_root';
  insertPatient: { __typename?: 'patients'; id: number } | null;
};

export type GetPatientFilesQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<Patients_Bool_Exp>;
  orderBy?: InputMaybe<Array<Patients_Order_By> | Patients_Order_By>;
}>;

export type GetPatientFilesQuery = {
  __typename?: 'query_root';
  patients: Array<{
    __typename?: 'patients';
    id: number;
    createdAt: string;
    updatedAt: string;
    firstname: string;
    lastname: string;
    usersViewed: any | null;
    user: {
      __typename?: 'users';
      id: number;
      firstname: string;
      lastname: string;
      image: string | null;
    } | null;
    location: {
      __typename?: 'locations';
      id: number;
      title: string;
      locations_users: Array<{
        __typename?: 'users_locations';
        isEnabled: boolean | null;
        user_id: number;
      }>;
    } | null;
  }>;
  patientsAggregate: {
    __typename?: 'patients_aggregate';
    aggregate: { __typename?: 'patients_aggregate_fields'; count: number } | null;
  };
};

export type GetPatientsQueryVariables = Exact<{
  where?: InputMaybe<Patients_Bool_Exp>;
}>;

export type GetPatientsQuery = {
  __typename?: 'query_root';
  patients: Array<{
    __typename?: 'patients';
    id: number;
    createdAt: string;
    firstname: string;
    lastname: string;
    usersViewed: any | null;
    locationId: number | null;
    consultations: Array<{ __typename?: 'consultation'; id: number }>;
  }>;
};

export type GetStatsPatientFilesQueryVariables = Exact<{
  where?: InputMaybe<Patients_Bool_Exp>;
}>;

export type GetStatsPatientFilesQuery = {
  __typename?: 'query_root';
  patients: Array<{
    __typename?: 'patients';
    id: number;
    createdAt: string;
    updatedAt: string;
    firstname: string;
    lastname: string;
    usersViewed: any | null;
    user: {
      __typename?: 'users';
      id: number;
      firstname: string;
      lastname: string;
      image: string | null;
    } | null;
    location: {
      __typename?: 'locations';
      id: number;
      title: string;
      locations_users: Array<{
        __typename?: 'users_locations';
        isEnabled: boolean | null;
        user_id: number;
      }>;
    } | null;
  }>;
};

export type UpdatePatientMutationVariables = Exact<{
  patientId: Scalars['Int'];
  patient?: InputMaybe<Patients_Set_Input>;
}>;

export type UpdatePatientMutation = {
  __typename?: 'mutation_root';
  updatePatient: {
    __typename?: 'patients';
    id: number;
    email: string | null;
    firstname: string;
    lastname: string;
    archivedAt: string | null;
    user: { __typename?: 'users'; lastname: string } | null;
  } | null;
};

export type UpdatePatientWithTreatmentMutationVariables = Exact<{
  patientId: Scalars['Int'];
  consultationId: Scalars['Int'];
  patient?: InputMaybe<Patients_Set_Input>;
  consultationDate: Scalars['timestamptz'];
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdatePatientWithTreatmentMutation = {
  __typename?: 'mutation_root';
  updatePatient: {
    __typename?: 'patients';
    id: number;
    email: string | null;
    firstname: string;
    lastname: string;
    archivedAt: string | null;
    user: { __typename?: 'users'; lastname: string } | null;
  } | null;
  update_consultation: { __typename?: 'consultation'; id: number; consultationDate: string } | null;
};

export type UpdatePatientInformationMutationVariables = Exact<{
  patientId: Scalars['Int'];
  patient?: InputMaybe<Patients_Set_Input>;
  archivedAt?: InputMaybe<Scalars['timestamptz']>;
}>;

export type UpdatePatientInformationMutation = {
  __typename?: 'mutation_root';
  updatePatient: {
    __typename?: 'patients';
    id: number;
    email: string | null;
    firstname: string;
    lastname: string;
    archivedAt: string | null;
  } | null;
};

export type UpdatePatientUsersViewedMutationVariables = Exact<{
  patientId: Scalars['Int'];
  usersViewed?: InputMaybe<Scalars['json']>;
}>;

export type UpdatePatientUsersViewedMutation = {
  __typename?: 'mutation_root';
  updatePatient: { __typename?: 'patients'; id: number; usersViewed: any | null } | null;
};

export type GetTreatmentPreferencesQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetTreatmentPreferencesQuery = {
  __typename?: 'query_root';
  treatment_preferences: Array<{
    __typename?: 'treatment_preferences';
    treatmentPreferences: any | null;
    id: string;
  }>;
};

export type InsertTreatmentPreferencesMutationVariables = Exact<{
  treatmentPreferences: Treatment_Preferences_Insert_Input;
}>;

export type InsertTreatmentPreferencesMutation = {
  __typename?: 'mutation_root';
  insert_treatment_preferences_one: {
    __typename?: 'treatment_preferences';
    userId: number | null;
    treatmentPreferences: any | null;
  } | null;
};

export type UpdateMultipleTreatmentPreferencesMutationVariables = Exact<{
  userIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateMultipleTreatmentPreferencesMutation = {
  __typename?: 'mutation_root';
  update_treatment_preferences_many: Array<{
    __typename?: 'treatment_preferences_mutation_response';
    affected_rows: number;
  } | null> | null;
};

export type UpdateToggleMutationVariables = Exact<{
  locationId: Scalars['Int'];
  enabled?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateToggleMutation = {
  __typename?: 'mutation_root';
  update_locations_by_pk: {
    __typename?: 'locations';
    id: number;
    isTreatmentPreferencesEnabled: boolean | null;
  } | null;
};

export type UpdateTreatmentPreferencesMutationVariables = Exact<{
  userId: Scalars['Int'];
  treatmentPreferences?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateTreatmentPreferencesMutation = {
  __typename?: 'mutation_root';
  update_treatment_preferences: {
    __typename?: 'treatment_preferences_mutation_response';
    affected_rows: number;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;

export type CreateUserMutation = {
  __typename?: 'mutation_root';
  createUser: { __typename?: 'CreateUserOutput'; id: number } | null;
};

export type GetCurrentUserQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type GetCurrentUserQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: number;
    lastname: string;
    firstname: string;
    email: string;
    contactEmail: string | null;
    image: string | null;
    dentistSpeciality: Dentist_Specialities_Enum | null;
    locale: Locale_Enum;
    phone: string | null;
    demoMadeAt: string | null;
    toothNumberingSystem: string;
    element_group_mode: Element_Group_Modes_Enum | null;
    isFirst: boolean | null;
    isValidated: boolean;
    renewPwd: boolean;
    rppsNumber: string | null;
    disclaimerApprovedAt: string | null;
    intercomTokenId: string | null;
    cguApprovedAt: string | null;
    locations: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      location: {
        __typename?: 'locations';
        id: number;
        title: string;
        type: string | null;
        isWatcherBetaPilot: boolean;
        country: Countries_Enum | null;
        isClinicBetaPilot: boolean;
        version: App_Versions_Enum;
        isTreatmentPreferencesEnabled: boolean | null;
        reseller: string | null;
        allisonePlusVersion: number | null;
        hasProductTour: boolean;
      };
    }>;
    roles: Array<{
      __typename?: 'users_roles';
      role: { __typename?: 'roles'; id: number; value: string };
    }>;
    treatmentPreferences: {
      __typename?: 'treatment_preferences';
      id: string;
      treatmentPreferences: any | null;
    } | null;
  }>;
};

export type UpdateUserMutationVariables = Exact<{
  userId: Scalars['Int'];
  data: Users_Set_Input;
}>;

export type UpdateUserMutation = {
  __typename?: 'mutation_root';
  update_users_by_pk: {
    __typename?: 'users';
    id: number;
    lastname: string;
    firstname: string;
    email: string;
    contactEmail: string | null;
    image: string | null;
    dentistSpeciality: Dentist_Specialities_Enum | null;
    locale: Locale_Enum;
    phone: string | null;
    demoMadeAt: string | null;
    toothNumberingSystem: string;
    element_group_mode: Element_Group_Modes_Enum | null;
    isFirst: boolean | null;
    isValidated: boolean;
    renewPwd: boolean;
    rppsNumber: string | null;
    disclaimerApprovedAt: string | null;
    intercomTokenId: string | null;
    cguApprovedAt: string | null;
    locations: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      location: {
        __typename?: 'locations';
        id: number;
        title: string;
        type: string | null;
        isWatcherBetaPilot: boolean;
        country: Countries_Enum | null;
        isClinicBetaPilot: boolean;
        version: App_Versions_Enum;
        isTreatmentPreferencesEnabled: boolean | null;
        reseller: string | null;
        allisonePlusVersion: number | null;
        hasProductTour: boolean;
      };
    }>;
    roles: Array<{
      __typename?: 'users_roles';
      role: { __typename?: 'roles'; id: number; value: string };
    }>;
    treatmentPreferences: {
      __typename?: 'treatment_preferences';
      id: string;
      treatmentPreferences: any | null;
    } | null;
  } | null;
};

export type UserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type UserQuery = {
  __typename?: 'query_root';
  users_by_pk: {
    __typename?: 'users';
    id: number;
    lastname: string;
    firstname: string;
    email: string;
    contactEmail: string | null;
    image: string | null;
    dentistSpeciality: Dentist_Specialities_Enum | null;
    locale: Locale_Enum;
    phone: string | null;
    demoMadeAt: string | null;
    toothNumberingSystem: string;
    element_group_mode: Element_Group_Modes_Enum | null;
    isFirst: boolean | null;
    isValidated: boolean;
    renewPwd: boolean;
    rppsNumber: string | null;
    disclaimerApprovedAt: string | null;
    intercomTokenId: string | null;
    cguApprovedAt: string | null;
    locations: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      location: {
        __typename?: 'locations';
        id: number;
        title: string;
        type: string | null;
        isWatcherBetaPilot: boolean;
        country: Countries_Enum | null;
        isClinicBetaPilot: boolean;
        version: App_Versions_Enum;
        isTreatmentPreferencesEnabled: boolean | null;
        reseller: string | null;
        allisonePlusVersion: number | null;
        hasProductTour: boolean;
      };
    }>;
    roles: Array<{
      __typename?: 'users_roles';
      role: { __typename?: 'roles'; id: number; value: string };
    }>;
    treatmentPreferences: {
      __typename?: 'treatment_preferences';
      id: string;
      treatmentPreferences: any | null;
    } | null;
  } | null;
};

export type UsersQueryVariables = Exact<{
  withLocations: Scalars['Boolean'];
  withRoles: Scalars['Boolean'];
  where?: InputMaybe<Users_Bool_Exp>;
}>;

export type UsersQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: number;
    lastname: string;
    firstname: string;
    email: string;
    image: string | null;
    rpps_number: string | null;
    dentistSpeciality: Dentist_Specialities_Enum | null;
    locations: Array<{
      __typename?: 'users_locations';
      isEnabled: boolean | null;
      locationId: number;
    }>;
    roles: Array<{
      __typename?: 'users_roles';
      role: { __typename?: 'roles'; id: number; value: string };
    }>;
  }>;
};

export type AddUserToLocationMutationVariables = Exact<{
  locationId: Scalars['Int'];
  userId: Scalars['Int'];
}>;

export type AddUserToLocationMutation = {
  __typename?: 'mutation_root';
  insert_users_locations_one: {
    __typename?: 'users_locations';
    user_id: number;
    location_id: number;
  } | null;
};

export type UpdateUserLocationMutationVariables = Exact<{
  locationId: Scalars['Int'];
  userId: Scalars['Int'];
  set?: InputMaybe<Users_Locations_Set_Input>;
}>;

export type UpdateUserLocationMutation = {
  __typename?: 'mutation_root';
  update_users_locations_by_pk: {
    __typename?: 'users_locations';
    location_id: number;
    user_id: number;
    isEnabled: boolean | null;
  } | null;
};

export type InsertUsersRolesMutationVariables = Exact<{
  usersRoles: Array<Users_Roles_Insert_Input> | Users_Roles_Insert_Input;
}>;

export type InsertUsersRolesMutation = {
  __typename?: 'mutation_root';
  insert_users_roles: {
    __typename?: 'users_roles_mutation_response';
    affected_rows: number;
  } | null;
};

export type DeleteXRayElementsMutationVariables = Exact<{
  elementIds: Array<Scalars['Int']> | Scalars['Int'];
}>;

export type DeleteXRayElementsMutation = {
  __typename?: 'mutation_root';
  delete_elements: { __typename?: 'elements_mutation_response'; affected_rows: number } | null;
};

export type GetXrayLightQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetXrayLightQuery = {
  __typename?: 'query_root';
  xray: {
    __typename?: 'xrays';
    id: string;
    consultation: { __typename?: 'consultation'; id: number; patientId: number | null };
  } | null;
};

export type InsertXRayMutationVariables = Exact<{
  newXRay: Xrays_Insert_Input;
}>;

export type InsertXRayMutation = {
  __typename?: 'mutation_root';
  insertXray: {
    __typename?: 'xrays';
    id: string;
    url: string;
    creationDate: string;
    consultationId: number;
  } | null;
};

export type InsertXrayElementsMutationVariables = Exact<{
  elements: Array<Elements_Insert_Input> | Elements_Insert_Input;
}>;

export type InsertXrayElementsMutation = {
  __typename?: 'mutation_root';
  insert_elements: { __typename?: 'elements_mutation_response'; affected_rows: number } | null;
};

export type UpdateXRayRetroBoneLevelMutationVariables = Exact<{
  id: Scalars['uuid'];
  retroBoneLevelSegments?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateXRayRetroBoneLevelMutation = {
  __typename?: 'mutation_root';
  updateXray: { __typename?: 'xrays'; id: string; retroBoneLevels: any | null } | null;
};

export type UpdateXRayRetroRulerMutationVariables = Exact<{
  id: Scalars['uuid'];
  retroRulerSegments?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateXRayRetroRulerMutation = {
  __typename?: 'mutation_root';
  updateXray: { __typename?: 'xrays'; id: string; retroRulerSegments: any | null } | null;
};

export type UpdateXRayTeethMutationVariables = Exact<{
  id: Scalars['uuid'];
  teeth?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['json']>;
}>;

export type UpdateXRayTeethMutation = {
  __typename?: 'mutation_root';
  updateXray: { __typename?: 'xrays'; name: any | null } | null;
};

export type UpdateXRayTeethAndPerioLineMutationVariables = Exact<{
  id: Scalars['uuid'];
  teeth?: InputMaybe<Scalars['jsonb']>;
  detectedPeriodonticLines?: InputMaybe<Scalars['jsonb']>;
  centralTeeth?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateXRayTeethAndPerioLineMutation = {
  __typename?: 'mutation_root';
  updateXray: {
    __typename?: 'xrays';
    id: string;
    url: string;
    type: Xray_Type_Enum;
    name: any | null;
    consultationId: number;
    isAnalysisValidated: boolean;
    isIncludedInReport: boolean;
    creationDate: string;
    imageKey: string | null;
    imageHeight: number | null;
    imageWidth: number | null;
    centralTeeth: Array<string> | null;
    teeth: any;
    detectedPeriodonticLines: any | null;
    bridges: any | null;
    report: any | null;
    retroRulerSegments: any | null;
    pixelSpacing: Array<number>;
    projection: any;
    retroBoneLevels: any | null;
    detectedCejLines: any | null;
    origin: Xrays_Origin_Enum | null;
    elements: Array<{
      __typename?: 'elements';
      id: number;
      editedByUser: boolean | null;
      coordinates: any;
      onTooth: any | null;
      detectionScore: any | null;
      type: {
        __typename?: 'element_types';
        id: number;
        color: string;
        name: string;
        parent: { __typename?: 'element_types'; id: number; name: string; color: string } | null;
      };
    }>;
    anatomies: Array<{
      __typename?: 'anatomies';
      detectionBox: any;
      detectionPoly: any;
      id: string;
      onTooth: any;
      type: string;
      anatomyType: { __typename?: 'anatomy_types'; name: string; color: string };
    }>;
    oralStructures: Array<{
      __typename?: 'oralStructures';
      id: string;
      detectionBox: any;
      detectionPoly: any;
      oralStructureType: { __typename?: 'oralStructureTypes'; name: string; color: string };
    }>;
  } | null;
};

export type UpdateXrayMutationVariables = Exact<{
  id: Scalars['uuid'];
  xray?: InputMaybe<Xrays_Set_Input>;
  elements: Array<Elements_Insert_Input> | Elements_Insert_Input;
  withElements: Scalars['Boolean'];
  anatomies: Array<Anatomies_Insert_Input> | Anatomies_Insert_Input;
  withAnatomies: Scalars['Boolean'];
}>;

export type UpdateXrayMutation = {
  __typename?: 'mutation_root';
  updateXray: { __typename?: 'xrays'; id: string } | null;
  delete_elements?: {
    __typename?: 'elements_mutation_response';
    returning: Array<{ __typename?: 'elements'; id: number }>;
  } | null;
  insert_elements?: {
    __typename?: 'elements_mutation_response';
    returning: Array<{ __typename?: 'elements'; id: number }>;
  } | null;
  delete_anatomies?: {
    __typename?: 'anatomies_mutation_response';
    returning: Array<{ __typename?: 'anatomies'; id: string }>;
  } | null;
  insert_anatomies?: {
    __typename?: 'anatomies_mutation_response';
    returning: Array<{ __typename?: 'anatomies'; id: string }>;
  } | null;
};

export type UpdateXrayInfoMutationVariables = Exact<{
  id: Scalars['uuid'];
  xray?: InputMaybe<Xrays_Set_Input>;
}>;

export type UpdateXrayInfoMutation = {
  __typename?: 'mutation_root';
  updateXray: { __typename?: 'xrays'; id: string } | null;
};

export const EducationalSheetsFragmentFragmentDoc = gql`
  fragment educationalSheetsFragment on educationalSheets {
    id
    locationId
    type
    url
  }
`;
export const CustomerCommonFieldsFragmentDoc = gql`
  fragment CustomerCommonFields on partner_customers {
    id
    clinicId
    clinicName
    diagnosticUrl
    locationId
    partnerId
    partner {
      name
    }
  }
`;
export const OperationsFieldsFragmentDoc = gql`
  fragment OperationsFields on operations {
    id
    order
    toothNumbers
    completedAt
    groupId
    pillarTeeth
    operationType {
      id
      name
      color
      allowed_teeth
    }
  }
`;
export const ClinicalCommonFieldsFragmentDoc = gql`
  fragment ClinicalCommonFields on clinical_monitoring {
    id
    consultationComment
    lastConsultationDate
    initialBilanDate
    esthetic
    prosthesis
    pain
    occlusion
    emergency
    routineCheck
    medicalBackground
    decayedTeeth
    damagedTeeth
    looseTeeth
    inflammationTeeth
    missingTeeth
    occlusionTeeth
  }
`;
export const ConsultationCommonFieldsFragmentDoc = gql`
  fragment ConsultationCommonFields on consultation {
    id
    consultationDate
    report
    proposedTreatment
    fromMigration
    last_sent_date
    createdAt
    updatedAt
    operations(order_by: { createdAt: asc, order: asc }) {
      ...OperationsFields
    }
    clinicalMonitoring {
      ...ClinicalCommonFields
    }
  }
  ${OperationsFieldsFragmentDoc}
  ${ClinicalCommonFieldsFragmentDoc}
`;
export const PatientFieldsFragmentDoc = gql`
  fragment PatientFields on patients {
    id
    createdAt
    updatedAt
    archivedAt
    email
    firstname
    lastname
    locationId
    usersViewed
    birthdate
    notes
    sex
    patients_partners {
      customer {
        ...CustomerCommonFields
      }
      id
      data
      partner {
        name
      }
      externalPatientId
      clinicId
      bridgePatientId
    }
    user {
      id
      lastname
      firstname
      image
      email
      phone
    }
    location {
      address
      city
      cp
      id
      title
      phone
      image
      type
      version
      reseller
      isMeasuresEnabled
    }
    consultations {
      ...ConsultationCommonFields
    }
  }
  ${CustomerCommonFieldsFragmentDoc}
  ${ConsultationCommonFieldsFragmentDoc}
`;
export const SearchedPatientsFragmentDoc = gql`
  fragment SearchedPatients on patients {
    id
    createdAt
    updatedAt
    firstname
    lastname
    usersViewed
    user {
      id
      firstname
      lastname
      image
    }
    location {
      id
      title
      locations_users {
        isEnabled
        user_id
      }
    }
  }
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on users {
    id
    lastname
    firstname
    email
    contactEmail
    image
    isFirst: is_first
    isValidated: is_validated
    dentistSpeciality
    renewPwd: renew_pwd
    rppsNumber: rpps_number
    locale
    phone
    disclaimerApprovedAt: disclaimer_approved_at
    intercomTokenId: intercom_token_id
    cguApprovedAt: cgu_approved_at
    demoMadeAt
    toothNumberingSystem
    locations: users_users_locations {
      isEnabled
      location {
        id
        title
        type
        isWatcherBetaPilot
        country
        isClinicBetaPilot
        version
        isTreatmentPreferencesEnabled
        reseller
        allisonePlusVersion
        hasProductTour
      }
    }
    roles: users_users_roles {
      role {
        id
        value
      }
    }
    treatmentPreferences: treatment_preferences {
      id
      treatmentPreferences
    }
    element_group_mode
  }
`;
export const ElementsCommonFieldsFragmentDoc = gql`
  fragment ElementsCommonFields on elements {
    id
    editedByUser
    coordinates
    onTooth
    detectionScore
    type {
      id
      color
      name
      parent {
        id
        name
        color
      }
    }
  }
`;
export const AnatomiesCommonFieldsFragmentDoc = gql`
  fragment AnatomiesCommonFields on anatomies {
    anatomyType {
      name
      color
    }
    detectionBox
    detectionPoly
    id
    onTooth
    type
  }
`;
export const OralStructuresCommonFieldsFragmentDoc = gql`
  fragment OralStructuresCommonFields on oralStructures {
    id
    detectionBox
    detectionPoly
    oralStructureType {
      name
      color
    }
  }
`;
export const XraysFieldsFragmentDoc = gql`
  fragment xraysFields on xrays {
    id
    url
    type
    name
    consultationId
    isAnalysisValidated
    isIncludedInReport
    creationDate
    imageKey
    imageHeight
    imageWidth
    centralTeeth
    elements {
      ...ElementsCommonFields
    }
    teeth
    detectedPeriodonticLines
    bridges
    report
    retroRulerSegments
    pixelSpacing
    projection
    retroBoneLevels
    anatomies {
      ...AnatomiesCommonFields
    }
    oralStructures {
      ...OralStructuresCommonFields
    }
    detectedCejLines
    origin
  }
  ${ElementsCommonFieldsFragmentDoc}
  ${AnatomiesCommonFieldsFragmentDoc}
  ${OralStructuresCommonFieldsFragmentDoc}
`;
export const CompleteActsDocument = gql`
  mutation CompleteActs(
    $completeIds: [Int!]!
    $uncompleteIds: [Int!]!
    $completedAt: timestamptz!
    $consultationId: Int!
  ) {
    update_consultation(pk_columns: { id: $consultationId }, _set: { fromMigration: false }) {
      id
      fromMigration
    }
    completeOperations: update_operations(
      where: { id: { _in: $completeIds } }
      _set: { completedAt: $completedAt }
    ) {
      affected_rows
    }
    uncompleteOperations: update_operations(
      where: { id: { _in: $uncompleteIds } }
      _set: { completedAt: null }
    ) {
      affected_rows
    }
  }
`;
export type CompleteActsMutationFn = Apollo.MutationFunction<
  CompleteActsMutation,
  CompleteActsMutationVariables
>;

/**
 * __useCompleteActsMutation__
 *
 * To run a mutation, you first call `useCompleteActsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteActsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeActsMutation, { data, loading, error }] = useCompleteActsMutation({
 *   variables: {
 *      completeIds: // value for 'completeIds'
 *      uncompleteIds: // value for 'uncompleteIds'
 *      completedAt: // value for 'completedAt'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useCompleteActsMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteActsMutation, CompleteActsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteActsMutation, CompleteActsMutationVariables>(
    CompleteActsDocument,
    options
  );
}
export type CompleteActsMutationHookResult = ReturnType<typeof useCompleteActsMutation>;
export type CompleteActsMutationResult = Apollo.MutationResult<CompleteActsMutation>;
export type CompleteActsMutationOptions = Apollo.BaseMutationOptions<
  CompleteActsMutation,
  CompleteActsMutationVariables
>;
export const CreateActsDocument = gql`
  mutation CreateActs($operations: [operations_insert_input!]!, $consultationId: Int!) {
    update_consultation(pk_columns: { id: $consultationId }, _set: { fromMigration: false }) {
      id
      fromMigration
    }
    create_operations(objects: $operations) {
      affected_rows
    }
  }
`;
export type CreateActsMutationFn = Apollo.MutationFunction<
  CreateActsMutation,
  CreateActsMutationVariables
>;

/**
 * __useCreateActsMutation__
 *
 * To run a mutation, you first call `useCreateActsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActsMutation, { data, loading, error }] = useCreateActsMutation({
 *   variables: {
 *      operations: // value for 'operations'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useCreateActsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateActsMutation, CreateActsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateActsMutation, CreateActsMutationVariables>(
    CreateActsDocument,
    options
  );
}
export type CreateActsMutationHookResult = ReturnType<typeof useCreateActsMutation>;
export type CreateActsMutationResult = Apollo.MutationResult<CreateActsMutation>;
export type CreateActsMutationOptions = Apollo.BaseMutationOptions<
  CreateActsMutation,
  CreateActsMutationVariables
>;
export const DeleteActsDocument = gql`
  mutation DeleteActs($ids: [Int!]!, $consultationId: Int!) {
    update_consultation(pk_columns: { id: $consultationId }, _set: { fromMigration: false }) {
      id
      fromMigration
    }
    delete_operations(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;
export type DeleteActsMutationFn = Apollo.MutationFunction<
  DeleteActsMutation,
  DeleteActsMutationVariables
>;

/**
 * __useDeleteActsMutation__
 *
 * To run a mutation, you first call `useDeleteActsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActsMutation, { data, loading, error }] = useDeleteActsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useDeleteActsMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteActsMutation, DeleteActsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteActsMutation, DeleteActsMutationVariables>(
    DeleteActsDocument,
    options
  );
}
export type DeleteActsMutationHookResult = ReturnType<typeof useDeleteActsMutation>;
export type DeleteActsMutationResult = Apollo.MutationResult<DeleteActsMutation>;
export type DeleteActsMutationOptions = Apollo.BaseMutationOptions<
  DeleteActsMutation,
  DeleteActsMutationVariables
>;
export const InsertClinicalMonitoringDocument = gql`
  mutation insertClinicalMonitoring($clinicalMonitoring: clinical_monitoring_insert_input!) {
    insert_clinical_monitoring_one(object: $clinicalMonitoring) {
      id
    }
  }
`;
export type InsertClinicalMonitoringMutationFn = Apollo.MutationFunction<
  InsertClinicalMonitoringMutation,
  InsertClinicalMonitoringMutationVariables
>;

/**
 * __useInsertClinicalMonitoringMutation__
 *
 * To run a mutation, you first call `useInsertClinicalMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertClinicalMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertClinicalMonitoringMutation, { data, loading, error }] = useInsertClinicalMonitoringMutation({
 *   variables: {
 *      clinicalMonitoring: // value for 'clinicalMonitoring'
 *   },
 * });
 */
export function useInsertClinicalMonitoringMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertClinicalMonitoringMutation,
    InsertClinicalMonitoringMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertClinicalMonitoringMutation,
    InsertClinicalMonitoringMutationVariables
  >(InsertClinicalMonitoringDocument, options);
}
export type InsertClinicalMonitoringMutationHookResult = ReturnType<
  typeof useInsertClinicalMonitoringMutation
>;
export type InsertClinicalMonitoringMutationResult =
  Apollo.MutationResult<InsertClinicalMonitoringMutation>;
export type InsertClinicalMonitoringMutationOptions = Apollo.BaseMutationOptions<
  InsertClinicalMonitoringMutation,
  InsertClinicalMonitoringMutationVariables
>;
export const UpdateClinicalMonitoringDocument = gql`
  mutation updateClinicalMonitoring(
    $id: uuid!
    $clinicalMonitoring: clinical_monitoring_set_input
  ) {
    update_clinical_monitoring_by_pk(pk_columns: { id: $id }, _set: $clinicalMonitoring) {
      ...ClinicalCommonFields
    }
  }
  ${ClinicalCommonFieldsFragmentDoc}
`;
export type UpdateClinicalMonitoringMutationFn = Apollo.MutationFunction<
  UpdateClinicalMonitoringMutation,
  UpdateClinicalMonitoringMutationVariables
>;

/**
 * __useUpdateClinicalMonitoringMutation__
 *
 * To run a mutation, you first call `useUpdateClinicalMonitoringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicalMonitoringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicalMonitoringMutation, { data, loading, error }] = useUpdateClinicalMonitoringMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clinicalMonitoring: // value for 'clinicalMonitoring'
 *   },
 * });
 */
export function useUpdateClinicalMonitoringMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicalMonitoringMutation,
    UpdateClinicalMonitoringMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicalMonitoringMutation,
    UpdateClinicalMonitoringMutationVariables
  >(UpdateClinicalMonitoringDocument, options);
}
export type UpdateClinicalMonitoringMutationHookResult = ReturnType<
  typeof useUpdateClinicalMonitoringMutation
>;
export type UpdateClinicalMonitoringMutationResult =
  Apollo.MutationResult<UpdateClinicalMonitoringMutation>;
export type UpdateClinicalMonitoringMutationOptions = Apollo.BaseMutationOptions<
  UpdateClinicalMonitoringMutation,
  UpdateClinicalMonitoringMutationVariables
>;
export const CreateConsultationDocument = gql`
  mutation CreateConsultation($newConsultation: consultation_insert_input!) {
    create_consultation(object: $newConsultation) {
      id
      patient {
        id
        userId
      }
    }
  }
`;
export type CreateConsultationMutationFn = Apollo.MutationFunction<
  CreateConsultationMutation,
  CreateConsultationMutationVariables
>;

/**
 * __useCreateConsultationMutation__
 *
 * To run a mutation, you first call `useCreateConsultationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConsultationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConsultationMutation, { data, loading, error }] = useCreateConsultationMutation({
 *   variables: {
 *      newConsultation: // value for 'newConsultation'
 *   },
 * });
 */
export function useCreateConsultationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateConsultationMutation,
    CreateConsultationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateConsultationMutation, CreateConsultationMutationVariables>(
    CreateConsultationDocument,
    options
  );
}
export type CreateConsultationMutationHookResult = ReturnType<typeof useCreateConsultationMutation>;
export type CreateConsultationMutationResult = Apollo.MutationResult<CreateConsultationMutation>;
export type CreateConsultationMutationOptions = Apollo.BaseMutationOptions<
  CreateConsultationMutation,
  CreateConsultationMutationVariables
>;
export const GetConsultationDocument = gql`
  query GetConsultation($consultationId: Int!) {
    consultation(id: $consultationId) {
      ...ConsultationCommonFields
    }
  }
  ${ConsultationCommonFieldsFragmentDoc}
`;

/**
 * __useGetConsultationQuery__
 *
 * To run a query within a React component, call `useGetConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsultationQuery({
 *   variables: {
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useGetConsultationQuery(
  baseOptions: Apollo.QueryHookOptions<GetConsultationQuery, GetConsultationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConsultationQuery, GetConsultationQueryVariables>(
    GetConsultationDocument,
    options
  );
}
export function useGetConsultationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConsultationQuery, GetConsultationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConsultationQuery, GetConsultationQueryVariables>(
    GetConsultationDocument,
    options
  );
}
export type GetConsultationQueryHookResult = ReturnType<typeof useGetConsultationQuery>;
export type GetConsultationLazyQueryHookResult = ReturnType<typeof useGetConsultationLazyQuery>;
export type GetConsultationQueryResult = Apollo.QueryResult<
  GetConsultationQuery,
  GetConsultationQueryVariables
>;
export const GetConsultationsByPatientDocument = gql`
  query GetConsultationsByPatient($patientId: Int!) {
    consultations(where: { patientId: { _eq: $patientId } }) {
      ...ConsultationCommonFields
    }
  }
  ${ConsultationCommonFieldsFragmentDoc}
`;

/**
 * __useGetConsultationsByPatientQuery__
 *
 * To run a query within a React component, call `useGetConsultationsByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsultationsByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsultationsByPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetConsultationsByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetConsultationsByPatientQuery,
    GetConsultationsByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConsultationsByPatientQuery, GetConsultationsByPatientQueryVariables>(
    GetConsultationsByPatientDocument,
    options
  );
}
export function useGetConsultationsByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetConsultationsByPatientQuery,
    GetConsultationsByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetConsultationsByPatientQuery,
    GetConsultationsByPatientQueryVariables
  >(GetConsultationsByPatientDocument, options);
}
export type GetConsultationsByPatientQueryHookResult = ReturnType<
  typeof useGetConsultationsByPatientQuery
>;
export type GetConsultationsByPatientLazyQueryHookResult = ReturnType<
  typeof useGetConsultationsByPatientLazyQuery
>;
export type GetConsultationsByPatientQueryResult = Apollo.QueryResult<
  GetConsultationsByPatientQuery,
  GetConsultationsByPatientQueryVariables
>;
export const GetLastConsultationDocument = gql`
  query GetLastConsultation($patientId: Int!) {
    consultations(
      limit: 1
      order_by: { createdAt: desc }
      where: { patientId: { _eq: $patientId }, patient: { archivedAt: { _is_null: true } } }
    ) {
      id
      xrays(order_by: { creationDate: desc }) {
        id
        url
        imageKey
        type
        elements {
          ...ElementsCommonFields
        }
      }
      operations {
        ...OperationsFields
      }
      xraysCount: xrays_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${ElementsCommonFieldsFragmentDoc}
  ${OperationsFieldsFragmentDoc}
`;

/**
 * __useGetLastConsultationQuery__
 *
 * To run a query within a React component, call `useGetLastConsultationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastConsultationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastConsultationQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetLastConsultationQuery(
  baseOptions: Apollo.QueryHookOptions<GetLastConsultationQuery, GetLastConsultationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLastConsultationQuery, GetLastConsultationQueryVariables>(
    GetLastConsultationDocument,
    options
  );
}
export function useGetLastConsultationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLastConsultationQuery,
    GetLastConsultationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLastConsultationQuery, GetLastConsultationQueryVariables>(
    GetLastConsultationDocument,
    options
  );
}
export type GetLastConsultationQueryHookResult = ReturnType<typeof useGetLastConsultationQuery>;
export type GetLastConsultationLazyQueryHookResult = ReturnType<
  typeof useGetLastConsultationLazyQuery
>;
export type GetLastConsultationQueryResult = Apollo.QueryResult<
  GetLastConsultationQuery,
  GetLastConsultationQueryVariables
>;
export const UpdateConsultationDocument = gql`
  mutation UpdateConsultation($id: Int!, $consultation: consultation_set_input!) {
    update_consultation(pk_columns: { id: $id }, _set: $consultation) {
      id
    }
  }
`;
export type UpdateConsultationMutationFn = Apollo.MutationFunction<
  UpdateConsultationMutation,
  UpdateConsultationMutationVariables
>;

/**
 * __useUpdateConsultationMutation__
 *
 * To run a mutation, you first call `useUpdateConsultationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConsultationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConsultationMutation, { data, loading, error }] = useUpdateConsultationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      consultation: // value for 'consultation'
 *   },
 * });
 */
export function useUpdateConsultationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateConsultationMutation,
    UpdateConsultationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateConsultationMutation, UpdateConsultationMutationVariables>(
    UpdateConsultationDocument,
    options
  );
}
export type UpdateConsultationMutationHookResult = ReturnType<typeof useUpdateConsultationMutation>;
export type UpdateConsultationMutationResult = Apollo.MutationResult<UpdateConsultationMutation>;
export type UpdateConsultationMutationOptions = Apollo.BaseMutationOptions<
  UpdateConsultationMutation,
  UpdateConsultationMutationVariables
>;
export const UpdatedConsultationDocument = gql`
  mutation UpdatedConsultation($id: Int!, $consultation: consultation_set_input!) {
    update_consultation(pk_columns: { id: $id }, _set: $consultation) {
      ...ConsultationCommonFields
    }
  }
  ${ConsultationCommonFieldsFragmentDoc}
`;
export type UpdatedConsultationMutationFn = Apollo.MutationFunction<
  UpdatedConsultationMutation,
  UpdatedConsultationMutationVariables
>;

/**
 * __useUpdatedConsultationMutation__
 *
 * To run a mutation, you first call `useUpdatedConsultationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatedConsultationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatedConsultationMutation, { data, loading, error }] = useUpdatedConsultationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      consultation: // value for 'consultation'
 *   },
 * });
 */
export function useUpdatedConsultationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatedConsultationMutation,
    UpdatedConsultationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatedConsultationMutation, UpdatedConsultationMutationVariables>(
    UpdatedConsultationDocument,
    options
  );
}
export type UpdatedConsultationMutationHookResult = ReturnType<
  typeof useUpdatedConsultationMutation
>;
export type UpdatedConsultationMutationResult = Apollo.MutationResult<UpdatedConsultationMutation>;
export type UpdatedConsultationMutationOptions = Apollo.BaseMutationOptions<
  UpdatedConsultationMutation,
  UpdatedConsultationMutationVariables
>;
export const GetEducationalSheetsDocument = gql`
  query GetEducationalSheets($locationId: Int_comparison_exp) {
    educationalSheets(where: { locationId: $locationId }) {
      ...educationalSheetsFragment
    }
  }
  ${EducationalSheetsFragmentFragmentDoc}
`;

/**
 * __useGetEducationalSheetsQuery__
 *
 * To run a query within a React component, call `useGetEducationalSheetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEducationalSheetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEducationalSheetsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetEducationalSheetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEducationalSheetsQuery,
    GetEducationalSheetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEducationalSheetsQuery, GetEducationalSheetsQueryVariables>(
    GetEducationalSheetsDocument,
    options
  );
}
export function useGetEducationalSheetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEducationalSheetsQuery,
    GetEducationalSheetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEducationalSheetsQuery, GetEducationalSheetsQueryVariables>(
    GetEducationalSheetsDocument,
    options
  );
}
export type GetEducationalSheetsQueryHookResult = ReturnType<typeof useGetEducationalSheetsQuery>;
export type GetEducationalSheetsLazyQueryHookResult = ReturnType<
  typeof useGetEducationalSheetsLazyQuery
>;
export type GetEducationalSheetsQueryResult = Apollo.QueryResult<
  GetEducationalSheetsQuery,
  GetEducationalSheetsQueryVariables
>;
export const ElementTypesDocument = gql`
  query ElementTypes {
    element_types {
      id
      color
      name
      isRoot
      fullId
      isRetro
    }
  }
`;

/**
 * __useElementTypesQuery__
 *
 * To run a query within a React component, call `useElementTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useElementTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useElementTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useElementTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<ElementTypesQuery, ElementTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ElementTypesQuery, ElementTypesQueryVariables>(
    ElementTypesDocument,
    options
  );
}
export function useElementTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ElementTypesQuery, ElementTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ElementTypesQuery, ElementTypesQueryVariables>(
    ElementTypesDocument,
    options
  );
}
export type ElementTypesQueryHookResult = ReturnType<typeof useElementTypesQuery>;
export type ElementTypesLazyQueryHookResult = ReturnType<typeof useElementTypesLazyQuery>;
export type ElementTypesQueryResult = Apollo.QueryResult<
  ElementTypesQuery,
  ElementTypesQueryVariables
>;
export const RegisterEventUserDocument = gql`
  mutation RegisterEventUser($eventName: String!, $patientId: Int, $consultationId: Int) {
    insert_event_user(
      objects: { event_name: $eventName, patient_id: $patientId, consultation_id: $consultationId }
    ) {
      affected_rows
    }
  }
`;
export type RegisterEventUserMutationFn = Apollo.MutationFunction<
  RegisterEventUserMutation,
  RegisterEventUserMutationVariables
>;

/**
 * __useRegisterEventUserMutation__
 *
 * To run a mutation, you first call `useRegisterEventUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterEventUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerEventUserMutation, { data, loading, error }] = useRegisterEventUserMutation({
 *   variables: {
 *      eventName: // value for 'eventName'
 *      patientId: // value for 'patientId'
 *      consultationId: // value for 'consultationId'
 *   },
 * });
 */
export function useRegisterEventUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterEventUserMutation,
    RegisterEventUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterEventUserMutation, RegisterEventUserMutationVariables>(
    RegisterEventUserDocument,
    options
  );
}
export type RegisterEventUserMutationHookResult = ReturnType<typeof useRegisterEventUserMutation>;
export type RegisterEventUserMutationResult = Apollo.MutationResult<RegisterEventUserMutation>;
export type RegisterEventUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterEventUserMutation,
  RegisterEventUserMutationVariables
>;
export const GetLocationsDocument = gql`
  query getLocations {
    locations(order_by: { title: asc }) {
      id
      title
      type
      address
      cp
      city
      phone
      isTreatmentPreferencesEnabled
      locations_users {
        user {
          id
          firstname
          lastname
          roles: users_users_roles {
            role {
              id
              value
            }
          }
        }
        isEnabled
      }
    }
  }
`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export function useGetLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(
    GetLocationsDocument,
    options
  );
}
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<
  GetLocationsQuery,
  GetLocationsQueryVariables
>;
export const GetPatientDocument = gql`
  query GetPatient($patientId: Int!) {
    patient(id: $patientId) {
      ...PatientFields
    }
  }
  ${PatientFieldsFragmentDoc}
`;

/**
 * __useGetPatientQuery__
 *
 * To run a query within a React component, call `useGetPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientQuery, GetPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientQuery, GetPatientQueryVariables>(GetPatientDocument, options);
}
export function useGetPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientQuery, GetPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientQuery, GetPatientQueryVariables>(
    GetPatientDocument,
    options
  );
}
export type GetPatientQueryHookResult = ReturnType<typeof useGetPatientQuery>;
export type GetPatientLazyQueryHookResult = ReturnType<typeof useGetPatientLazyQuery>;
export type GetPatientQueryResult = Apollo.QueryResult<GetPatientQuery, GetPatientQueryVariables>;
export const GetPatientXraysDocument = gql`
  query GetPatientXrays($patientId: Int!) {
    xrays: xrays(
      order_by: { creationDate: desc }
      where: { consultation: { patientId: { _eq: $patientId } } }
    ) {
      ...xraysFields
    }
  }
  ${XraysFieldsFragmentDoc}
`;

/**
 * __useGetPatientXraysQuery__
 *
 * To run a query within a React component, call `useGetPatientXraysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientXraysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientXraysQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientXraysQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientXraysQuery, GetPatientXraysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientXraysQuery, GetPatientXraysQueryVariables>(
    GetPatientXraysDocument,
    options
  );
}
export function useGetPatientXraysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientXraysQuery, GetPatientXraysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientXraysQuery, GetPatientXraysQueryVariables>(
    GetPatientXraysDocument,
    options
  );
}
export type GetPatientXraysQueryHookResult = ReturnType<typeof useGetPatientXraysQuery>;
export type GetPatientXraysLazyQueryHookResult = ReturnType<typeof useGetPatientXraysLazyQuery>;
export type GetPatientXraysQueryResult = Apollo.QueryResult<
  GetPatientXraysQuery,
  GetPatientXraysQueryVariables
>;
export const GetRolesDocument = gql`
  query GetRoles {
    roles(
      where: { value: { _in: ["DENTIST", "DIRECTOR", "ASSISTANT", "SECRETARY", "ACCOUNTANT"] } }
      order_by: { value: asc }
    ) {
      id
      value
    }
  }
`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export function useGetRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
}
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const UpdateLocationByIdDocument = gql`
  mutation UpdateLocationById($locationId: Int!, $data: locations_set_input!) {
    update_locations_by_pk(pk_columns: { id: $locationId }, _set: $data) {
      id
      title
      address
      cp
      city
      image
      phone
    }
  }
`;
export type UpdateLocationByIdMutationFn = Apollo.MutationFunction<
  UpdateLocationByIdMutation,
  UpdateLocationByIdMutationVariables
>;

/**
 * __useUpdateLocationByIdMutation__
 *
 * To run a mutation, you first call `useUpdateLocationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationByIdMutation, { data, loading, error }] = useUpdateLocationByIdMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLocationByIdMutation,
    UpdateLocationByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLocationByIdMutation, UpdateLocationByIdMutationVariables>(
    UpdateLocationByIdDocument,
    options
  );
}
export type UpdateLocationByIdMutationHookResult = ReturnType<typeof useUpdateLocationByIdMutation>;
export type UpdateLocationByIdMutationResult = Apollo.MutationResult<UpdateLocationByIdMutation>;
export type UpdateLocationByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationByIdMutation,
  UpdateLocationByIdMutationVariables
>;
export const LocationByIdDocument = gql`
  query LocationById($locationId: Int!, $userId: Int, $search: String) {
    location: locations_by_pk(id: $locationId) {
      id
      title
      address
      city
      cp
      country
      phone
      image
      version
      locations_users(
        where: {
          user_id: { _neq: $userId }
          user: {
            _and: [
              { users_users_roles: { role: { value: { _neq: "HEAD_OF" } } } }
              {
                _or: [
                  { firstname: { _ilike: $search } }
                  { lastname: { _ilike: $search } }
                  { email: { _ilike: $search } }
                  { rpps_number: { _ilike: $search } }
                ]
              }
            ]
          }
        }
        order_by: { user: { created_at: desc } }
      ) {
        isEnabled
        user {
          id
          lastname
          firstname
          email
          created_at
          rpps_number
          is_validated
          roles: users_users_roles {
            role {
              id
              value
            }
          }
        }
      }
    }
    headof: users(
      where: {
        users_users_locations: { location_id: { _eq: $locationId } }
        users_users_roles: { role: { value: { _eq: "HEAD_OF" } } }
      }
    ) {
      id
      firstname
      lastname
    }
    director: users(
      where: {
        users_users_locations: { location_id: { _eq: $locationId } }
        users_users_roles: { role: { value: { _eq: "HEAD_OF" } } }
      }
    ) {
      id
      firstname
      lastname
    }
  }
`;

/**
 * __useLocationByIdQuery__
 *
 * To run a query within a React component, call `useLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationByIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useLocationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<LocationByIdQuery, LocationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocationByIdQuery, LocationByIdQueryVariables>(
    LocationByIdDocument,
    options
  );
}
export function useLocationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LocationByIdQuery, LocationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocationByIdQuery, LocationByIdQueryVariables>(
    LocationByIdDocument,
    options
  );
}
export type LocationByIdQueryHookResult = ReturnType<typeof useLocationByIdQuery>;
export type LocationByIdLazyQueryHookResult = ReturnType<typeof useLocationByIdLazyQuery>;
export type LocationByIdQueryResult = Apollo.QueryResult<
  LocationByIdQuery,
  LocationByIdQueryVariables
>;
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($id: Int!) {
    updateNotification(pk_columns: { id: $id }, _set: { viewed: true }) {
      id
    }
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(
    DeleteNotificationDocument,
    options
  );
}
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const InsertNotificationDocument = gql`
  mutation insertNotification($name: notifications_type_enum!, $userId: Int!, $url: String!) {
    insertNotification(object: { name: $name, userId: $userId, url: $url }) {
      id
    }
  }
`;
export type InsertNotificationMutationFn = Apollo.MutationFunction<
  InsertNotificationMutation,
  InsertNotificationMutationVariables
>;

/**
 * __useInsertNotificationMutation__
 *
 * To run a mutation, you first call `useInsertNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertNotificationMutation, { data, loading, error }] = useInsertNotificationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      userId: // value for 'userId'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useInsertNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertNotificationMutation,
    InsertNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertNotificationMutation, InsertNotificationMutationVariables>(
    InsertNotificationDocument,
    options
  );
}
export type InsertNotificationMutationHookResult = ReturnType<typeof useInsertNotificationMutation>;
export type InsertNotificationMutationResult = Apollo.MutationResult<InsertNotificationMutation>;
export type InsertNotificationMutationOptions = Apollo.BaseMutationOptions<
  InsertNotificationMutation,
  InsertNotificationMutationVariables
>;
export const SubscribeNotificationsDocument = gql`
  subscription subscribeNotifications {
    notifications {
      id
      name
      url
    }
  }
`;

/**
 * __useSubscribeNotificationsSubscription__
 *
 * To run a query within a React component, call `useSubscribeNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeNotificationsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SubscribeNotificationsSubscription,
    SubscribeNotificationsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    SubscribeNotificationsSubscription,
    SubscribeNotificationsSubscriptionVariables
  >(SubscribeNotificationsDocument, options);
}
export type SubscribeNotificationsSubscriptionHookResult = ReturnType<
  typeof useSubscribeNotificationsSubscription
>;
export type SubscribeNotificationsSubscriptionResult =
  Apollo.SubscriptionResult<SubscribeNotificationsSubscription>;
export const CreateObservationDocument = gql`
  mutation CreateObservation($observation: observations_insert_input!) {
    addObservation(object: $observation) {
      id
    }
  }
`;
export type CreateObservationMutationFn = Apollo.MutationFunction<
  CreateObservationMutation,
  CreateObservationMutationVariables
>;

/**
 * __useCreateObservationMutation__
 *
 * To run a mutation, you first call `useCreateObservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateObservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createObservationMutation, { data, loading, error }] = useCreateObservationMutation({
 *   variables: {
 *      observation: // value for 'observation'
 *   },
 * });
 */
export function useCreateObservationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateObservationMutation,
    CreateObservationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObservationMutation, CreateObservationMutationVariables>(
    CreateObservationDocument,
    options
  );
}
export type CreateObservationMutationHookResult = ReturnType<typeof useCreateObservationMutation>;
export type CreateObservationMutationResult = Apollo.MutationResult<CreateObservationMutation>;
export type CreateObservationMutationOptions = Apollo.BaseMutationOptions<
  CreateObservationMutation,
  CreateObservationMutationVariables
>;
export const GetObservationsDocument = gql`
  query GetObservations($patientId: Int!) {
    getObservations(where: { patientId: { _eq: $patientId } }, order_by: { createdAt: desc }) {
      observations
      createdAt
    }
  }
`;

/**
 * __useGetObservationsQuery__
 *
 * To run a query within a React component, call `useGetObservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObservationsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetObservationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetObservationsQuery, GetObservationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetObservationsQuery, GetObservationsQueryVariables>(
    GetObservationsDocument,
    options
  );
}
export function useGetObservationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetObservationsQuery, GetObservationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetObservationsQuery, GetObservationsQueryVariables>(
    GetObservationsDocument,
    options
  );
}
export type GetObservationsQueryHookResult = ReturnType<typeof useGetObservationsQuery>;
export type GetObservationsLazyQueryHookResult = ReturnType<typeof useGetObservationsLazyQuery>;
export type GetObservationsQueryResult = Apollo.QueryResult<
  GetObservationsQuery,
  GetObservationsQueryVariables
>;
export const OperationTypesDocument = gql`
  query OperationTypes {
    operation_types(order_by: { defaultOrder: asc }) {
      id
      color
      name
      allowed_teeth
    }
  }
`;

/**
 * __useOperationTypesQuery__
 *
 * To run a query within a React component, call `useOperationTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperationTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<OperationTypesQuery, OperationTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OperationTypesQuery, OperationTypesQueryVariables>(
    OperationTypesDocument,
    options
  );
}
export function useOperationTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OperationTypesQuery, OperationTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OperationTypesQuery, OperationTypesQueryVariables>(
    OperationTypesDocument,
    options
  );
}
export type OperationTypesQueryHookResult = ReturnType<typeof useOperationTypesQuery>;
export type OperationTypesLazyQueryHookResult = ReturnType<typeof useOperationTypesLazyQuery>;
export type OperationTypesQueryResult = Apollo.QueryResult<
  OperationTypesQuery,
  OperationTypesQueryVariables
>;
export const GetPatientPartnerByPkDocument = gql`
  query GetPatientPartnerByPk($patientPartnerId: uuid!) {
    patients_partners_by_pk(id: $patientPartnerId) {
      data
      patient {
        id
        firstname
        lastname
        birthdate
        sex
        email
        locationId
        userId
        archivedAt
        consultations(order_by: { consultationDate: desc }) {
          id
        }
      }
    }
  }
`;

/**
 * __useGetPatientPartnerByPkQuery__
 *
 * To run a query within a React component, call `useGetPatientPartnerByPkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientPartnerByPkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientPartnerByPkQuery({
 *   variables: {
 *      patientPartnerId: // value for 'patientPartnerId'
 *   },
 * });
 */
export function useGetPatientPartnerByPkQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientPartnerByPkQuery,
    GetPatientPartnerByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientPartnerByPkQuery, GetPatientPartnerByPkQueryVariables>(
    GetPatientPartnerByPkDocument,
    options
  );
}
export function useGetPatientPartnerByPkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientPartnerByPkQuery,
    GetPatientPartnerByPkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientPartnerByPkQuery, GetPatientPartnerByPkQueryVariables>(
    GetPatientPartnerByPkDocument,
    options
  );
}
export type GetPatientPartnerByPkQueryHookResult = ReturnType<typeof useGetPatientPartnerByPkQuery>;
export type GetPatientPartnerByPkLazyQueryHookResult = ReturnType<
  typeof useGetPatientPartnerByPkLazyQuery
>;
export type GetPatientPartnerByPkQueryResult = Apollo.QueryResult<
  GetPatientPartnerByPkQuery,
  GetPatientPartnerByPkQueryVariables
>;
export const GetPatientPartnerIdDocument = gql`
  query GetPatientPartnerId($patientId: Int!) {
    patient(id: $patientId) {
      patients_partners {
        partnerId
      }
    }
  }
`;

/**
 * __useGetPatientPartnerIdQuery__
 *
 * To run a query within a React component, call `useGetPatientPartnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientPartnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientPartnerIdQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientPartnerIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientPartnerIdQuery, GetPatientPartnerIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientPartnerIdQuery, GetPatientPartnerIdQueryVariables>(
    GetPatientPartnerIdDocument,
    options
  );
}
export function useGetPatientPartnerIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientPartnerIdQuery,
    GetPatientPartnerIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientPartnerIdQuery, GetPatientPartnerIdQueryVariables>(
    GetPatientPartnerIdDocument,
    options
  );
}
export type GetPatientPartnerIdQueryHookResult = ReturnType<typeof useGetPatientPartnerIdQuery>;
export type GetPatientPartnerIdLazyQueryHookResult = ReturnType<
  typeof useGetPatientPartnerIdLazyQuery
>;
export type GetPatientPartnerIdQueryResult = Apollo.QueryResult<
  GetPatientPartnerIdQuery,
  GetPatientPartnerIdQueryVariables
>;
export const UpdateLastSentDateDocument = gql`
  mutation updateLastSentDate($id: Int!, $date: timestamptz!) {
    update_consultation(pk_columns: { id: $id }, _set: { last_sent_date: $date }) {
      updatedAt
      last_sent_date
      __typename
    }
  }
`;
export type UpdateLastSentDateMutationFn = Apollo.MutationFunction<
  UpdateLastSentDateMutation,
  UpdateLastSentDateMutationVariables
>;

/**
 * __useUpdateLastSentDateMutation__
 *
 * To run a mutation, you first call `useUpdateLastSentDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLastSentDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLastSentDateMutation, { data, loading, error }] = useUpdateLastSentDateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateLastSentDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLastSentDateMutation,
    UpdateLastSentDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLastSentDateMutation, UpdateLastSentDateMutationVariables>(
    UpdateLastSentDateDocument,
    options
  );
}
export type UpdateLastSentDateMutationHookResult = ReturnType<typeof useUpdateLastSentDateMutation>;
export type UpdateLastSentDateMutationResult = Apollo.MutationResult<UpdateLastSentDateMutation>;
export type UpdateLastSentDateMutationOptions = Apollo.BaseMutationOptions<
  UpdateLastSentDateMutation,
  UpdateLastSentDateMutationVariables
>;
export const CreatePatientDocument = gql`
  mutation CreatePatient($firstname: String!, $lastname: String!, $userId: Int, $locationId: Int) {
    insertPatient(
      object: {
        firstname: $firstname
        lastname: $lastname
        locationId: $locationId
        userId: $userId
      }
    ) {
      id
    }
  }
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      userId: // value for 'userId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(
    CreatePatientDocument,
    options
  );
}
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const GetPatientFilesDocument = gql`
  query GetPatientFiles(
    $offset: Int
    $limit: Int
    $filter: patients_bool_exp
    $orderBy: [patients_order_by!]
  ) {
    patients(offset: $offset, limit: $limit, order_by: $orderBy, where: $filter) {
      ...SearchedPatients
    }
    patientsAggregate(where: $filter) {
      aggregate {
        count
      }
    }
  }
  ${SearchedPatientsFragmentDoc}
`;

/**
 * __useGetPatientFilesQuery__
 *
 * To run a query within a React component, call `useGetPatientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientFilesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPatientFilesQuery, GetPatientFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientFilesQuery, GetPatientFilesQueryVariables>(
    GetPatientFilesDocument,
    options
  );
}
export function useGetPatientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientFilesQuery, GetPatientFilesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientFilesQuery, GetPatientFilesQueryVariables>(
    GetPatientFilesDocument,
    options
  );
}
export type GetPatientFilesQueryHookResult = ReturnType<typeof useGetPatientFilesQuery>;
export type GetPatientFilesLazyQueryHookResult = ReturnType<typeof useGetPatientFilesLazyQuery>;
export type GetPatientFilesQueryResult = Apollo.QueryResult<
  GetPatientFilesQuery,
  GetPatientFilesQueryVariables
>;
export const GetPatientsDocument = gql`
  query getPatients($where: patients_bool_exp) {
    patients(limit: 15, where: $where, order_by: { createdAt: desc }) {
      id
      createdAt
      firstname
      lastname
      usersViewed
      consultations(limit: 1, order_by: { createdAt: desc }) {
        id
      }
      locationId
    }
  }
`;

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPatientsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options);
}
export function useGetPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(
    GetPatientsDocument,
    options
  );
}
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>;
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>;
export type GetPatientsQueryResult = Apollo.QueryResult<
  GetPatientsQuery,
  GetPatientsQueryVariables
>;
export const GetStatsPatientFilesDocument = gql`
  query GetStatsPatientFiles($where: patients_bool_exp) {
    patients(where: $where, order_by: { createdAt: desc }) {
      ...SearchedPatients
    }
  }
  ${SearchedPatientsFragmentDoc}
`;

/**
 * __useGetStatsPatientFilesQuery__
 *
 * To run a query within a React component, call `useGetStatsPatientFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsPatientFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsPatientFilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetStatsPatientFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStatsPatientFilesQuery,
    GetStatsPatientFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsPatientFilesQuery, GetStatsPatientFilesQueryVariables>(
    GetStatsPatientFilesDocument,
    options
  );
}
export function useGetStatsPatientFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStatsPatientFilesQuery,
    GetStatsPatientFilesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsPatientFilesQuery, GetStatsPatientFilesQueryVariables>(
    GetStatsPatientFilesDocument,
    options
  );
}
export type GetStatsPatientFilesQueryHookResult = ReturnType<typeof useGetStatsPatientFilesQuery>;
export type GetStatsPatientFilesLazyQueryHookResult = ReturnType<
  typeof useGetStatsPatientFilesLazyQuery
>;
export type GetStatsPatientFilesQueryResult = Apollo.QueryResult<
  GetStatsPatientFilesQuery,
  GetStatsPatientFilesQueryVariables
>;
export const UpdatePatientDocument = gql`
  mutation UpdatePatient($patientId: Int!, $patient: patients_set_input) {
    updatePatient(pk_columns: { id: $patientId }, _set: $patient) {
      id
      email
      firstname
      lastname
      archivedAt
      user {
        lastname
      }
    }
  }
`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(
    UpdatePatientDocument,
    options
  );
}
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const UpdatePatientWithTreatmentDocument = gql`
  mutation UpdatePatientWithTreatment(
    $patientId: Int!
    $consultationId: Int!
    $patient: patients_set_input
    $consultationDate: timestamptz!
    $archivedAt: timestamptz
  ) {
    updatePatient(pk_columns: { id: $patientId }, _set: $patient) {
      id
      email
      firstname
      lastname
      archivedAt
      user {
        lastname
      }
    }
    update_consultation(
      pk_columns: { id: $consultationId }
      _set: { consultationDate: $consultationDate }
    ) {
      id
      consultationDate
    }
  }
`;
export type UpdatePatientWithTreatmentMutationFn = Apollo.MutationFunction<
  UpdatePatientWithTreatmentMutation,
  UpdatePatientWithTreatmentMutationVariables
>;

/**
 * __useUpdatePatientWithTreatmentMutation__
 *
 * To run a mutation, you first call `useUpdatePatientWithTreatmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientWithTreatmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientWithTreatmentMutation, { data, loading, error }] = useUpdatePatientWithTreatmentMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      consultationId: // value for 'consultationId'
 *      patient: // value for 'patient'
 *      consultationDate: // value for 'consultationDate'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useUpdatePatientWithTreatmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientWithTreatmentMutation,
    UpdatePatientWithTreatmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientWithTreatmentMutation,
    UpdatePatientWithTreatmentMutationVariables
  >(UpdatePatientWithTreatmentDocument, options);
}
export type UpdatePatientWithTreatmentMutationHookResult = ReturnType<
  typeof useUpdatePatientWithTreatmentMutation
>;
export type UpdatePatientWithTreatmentMutationResult =
  Apollo.MutationResult<UpdatePatientWithTreatmentMutation>;
export type UpdatePatientWithTreatmentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientWithTreatmentMutation,
  UpdatePatientWithTreatmentMutationVariables
>;
export const UpdatePatientInformationDocument = gql`
  mutation UpdatePatientInformation(
    $patientId: Int!
    $patient: patients_set_input
    $archivedAt: timestamptz
  ) {
    updatePatient(pk_columns: { id: $patientId }, _set: $patient) {
      id
      email
      firstname
      lastname
      archivedAt
    }
  }
`;
export type UpdatePatientInformationMutationFn = Apollo.MutationFunction<
  UpdatePatientInformationMutation,
  UpdatePatientInformationMutationVariables
>;

/**
 * __useUpdatePatientInformationMutation__
 *
 * To run a mutation, you first call `useUpdatePatientInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientInformationMutation, { data, loading, error }] = useUpdatePatientInformationMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      patient: // value for 'patient'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useUpdatePatientInformationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientInformationMutation,
    UpdatePatientInformationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientInformationMutation,
    UpdatePatientInformationMutationVariables
  >(UpdatePatientInformationDocument, options);
}
export type UpdatePatientInformationMutationHookResult = ReturnType<
  typeof useUpdatePatientInformationMutation
>;
export type UpdatePatientInformationMutationResult =
  Apollo.MutationResult<UpdatePatientInformationMutation>;
export type UpdatePatientInformationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientInformationMutation,
  UpdatePatientInformationMutationVariables
>;
export const UpdatePatientUsersViewedDocument = gql`
  mutation UpdatePatientUsersViewed($patientId: Int!, $usersViewed: json) {
    updatePatient(pk_columns: { id: $patientId }, _set: { usersViewed: $usersViewed }) {
      id
      usersViewed
    }
  }
`;
export type UpdatePatientUsersViewedMutationFn = Apollo.MutationFunction<
  UpdatePatientUsersViewedMutation,
  UpdatePatientUsersViewedMutationVariables
>;

/**
 * __useUpdatePatientUsersViewedMutation__
 *
 * To run a mutation, you first call `useUpdatePatientUsersViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientUsersViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientUsersViewedMutation, { data, loading, error }] = useUpdatePatientUsersViewedMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      usersViewed: // value for 'usersViewed'
 *   },
 * });
 */
export function useUpdatePatientUsersViewedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientUsersViewedMutation,
    UpdatePatientUsersViewedMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientUsersViewedMutation,
    UpdatePatientUsersViewedMutationVariables
  >(UpdatePatientUsersViewedDocument, options);
}
export type UpdatePatientUsersViewedMutationHookResult = ReturnType<
  typeof useUpdatePatientUsersViewedMutation
>;
export type UpdatePatientUsersViewedMutationResult =
  Apollo.MutationResult<UpdatePatientUsersViewedMutation>;
export type UpdatePatientUsersViewedMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientUsersViewedMutation,
  UpdatePatientUsersViewedMutationVariables
>;
export const GetTreatmentPreferencesDocument = gql`
  query GetTreatmentPreferences($userId: Int!) {
    treatment_preferences(where: { userId: { _eq: $userId } }) {
      treatmentPreferences
      id
    }
  }
`;

/**
 * __useGetTreatmentPreferencesQuery__
 *
 * To run a query within a React component, call `useGetTreatmentPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreatmentPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreatmentPreferencesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTreatmentPreferencesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTreatmentPreferencesQuery,
    GetTreatmentPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTreatmentPreferencesQuery, GetTreatmentPreferencesQueryVariables>(
    GetTreatmentPreferencesDocument,
    options
  );
}
export function useGetTreatmentPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTreatmentPreferencesQuery,
    GetTreatmentPreferencesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTreatmentPreferencesQuery, GetTreatmentPreferencesQueryVariables>(
    GetTreatmentPreferencesDocument,
    options
  );
}
export type GetTreatmentPreferencesQueryHookResult = ReturnType<
  typeof useGetTreatmentPreferencesQuery
>;
export type GetTreatmentPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetTreatmentPreferencesLazyQuery
>;
export type GetTreatmentPreferencesQueryResult = Apollo.QueryResult<
  GetTreatmentPreferencesQuery,
  GetTreatmentPreferencesQueryVariables
>;
export const InsertTreatmentPreferencesDocument = gql`
  mutation InsertTreatmentPreferences($treatmentPreferences: treatment_preferences_insert_input!) {
    insert_treatment_preferences_one(object: $treatmentPreferences) {
      userId
      treatmentPreferences
    }
  }
`;
export type InsertTreatmentPreferencesMutationFn = Apollo.MutationFunction<
  InsertTreatmentPreferencesMutation,
  InsertTreatmentPreferencesMutationVariables
>;

/**
 * __useInsertTreatmentPreferencesMutation__
 *
 * To run a mutation, you first call `useInsertTreatmentPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertTreatmentPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertTreatmentPreferencesMutation, { data, loading, error }] = useInsertTreatmentPreferencesMutation({
 *   variables: {
 *      treatmentPreferences: // value for 'treatmentPreferences'
 *   },
 * });
 */
export function useInsertTreatmentPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertTreatmentPreferencesMutation,
    InsertTreatmentPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertTreatmentPreferencesMutation,
    InsertTreatmentPreferencesMutationVariables
  >(InsertTreatmentPreferencesDocument, options);
}
export type InsertTreatmentPreferencesMutationHookResult = ReturnType<
  typeof useInsertTreatmentPreferencesMutation
>;
export type InsertTreatmentPreferencesMutationResult =
  Apollo.MutationResult<InsertTreatmentPreferencesMutation>;
export type InsertTreatmentPreferencesMutationOptions = Apollo.BaseMutationOptions<
  InsertTreatmentPreferencesMutation,
  InsertTreatmentPreferencesMutationVariables
>;
export const UpdateMultipleTreatmentPreferencesDocument = gql`
  mutation UpdateMultipleTreatmentPreferences($userIds: [Int!], $treatmentPreferences: jsonb) {
    update_treatment_preferences_many(
      updates: {
        where: { userId: { _in: $userIds } }
        _set: { treatmentPreferences: $treatmentPreferences }
      }
    ) {
      affected_rows
    }
  }
`;
export type UpdateMultipleTreatmentPreferencesMutationFn = Apollo.MutationFunction<
  UpdateMultipleTreatmentPreferencesMutation,
  UpdateMultipleTreatmentPreferencesMutationVariables
>;

/**
 * __useUpdateMultipleTreatmentPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateMultipleTreatmentPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultipleTreatmentPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultipleTreatmentPreferencesMutation, { data, loading, error }] = useUpdateMultipleTreatmentPreferencesMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      treatmentPreferences: // value for 'treatmentPreferences'
 *   },
 * });
 */
export function useUpdateMultipleTreatmentPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMultipleTreatmentPreferencesMutation,
    UpdateMultipleTreatmentPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMultipleTreatmentPreferencesMutation,
    UpdateMultipleTreatmentPreferencesMutationVariables
  >(UpdateMultipleTreatmentPreferencesDocument, options);
}
export type UpdateMultipleTreatmentPreferencesMutationHookResult = ReturnType<
  typeof useUpdateMultipleTreatmentPreferencesMutation
>;
export type UpdateMultipleTreatmentPreferencesMutationResult =
  Apollo.MutationResult<UpdateMultipleTreatmentPreferencesMutation>;
export type UpdateMultipleTreatmentPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateMultipleTreatmentPreferencesMutation,
  UpdateMultipleTreatmentPreferencesMutationVariables
>;
export const UpdateToggleDocument = gql`
  mutation UpdateToggle($locationId: Int!, $enabled: Boolean) {
    update_locations_by_pk(
      pk_columns: { id: $locationId }
      _set: { isTreatmentPreferencesEnabled: $enabled }
    ) {
      id
      isTreatmentPreferencesEnabled
    }
  }
`;
export type UpdateToggleMutationFn = Apollo.MutationFunction<
  UpdateToggleMutation,
  UpdateToggleMutationVariables
>;

/**
 * __useUpdateToggleMutation__
 *
 * To run a mutation, you first call `useUpdateToggleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateToggleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateToggleMutation, { data, loading, error }] = useUpdateToggleMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useUpdateToggleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateToggleMutation, UpdateToggleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateToggleMutation, UpdateToggleMutationVariables>(
    UpdateToggleDocument,
    options
  );
}
export type UpdateToggleMutationHookResult = ReturnType<typeof useUpdateToggleMutation>;
export type UpdateToggleMutationResult = Apollo.MutationResult<UpdateToggleMutation>;
export type UpdateToggleMutationOptions = Apollo.BaseMutationOptions<
  UpdateToggleMutation,
  UpdateToggleMutationVariables
>;
export const UpdateTreatmentPreferencesDocument = gql`
  mutation UpdateTreatmentPreferences($userId: Int!, $treatmentPreferences: jsonb) {
    update_treatment_preferences(
      where: { userId: { _eq: $userId } }
      _set: { treatmentPreferences: $treatmentPreferences }
    ) {
      affected_rows
    }
  }
`;
export type UpdateTreatmentPreferencesMutationFn = Apollo.MutationFunction<
  UpdateTreatmentPreferencesMutation,
  UpdateTreatmentPreferencesMutationVariables
>;

/**
 * __useUpdateTreatmentPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateTreatmentPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTreatmentPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTreatmentPreferencesMutation, { data, loading, error }] = useUpdateTreatmentPreferencesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      treatmentPreferences: // value for 'treatmentPreferences'
 *   },
 * });
 */
export function useUpdateTreatmentPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTreatmentPreferencesMutation,
    UpdateTreatmentPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTreatmentPreferencesMutation,
    UpdateTreatmentPreferencesMutationVariables
  >(UpdateTreatmentPreferencesDocument, options);
}
export type UpdateTreatmentPreferencesMutationHookResult = ReturnType<
  typeof useUpdateTreatmentPreferencesMutation
>;
export type UpdateTreatmentPreferencesMutationResult =
  Apollo.MutationResult<UpdateTreatmentPreferencesMutation>;
export type UpdateTreatmentPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTreatmentPreferencesMutation,
  UpdateTreatmentPreferencesMutationVariables
>;
export const CreateUserDocument = gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(userData: $user) {
      id
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser($email: String!) {
    users(where: { email: { _ilike: $email } }) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($userId: Int!, $data: users_set_input!) {
    update_users_by_pk(pk_columns: { id: $userId }, _set: $data) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UserDocument = gql`
  query User($userId: Int!) {
    users_by_pk(id: $userId) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
  query Users($withLocations: Boolean!, $withRoles: Boolean!, $where: users_bool_exp) {
    users(where: $where, order_by: { lastname: asc }) {
      id
      lastname
      firstname
      email
      image
      rpps_number
      dentistSpeciality
      locations: users_users_locations @include(if: $withLocations) {
        locationId: location_id
        isEnabled
      }
      roles: users_users_roles @include(if: $withRoles) {
        role {
          id
          value
        }
      }
    }
  }
`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      withLocations: // value for 'withLocations'
 *      withRoles: // value for 'withRoles'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export function useUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const AddUserToLocationDocument = gql`
  mutation AddUserToLocation($locationId: Int!, $userId: Int!) {
    insert_users_locations_one(object: { location_id: $locationId, user_id: $userId }) {
      user_id
      location_id
    }
  }
`;
export type AddUserToLocationMutationFn = Apollo.MutationFunction<
  AddUserToLocationMutation,
  AddUserToLocationMutationVariables
>;

/**
 * __useAddUserToLocationMutation__
 *
 * To run a mutation, you first call `useAddUserToLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToLocationMutation, { data, loading, error }] = useAddUserToLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserToLocationMutation,
    AddUserToLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUserToLocationMutation, AddUserToLocationMutationVariables>(
    AddUserToLocationDocument,
    options
  );
}
export type AddUserToLocationMutationHookResult = ReturnType<typeof useAddUserToLocationMutation>;
export type AddUserToLocationMutationResult = Apollo.MutationResult<AddUserToLocationMutation>;
export type AddUserToLocationMutationOptions = Apollo.BaseMutationOptions<
  AddUserToLocationMutation,
  AddUserToLocationMutationVariables
>;
export const UpdateUserLocationDocument = gql`
  mutation UpdateUserLocation($locationId: Int!, $userId: Int!, $set: users_locations_set_input) {
    update_users_locations_by_pk(
      pk_columns: { location_id: $locationId, user_id: $userId }
      _set: $set
    ) {
      location_id
      user_id
      isEnabled
    }
  }
`;
export type UpdateUserLocationMutationFn = Apollo.MutationFunction<
  UpdateUserLocationMutation,
  UpdateUserLocationMutationVariables
>;

/**
 * __useUpdateUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocationMutation, { data, loading, error }] = useUpdateUserLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      userId: // value for 'userId'
 *      set: // value for 'set'
 *   },
 * });
 */
export function useUpdateUserLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserLocationMutation,
    UpdateUserLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>(
    UpdateUserLocationDocument,
    options
  );
}
export type UpdateUserLocationMutationHookResult = ReturnType<typeof useUpdateUserLocationMutation>;
export type UpdateUserLocationMutationResult = Apollo.MutationResult<UpdateUserLocationMutation>;
export type UpdateUserLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserLocationMutation,
  UpdateUserLocationMutationVariables
>;
export const InsertUsersRolesDocument = gql`
  mutation insertUsersRoles($usersRoles: [users_roles_insert_input!]!) {
    insert_users_roles(
      objects: $usersRoles
      on_conflict: { constraint: users_roles_pkey, update_columns: [] }
    ) {
      affected_rows
    }
  }
`;
export type InsertUsersRolesMutationFn = Apollo.MutationFunction<
  InsertUsersRolesMutation,
  InsertUsersRolesMutationVariables
>;

/**
 * __useInsertUsersRolesMutation__
 *
 * To run a mutation, you first call `useInsertUsersRolesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertUsersRolesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertUsersRolesMutation, { data, loading, error }] = useInsertUsersRolesMutation({
 *   variables: {
 *      usersRoles: // value for 'usersRoles'
 *   },
 * });
 */
export function useInsertUsersRolesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertUsersRolesMutation,
    InsertUsersRolesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertUsersRolesMutation, InsertUsersRolesMutationVariables>(
    InsertUsersRolesDocument,
    options
  );
}
export type InsertUsersRolesMutationHookResult = ReturnType<typeof useInsertUsersRolesMutation>;
export type InsertUsersRolesMutationResult = Apollo.MutationResult<InsertUsersRolesMutation>;
export type InsertUsersRolesMutationOptions = Apollo.BaseMutationOptions<
  InsertUsersRolesMutation,
  InsertUsersRolesMutationVariables
>;
export const DeleteXRayElementsDocument = gql`
  mutation DeleteXRayElements($elementIds: [Int!]!) {
    delete_elements(where: { id: { _in: $elementIds } }) {
      affected_rows
    }
  }
`;
export type DeleteXRayElementsMutationFn = Apollo.MutationFunction<
  DeleteXRayElementsMutation,
  DeleteXRayElementsMutationVariables
>;

/**
 * __useDeleteXRayElementsMutation__
 *
 * To run a mutation, you first call `useDeleteXRayElementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteXRayElementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteXRayElementsMutation, { data, loading, error }] = useDeleteXRayElementsMutation({
 *   variables: {
 *      elementIds: // value for 'elementIds'
 *   },
 * });
 */
export function useDeleteXRayElementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteXRayElementsMutation,
    DeleteXRayElementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteXRayElementsMutation, DeleteXRayElementsMutationVariables>(
    DeleteXRayElementsDocument,
    options
  );
}
export type DeleteXRayElementsMutationHookResult = ReturnType<typeof useDeleteXRayElementsMutation>;
export type DeleteXRayElementsMutationResult = Apollo.MutationResult<DeleteXRayElementsMutation>;
export type DeleteXRayElementsMutationOptions = Apollo.BaseMutationOptions<
  DeleteXRayElementsMutation,
  DeleteXRayElementsMutationVariables
>;
export const GetXrayLightDocument = gql`
  query getXrayLight($id: uuid!) {
    xray(id: $id) {
      id
      consultation {
        id
        patientId
      }
    }
  }
`;

/**
 * __useGetXrayLightQuery__
 *
 * To run a query within a React component, call `useGetXrayLightQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetXrayLightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetXrayLightQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetXrayLightQuery(
  baseOptions: Apollo.QueryHookOptions<GetXrayLightQuery, GetXrayLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetXrayLightQuery, GetXrayLightQueryVariables>(
    GetXrayLightDocument,
    options
  );
}
export function useGetXrayLightLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetXrayLightQuery, GetXrayLightQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetXrayLightQuery, GetXrayLightQueryVariables>(
    GetXrayLightDocument,
    options
  );
}
export type GetXrayLightQueryHookResult = ReturnType<typeof useGetXrayLightQuery>;
export type GetXrayLightLazyQueryHookResult = ReturnType<typeof useGetXrayLightLazyQuery>;
export type GetXrayLightQueryResult = Apollo.QueryResult<
  GetXrayLightQuery,
  GetXrayLightQueryVariables
>;
export const InsertXRayDocument = gql`
  mutation InsertXRay($newXRay: xrays_insert_input!) {
    insertXray(object: $newXRay) {
      id
      url
      creationDate
      consultationId
    }
  }
`;
export type InsertXRayMutationFn = Apollo.MutationFunction<
  InsertXRayMutation,
  InsertXRayMutationVariables
>;

/**
 * __useInsertXRayMutation__
 *
 * To run a mutation, you first call `useInsertXRayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertXRayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertXRayMutation, { data, loading, error }] = useInsertXRayMutation({
 *   variables: {
 *      newXRay: // value for 'newXRay'
 *   },
 * });
 */
export function useInsertXRayMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertXRayMutation, InsertXRayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertXRayMutation, InsertXRayMutationVariables>(
    InsertXRayDocument,
    options
  );
}
export type InsertXRayMutationHookResult = ReturnType<typeof useInsertXRayMutation>;
export type InsertXRayMutationResult = Apollo.MutationResult<InsertXRayMutation>;
export type InsertXRayMutationOptions = Apollo.BaseMutationOptions<
  InsertXRayMutation,
  InsertXRayMutationVariables
>;
export const InsertXrayElementsDocument = gql`
  mutation InsertXrayElements($elements: [elements_insert_input!]!) {
    insert_elements(
      objects: $elements
      on_conflict: {
        constraint: elements_pkey
        update_columns: [coordinates, detectionScore, editedByUser, onTooth, typeId, fullId]
      }
    ) {
      affected_rows
    }
  }
`;
export type InsertXrayElementsMutationFn = Apollo.MutationFunction<
  InsertXrayElementsMutation,
  InsertXrayElementsMutationVariables
>;

/**
 * __useInsertXrayElementsMutation__
 *
 * To run a mutation, you first call `useInsertXrayElementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertXrayElementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertXrayElementsMutation, { data, loading, error }] = useInsertXrayElementsMutation({
 *   variables: {
 *      elements: // value for 'elements'
 *   },
 * });
 */
export function useInsertXrayElementsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertXrayElementsMutation,
    InsertXrayElementsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertXrayElementsMutation, InsertXrayElementsMutationVariables>(
    InsertXrayElementsDocument,
    options
  );
}
export type InsertXrayElementsMutationHookResult = ReturnType<typeof useInsertXrayElementsMutation>;
export type InsertXrayElementsMutationResult = Apollo.MutationResult<InsertXrayElementsMutation>;
export type InsertXrayElementsMutationOptions = Apollo.BaseMutationOptions<
  InsertXrayElementsMutation,
  InsertXrayElementsMutationVariables
>;
export const UpdateXRayRetroBoneLevelDocument = gql`
  mutation UpdateXRayRetroBoneLevel($id: uuid!, $retroBoneLevelSegments: jsonb) {
    updateXray(pk_columns: { id: $id }, _set: { retroBoneLevels: $retroBoneLevelSegments }) {
      id
      retroBoneLevels
    }
  }
`;
export type UpdateXRayRetroBoneLevelMutationFn = Apollo.MutationFunction<
  UpdateXRayRetroBoneLevelMutation,
  UpdateXRayRetroBoneLevelMutationVariables
>;

/**
 * __useUpdateXRayRetroBoneLevelMutation__
 *
 * To run a mutation, you first call `useUpdateXRayRetroBoneLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXRayRetroBoneLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXRayRetroBoneLevelMutation, { data, loading, error }] = useUpdateXRayRetroBoneLevelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      retroBoneLevelSegments: // value for 'retroBoneLevelSegments'
 *   },
 * });
 */
export function useUpdateXRayRetroBoneLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateXRayRetroBoneLevelMutation,
    UpdateXRayRetroBoneLevelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateXRayRetroBoneLevelMutation,
    UpdateXRayRetroBoneLevelMutationVariables
  >(UpdateXRayRetroBoneLevelDocument, options);
}
export type UpdateXRayRetroBoneLevelMutationHookResult = ReturnType<
  typeof useUpdateXRayRetroBoneLevelMutation
>;
export type UpdateXRayRetroBoneLevelMutationResult =
  Apollo.MutationResult<UpdateXRayRetroBoneLevelMutation>;
export type UpdateXRayRetroBoneLevelMutationOptions = Apollo.BaseMutationOptions<
  UpdateXRayRetroBoneLevelMutation,
  UpdateXRayRetroBoneLevelMutationVariables
>;
export const UpdateXRayRetroRulerDocument = gql`
  mutation UpdateXRayRetroRuler($id: uuid!, $retroRulerSegments: jsonb) {
    updateXray(pk_columns: { id: $id }, _set: { retroRulerSegments: $retroRulerSegments }) {
      id
      retroRulerSegments
    }
  }
`;
export type UpdateXRayRetroRulerMutationFn = Apollo.MutationFunction<
  UpdateXRayRetroRulerMutation,
  UpdateXRayRetroRulerMutationVariables
>;

/**
 * __useUpdateXRayRetroRulerMutation__
 *
 * To run a mutation, you first call `useUpdateXRayRetroRulerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXRayRetroRulerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXRayRetroRulerMutation, { data, loading, error }] = useUpdateXRayRetroRulerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      retroRulerSegments: // value for 'retroRulerSegments'
 *   },
 * });
 */
export function useUpdateXRayRetroRulerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateXRayRetroRulerMutation,
    UpdateXRayRetroRulerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateXRayRetroRulerMutation, UpdateXRayRetroRulerMutationVariables>(
    UpdateXRayRetroRulerDocument,
    options
  );
}
export type UpdateXRayRetroRulerMutationHookResult = ReturnType<
  typeof useUpdateXRayRetroRulerMutation
>;
export type UpdateXRayRetroRulerMutationResult =
  Apollo.MutationResult<UpdateXRayRetroRulerMutation>;
export type UpdateXRayRetroRulerMutationOptions = Apollo.BaseMutationOptions<
  UpdateXRayRetroRulerMutation,
  UpdateXRayRetroRulerMutationVariables
>;
export const UpdateXRayTeethDocument = gql`
  mutation UpdateXRayTeeth($id: uuid!, $teeth: jsonb, $name: json) {
    updateXray(pk_columns: { id: $id }, _set: { teeth: $teeth, name: $name }) {
      name
    }
  }
`;
export type UpdateXRayTeethMutationFn = Apollo.MutationFunction<
  UpdateXRayTeethMutation,
  UpdateXRayTeethMutationVariables
>;

/**
 * __useUpdateXRayTeethMutation__
 *
 * To run a mutation, you first call `useUpdateXRayTeethMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXRayTeethMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXRayTeethMutation, { data, loading, error }] = useUpdateXRayTeethMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teeth: // value for 'teeth'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateXRayTeethMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateXRayTeethMutation,
    UpdateXRayTeethMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateXRayTeethMutation, UpdateXRayTeethMutationVariables>(
    UpdateXRayTeethDocument,
    options
  );
}
export type UpdateXRayTeethMutationHookResult = ReturnType<typeof useUpdateXRayTeethMutation>;
export type UpdateXRayTeethMutationResult = Apollo.MutationResult<UpdateXRayTeethMutation>;
export type UpdateXRayTeethMutationOptions = Apollo.BaseMutationOptions<
  UpdateXRayTeethMutation,
  UpdateXRayTeethMutationVariables
>;
export const UpdateXRayTeethAndPerioLineDocument = gql`
  mutation UpdateXRayTeethAndPerioLine(
    $id: uuid!
    $teeth: jsonb
    $detectedPeriodonticLines: jsonb
    $centralTeeth: [String!]
  ) {
    updateXray(
      pk_columns: { id: $id }
      _set: {
        teeth: $teeth
        detectedPeriodonticLines: $detectedPeriodonticLines
        centralTeeth: $centralTeeth
      }
    ) {
      ...xraysFields
    }
  }
  ${XraysFieldsFragmentDoc}
`;
export type UpdateXRayTeethAndPerioLineMutationFn = Apollo.MutationFunction<
  UpdateXRayTeethAndPerioLineMutation,
  UpdateXRayTeethAndPerioLineMutationVariables
>;

/**
 * __useUpdateXRayTeethAndPerioLineMutation__
 *
 * To run a mutation, you first call `useUpdateXRayTeethAndPerioLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXRayTeethAndPerioLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXRayTeethAndPerioLineMutation, { data, loading, error }] = useUpdateXRayTeethAndPerioLineMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teeth: // value for 'teeth'
 *      detectedPeriodonticLines: // value for 'detectedPeriodonticLines'
 *      centralTeeth: // value for 'centralTeeth'
 *   },
 * });
 */
export function useUpdateXRayTeethAndPerioLineMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateXRayTeethAndPerioLineMutation,
    UpdateXRayTeethAndPerioLineMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateXRayTeethAndPerioLineMutation,
    UpdateXRayTeethAndPerioLineMutationVariables
  >(UpdateXRayTeethAndPerioLineDocument, options);
}
export type UpdateXRayTeethAndPerioLineMutationHookResult = ReturnType<
  typeof useUpdateXRayTeethAndPerioLineMutation
>;
export type UpdateXRayTeethAndPerioLineMutationResult =
  Apollo.MutationResult<UpdateXRayTeethAndPerioLineMutation>;
export type UpdateXRayTeethAndPerioLineMutationOptions = Apollo.BaseMutationOptions<
  UpdateXRayTeethAndPerioLineMutation,
  UpdateXRayTeethAndPerioLineMutationVariables
>;
export const UpdateXrayDocument = gql`
  mutation UpdateXray(
    $id: uuid!
    $xray: xrays_set_input
    $elements: [elements_insert_input!]!
    $withElements: Boolean!
    $anatomies: [anatomies_insert_input!]!
    $withAnatomies: Boolean!
  ) {
    updateXray(pk_columns: { id: $id }, _set: $xray) {
      id
    }
    delete_elements(where: { xrayId: { _eq: $id } }) @include(if: $withElements) {
      returning {
        id
      }
    }
    insert_elements(objects: $elements) @include(if: $withElements) {
      returning {
        id
      }
    }
    delete_anatomies(where: { xrayId: { _eq: $id } }) @include(if: $withAnatomies) {
      returning {
        id
      }
    }
    insert_anatomies(objects: $anatomies) @include(if: $withAnatomies) {
      returning {
        id
      }
    }
  }
`;
export type UpdateXrayMutationFn = Apollo.MutationFunction<
  UpdateXrayMutation,
  UpdateXrayMutationVariables
>;

/**
 * __useUpdateXrayMutation__
 *
 * To run a mutation, you first call `useUpdateXrayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXrayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXrayMutation, { data, loading, error }] = useUpdateXrayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      xray: // value for 'xray'
 *      elements: // value for 'elements'
 *      withElements: // value for 'withElements'
 *      anatomies: // value for 'anatomies'
 *      withAnatomies: // value for 'withAnatomies'
 *   },
 * });
 */
export function useUpdateXrayMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateXrayMutation, UpdateXrayMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateXrayMutation, UpdateXrayMutationVariables>(
    UpdateXrayDocument,
    options
  );
}
export type UpdateXrayMutationHookResult = ReturnType<typeof useUpdateXrayMutation>;
export type UpdateXrayMutationResult = Apollo.MutationResult<UpdateXrayMutation>;
export type UpdateXrayMutationOptions = Apollo.BaseMutationOptions<
  UpdateXrayMutation,
  UpdateXrayMutationVariables
>;
export const UpdateXrayInfoDocument = gql`
  mutation UpdateXrayInfo($id: uuid!, $xray: xrays_set_input) {
    updateXray(pk_columns: { id: $id }, _set: $xray) {
      id
    }
  }
`;
export type UpdateXrayInfoMutationFn = Apollo.MutationFunction<
  UpdateXrayInfoMutation,
  UpdateXrayInfoMutationVariables
>;

/**
 * __useUpdateXrayInfoMutation__
 *
 * To run a mutation, you first call `useUpdateXrayInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateXrayInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateXrayInfoMutation, { data, loading, error }] = useUpdateXrayInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      xray: // value for 'xray'
 *   },
 * });
 */
export function useUpdateXrayInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateXrayInfoMutation, UpdateXrayInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateXrayInfoMutation, UpdateXrayInfoMutationVariables>(
    UpdateXrayInfoDocument,
    options
  );
}
export type UpdateXrayInfoMutationHookResult = ReturnType<typeof useUpdateXrayInfoMutation>;
export type UpdateXrayInfoMutationResult = Apollo.MutationResult<UpdateXrayInfoMutation>;
export type UpdateXrayInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateXrayInfoMutation,
  UpdateXrayInfoMutationVariables
>;
