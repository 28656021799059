import { Dentist } from '@allisone/react-components';
import { useEffect, useMemo } from 'react';

import { Role } from 'src/common/enums';
import { useGetLocationsQuery } from 'src/communication/hasura/generated/graphql';
import { useAppStore } from 'src/controller/store';

import { useBillings, useGetStripePortalUrls } from '../stripe';

export const useLocations = () => {
  const { data, loading, error } = useGetLocationsQuery();
  const { user } = useAppStore();
  const { data: dataStripe, loading: loadingStripe } = useGetStripePortalUrls();
  const { getBillings, billingsNotFiltered, billings } = useBillings();

  useEffect(() => {
    if (user) {
      getBillings();
    }
  }, [user]);

  return useMemo(() => {
    const locations = data?.locations
      .filter(({ locations_users }) =>
        locations_users?.find(
          ({ user: userLocation, isEnabled }) => userLocation.id === user?.id && isEnabled
        )
      )
      .map((location) => {
        const locationBilling = billingsNotFiltered?.find(
          ({ locationId }) => locationId === location.id
        );
        const locationBillingWhichUserIsPaymaster = billings?.find(
          ({ locationId }) => locationId === location.id
        );
        const isActive = locationBilling
          ? ['active', 'not_started'].includes(locationBilling.subscriptionStatus)
          : true;

        const dentists = location.locations_users?.map(({ user }) => {
          const roles = user.roles.map(({ role }) => Role[role.value as keyof typeof Role]);

          return { ...user, roles, selected: false } as Dentist;
        });

        return {
          id: location.id,
          title: location.title,
          address: location.address || '',
          cp: location.cp || '',
          city: location.city || '',
          type: location.type,
          phone: location.phone,
          dentists,
          locationUsers: location.locations_users,
          isPaymaster: !!locationBillingWhichUserIsPaymaster,
          paymasters: locationBilling?.paymasterName
            ? [locationBilling?.paymasterName]
            : locationBilling?.potentialPaymasterNames,
          paymentIsActive: location.type === 'center' || isActive,
          haveSubscription: dataStripe?.some(({ locationId }) => locationId === location.id),
          isTreatmentPreferencesEnabled: location.isTreatmentPreferencesEnabled,
        };
      });

    return {
      loading: loading || loadingStripe,
      error,
      locations: locations ?? null,
    };
  }, [data?.locations, loading, loadingStripe, error, dataStripe]);
};
