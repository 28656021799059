import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import { getRecentXrays, RecentXray } from 'src/communication/api/recentXrays';
import { getUser } from 'src/controller/Keycloak/keycloak';

import type { RecentDataContextValues } from '.';

const POLLING_INTERVAL = (import.meta.env.VITE_POLLING_INTERVAL as number) || 5000;

export const useRecentXrays = (
  locationId: number | undefined,
  onError: (error: any) => void
): Pick<RecentDataContextValues, 'recentXrays'> => {
  const [recentXrays, setRecentXrays] = useState<RecentXray[]>([]);

  const refreshRecentXrays = useCallback(async () => {
    if (!locationId) return;
    const newRecentXrays = await getRecentXrays(locationId);

    setRecentXrays((oldRecentXrays) => {
      if (isEqual(newRecentXrays, oldRecentXrays)) return oldRecentXrays;
      return [...newRecentXrays];
    });
  }, [locationId]);

  const callRefreshRecentXrays = async () => {
    // If the token expires in less than 60 seconds, don't call API
    // This is to avoid calling the API with an expired token
    // (in theory, the refresh token is already refreshed by react-oidc-context 1 min before the token expires,
    // however there are some edge cases where the token is not refreshed in time,
    // in particular when the browser tab was inactive)
    const user = getUser();
    const expiresSoon = user?.expires_at && user.expires_at * 1000 - Date.now() <= 60 * 1000;
    if (expiresSoon) return;

    try {
      await refreshRecentXrays();
    } catch (error) {
      console.error('Error fetching recent xrays', error);
      onError(error);
    }
  };

  // First call
  useEffect(() => {
    if (!locationId) return;
    const timeout = setTimeout(callRefreshRecentXrays, 250);
    return () => clearTimeout(timeout);
  }, [locationId]);

  // Periodically refresh recent xrays
  useEffect(() => {
    if (!locationId) return;
    const interval = setInterval(callRefreshRecentXrays, POLLING_INTERVAL);

    return () => clearInterval(interval);
  }, [locationId]);

  return {
    recentXrays,
  };
};
