import * as Sentry from '@sentry/react';
import axios from 'axios';

import { getAllowedRoles, getAuthTokens } from 'src/controller/Keycloak/keycloak';
import { getDefaultRole } from 'src/controller/user/User';

import { axiosGet } from './utils/axiosUtils';

export type RecentPatient = {
  id: number;
  createdAt: string;
  userId?: number;
};

export const getRecentPatients = async (locationId: number): Promise<RecentPatient[]> => {
  const authTokens = getAuthTokens() ?? undefined;
  const currentRoles = getAllowedRoles();
  const role = getDefaultRole(currentRoles);

  try {
    if (!authTokens || !role) throw new Error('Token or role not found');
    const { data } = await axiosGet({
      url: '/api/v2/internal/recentPatients',
      token: authTokens,
      role,
      params: { locationId },
    });

    return data;
  } catch (error) {
    // Do not send 401 errors to Sentry
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      throw error;
    }
    Sentry.captureException(error, { extra: { url: '/api/v2/internal/recentPatients' } });
    throw new Error(error as any);
  }
};
