export const FILTERED_PATIENTS = 'FILTERED_PATIENTS';
export const RETRY_LAZY_REFRESHED = 'retry-lazy-refreshed';
export const CONFIRM_MODAL_PANO = 'confirm-modal-pano';

// COMPLIANCE DOCUMENTS
export const PATH_PDF_CGU = 'allisone-cgu.pdf';
export const PATH_DPA_RESELLER = 'DPA_allisone_HSO.pdf';
export const PATH_DPA_NO_RESELLER = 'DPA_allisone.pdf';

type DefaultUrls = {
  diagnosticUrl?: string;
  treatmentPlanUrl?: string;
};

export type LocalPartnerConfig = {
  name: string;
  defaultUrls: DefaultUrls;
};

export const localPartners: LocalPartnerConfig[] = [
  {
    name: 'logosw',
    defaultUrls: {
      diagnosticUrl: 'http://localhost:57203/patient/diagnostic',
    },
  },
  {
    name: 'julie',
    defaultUrls: {},
  },
];

export const imageKeyPrefix = {
  LARGE: 'radios/large/',
  MEDIUM: 'radios/medium/',
  SMALL: 'radios/small/',
};

export const DPAResellers = ['KOPFWERK_AUSTRIA', 'ORISLINE_PORTUGAL', 'ORISLINE_ITALY'];

export const O1Numbers = [
  '18',
  '17',
  '16',
  '15',
  '14',
  '13',
  '12',
  '11',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
];

export const O2Numbers = [
  '48',
  '47',
  '46',
  '45',
  '44',
  '43',
  '42',
  '41',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
];
